@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    Map
============================================================================================
*/

#search-map-container {
    display: inline-block;
    padding: 5px;
}

#search-map-left-column {
    display: inline-block;
}

#search-map {
    display: inline-block;
    overflow: hidden;
    border-radius: 10px;
    /* This fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

#search-map-small {
    display: inline-block;
    overflow: hidden;
    //border-radius: 10px;
    /* This fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

/*
============================================================================================
    Custom Markers
============================================================================================
*/

.search-map-marker {
    position: absolute !important;
    width: 0px !important;
    height: 0px !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.search-map-marker-hovered {
    border: solid 10px rgba(0, 0, 0, 0.3) !important;
}

.search-map-marker-name {
    position: absolute !important;
    top: 65px;
    left: 0px;
    border-radius: 12px;
    padding: 5px 12px;
    color: $white;
    transform: translate(-50%, 0%);
    background-color: rgba(0, 0, 0, 0.65);
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
}

.search-map-marker-name-off {
    opacity: 0.0;
    z-index: 1;
}

.search-map-marker-name-on {
    opacity: 1.0;
    z-index: 2;
}

.search-map-marker-media {
    position: absolute !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    border: solid 1px #ddd !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transform: translate(-50%, -50%);
    //-webkit-transition: transform 0.15s ease-in-out;
    //-moz-transition: transform 0.15s ease-in-out;
    //-o-transition: transform 0.15s ease-in-out;
    //transition: transform 0.15s ease-in-out;
    -webkit-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    -moz-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    -o-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
}

.search-map-marker-media-off {
    z-index: 1;
    width: 60px !important;
    height: 60px !important;
    //transform: scale(1.0);
}

.search-map-marker-media-on {
    z-index: 2;
    width: 120px !important;
    height: 120px !important;
    //transform: scale(2.0);
}