@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Day Info
============================================================================================
*/

.dayinfo{
	text-align: center;
}

.dayinfo{
	display: inline-block;
	height: 40px;
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
	border:solid 0px #F2F2F2;
	color: #888;
	font-size: 16px;
	line-height: 16px;
	overflow: hidden;
}

.dayinfo-weather {
	display: inline-block;
	vertical-align: middle;
	height: 40px;
	padding: 0px 0px 0px 0px;
	margin: 0px 4px 0px 0px;
	line-height: 18px;
	overflow: hidden;
}

.dayinfo-weather-icon {
	width: 36px;
	height: 36px;
	padding: 0px;
	margin: 2px 4px 2px 2px;
	float: left;
}

.dayinfo-weather-precip {
	height: 40px;
	padding: 0px 0px 0px 0px;
	margin: 0px 8px 0px 0px;
	float: left;
	overflow: hidden;
}

.dayinfo-weather-temp {
	height: 40px;
	padding: 0px;
	margin: 0px;
	float: left;
	display: block;
	overflow: hidden;
}

.dayinfo-weather-precip-type,
.dayinfo-weather-precip-prob,
.dayinfo-weather-temp-high,
.dayinfo-weather-temp-low {
	height: 20px;
	line-height: 20px;
}

.dayinfo-weather p {
	padding: 0px;
	margin: 2px 0px 2px 0px;
}

.dayinfo-sun {
	display: inline-block;
	vertical-align: middle;
	height: 40px;
	padding: 0px;
	margin: 0px 8px 0px 0px;
	overflow: hidden;
}

.dayinfo-sunrise {
	height: 20px;
	padding: 0px;
	margin: 0px;
	float: left;
	line-height: 20px;
	clear: both;
	overflow: hidden;
}

.dayinfo-sunrise span {
	margin-top: 8px;
}

.dayinfo-sunrise-icon {
	width: 36px;
	height: 18px;
	padding: 0px;
	margin: 1px 4px 1px 2px;
	float: left;
}

.dayinfo-sunset {
	height: 20px;
	padding: 0px;
	margin: 0px;
	float: left;
	clear: both;
	line-height: 20px;
	overflow: hidden;
}

.dayinfo-sunset span {
	margin-bottom: 0px;
}

.dayinfo-sunset-icon {
	width: 36px;
	height: 18px;
	padding: 0px;
	margin: 1px 4px 1px 2px;
	float: left;
}

.dayinfo-moon {
	display: inline-block;
	vertical-align: middle;
	height: 40px;
	padding: 0px;
	margin: 0px 0px 0px 0px;
	line-height: 38px;
	overflow: hidden;
}

.dayinfo-moon-icon {
	width: 22px;
	height: 22px;
	padding: 0px;
	margin: 9px 5px 9px 1px;
	float: left;
}

.dayinfo-moon span{
}