@import "../../css/_variables";
@import "../../css/_mixins";


.trip-plan-time-bar {
	/*
	width: 100%;
	height: 16px;
	margin: 0px;
	border-radius: 10px;
	border: solid 2px #207DA3;
	*/
    width: 100%;
    height: 12px;
    margin: 0px;
    border-radius: 10px;
    border: solid 1px #333;
    opacity: 0.7;
}

#trip-plan-time-bar-container {
    width: 720px;
    margin: 16px auto 0px auto;
}

#trip-plan-time-bar-tooltip {
	padding: 0px;
}
#trip-plan-time-bar-tooltip-content {
	padding: 0px;
}

#trip-plan-time-bar-tooltip-number {
	display: inline-block;
	vertical-align: middle;
	width: 26px;
	height: 26px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#trip-plan-time-bar-tooltip-transit {
	display: inline-block;
	vertical-align: middle;
	width: 30px;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#trip-plan-time-bar-tooltip-text {
	display: inline-block;
	vertical-align: middle;
	padding: 1px 0px 1px 4px;
	font-size: 14px;
}