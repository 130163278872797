@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
   Main Image
============================================================================================
*/

#upload-gallery {
    margin-bottom: 25px;
}

#upload-gallery-warning {
    padding: 16px;
}

#upload-gallery-media-container {
	margin: 0px auto;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    overflow: hidden;
}

#upload-gallery-media-loader {
    background-size: 64px 64px;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 2.0s ease-in-out;
    -webkit-transition: all 2.0s ease-in-out;
    -moz-transition: all 2.0s ease-in-out;
    -o-transition: all 2.0s ease-in-out;
}

#upload-gallery-media-image {
    margin: 0px auto;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#upload-gallery-media-video {
    margin: 0px auto;
}

#upload-gallery-media-video video {
    border-radius: 10px;
}


/*
============================================================================================
   Thumbnails
============================================================================================
*/

#upload-gallery-thumbnails-container {
    margin: 5px 0px;
}

#upload-gallery-thumbnails {
    transition: opacity 0.25s ease-in-out;   
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
}

.upload-gallery-thumbnail-container {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0px;
}

.upload-gallery-thumbnail {
	position: relative;
	display: inline-block;
	vertical-align: middle;
    margin: 0px 5px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.upload-gallery-thumbnail-content {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.upload-gallery-thumbnail:hover,
.upload-gallery-thumbnail:focus {
    transform: scale(1.05);
    -ms-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.upload-gallery-thumbnail-prev {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 10px 5px 0px;
    cursor: pointer;
}

.upload-gallery-thumbnail-next {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0px 5px 10px;
    cursor: pointer;
}

.upload-gallery-thumbnail-left-button {
    position: absolute;
    bottom: 4px;
    left: 4px;
    cursor: pointer;
}

.upload-gallery-thumbnail-right-button {
    position: absolute;
    bottom: 4px;
    right: 4px;
    cursor: pointer;
}

.upload-gallery-thumbnail-left-button:hover,
.upload-gallery-thumbnail-right-button:hover {
    opacity: 0.8;
}

.upload-gallery-thumbnail-video {
    background-size: 48px 48px;
    background-position: center;
    background-repeat: no-repeat;
}

.upload-gallery-thumbnail-video-mobile {
    background-size: 36px 36px;
    background-position: center;
    background-repeat: no-repeat;
}


/*
============================================================================================
   Add and remove image buttons
============================================================================================
*/

#upload-gallery-add-buttons {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    margin: 5px 0px;
}

#upload-gallery-add-image-container {
	vertical-align: middle;
    width: 54px;
    height: 54px;
    margin: 0px 10px 0px 20px;
}

#upload-gallery-add-image-container-medium {
    vertical-align: middle;
    width: 49px;
    height: 49px;
    margin: 0px 7.5px 0px 15px;
}

#upload-gallery-add-image-container-small {
    vertical-align: middle;
    width: 44px;
    height: 44px;
    margin: 0px 5px 0px 10px;
}

#upload-gallery-add-image {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#upload-gallery-add-video-container {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    margin: 0px 20px 0px 10px;
}

#upload-gallery-add-video-container-medium {
    vertical-align: middle;
    width: 45px;
    height: 45px;
    margin: 0px 15px 0px 7.5px;
}

#upload-gallery-add-video-container-small {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 5px;
}

#upload-gallery-add-video {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload-gallery-remove-media-button {
	position: absolute;
	top: 4px;
	right: 4px;
}

.upload-gallery-remove-media-button:hover {
    opacity: 0.8;
}


/*
============================================================================================
   Add and remove image buttons
============================================================================================
*/

#upload-gallery-search-container {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

#upload-gallery-search {
    display: inline-block;
    vertical-align: middle;
}

#upload-gallery-search-input {
    width: 180px;
    display: inline-block;
    vertical-align: middle;
}

#upload-gallery-search-fetch-button {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
}

#upload-gallery-search-clear-button {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
}

#upload-gallery-search-results-container {
    width: 100%;
    text-align: center;
}

#upload-gallery-search-results {
    text-align: left;
    margin: 0px auto 10px auto;
}

.upload-gallery-search-results {
    width: 520px;
}

.upload-gallery-search-results-medium {
    width: 390px;
}

.upload-gallery-search-results-small {
    min-width: 300px;
    width: calc(100vw - 20px);
}


/*
============================================================================================
   Bucket Item List
============================================================================================
*/

.upload-gallery-search-result-item-selected {
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0%, -50%);
    text-align: center;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.65);
}

.upload-gallery-search-result-item-loader {
    display: inline-block;
    position: relative;
    width: 120px !important;
    height: 120px !important;
    margin: 5px;
}

.upload-gallery-search-result-item-loader-small {
    display: inline-block;
    position: relative;
    min-width: 136px;
    min-height: 136px;
    width: calc(46vw - 10px) !important;
    height: calc(46vw - 10px) !important;
    margin: 2vw;
}

.upload-gallery-search-result-item-image, 
.upload-gallery-search-result-item-video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    object-fit: cover;
}

.upload-gallery-search-result-item-video {
    background-size: 48px !important;
}

.upload-gallery-search-result-item-shadow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.upload-gallery-search-result-item-shadow-day, 
.upload-gallery-search-result-item-shadow-night,
.upload-gallery-search-result-item-shadow-hover-day,
.upload-gallery-search-result-item-shadow-hover-night {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out; 
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.upload-gallery-search-result-item-shadow-day {
    opacity: 0.0;
    background-color: rgba(0,0,0, 0.35);
}

.upload-gallery-search-result-item-shadow-night {
    opacity: 0.0;
    background-color: rgba(0,0,0, 0.50);
}

.upload-gallery-search-result-item-shadow-hover-day {
    opacity: 1.0;
    background-color: rgba(0,0,0, 0.35);
}

.upload-gallery-search-result-item-shadow-hover-night {
    opacity: 1.0;
    background-color: rgba(0,0,0, 0.50);
}

.upload-gallery-search-result-add-button {
    opacity: 0.0 !important;
    float: right;
    margin: 6px;
}

.upload-gallery-search-result-remove-button {
    opacity: 0.0 !important;
    float: right;
    margin: 6px;
}

.upload-gallery-search-result-item-shadow:hover > .upload-gallery-search-result-add-button,
.upload-gallery-search-result-item-shadow:hover > .upload-gallery-search-result-remove-button {
    opacity: 0.85 !important;
}


/*
============================================================================================
    Search Results Pagination
============================================================================================
*/

.upload-gallery-search-results-pages-container {
    text-align: center;
    padding-bottom: 10px;
}

.upload-gallery-search-results-pages {
    display: inline-block;
    vertical-align: middle;
}

.upload-gallery-search-results-page {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
}

.upload-gallery-search-results-page:hover {
    opacity: 1.0;
}

.upload-gallery-search-results-previous,
.upload-gallery-search-results-next {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 6px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.3;
}

.upload-gallery-search-results-previous:hover,
.upload-gallery-search-results-next:hover {
    opacity: 0.6;
}
