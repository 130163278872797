@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    Search Results
============================================================================================
*/

#search-results-container {
    width: 100%;
    min-width: 320px;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
}

.search-results-container {
    padding-top: 72px;
}

.search-results-container-small {
    padding-top: 62px;
}

.search-results-partition {
    width: 100%;
    margin-bottom: 15px;
    margin: 0 auto;
}

#search-results-map-header {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    width: 60px;
}

#search-results-map-header-small {
    display: block;
    vertical-align: top;
    margin-top: 5px;
    width: 60px;
}

#search-results-map {
    width: 100%;
    margin-bottom: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#search-no-results {
    width: 100%;
    padding: 15px;
    text-align: center;
}

/*
============================================================================================
    Results
============================================================================================
*/

#search-results-header {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    width: 60px;
}

#search-results-header-small {
    display: block;
    text-align: center;
    height: 40px;
}

.search-results-mode-button {
    display: inline-block;
    cursor: pointer;
    opacity: 0.6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.search-results-mode-button:hover {
    display: inline-block;
    cursor: pointer;
    opacity: 1.0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.search-results-icon {
    display: block;
    width: 36px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0px auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.search-results-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#search-results-text {
    display: block;
    margin-bottom: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#search-results-text-small {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 15px 0px 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#search-results {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

#search-results-padding{
    display: inline-block;
}

.search-results-item-container {
    display: inline-block;
}

.search-results-item-day,
.search-results-item-night {
    margin: 5px;
    border: 1px solid #464646;
    border-radius: 10px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.search-results-item-day-hover,
.search-results-item-night-hover {
    margin: 5px;
    border: 1px solid #464646;
    border-radius: 10px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.search-results-item-day:hover {
    border: 1px solid #4EA3D0;
    border-radius: 10px;
}

.search-results-item-day-hover {
    border: 1px solid #4EA3D0;
    border-radius: 10px;
}

.search-results-item-night:hover {
    border: 1px solid #256486;
    border-radius: 10px;
}

.search-results-item-night-hover {
    border: 1px solid #256486;
    border-radius: 10px;
}

.search-results-item-title {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding-bottom: 10px;
    text-align: center;
}

.search-results-item-name {
    display: block;
    width: 100%;
    padding: 0px 10px 2px 10px;
}

.search-results-item-shadow-day,
.search-results-item-shadow-night,
.search-results-item-shadow-day:hover,
.search-results-item-shadow-night:hover {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    opacity: 1.0;
}

.search-results-item-shadow-day {
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* Standard syntax (must be last) */
}


.search-results-item-shadow-night {
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* Standard syntax (must be last) */
}


/*
============================================================================================
    Dot Results
============================================================================================
*/

#search-results-dots-header {
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
    width: 60px;
}

#search-results-dots-header-small {
    display: block;
    text-align: center;
    height: 40px;
}

.search-results-dots-icon {
    display: block;
    width: 36px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0px auto;
}

.search-results-dots-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#search-results-dots-text {
    display: block;
    margin-bottom: 10px;
}

#search-results-dots-text-small {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 20px 0px 10px;}

#search-results-dots {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

#search-results-dots-padding{
    display: inline-block;
}

.search-results-dot-day,
.search-results-dot-night {
    display: inline-block;
    margin: 5px;
    border: 1px solid #464646;
    border-radius: 10px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.search-results-dot-day:hover {
    border: 1px solid #4EA3D0;
    border-radius: 10px;
}

.search-results-dot-night:hover {
    border: 1px solid #256486;
    border-radius: 10px;
}

.search-results-dot-title {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding-bottom: 10px;
    text-align: center;
}

.search-results-dot-name {
    display: block;
    width: 100%;
    padding: 0px 10px 2px 10px;
}

.search-results-dot-shadow-day,
.search-results-dot-shadow-night,
.search-results-dot-shadow-day:hover,
.search-results-dot-shadow-night:hover {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    opacity: 1.0;
}

.search-results-dot-shadow-day {
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* Standard syntax (must be last) */
}


.search-results-dot-shadow-night {
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* Standard syntax (must be last) */
}


/*
============================================================================================
    Trip Results
============================================================================================
*/

#search-results-trips-header {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    width: 60px;
}

#search-results-trips-header-small {
    display: block;
    text-align: center;
    height: 40px;
}

.search-results-trips-icon {
    display: block;
    width: 36px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0px auto;
}

.search-results-trips-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#search-results-trips-text {
    display: block;
    margin-bottom: 10px;
}

#search-results-trips-text-small {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 20px 0px 10px;
}

#search-results-trips {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.search-results-trip-day,
.search-results-trip-night {
    display: inline-block;
    margin: 5px;
    border: 1px solid #464646;
    border-radius: 10px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.search-results-trip-day:hover {
    border: 1px solid #4EA3D0;
    border-radius: 10px;
}

.search-results-trip-night:hover {
    border: 1px solid #256486;
    border-radius: 10px;
}

.search-results-trip-title {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding-bottom: 10px;
    text-align: center;
}

.search-results-trip-name {
    display: block;
    width: 100%;
    padding: 0px 10px 2px 10px;
}

.search-results-trip-shadow-day,
.search-results-trip-shadow-night,
.search-results-trip-shadow-day:hover,
.search-results-trip-shadow-night:hover {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    opacity: 1.0;
}

.search-results-trip-shadow-day {
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.25) 70%, rgba(0,0,0,0.5) 100%); /* Standard syntax (must be last) */
}

.search-results-trip-shadow-night {
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* Standard syntax (must be last) */
}

/*
============================================================================================
    User Results
============================================================================================
*/

#search-results-users-header {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    width: 60px;
}

#search-results-users-header-small {
    display: block;
    text-align: center;
    height: 40px;
}

.search-results-users-icon {
    display: block;
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0px auto;
}

.search-results-users-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#search-results-users-text {
    display: block;
    margin-bottom: 10px;
}

#search-results-users-text-small {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 20px 0px 10px;
}

#search-results-users {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.search-results-user {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
}

.search-results-user-pic {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.search-results-user-info {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    padding-left: 10px;
}

.search-results-user-name {
    width: 100%;
    margin-bottom: 5px;
}

/*
============================================================================================
    Search Results Pagination
============================================================================================
*/

.search-results-pages-container {
    text-align: center;
    padding-bottom: 10px;
}

.search-results-pages {
    display: inline-block;
    vertical-align: middle;
}

.search-results-page {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
}

.search-results-page:hover {
    opacity: 1.0;
}

.search-results-previous,
.search-results-next {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 6px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.3;
}

.search-results-previous:hover,
.search-results-next:hover {
    opacity: 0.6;
}


/*
============================================================================================
   Pagination
============================================================================================
*/

.search-results-pages-container {
    text-align: center;
    padding-bottom: 10px;
}

.search-results-pages {
    display: inline-block;
    vertical-align: middle;
}

.search-results-page {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
}

.search-results-page:hover {
    opacity: 1.0;
}

.search-results-previous,
.search-results-next {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 6px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.3;
}

.search-results-previous:hover,
.search-results-next:hover {
    opacity: 0.6;
}
