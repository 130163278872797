@import "../../css/_variables";
@import "../../css/_mixins";


.alert-warning {
    @include alert-basic-center;
    padding: 20px 50px 20px 30px;
}

.alert-warning-day {
    @include alert-warning-center-day;
}

.alert-warning-night {
    @include alert-warning-center-night;
}
