@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Map
============================================================================================
*/

.map-container {
    width: 100%;
    padding: 12px 24px;
}

.map {
    width: 100%;
    box-sizing: content-box;
    overflow: hidden;
    position: relative;
    /* Not sure the following is needed */
    /* This fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); 
} 

.map-buttons {
    width: 24px;
    margin: 8px;
    padding: 0px;
    cursor: pointer;
}

.map-button-increase {
    cursor: pointer;
    width: 24px;
    height: 36px;
    clear: right;
    float: right;
    padding: 0px;
    margin: 0px 0px 0px 0px;
    opacity: 0.75;
    background-size: contain;
    cursor: pointer;
}

.map-button-increase:hover {
    opacity: 1.0;
}

.map-button-decrease {
    cursor: pointer;
    width: 24px;
    height: 36px;
    clear: right;
    float: right;
    padding: 0px;
    margin-top: 8px;
    opacity: 0.75;
    background-size: contain;
    cursor: pointer;
}

.map-button-decrease:hover {
    opacity: 1.0;
}


/* 
============================================================================================
    Markers
============================================================================================
*/

.marker {
    border-radius: 50%;
    padding: 0px;
    margin: 0px;
}
.hike-marker {
    padding: 0px;
    margin: 0px;
}
.temp-marker {
    padding: 0px;
    margin: 0px;
}

.marker-name, .temp-marker-name {
    width: 100px;
    padding: 4px;
    margin: 0px;
    position: absolute;
    bottom: 38px;
    left: -34px;
    border-radius: 12px;
    font-size: 12px;
    font-family: "FBook", "Helvetica Neue", "HelveticaNeue", "Helvetica", Arial, Sans-Serif;
    text-align: center;
    line-height: 14px;
    opacity: 0.85;
    z-index: 1;
}

.marker-name {
    border: solid 1px #888;
    color: #000;
    font-weight: 600;
    background: #FFF;
}

.temp-marker-name {
    border: solid 1px #FFF;
    color: #FFF;
    font-weight: 500;
    background: #444;
}


/*
============================================================================================
    Marker Factors (top: 38px; left: -22px)
============================================================================================
*/

.marker-factors {
    width: 72px;
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 38px;
    left: -22px;
    opacity: 0.85;
    z-index: 1;
}

.marker-scenic-rating, .marker-scenic-rating-end {
    height: 6px;
    padding: 0px;
    margin: 0px;
    background-color: #249CCE;
}

.marker-scenic-rating {
    border-radius: 3px 0px 0px 3px;  
}

.marker-scenic-rating-end {
    border-radius: 3px 3px 3px 3px;  
}

.marker-scenic-rating-wrapper {
    width: 72px;
    border: solid 1px #CCC;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 3px 0px;
    background: #000;
    border-radius: 4px;  
}

.marker-difficulty, .marker-difficulty-end {
    height: 6px;
    padding: 0px;
    margin: 0px;
    /*background-color: #D31A16;*/
    background-color: #C72723;
}

.marker-difficulty {
    border-radius: 3px 0px 0px 3px;  
}

.marker-difficulty-end {
    border-radius: 3px 3px 3px 3px;  
}

.marker-difficulty-wrapper {
    width: 72px;
    border: solid 1px #CCC;
    padding: 0px;
    margin: 0px;
    background: #000;
    border-radius: 4px;
}

.marker-rating, .marker-rating-end {
    height: 6px;
    padding: 0px;
    margin: 0px;
    background-color: #C5833B;
}

.marker-rating {
    border-radius: 3px 0px 0px 3px;  
}

.marker-rating-end {
    border-radius: 3px 3px 3px 3px;  
}

.marker-rating-wrapper {
    width: 72px;
    border: solid 1px #CCC;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 3px 0px;
    background: #000;
    border-radius: 4px;  
}


/*
============================================================================================
    Regular Marker Pulse
============================================================================================
*/

.pulse {
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: pulse;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}

@keyframes pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16,1.0), 0 0 0 15px rgba(175,20,16, 0.25); 
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(175,20,16,0.25), 0 0 0 0px rgba(175,20,16, 1.0);
        box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);
        box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);
        box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}

.pulse-hover {
    border-radius: 50%;
    cursor: pointer; 
    box-shadow: 0 0 0px 8px rgba(28,100,138, 0.4);
    box-shadow: 0 0 0px 8px rgba(255,255,255, 0.4);
    box-shadow: 0 0 0px 8px rgba(175,20,16, 0.4);
}


/*
============================================================================================
    Temporary Marker Pulse
============================================================================================
*/

.temp-pulse {
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: temp-pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: temp-pulse;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;  
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes temp-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(98,98,98, 1.0), 0 0 0 15px rgba(98,98,98, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(98,98,98, 0.75), 0 0 0 20px rgba(98,98,98, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(98,98,98, 0.5), 0 0 0 0px rgba(98,98,98, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(98,98,98, 0.25), 0 0 0 0px rgba(98,98,98, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(98,98,98, 0.0), 0 0 0 5px rgba(98,98,98, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(98,98,98, 0.0), 0 0 0 10px rgba(98,98,98, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(98,98,98, 1.0), 0 0 0 15px rgba(98,98,98, 0.25);}
}

@keyframes temp-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(98,98,98, 1.0), 0 0 0 15px rgba(98,98,98, 0.25);
        box-shadow: 0 0 0 0px rgba(98,98,98, 1.0), 0 0 0 15px rgba(98,98,98, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(98,98,98, 0.75), 0 0 0 20px rgba(98,98,98, 0.0);
        box-shadow: 0 0 0 5px rgba(98,98,98, 0.75), 0 0 0 20px rgba(98,98,98, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(98,98,98, 0.5), 0 0 0 0px rgba(98,98,98, 0.0);
        box-shadow: 0 0 0 10px rgba(98,98,98, 0.5), 0 0 0 0px rgba(98,98,98, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(98,98,98, 0.25), 0 0 0 0px rgba(98,98,98, 1.0);
        box-shadow: 0 0 0 15px rgba(98,98,98, 0.25), 0 0 0 0px rgba(98,98,98, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(98,98,98, 0.0), 0 0 0 5px rgba(98,98,98, 0.75);
        box-shadow: 0 0 0 20px rgba(98,98,98, 0.0), 0 0 0 5px rgba(98,98,98, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(98,98,98, 0.0), 0 0 0 10px rgba(98,98,98, 0.5);
        box-shadow: 0 0 0 0px rgba(98,98,98, 0.0), 0 0 0 10px rgba(98,98,98, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(98,98,98, 1.0), 0 0 0 15px rgba(98,98,98, 0.25);
        box-shadow: 0 0 0 0px rgba(98,98,98, 1.0), 0 0 0 15px rgba(98,98,98, 0.25);}
}

.temp-pulse-hover {
    border-radius: 50%;
    cursor: pointer; 
    box-shadow: 0 0 0px 8px rgba(98,98,98, 0.5);
}


/*
============================================================================================
    Hike Marker Pulse
============================================================================================
*/

.hike-pulse {
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: hike-pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: hike-pulse;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes hike-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(33,33,33, 1.0), 0 0 0 15px rgba(33,33,33, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(33,33,33, 0.75), 0 0 0 20px rgba(33,33,33, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(33,33,33, 0.5), 0 0 0 0px rgba(33,33,33, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(33,33,33, 0.25), 0 0 0 0px rgba(33,33,33,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(33,33,33, 0.0), 0 0 0 5px rgba(33,33,33, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(33,33,33, 0.0), 0 0 0 10px rgba(33,33,33, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(33,33,33, 1.0), 0 0 0 15px rgba(33,33,33, 0.25);}
}

@keyframes hike-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(33,33,33, 1.0), 0 0 0 15px rgba(33,33,33, 0.25);
        box-shadow: 0 0 0 0px rgba(33,33,33, 1.0), 0 0 0 15px rgba(33,33,33, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(33,33,33, 0.75), 0 0 0 20px rgba(33,33,33, 0.0);
        box-shadow: 0 0 0 5px rgba(33,33,33, 0.75), 0 0 0 20px rgba(33,33,33, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(33,33,33, 0.5), 0 0 0 0px rgba(33,33,33, 0.0);
        box-shadow: 0 0 0 10px rgba(33,33,33, 0.5), 0 0 0 0px rgba(33,33,33, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(33,33,33, 0.25), 0 0 0 0px rgba(33,33,33,1.0);
        box-shadow: 0 0 0 15px rgba(33,33,33, 0.25), 0 0 0 0px rgba(33,33,33,1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(33,33,33, 0.0), 0 0 0 5px rgba(33,33,33, 0.75);
        box-shadow: 0 0 0 20px rgba(33,33,33, 0.0), 0 0 0 5px rgba(33,33,33, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(33,33,33, 0.0), 0 0 0 10px rgba(33,33,33, 0.5);
        box-shadow: 0 0 0 0px rgba(33,33,33, 0.0), 0 0 0 10px rgba(33,33,33, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(33,33,33, 1.0), 0 0 0 15px rgba(33,33,33, 0.25);
        box-shadow: 0 0 0 0px rgba(33,33,33, 1.0), 0 0 0 15px rgba(33,33,33, 0.25);}
}

.hike-pulse-hover {
    border-radius: 50%;
    cursor: pointer; 
    box-shadow: 0 0 0px 8px rgba(33,33,33, 0.5);
}


/*
============================================================================================
    Trip Overview Marker Pulse
============================================================================================
*/

.pulse-overview {
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: pulse-overview;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: pulse-overview;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes pulse-overview {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}

@keyframes pulse-overview {
    0% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0,1.0), 0 0 0 15px rgba(0,0,0, 0.25); 
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(0,0,0,0.25), 0 0 0 0px rgba(0,0,0, 1.0);
        box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);
        box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);
        box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}

.pulse-overview-hover {
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0px 8px rgba(0,0,0, 0.3);
}
