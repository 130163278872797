@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    SignUp
============================================================================================
*/

#sign-up-modal-container {
    @include modal-background;
}

#sign-up-modal {
    @include modal-minimal;
    width: 340px;
}

#sign-up-modal-mobile {
    @include modal-minimal;
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 340px;
}

.sign-up-row {
    width: 100%;
    box-sizing: border-box;
}

#sign-up-or {
    padding: 10px;
    text-align: center;
}


/* 
============================================================================================
    Facebook and Google Sign Up
============================================================================================
*/

#sign-up-facebook-container,
#sign-up-google-container {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
}

#sign-up-facebook-container-active, 
#sign-up-google-container-active {
    width: 720px;
    margin: 0px auto;
    text-align: center;  
}

#sign-up-facebook-button {
    width: 68px;
    height: 68px;
    margin-right: 10px;  
    float: right;
}

#sign-up-facebook-image {
    width: 100%;
    height: 100%;
}

#sign-up-google-image {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    float: left;
}

#sign-up-facebook-google-warning {
    padding: 0px 40px;
}


/* 
============================================================================================
    Sign Up Forms
============================================================================================
*/

#sign-up-form-container {
    width: 100%;
}

.sign-up-form {
    box-sizing: border-box;
    text-align: center;
    margin: 0px auto;
}

.sign-up-form-row {
    width: 100%;
    height: 40px;
    text-align: center;
    padding: 2px 0px 2px 0px;
}

.sign-up-form-text {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    text-align: right;
    font-size: 16px;
    padding-right: 16px;
}

.sign-up-form-input {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

#sign-up-email, 
#sign-up-password-once, #sign-up-password-twice, 
#sign-up-first-name, #sign-up-last-name, 
#sign-up-username, #sign-up-area {
    width: 180px;
    margin: 1px 0px 1px 0px;
}

.sign-up-warning {
    padding: 3px 30px 5px 20px;
}

#sign-up-username {
}

#sign-up-password-once {
}

#sign-up-password-twice {
}

#sign-up-first-name {

}
#sign-up-last-name {
}

#sign-up-email {
}

#sign-up-area {
    position: relative;
    height: "auto";
    resize: none;
}

#sign-up-form-submit-container {
    width: 100%;
    padding: 10px 0px 0px 0px;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

#sign-up-form-submit {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 6px;
    border: none;
}
