@import "./_variables";
@import "./_mixins";


/* 
============================================================================================
  	Project Dots
--------------------------------------------------------------------------------------------
  	Font.css
============================================================================================
  	Content
    - Various fonts
============================================================================================
*/


/* 
============================================================================================
    Cabin Fonts 
============================================================================================
*/

@font-face {
    font-family: "Cabin-Regular";
    src: url("./../fonts/Cabin/Cabin-Regular.ttf");
}

@font-face {
    font-family: "Cabin-Medium";
    src: url("./../fonts/Cabin/Cabin-Medium.ttf");
}

@font-face {
    font-family: "Cabin-SemiBold";
    src: url("./../fonts/Cabin/Cabin-SemiBold.ttf");
}

@font-face {
    font-family: "Cabin-Bold";
    src: url("./../fonts/Cabin/Cabin-Bold.ttf");
}


/* 
============================================================================================
    Catamaran Fonts 
============================================================================================
*/

@font-face {
    font-family: "Catamaran-Regular";
    src: url("./../fonts/Catamaran/Catamaran-Regular.ttf");
}

@font-face {
    font-family: "Catamaran-Medium";
    src: url("./../fonts/Catamaran/Catamaran-Medium.ttf");
}

@font-face {
    font-family: "Catamaran-Light";
    src: url("./../fonts/Catamaran/Catamaran-Light.ttf");
}

@font-face {
    font-family: "Catamaran-SemiBold";
    src: url("./../fonts/Catamaran/Catamaran-SemiBold.ttf");
}

@font-face {
    font-family: "Catamaran-Bold";
    src: url("./../fonts/Catamaran/Catamaran-Bold.ttf");
}


/* 
============================================================================================
    Century Fonts
============================================================================================
*/

@font-face {
    font-family: "Century-Gothic";
    src: url("./../fonts/Century/Century-Gothic.ttf");
}


/* 
============================================================================================
    Circular Fonts 
============================================================================================
*/

@font-face {
    font-family: "Circular-Book";
    src: url("./../fonts/Circular/Circular-Book.ttf");
}

@font-face {
    font-family: "Circular-Book-Italic";
    src: url("./../fonts/Circular/Circular-Book-Italic.ttf");
}

@font-face {
    font-family: "Circular-Medium";
    src: url("./../fonts/Circular/Circular-Medium.ttf");
}

@font-face {
    font-family: "Circular-Medium-Italic";
    src: url("./../fonts/Circular/Circular-Medium-Italic.ttf");
}

@font-face {
    font-family: "Circular-Bold";
    src: url("./../fonts/Circular/Circular-Bold.ttf");
}

@font-face {
    font-family: "Circular-Bold-Italic";
    src: url("./../fonts/Circular/Circular-Bold-Italic.ttf");
}

@font-face {
    font-family: "Circular-Black";
    src: url("./../fonts/Circular/Circular-Black.ttf");
}

@font-face {
    font-family: "Circular-Black-Italic";
    src: url("./../fonts/Circular/Circular-Black-Italic.ttf");
}


/*
============================================================================================
    Coustard Fonts 
============================================================================================
*/

@font-face {
    font-family: "Coustard-Regular";
    src: url("./../fonts/Coustard/Coustard-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Coustard-Black";
    src: url("./../fonts/Coustard/Coustard-Black.ttf");
    font-weight: normal;
    font-style: normal;
}


/* 
============================================================================================
    DidactGothic Fonts 
============================================================================================
*/

@font-face {
    font-family: "Didact-Gothic";
    src: url("./../fonts/DidactGothic/DidactGothic.ttf");
}


/*
============================================================================================
    JuliusSansOne Gothic Fonts 
============================================================================================
*/

@font-face {
    font-family: "JuliusSansOne";
    src: url("./../fonts/JuliusSansOne/JuliusSansOne.ttf");
}


/* 
============================================================================================
    Lato Fonts 
============================================================================================
*/

@font-face {
    font-family: "Lato-Regular";
    src: url("./../fonts/Lato/Lato-Regular.ttf");
}

@font-face {
    font-family: "Lato-Light";
    src: url("./../fonts/Lato/Lato-Light.ttf");
}

@font-face {
    font-family: "Lato-Black";
    src: url("./../fonts/Lato/Lato-Black.ttf");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("./../fonts/Lato/Lato-Bold.ttf");
}


/*
============================================================================================
    Manrope Fonts 
============================================================================================
*/

@font-face {
    font-family: "Manrope-Regular";
    src: url("./../fonts/Manrope/Manrope-Regular.ttf");
}


/* 
============================================================================================
    Merriweather Fonts 
============================================================================================
*/

@font-face {
    font-family: "Merriweather-Regular";
    src: url("./../fonts/Merriweather/Merriweather-Regular.ttf");
}

@font-face {
    font-family: "Merriweather-Black";
    src: url("./../fonts/Merriweather/Merriweather-Black.ttf");
}

@font-face {
    font-family: "Merriweather-Light";
    src: url("./../fonts/Merriweather/Merriweather-Light.ttf");
}

@font-face {
    font-family: "Merriweather-Bold";
    src: url("./../fonts/Merriweather/Merriweather-Bold.ttf");
}


/* 
============================================================================================
    Montserrat Fonts 
============================================================================================
*/

@font-face {
    font-family: "Montserrat-Regular";
    src: url("./../fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("./../fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
    font-family: "Montserrat-Light";
    src: url("./../fonts/Montserrat/Montserrat-Light.ttf");
}

@font-face {
    font-family: "Montserrat-Black";
    src: url("./../fonts/Montserrat/Montserrat-Black.ttf");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: url("./../fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("./../fonts/Montserrat/Montserrat-Bold.ttf");
}


/* 
============================================================================================
    Nunito Fonts 
============================================================================================
*/

@font-face {
    font-family: "Nunito-Regular";
    src: url("./../fonts/Nunito/Nunito-Regular.ttf");
}

@font-face {
    font-family: "Nunito-Light";
    src: url("./../fonts/Nunito/Nunito-Light.ttf");
}

@font-face {
    font-family: "Nunito-Black";
    src: url("./../fonts/Nunito/Nunito-Black.ttf");
}

@font-face {
    font-family: "Nunito-SemiBold";
    src: url("./../fonts/Nunito/Nunito-SemiBold.ttf");
}

@font-face {
    font-family: "Nunito-Bold";
    src: url("./../fonts/Nunito/Nunito-Bold.ttf");
}


/*
============================================================================================
    Oxygen Fonts 
============================================================================================
*/

@font-face {
    font-family: "Oxygen-Regular";
    src: url("./../fonts/Oxygen/Oxygen-Regular.ttf");
    font-weight: normal;
    font-style: normal;    
}

@font-face {
    font-family: "Oxygen-Light";
    src: url("./../fonts/Oxygen/Oxygen-Light.ttf");
    font-weight: normal;
    font-style: normal;    
}

@font-face {
    font-family: "Oxygen-Bold";
    src: url("./../fonts/Oxygen/Oxygen-Bold.ttf");
    font-weight: normal;
    font-style: normal;    
}


/* 
============================================================================================
    Poppins Fonts 
============================================================================================
*/

@font-face {
    font-family: "Poppins-Regular";
    src: url("./../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("./../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("./../fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("./../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("./../fonts/Poppins/Poppins-Bold.ttf");
}



/*
============================================================================================
    Questrial Fonts 
============================================================================================
*/

@font-face {
    font-family: "Questrial-Regular";
    src: url("./../fonts/Questrial/Questrial-Regular.ttf");
}


/*
============================================================================================
    Quicksand Fonts 
============================================================================================
*/

@font-face {
    font-family: "Quicksand-Regular";
    src: url("./../fonts/Quicksand/Quicksand-Regular.ttf");
}

@font-face {
    font-family: "Quicksand-Medium";
    src: url("./../fonts/Quicksand/Quicksand-Medium.ttf");
}

@font-face {
    font-family: "Quicksand-Light";
    src: url("./../fonts/Quicksand/Quicksand-Light.ttf");
}

@font-face {
    font-family: "Quicksand-Bold";
    src: url("./../fonts/Quicksand/Quicksand-Bold.ttf");
}


/*
============================================================================================
    Nanum Fonts 
============================================================================================
*/

@font-face {
    font-family: "NanumBarunGothic";
    src: url("./../fonts/Nanum/NanumBarunGothic.ttf");
}

@font-face {
    font-family: "NanumBarunGothicBold";
    src: url("./../fonts/Nanum/NanumBarunGothicBold.ttf");
}

@font-face {
    font-family: "NanumBarunGothicLight";
    src: url("./../fonts/Nanum/NanumBarunGothicLight.ttf");
}

@font-face {
    font-family: "NanumBarunGothicUltraLight";
    src: url("./../fonts/Nanum/NanumBarunGothicUltraLight.ttf");
}

@font-face {
    font-family: "NanumBarunpenRegular";
    src: url("./../fonts/Nanum/NanumBarunpenRegular.ttf");
}

@font-face {
    font-family: "NanumBarunpenBold";
    src: url("./../fonts/Nanum/NanumBarunpenBold.ttf");
}

@font-face {
    font-family: "NanumBrush";
    src: url("./../fonts/Nanum/NanumBrush.ttf");
}

@font-face {
    font-family: "NanumGothic";
    src: url("./../fonts/Nanum/NanumGothic.ttf");
}

@font-face {
    font-family: "NanumGothicBold";
    src: url("./../fonts/Nanum/NanumGothicBold.ttf");
}

@font-face {
    font-family: "NanumGothicExtraBold";
    src: url("./../fonts/Nanum/NanumGothicExtraBold.ttf");
}

@font-face {
    font-family: "NanumGothicLight";
    src: url("./../fonts/Nanum/NanumGothicLight.ttf");
}

@font-face {
    font-family: "NanumMyeongjo";
    src: url("./../fonts/Nanum/NanumMyeongjo.ttf");
}

@font-face {
    font-family: "NanumMyeongjoBold";
    src: url("./../fonts/Nanum/NanumMyeongjoBold.ttf");
}

@font-face {
    font-family: "NanumMyeongjoExtraBold";
    src: url("./../fonts/Nanum/NanumMyeongjoExtraBold.ttf");
}

@font-face {
    font-family: "NanumSquareBold";
    src: url("./../fonts/Nanum/NanumSquareBold.ttf");
}

@font-face {
    font-family: "NanumSquareExtraBold";
    src: url("./../fonts/Nanum/NanumSquareExtraBold.ttf");
}

@font-face {
    font-family: "NanumSquareLight";
    src: url("./../fonts/Nanum/NanumSquareLight.ttf");
}

@font-face {
    font-family: "NanumSquareRegular";
    src: url("./../fonts/Nanum/NanumSquareRegular.ttf");
}

@font-face {
    font-family: "NanumSquareRoundB";
    src: url("./../fonts/Nanum/NanumSquareRoundB.ttf");
}

@font-face {
    font-family: "NanumSquareRoundEB";
    src: url("./../fonts/Nanum/NanumSquareRoundEB.ttf");
}

@font-face {
    font-family: "NanumSquareRoundL";
    src: url("./../fonts/Nanum/NanumSquareRoundL.ttf");
}

@font-face {
    font-family: "NanumSquareRoundR";
    src: url("./../fonts/Nanum/NanumSquareRoundR.ttf");
}
