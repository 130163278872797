@import "../../css/_variables";
@import "../../css/_mixins";

/*
============================================================================================
    Search Input
============================================================================================
*/

.search-bar-form {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}

.search-bar-input-menu {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 30px);
}

.search-bar-input {
    display: inline-block;
    vertical-align: middle;
    width: 240px;
}

.search-bar-input-small {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
}

#search-bar-input {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px !important;
    /*transform: scale( 0.875, 0.875 );
    -webkit-transform: scale( 0.875, 0.875 );
    -moz-transform: scale( 0.875, 0.875 );
    -ms-transform: scale( 0.875, 0.875 );
    -o-transform: scale( 0.875, 0.875 );*/
}

.search-bar-icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 10px;
}