@import "../../css/_variables";
@import "../../css/_mixins";

.schedule-modal {
    @include modal-background;
}

.schedule-modal-content {
    @include modal-minimal;
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 700px;
    max-height: calc(100% - 20px);
    padding-bottom: 0px !important;
    overflow: hidden;
}

.schedule-modal-content__title {
    padding: 15px 50px 0px 50px;
}


/*
============================================================================================
    Modal Layout
============================================================================================
*/

#schedule-modal-title {

}

#schedule-modal-dayinfo {

}

#schedule-modal-spacer {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
}

#schedule-modal-dayinfo-small {
	margin: 5px 0px;
	padding: 0px 15px;
}

#schedule-modal-unscroll {
	position: relative;
	padding-bottom: 10px;
}

#schedule-modal-scroll {
	overflow-y: scroll;
}

#schedule-modal-scroll {
	scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

#schedule-modal-scroll::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

#schedule-modal-map {
	width: 100%;
}

#schedule-modal-time-bar {
	width: 100%;
	padding: 0px 20px;
    margin-top: 20px;
}

#schedule-modal-time-bar-small {
	width: 100%;
	padding: 0px 10px;
    margin-top: 20px;
}

#schedule-modal-time-header {
    margin: 25px 0px 10px 0px;
}

#schedule-modal-header {

}

#schedule-modal-summary {

}



/*
============================================================================================
    Date / DayInfo
============================================================================================
*/

#schedule-date-dayinfo-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
}

#schedule-date-dayinfo {
	text-align: center;
}

#schedule-date-column,
#schedule-date-column-small,
#schedule-dayinfo-column {
	display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 5px;
}

#schedule-date-column {
    font-size: 28px;
    cursor: pointer;    
}

#schedule-date-column-small {
    font-size: 26px;
    cursor: pointer;    
}

#schedule-date-title, 
#schedule-dayinfo-title {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 6px;
    text-align: center;
}

#schedule-dayinfo {
    text-align: center;
}


/*
============================================================================================
    Title / Name
============================================================================================
*/

#schedule-title-container {
    text-align: center;
    padding: 0px 10px;
}


#schedule-title {
    display: inline-block;
    margin: 0px 4px;
}

#schedule-area {
    display: inline-block;
    margin: 0px 4px;
}


/*
============================================================================================
    Map
============================================================================================
*/

#schedule-map {
    width: 100%;
    height: 100%;
}

#schedule-map-title {
    padding-bottom: 16px;
    text-align: center;
}

#schedule-map-container {
    width: 100%;
    margin: 0px auto;
}

#schedule-map-wrapper {
    overflow: hidden;
}



/*
============================================================================================
    Time Bar
============================================================================================
*/

.schedule-time-bar-container {
    width: 100%;
}

.schedule-time-bar {
	width: 100%;
    height: 10px;
    margin: 0px;
    border-radius: 10px;
    //border: solid 1px #333;
    opacity: 0.7;
}

.schedule-time-bar-tooltip {
	padding: 0px;
}

.schedule-time-bar-tooltip-content {
	padding: 0px;
}

.schedule-time-bar-tooltip-number {
	display: inline-block;
	vertical-align: middle;
	width: 26px;
	height: 26px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.schedule-time-bar-tooltip-transit {
	display: inline-block;
	vertical-align: middle;
	width: 30px;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.schedule-time-bar-tooltip-text {
	display: inline-block;
	vertical-align: middle;
	padding: 1px 0px 1px 4px;
	font-size: 14px;
}


/*
============================================================================================
    Item Time
============================================================================================
*/

.schedule-time-header {
	position: relative;
	width: 100%;
	white-space: nowrap;
}

.schedule-time-header-left,
.schedule-time-header-right {
	position: relative;
	width: 50%;
	height: 80px;
	display: inline-block;
	vertical-align: middle;
	background-size: 1200px 60px;
	background-repeat: no-repeat;
	background-position: center;

}

.schedule-time-header-transit-mode-left,
.schedule-time-header-transit-mode-right {
	position: absolute;
	width: 40px;
	height: 40px;
	bottom: 50%;
    transition: initial !important;
}

.schedule-time-header-transit-mode-left {
    left: 50%;
    transform: translate(-50%, 0px)
}

.schedule-time-header-transit-mode-right {
	right: 50%;
    transform: translate(50%, 0px)
}

.schedule-time-header-transit-time-left,
.schedule-time-header-transit-time-right {
	position: absolute;
	height: 30px;
	line-height: 30px;
	top: 50%;
}

.schedule-time-header-transit-time-left {
    left: 50%;
    transform: translate(-50%, 0px)
}

.schedule-time-header-transit-time-right {
    right: 50%;
    transform: translate(50%, 0px)
}

.schedule-time-header-number {
	position: absolute;
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}

.schedule-time-header-timestamp-container {
	position: absolute;
	width: 100px;
	left: calc(50% - 50px);
	bottom: calc(50% + 28px);
	text-align: center;
}

.schedule-time-header-timestamp-day, 
.schedule-time-header-timestamp-night {
	display: inline-block;
    padding: 1px 8px;
    border-radius: 15px;
    font-size: 14px;
    opacity: 0.8;
}

.schedule-time-header-timestamp-day {
    color: $white;
    background-color: $light-blue;
}

.schedule-time-header-timestamp-night {
    color: $black;
    background-color: $blue;
}

.schedule-time-header-duration {
	position: absolute;
	width: 100px;
	left: calc(50% - 50px);
	top: calc(50% + 22px);
	text-align: center;
}

.schedule-time-header-prev-item {
	position: absolute;
	top: -6px;
	left: 20px;
}

.schedule-time-header-prev-item-small {
	position: absolute;
	top: -6px;
	left: 10px;
}

.schedule-time-header-next-item {
	position: absolute;
	top: -6px;
	right: 20px;
}

.schedule-time-header-next-item-small {
	position: absolute;
	top: -6px;
	right: 10px;
}

.schedule-time-header-start {
	position: absolute;
	height: 30px;
	line-height: 30px;
	top: -6px;
	left: 20px;
}

.schedule-time-header-start-small {
	position: absolute;
	height: 30px;
	line-height: 30px;
	top: -6px;
	left: 10px;
}

.schedule-time-header-end {
	position: absolute;
	height: 30px;
	line-height: 30px;
	top: -6px;
	right: 20px;
}

.schedule-time-header-end-small {
	position: absolute;
	height: 30px;
	line-height: 30px;
	top: -6px;
	right: 10px;
}


/*
============================================================================================
    Start Button
============================================================================================
*/

#schedule-start-button-container {
    margin-top: 15px;
    text-align: center;
}

.schedule-start-button {
    border-radius: 15px;
    padding: 2px 14px;
}


/*
============================================================================================
    Item
============================================================================================
*/

.schedule-summary {
	padding: 0px 20px;
}

.schedule-stats {
	padding: 0px 20px;
}



/*
============================================================================================
    Item
============================================================================================
*/

.schedule-item-content {
	//padding-bottom: 20px;
	text-align: left;
}

.schedule-item-header {
	display: block;
	padding: 0px 20px;
	margin-bottom: 6px;
}

.schedule-item-header-small {
	display: block;
    text-align: center;
	padding: 0px 10px;
	margin-bottom: 15px;
}

.schedule-item-title {
    padding: 2px 0px;
}

.schedule-item-title-small {
    padding: 2px 0px;
    margin: 0px 4px;
}

.schedule-item-dots {
    margin-top: 15px;
}

.schedule-item-dots-icon {
	vertical-align: middle;
	width: 24px;
	height: 24px;
	margin: 0px 11px 0px 1px;
}

.schedule-item-dots-text {
	display: inline-block;
	vertical-align: middle;
}

.schedule-item-time {
    padding-bottom: 5px;
}

.schedule-item-time-icon {
	vertical-align: middle;
	width: 20px;
	height: 20px;
	margin: 0px 13px 0px 3px;
}

.schedule-item-time-text {
	display: inline-block;
	vertical-align: middle;
}

.schedule-item-time-line {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
    height: 20px;
    margin: 5px 10px 0px 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 600px 30px;
}

.schedule-item-name {
    padding: 2px 0px;
    margin-right: 8px;
}

.schedule-item-name-small {
    padding: 2px 0px;
    margin: 0px 4px;
}

.schedule-item-curation {
	padding: 0px 20px 30px 20px;
}

.schedule-item-curation-small {
	padding: 0px 10px 30px 10px;
}


/*
============================================================================================
    Gallery
============================================================================================
*/
.schedule-item-gallery {
	display: inline-block;
    margin: 0px 20px 20px 20px;
    float: right;
}

.schedule-item-gallery-small {
	display: block;
	text-align: center;
    margin-bottom: 10px;
}

.schedule-item-gallery-container {
	display: inline-block;
    position: relative;
    text-align: center;
}

.schedule-item-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.schedule-item-gallery-wrapper-square {
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}

.schedule-item-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    pointer-events: none;
}

.schedule-item-gallery-video {}

.schedule-item-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.schedule-item-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Gallery Navigation
============================================================================================
*/

.schedule-item-gallery-prev-arrow, 
.schedule-item-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.schedule-item-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.schedule-item-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.schedule-item-gallery-prev-arrow:hover,
.schedule-item-gallery-next-arrow:hover {
    opacity: 0.85;
}

.schedule-item-gallery-video:hover ~ .schedule-item-gallery-prev-arrow,
.schedule-item-gallery-video:hover ~ .schedule-item-gallery-next-arrow,
.schedule-item-gallery-image:hover ~ .schedule-item-gallery-prev-arrow,
.schedule-item-gallery-image:hover ~ .schedule-item-gallery-next-arrow {    
    opacity: 0.5;
}

.schedule-item-gallery-dot-on,
.schedule-item-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.schedule-item-gallery-dot-on {
    opacity: 0.8;
}

.schedule-item-gallery-dot-off {
    opacity: 0.4;
}

.schedule-item-gallery-dot-off:hover {
    opacity: 0.8;
}

.schedule-item-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    text-align: center;
    z-index: 1;
}


/*
============================================================================================
    Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.schedule-item-gallery-dot-tag-image, 
.schedule-item-gallery-user-tag-image,
.schedule-item-gallery-unsave-image,
.schedule-item-gallery-enlarge-image,
.schedule-item-gallery-dot-tag-video,
.schedule-item-gallery-user-tag-video,
.schedule-item-gallery-unsave-video,
.schedule-item-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.schedule-item-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.schedule-item-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.schedule-item-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.schedule-item-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.schedule-item-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.schedule-item-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.schedule-item-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.schedule-item-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.schedule-item-gallery-dot-tag-image:hover,
.schedule-item-gallery-user-tag-image:hover,
.schedule-item-gallery-unsave-image:hover,
.schedule-item-gallery-enlarge-image:hover,
.schedule-item-gallery-dot-tag-video:hover,
.schedule-item-gallery-user-tag-video:hover,
.schedule-item-gallery-unsave-video:hover,
.schedule-item-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.schedule-item-gallery-video:hover ~ .schedule-item-gallery-dot-tag-video,
.schedule-item-gallery-video:hover ~ .schedule-item-gallery-user-tag-video,
.schedule-item-gallery-video:hover ~ .schedule-item-gallery-unsave-video,
.schedule-item-gallery-video:hover ~ .schedule-item-gallery-enlarge-video,
.schedule-item-gallery-image:hover ~ .schedule-item-gallery-dot-tag-image,
.schedule-item-gallery-image:hover ~ .schedule-item-gallery-user-tag-image,
.schedule-item-gallery-image:hover ~ .schedule-item-gallery-unsave-image,
.schedule-item-gallery-image:hover ~ .schedule-item-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/*
============================================================================================
    Curation
============================================================================================
*/

.schedule-item-curation-highlight {
}

.schedule-item-curation-regular {
}

.schedule-item-curation-tab {
}

.schedule-item-curation-tabs {
    margin-bottom: 15px;
    text-align: center;
}

.schedule-item-curation-tabs-small {
    margin-bottom: 10px;
    text-align: center;
}


/*
============================================================================================
    Direction
============================================================================================
*/

#direction-background {
    display: block;
    position: fixed;
    z-index: 45;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

#direction-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 20px);
    max-height: 100%;
    min-width: 320px;
    max-width: 500px;
    padding: 20px 10px;
    z-index: 46;
    transform: translate(-50%, -50%);
    border-radius: 20px;
}

#direction-cancel-button {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 25px !important;
    height: 25px !important;
    margin: 15px 15px 0px 0px;
}

#direction-scroll {
    overflow-y: scroll;
}

#direction-scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

#direction-scroll::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

#direction-title {
    text-align: center;
    margin-bottom: 6px;
}

#direction-stats {
    text-align: center;
    padding-bottom: 6px;
}

#direction-stats-divider {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 10px;
}

#direction-distance {
    display: inline-block;
    vertical-align: middle;
}

#direction-duration {
    display: inline-block;
    vertical-align: middle;
}

#direction-items {}

.direction-item {
    max-width: 450px;
    text-align: left;
    padding: 5px;
    margin: 0px auto;
}

.direction-item-image {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.direction-item-instruction {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 125px);
}

.direction-item-distance {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    text-align: center;
    margin-left: 10px;
}
