@import "../../css/_variables";
@import "../../css/_mixins";


.globe-wrapper-day,
.globe-wrapper-night {
    width: 100%;
    margin: 0px auto;
    padding: 0px 0px 20px 0px;
    text-align: center;
}

.globe-wrapper-day {
    background: $almost-white;
}

.globe-wrapper-night {
    background: $black;
}

.globe-path-day, 
.globe-path-night {
    stroke-width: 0.5;
}

.globe-path-day {
    fill: $lightest-gray;
    stroke: $light-gray;
}

.globe-path-night {
    fill: $darkest-gray;
    stroke: $dark-gray;
}

.globe-wrapper {
    display: inline-block;
    position: relative;
}

.globe-background-day, 
.globe-background-night {
    display: inline-block;
    border-radius: 50%;
}

.globe-background-day {
    background: $close-to-white;
}

.globe-background-night {
    background: $almost-black;  
}

.globe-mode-container {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.globe-mode-day,
.globe-mode-night {
    //position: absolute;
    //display: inline-block;
    //top: -42px;
    //left: 50%;
    //transform: translate(-50%, 0px);
    display: inline-block;
    border-radius: 10px;
    padding: 2px 10px;
    opacity: 0.4;
    cursor: pointer;
}

.globe-mode-day {
    background-color: $light-blue;
}

.globe-mode-night {
    background-color: $blue;
}

.globe-mode-day:hover,
.globe-mode-night:hover {
    opacity: 0.6;
}

.globe-count {
    position: absolute;
    top: 0px;
    right: calc(100% - 26px);
    font-size: 32px;
    opacity: 0.6;
}

.globe-drag-button {
    position: absolute;
    top: 0px;
    right: 0px;
}

.globe-drag-button:hover {
}

