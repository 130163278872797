/* Legacy variables */
$background: #000;
$boxShadow: #000000;
$border: #464646;
$borderRadius: 10px;


/* Window size settings */
$min_window_size: 310px;

/* Device size settings */
$device_size_a: 411px;
$device_size_b: 710px;
$device_size_c: 830px;
$device_size_d: 950px;
$device_size_e: 1120px;


/* Basic Colors */

$white: #FFFFFF;
$almost-white: #F8F8F8;
$close-to-white: #DDDDDD;
$black: #000000;
$almost-black: #101010;
$close-to-black: #222222;
$closer-to-black: #181818;

$gray: #888888;
$darkest-gray: #333333;
$dark-gray: #555555;
$dim-gray: #707070;
$light-gray: #AAAAAA;
$lightest-gray: #CCCCCC;

$red: #950000;
$light-red: #B30000;
$dark-red: #840000;

$orange: #B35E09;
$light-orange: #D87109;
$dark-orange: #964F07;

$blue: #3284B0;
$light-blue: #4EA3D0;
$lightest-blue: #55B3E5;
$faint-blue: #92CDEC;
$dark-blue: #216C95;

$gold: #A79C22;


/* Components based colors */

/* Borders */
$border-day: #AAAAAA;
$border-night: #333333;

/* Notification */
$notification-day: #AD1F1B;
$notification-night: #8F0400;

/* Dot Preview Editor Info */
$menu-frame-day: #CFCFCF;
$menu-frame-night: #303030;
