@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    General
============================================================================================
*/

.body {
    padding-bottom: 50px;
}


/*
============================================================================================
    Layout
============================================================================================
*/

#dot-container {}

.dot-container {
    padding-top: 62px;
}

.dot-container-small {
    padding-top: 52px;
}

#dot-more-cotainer {
    float: right;
}

#dot-preview {
    width: 100%;
    padding-top: 8px;
    text-align: center;
    display: block;
    overflow: visible;
}

#dot-preview-left {
    width: 160px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

#dot-preview-left-mobile {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.dot-preview-left-mobile-column {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#dot-preview-middle {
    margin: 0px 10px;
    display: inline-block;
    vertical-align: top;
}

#dot-preview-right {
    width: 160px;
    display: inline-block;
    vertical-align: top;
}

#dot-preview-right-mobile {
    display: inline-block;
    vertical-align: middle;
}

.dot-preview-right-mobile-column {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#dot-preview-narrow {
    width: 100%;
    min-width: 320px;
    margin-top: 16px;
    white-space: nowrap;
    overflow: visible;
}


/*
============================================================================================
    Dot Numbers
============================================================================================
*/

.dot-preview-numbers-container {
    margin-bottom: 12px;
}

.dot-preview-numbers-title {
    padding: 6px;
    text-align: center;
}

#dot-preview-numbers-rating {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#dot-preview-numbers-rating-mobile {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#dot-preview-numbers-rating-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}

#dot-preview-numbers-difficulty {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#dot-preview-numbers-difficulty-mobile {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#dot-preview-numbers-difficulty-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}


/*
============================================================================================
    Completed Users
============================================================================================
*/

.dot-preview-completed-users-title {
}

.dot-preview-completed-users {
}

.dot-preview-completed-users-count {
}

.dot-preview-completed-user-profile-pics {
}

.dot-preview-completed-user-profile-pic {
}

.dot-preview-completed-users-more {
}

.dot-preview-completed-users-short {
}


/*
============================================================================================
    Dot Intro
============================================================================================
*/

#dot-preview-intro {
}

#dot-preview-title-container {
    margin-bottom: 12px;
}

#dot-preview-title {
    display: inline-block;
    vertical-align: middle;
    padding: 2px 0px;
    margin: 0px 4px;
}

#dot-preview-name {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 4px;
}

#dot-preview-overview {
    text-align: left;
}


/* 
============================================================================================
    Dot Like / Share / Save Buttons
============================================================================================
*/

#dot-interaction-box {
    width: 100%;
    margin-top: 16px;
}

#dot-like-share-container {
    margin-left: 4px;
    float: left;
}

#dot-save-container {
    margin-right: 4px;
    float: right;
}

#dot-like-button-on, 
#dot-like-button-off,
#dot-share-button,
#dot-save-button-on,
#dot-save-button-off {
    vertical-align: middle;
}

#dot-like-button-on,
#dot-like-button-off,
#dot-share-button {
    margin-right: 6px;
}

#dot-save-button-on,
#dot-save-button-off {
}

#dot-like-button-on,
#dot-save-button-on {
    opacity: 0.8 !important;
}

#dot-like-button-off,
#dot-share-button,
#dot-save-button-off {
}

#dot-like-button-off:hover,
#dot-share-button:hover,
#dot-save-button-off:hover {
}

#dot-save-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    line-height: 32px;
}

#dot-like-count {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    margin-right: 8px;
}

#dot-more-button {
    cursor: pointer;
    width: 20px;
    height: 30px;
    margin-left: 6px;
}


/*
============================================================================================
    Curation
============================================================================================
*/

.dot-preview-overview-container,
.dot-preview-todos-container,
.dot-preview-history-container,
.dot-preview-stories-container,
.dot-preview-overview-container-expanded,
.dot-preview-todos-container-expanded,
.dot-preview-history-container-expanded,
.dot-preview-stories-container-expanded,
.dot-preview-overview-container-folded,
.dot-preview-todos-container-folded,
.dot-preview-history-container-folded,
.dot-preview-stories-container-folded {

}

.dot-preview-overview-container,
.dot-preview-todos-container,
.dot-preview-history-container,
.dot-preview-stories-container {
}

.dot-preview-overview-container-expanded,
.dot-preview-todos-container-expanded,
.dot-preview-history-container-expanded,
.dot-preview-stories-container-expanded {
}

.dot-preview-overview-container-folded,
.dot-preview-todos-container-folded,
.dot-preview-history-container-folded,
.dot-preview-stories-container-folded {
    overflow: hidden;
}

.dot-preview-overview-container-less,
.dot-preview-todos-container-less,
.dot-preview-history-container-less,
.dot-preview-stories-container-less,
.dot-preview-overview-container-more,
.dot-preview-todos-container-more,
.dot-preview-history-container-more,
.dot-preview-stories-container-more {
    width: 100%;
    height: 14px;
    cursor: pointer;
    margin-top: 8px;
}

.dot-preview-overview-container-less,
.dot-preview-todos-container-less,
.dot-preview-history-container-less,
.dot-preview-stories-container-less {
    background-size: auto 6px !important;
}

.dot-preview-overview-container-more,
.dot-preview-todos-container-more,
.dot-preview-history-container-more,
.dot-preview-stories-container-more {
    background-size: auto 14px !important;
}


/*
============================================================================================
    Bottle
============================================================================================
*/

.dot-bottle {
    text-align: center;
    margin-bottom: 30px;
    white-space: nowrap;
}

.dot-bottle-user-count {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    white-space: nowrap;
    text-align: center;
}

.dot-bottle-user-profile-pics {
    display: inline-block;
    vertical-align: middle;
}

.dot-bottle-user-left,
.dot-bottle-user-middle,
.dot-bottle-user-right {
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    background-repeat: no-repeat;
    background-size: auto 60px;
}

.dot-bottle-user-left {
    width: 93px;
    padding: 8px 3px 8px 46px;
    background-position: right;
}
.dot-bottle-user-middle {
    width: 50px;
    padding: 8px 3px;
    background-position: center;
}
.dot-bottle-user-right {
    width: 55px;
    padding: 8px 8px 8px 3px;
    background-position: left;
}

.dot-bottle-speakerphone {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -4px;
    right: -4px;
    opacity: 1.0;
    cursor: pointer;
}

.dot-bottle-user-profile-pic {
    position: relative;
}


/*
============================================================================================
    Map
============================================================================================
*/

#dot-map-container {
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto;
    overflow: hidden;
}

#dot-map-wrapper {
    border-radius: 10px;
    overflow: hidden;
}

#dot-map-title {
    padding-bottom: 16px;
    text-align: center;
}

#dot-map-static {
    position: absolute;
    top: -210px;
    left: calc(50% - 360px);
}

#dot-map-static-wrapper {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
}


/*
============================================================================================
    Editor
============================================================================================
*/

#dot-preview-editor {
}

#dot-preview-editor-title {
    padding-bottom: 8px;
    text-align: center;
}

#dot-preview-editor-profile-pic-loader {
    width: 120px;
    height: 120px;
    margin: 0px auto 2px auto;
}

#dot-preview-editor-profile-pic-loader-medium {
    width: 80px;
    height: 80px;
}

#dot-preview-editor-profile-pic-loader-small {
    width: 64px;
    height: 64px;
}

#dot-preview-editor-profile-pic {
    width: 100%;
    height: 100%;
}

#dot-preview-editor-name {
    padding-bottom: 6px;
    text-align: center;
    cursor: pointer;
}

#dot-preview-editor-follow-button-container {
}

#dot-preview-editor-follow-button-on,
#dot-preview-editor-follow-button-off {
    width: 72px;
    margin: 0px auto 6px auto;
}

#dot-preview-editor-follow-button-on {
    opacity: 1.0 !important;
}

#dot-preview-editor-followers {
    padding-bottom: 4px;
    text-align: center;
}

#dot-preview-editor-follow-button:hover {
    opacity: 1.0;
}

#dot-preview-editor-buttons-container {
    margin-bottom: 15px;
    position: relative;
}

#dot-preview-editor-buttons-container-mobile {
    position: relative;
}

#dot-preview-editor-buttons {
    width: 69px;
    height: 20px;
    margin: 0px auto;
}

#dot-preview-editor-chat-button-on,
#dot-preview-editor-chat-button-off,
#dot-preview-editor-calendar-button-on,
#dot-preview-editor-calendar-button-off,
#dot-preview-editor-info-button-on,
#dot-preview-editor-info-button-off {
    width: 23px;
    height: 20px;
}

#dot-preview-editor-chat-button-on,
#dot-preview-editor-calendar-button-on,
#dot-preview-editor-info-button-on {
    opacity: 0.8 !important;
}


/*
============================================================================================
    Editor Information
============================================================================================
*/

#dot-preview-editor-info {
    width: 140px;
    height: 200px;
    position: absolute;
    top: 100px;
    right: -140px;
    border-radius: 10px;
    text-align: center;
    z-index: 5;
    transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
}

.dot-preview-editor-info-day {
    background-color: $menu-frame-day;
}

.dot-preview-editor-info-night {
    background-color: $menu-frame-night;
}

#dot-preview-editor-info-triangle {
    width: 50px;
    height: 20px;
    position: absolute;
    top: 0px;
    right: -10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
}


/*
============================================================================================
    Editor Information Window
============================================================================================
*/
#dot-preview-editor-info-published, 
#dot-preview-editor-info-guided {
    padding: 16px 0px 6px 0px;
    margin-bottom: 6px;
}

.dot-preview-editor-info-published-day,
.dot-preview-editor-info-guided-day {
    border-bottom: solid 1px $white;
}

.dot-preview-editor-info-published-night,
.dot-preview-editor-info-guided-night {
    border-bottom: solid 1px $black;
}

#dot-preview-editor-info-published-dots {
}

.dot-preview-editor-info-published-number {
}

.dot-preview-editor-info-published-title {
}


/*
============================================================================================
    Staff (Contributors and Helpers)
============================================================================================
*/

#dot-preview-staff {
    padding-bottom: 15px;
    margin: 0px auto;
}

#dot-preview-staff-spacer {
    width: 100%;
    height: 15px;
}

.dot-preview-contributors, .dot-preview-helpers {
    margin-bottom: 15px;
    text-align: center;
}

.dot-preview-contributors-count, .dot-preview-helpers-count {
    padding: 0px 6px 6px 6px;
    white-space: nowrap;
    text-align: center;
}

.dot-preview-contributor-profile-pics, .dot-preview-helper-profile-pics {
}

.dot-preview-contributor-profile-pic, .dot-preview-helper-profile-pic {
    margin: 0px 2px;
}

.dot-preview-contributors-more, .dot-preview-helpers-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

.dot-preview-contributors-short, .dot-preview-helpers-short {
    padding: 6px;
    white-space: nowrap;
    text-align: center;
}

.dot-preview-helper-simple-list {
    padding: 6px;
    white-space: nowrap;
    text-align: center;
}


/*
============================================================================================
    Curation
============================================================================================
*/

.dot-curation-capitalize {
    float: left;
    padding-right: 0px;
}

.dot-curation-highlight {
    width: 80%;
    margin: 0px auto;
    padding: 0px 15px;
    clear: left;
    text-align: center;
}

.dot-curation-paragraph {
}

.dot-space-paragraph {
    height: 20px;
}

.dot-curation-text {
    white-space: pre-wrap;
    line-height: 20px;
}


/*
============================================================================================
    Overview Gallery
============================================================================================
*/

.dot-overview-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.dot-overview-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.dot-overview-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.dot-overview-gallery-video {}

.dot-overview-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.dot-overview-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Overview Gallery Navigation
============================================================================================
*/

.dot-overview-gallery-prev-arrow, 
.dot-overview-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-overview-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.dot-overview-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.dot-overview-gallery-prev-arrow:hover,
.dot-overview-gallery-next-arrow:hover {
    opacity: 0.85;
}

.dot-overview-gallery-video:hover ~ .dot-overview-gallery-prev-arrow,
.dot-overview-gallery-video:hover ~ .dot-overview-gallery-next-arrow,
.dot-overview-gallery-image:hover ~ .dot-overview-gallery-prev-arrow,
.dot-overview-gallery-image:hover ~ .dot-overview-gallery-next-arrow {    
    opacity: 0.5;
}

.dot-overview-gallery-dot-on,
.dot-overview-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-overview-gallery-dot-on {
    opacity: 0.8;
}

.dot-overview-gallery-dot-off {
    opacity: 0.4;
}

.dot-overview-gallery-dot-off:hover {
    opacity: 0.8;
}

.dot-overview-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Overview Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.dot-overview-gallery-dot-tag-image, 
.dot-overview-gallery-user-tag-image,
.dot-overview-gallery-unsave-image,
.dot-overview-gallery-enlarge-image,
.dot-overview-gallery-dot-tag-video,
.dot-overview-gallery-user-tag-video,
.dot-overview-gallery-unsave-video,
.dot-overview-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.dot-overview-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.dot-overview-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.dot-overview-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.dot-overview-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.dot-overview-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.dot-overview-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.dot-overview-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.dot-overview-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.dot-overview-gallery-dot-tag-image:hover,
.dot-overview-gallery-user-tag-image:hover,
.dot-overview-gallery-unsave-image:hover,
.dot-overview-gallery-enlarge-image:hover,
.dot-overview-gallery-dot-tag-video:hover,
.dot-overview-gallery-user-tag-video:hover,
.dot-overview-gallery-unsave-video:hover,
.dot-overview-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.dot-overview-gallery-video:hover ~ .dot-overview-gallery-dot-tag-video,
.dot-overview-gallery-video:hover ~ .dot-overview-gallery-user-tag-video,
.dot-overview-gallery-video:hover ~ .dot-overview-gallery-unsave-video,
.dot-overview-gallery-video:hover ~ .dot-overview-gallery-enlarge-video,
.dot-overview-gallery-image:hover ~ .dot-overview-gallery-dot-tag-image,
.dot-overview-gallery-image:hover ~ .dot-overview-gallery-user-tag-image,
.dot-overview-gallery-image:hover ~ .dot-overview-gallery-unsave-image,
.dot-overview-gallery-image:hover ~ .dot-overview-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Todos 
============================================================================================
*/

#dot-todos-title {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto 10px auto;
    text-align: center;
}

#dot-todos {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 0px auto;
    color: #999999;
    text-align: left;
}


/*
============================================================================================
    Todos Gallery
============================================================================================
*/

.dot-todos-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.dot-todos-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.dot-todos-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.dot-todos-gallery-video {}

.dot-todos-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.dot-todos-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Todos Gallery Navigation
============================================================================================
*/

.dot-todos-gallery-prev-arrow, 
.dot-todos-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-todos-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.dot-todos-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.dot-todos-gallery-prev-arrow:hover,
.dot-todos-gallery-next-arrow:hover {
    opacity: 0.85;
}

.dot-todos-gallery-video:hover ~ .dot-todos-gallery-prev-arrow,
.dot-todos-gallery-video:hover ~ .dot-todos-gallery-next-arrow,
.dot-todos-gallery-image:hover ~ .dot-todos-gallery-prev-arrow,
.dot-todos-gallery-image:hover ~ .dot-todos-gallery-next-arrow {    
    opacity: 0.5;
}

.dot-todos-gallery-dot-on,
.dot-todos-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-todos-gallery-dot-on {
    opacity: 0.8;
}

.dot-todos-gallery-dot-off {
    opacity: 0.4;
}

.dot-todos-gallery-dot-off:hover {
    opacity: 0.8;
}

.dot-todos-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Todos Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.dot-todos-gallery-dot-tag-image, 
.dot-todos-gallery-user-tag-image,
.dot-todos-gallery-unsave-image,
.dot-todos-gallery-enlarge-image,
.dot-todos-gallery-dot-tag-video,
.dot-todos-gallery-user-tag-video,
.dot-todos-gallery-unsave-video,
.dot-todos-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.dot-todos-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.dot-todos-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.dot-todos-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.dot-todos-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.dot-todos-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.dot-todos-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.dot-todos-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.dot-todos-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.dot-todos-gallery-dot-tag-image:hover,
.dot-todos-gallery-user-tag-image:hover,
.dot-todos-gallery-unsave-image:hover,
.dot-todos-gallery-enlarge-image:hover,
.dot-todos-gallery-dot-tag-video:hover,
.dot-todos-gallery-user-tag-video:hover,
.dot-todos-gallery-unsave-video:hover,
.dot-todos-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.dot-todos-gallery-video:hover ~ .dot-todos-gallery-dot-tag-video,
.dot-todos-gallery-video:hover ~ .dot-todos-gallery-user-tag-video,
.dot-todos-gallery-video:hover ~ .dot-todos-gallery-unsave-video,
.dot-todos-gallery-video:hover ~ .dot-todos-gallery-enlarge-video,
.dot-todos-gallery-image:hover ~ .dot-todos-gallery-dot-tag-image,
.dot-todos-gallery-image:hover ~ .dot-todos-gallery-user-tag-image,
.dot-todos-gallery-image:hover ~ .dot-todos-gallery-unsave-image,
.dot-todos-gallery-image:hover ~ .dot-todos-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    History
============================================================================================
*/

#dot-history-title {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto 10px auto;
    text-align: center;
}

#dot-history {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 0px auto;
    color: #999999;
    text-align: left;
}


/*
============================================================================================
    History Gallery
============================================================================================
*/

.dot-history-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.dot-history-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.dot-history-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.dot-history-gallery-video {}

.dot-history-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.dot-history-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    History Gallery Navigation
============================================================================================
*/

.dot-history-gallery-prev-arrow, 
.dot-history-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-history-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.dot-history-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.dot-history-gallery-prev-arrow:hover,
.dot-history-gallery-next-arrow:hover {
    opacity: 0.85;
}

.dot-history-gallery-video:hover ~ .dot-history-gallery-prev-arrow,
.dot-history-gallery-video:hover ~ .dot-history-gallery-next-arrow,
.dot-history-gallery-image:hover ~ .dot-history-gallery-prev-arrow,
.dot-history-gallery-image:hover ~ .dot-history-gallery-next-arrow {    
    opacity: 0.5;
}

.dot-history-gallery-dot-on,
.dot-history-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-history-gallery-dot-on {
    opacity: 0.8;
}

.dot-history-gallery-dot-off {
    opacity: 0.4;
}

.dot-history-gallery-dot-off:hover {
    opacity: 0.8;
}

.dot-history-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    History Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.dot-history-gallery-dot-tag-image, 
.dot-history-gallery-user-tag-image,
.dot-history-gallery-unsave-image,
.dot-history-gallery-enlarge-image,
.dot-history-gallery-dot-tag-video,
.dot-history-gallery-user-tag-video,
.dot-history-gallery-unsave-video,
.dot-history-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.dot-history-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.dot-history-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.dot-history-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.dot-history-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.dot-history-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.dot-history-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.dot-history-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.dot-history-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.dot-history-gallery-dot-tag-image:hover,
.dot-history-gallery-user-tag-image:hover,
.dot-history-gallery-unsave-image:hover,
.dot-history-gallery-enlarge-image:hover,
.dot-history-gallery-dot-tag-video:hover,
.dot-history-gallery-user-tag-video:hover,
.dot-history-gallery-unsave-video:hover,
.dot-history-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.dot-history-gallery-video:hover ~ .dot-history-gallery-dot-tag-video,
.dot-history-gallery-video:hover ~ .dot-history-gallery-user-tag-video,
.dot-history-gallery-video:hover ~ .dot-history-gallery-unsave-video,
.dot-history-gallery-video:hover ~ .dot-history-gallery-enlarge-video,
.dot-history-gallery-image:hover ~ .dot-history-gallery-dot-tag-image,
.dot-history-gallery-image:hover ~ .dot-history-gallery-user-tag-image,
.dot-history-gallery-image:hover ~ .dot-history-gallery-unsave-image,
.dot-history-gallery-image:hover ~ .dot-history-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Stories
============================================================================================
*/

#dot-stories-title {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto 10px auto;
    text-align: center;
}

#dot-stories {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 0px auto;
    color: #999999;
    text-align: left;
}


/*
============================================================================================
    Stories Gallery
============================================================================================
*/

.dot-stories-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.dot-stories-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.dot-stories-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.dot-stories-gallery-video {}

.dot-stories-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.dot-stories-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Stories Gallery Navigation
============================================================================================
*/

.dot-stories-gallery-prev-arrow, 
.dot-stories-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-stories-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.dot-stories-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.dot-stories-gallery-prev-arrow:hover,
.dot-stories-gallery-next-arrow:hover {
    opacity: 0.85;
}

.dot-stories-gallery-video:hover ~ .dot-stories-gallery-prev-arrow,
.dot-stories-gallery-video:hover ~ .dot-stories-gallery-next-arrow,
.dot-stories-gallery-image:hover ~ .dot-stories-gallery-prev-arrow,
.dot-stories-gallery-image:hover ~ .dot-stories-gallery-next-arrow {    
    opacity: 0.5;
}

.dot-stories-gallery-dot-on,
.dot-stories-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dot-stories-gallery-dot-on {
    opacity: 0.8;
}

.dot-stories-gallery-dot-off {
    opacity: 0.4;
}

.dot-stories-gallery-dot-off:hover {
    opacity: 0.8;
}

.dot-stories-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Stories Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.dot-stories-gallery-dot-tag-image, 
.dot-stories-gallery-user-tag-image,
.dot-stories-gallery-unsave-image,
.dot-stories-gallery-enlarge-image,
.dot-stories-gallery-dot-tag-video,
.dot-stories-gallery-user-tag-video,
.dot-stories-gallery-unsave-video,
.dot-stories-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.dot-stories-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.dot-stories-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.dot-stories-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.dot-stories-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.dot-stories-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.dot-stories-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.dot-stories-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.dot-stories-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.dot-stories-gallery-dot-tag-image:hover,
.dot-stories-gallery-user-tag-image:hover,
.dot-stories-gallery-unsave-image:hover,
.dot-stories-gallery-enlarge-image:hover,
.dot-stories-gallery-dot-tag-video:hover,
.dot-stories-gallery-user-tag-video:hover,
.dot-stories-gallery-unsave-video:hover,
.dot-stories-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.dot-stories-gallery-video:hover ~ .dot-stories-gallery-dot-tag-video,
.dot-stories-gallery-video:hover ~ .dot-stories-gallery-user-tag-video,
.dot-stories-gallery-video:hover ~ .dot-stories-gallery-unsave-video,
.dot-stories-gallery-video:hover ~ .dot-stories-gallery-enlarge-video,
.dot-stories-gallery-image:hover ~ .dot-stories-gallery-dot-tag-image,
.dot-stories-gallery-image:hover ~ .dot-stories-gallery-user-tag-image,
.dot-stories-gallery-image:hover ~ .dot-stories-gallery-unsave-image,
.dot-stories-gallery-image:hover ~ .dot-stories-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Bucketed Out
============================================================================================
*/

.dot-bucketed-out {
    width: 100px;
    height: 48px;
}

.dot-bucketed-out-small {
    width: 84px;
    height: 44px;
}


/* 
============================================================================================
    Board
============================================================================================
*/

#dot-board-title {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
}

#dot-board-container {
    min-width: 320px;
    margin: 32px 0px;    
}
