@import "./_variables";
@import "./_mixins";


/* 
============================================================================================
    Dot
============================================================================================
*/
.dot-object {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    border: 1px solid #464646;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;    
}

.dot-object-container {
    width: 152px;
    height: 152px;
    padding: 6px;
}

.dot-object-wrapper {
    width: 152px;
    height: 152px;
    position: relative;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    top: 0px;
    left:0px;
}

.dot-object-wrapper-animation {
    width: 152px;
    height: 152px;
    position: relative;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    top: 0px;
    left:0px;
    -webkit-transition: top 1.0s ease-in-out, left 1.0s ease-in-out;
    -moz-transition: top 1.0s ease-in-out, left 1.0s ease-in-out;
    -o-transition: top 1.0s ease-in-out, left 1.0s ease-in-out;
    transition: top 1.0s ease-in-out, left 1.0s ease-in-out;    
}

.dot-object-upper-row {
    display: table-row;
}
.dot-object-upper-cell {
    display: table-cell;
}
.dot-object-middle-row {
    display: table-row;
    height: 100%;
}
.dot-object-bottom-row {
    display: table-row;
}

.dot-object-lower-cell {
    display: table-cell;
}

.dot-object-name-day,
.dot-object-name-night {
    border-radius: 9px 9px 0px 0px;
    text-align: center;
    padding: 6px 6px 6px 30px;
    letter-spacing: -1px;
}

.dot-object-name-day {
    background-color: rgba(0, 0, 0, 0.6);
}

.dot-object-name-night {
    background-color: rgba(0, 0, 0, 0.82);
}

.dot-object-name-options-day,
.dot-object-name-options-night {
    border-radius: 9px 9px 0px 0px;
    text-align: center;
    padding: 6px 6px 6px 6px;
    letter-spacing: -1px;
}

.dot-object-name-options-day {
    background-color: rgba(0,0,0, 0.6);
}

.dot-object-name-options-night {
    background-color: rgba(0,0,0, 0.82);
}

.dot-object-image-loader {
    height: 100%;
    background-size: 40px 40px;
    background-position: center;
    background-repeat: no-repeat;
}

.dot-object-image {
    position: relative;
    height: 100%;
    border-radius: 0px 0px 9px 9px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
}

.dot-object-image-mirrored, 
.dot-object-image-mirrored-back {
    padding: 0px;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg) scaleX(-1);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg) scaleX(-1);        /* FF */
    -o-transform: rotate(180deg) scaleX(-1);          /* Opera */
    -ms-transform: rotate(180deg) scaleX(-1);  
    transform: rotate(180deg) scaleX(-1);
}

.dot-object-image-mirrored {
    border-radius: 0px 0px 9px 9px;
}

.dot-object-image-mirrored-back {
    position: relative;
    border-radius: 9px 9px 0px 0px;
}

.dot-object-time {
    position: absolute;
    opacity: 0.9;
    box-shadow: 0px 0px 4px #333;
    right: -1px;
    top: 8px;
    letter-spacing: -1px;
    border-radius: 10px 0px 0px 10px;
    padding: 2px 5px 2px 10px;
    background-color: rgba(120, 120, 120, 0.8);
}

.dot-object-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

.dot-object-estimated-closure {
    width: 100%;
    padding: 4px 8px;
    margin-top: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
}

.dot-object-gradient {
    display: table;
    width: 100%;
    height: 100%;
    border-radius: 9px;
}

.dot-object-index {
    position: absolute;
    left: 3px;
    top: 3px;  
    width: 26px;
    height: 26px;
    background-size: 28px 28px !important;
}

.dot-object-factor {
    display: table;
    width: 138px;
    position: relative;
    top: -13px;
    opacity: 0.9;
}

.dot-object-factor-row {
    display: table-row;
    width: 100%;
}

.dot-object-factor-left {
    display: table-cell;
    width: 50%;
    height: 30px;
    padding: 13px 0px 13px 0px;
}

.dot-object-factor-right {
    display: table-cell;
    width: 50%;
    height: 30px;
    padding: 13px 0px 13px 0px;
}

.dot-object-factor-single {
    display: table-cell;
    width: 100%;
    height: 30px;
    padding: 13px 0px 13px 0px;
}

.dot-object-factor-rating {
    height: 100%;
    float: left;
}

.dot-object-factor-background {
    width: 100%;
    height: 100%;
    border: 0px solid #464646;
    background-color: #101010;
}

.dot-object-factor-scenic-rating {
    height: 100%;
    float: right;
    background-color: #249CCE;
}

.dot-object-factor-difficulty {
    height: 100%;
    float: left;
    background-color: #C72723;
}

.dot-object-add-button, 
.dot-object-remove-button {
    position: absolute;
    top: 112px;
    right: 12px;
    //opacity: 0.0 !important;
}

.dot-object-left-button {
    position: absolute;
    top: 112px;
    left: 12px;
    //opacity: 0.0 !important;
}

.dot-object-right-button {
    position: absolute;
    top: 112px;
    left: 46px;
    //opacity: 0.0 !important;
}

.dot-object-information-button {
    position: absolute;
    top: 60px;
    left: 40px;
    //opacity: 0.0 !important;
}

/*
.dot-object-wrapper:hover {
    opacity: 1.0;
}

.dot-object-wrapper:hover .dot-object-add-button,
.dot-object-wrapper:hover .dot-object-remove-button,
.dot-object-wrapper:hover .dot-object-information-button {
    opacity: 0.75 !important;
}

.dot-object-wrapper:hover .dot-object-right-button,
.dot-object-wrapper:hover .dot-object-left-button {
    opacity: 0.6 !important;
}
*/

.dot-object-list {
    padding: 0px;
}