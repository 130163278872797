@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
   Save Bucket
============================================================================================
*/

#save-bucket-container {
    position: fixed;
    padding: 6px 8px 8px 8px;
    top: 50%;
    right: 12px;
    z-index: 10;
    margin-top: -160px;
    border-radius: 20px;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.save-bucket-container-day {
    background-color: $almost-white;
}

.save-bucket-container-night {
    background-color: $black;
    box-shadow: 0px 0px 2px $black;
}


#save-bucket-title {
    text-align: center;
    opacity: 0.8;    
}

#save-bucket-saved-count {
    text-align: center;
    padding-bottom: 4px;
    opacity: 0.8;
}

#save-bucket-items-container {
    cursor: pointer;
}

#save-bucket-completed-count {
    padding-top: 2px;
    opacity: 0.7;
}

.save-bucket-item {
    padding-bottom: 2px;
    cursor: pointer;
}

.save-bucket-item-black {
    opacity: 0.85;
}

.save-bucket-item-black:hover {
    opacity: 1.0;
}

.save-bucket-item-white {
    opacity: 1.0;
}

.save-bucket-item-white:hover {
    opacity: 0.9;
}

.save-bucket-item-image, 
.save-bucket-item-video {
    box-shadow: 0px 0px 1px $black;
}

.save-bucket-item-video {
    background-size: 36px !important;
}

#save-bucket-image {
    width: 100%;
    height: 32px;
    margin-top: 2px;
    opacity: 0.7;
}
