@import "../../css/_variables";
@import "../../css/_mixins";

.participant-modal {
    @include modal-background;
}

.participant-modal-content {
    @include modal-participant;
    width: 310px;
}

.participant-modal-content__image,
.participant-modal-content__video {
    width: 100%;
    height: 200px;
}

.participant-modal-content__input {
}

.participant-modal-content__item-approved {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 8px;
    opacity: 0.8;
}