@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Basic Structure
============================================================================================
*/

#dot-edit-container {
    padding-top: 68px;
    padding-bottom: 70px;
}

#dot-more-cotainer {
    float: right;
}

#dot-edit-preview {
    width: 100%;
    padding-top: 8px;
    text-align: center;
    display: block;
    overflow: visible;
}

#dot-edit-preview-left {
    width: 160px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

#dot-edit-preview-left-small {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.dot-edit-preview-left-small-column {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#dot-edit-preview-middle {
    margin: 0px 10px;
    display: inline-block;
    vertical-align: top;
}

#dot-edit-preview-right {
    width: 160px;
    display: inline-block;
    vertical-align: top;
}

#dot-edit-preview-right-small {
    display: inline-block;
    vertical-align: middle;
}

.dot-edit-preview-right-small-column {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#dot-edit-preview-narrow {
    width: 100%;
    margin-top: 16px;
    white-space: nowrap;
    overflow: visible;
}


#dot-edit-overview-container {
    width : 600px;
    margin: 0px auto;
    overflow: visible;
    white-space: unset;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;   
}


/*
============================================================================================
    Basic
============================================================================================
*/

.dot-edit-basic-row {
    width: 100%;
    padding-bottom: 16px;
    text-align: center;
}

.dot-edit-basic-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    text-align: center;
}

.dot-edit-basic-input {
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}


/*
============================================================================================
    Editor
============================================================================================
*/

#dot-edit-preview-editor {
}

#dot-edit-preview-editor-title {
    padding-bottom: 8px;
    text-align: center;
}

#dot-edit-preview-editor-profile-pic-loader {
    width: 120px;
    height: 120px;
    margin: 0px auto 2px auto;
}

#dot-edit-preview-editor-profile-pic-loader-medium {
    width: 80px;
    height: 80px;
}

#dot-edit-preview-editor-profile-pic-loader-small {
    width: 64px;
    height: 64px;
}

#dot-edit-preview-editor-profile-pic {
    width: 100%;
    height: 100%;
}

#dot-edit-preview-editor-name {
    padding-bottom: 6px;
    text-align: center;
    cursor: pointer;
}


/*
============================================================================================
    Dot Numbers
============================================================================================
*/

.dot-edit-preview-numbers-container {
    margin-bottom: 12px;
}

.dot-edit-preview-numbers-title {
    padding: 6px;
    text-align: center;
}

#dot-edit-preview-numbers-rating {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#dot-edit-preview-numbers-rating-small {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#dot-edit-preview-numbers-rating-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}

#dot-edit-preview-numbers-difficulty {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#dot-edit-preview-numbers-difficulty-small {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#dot-edit-preview-numbers-difficulty-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}


/*
============================================================================================
    Contributor Button
============================================================================================
*/

#dot-edit-contributor-confirm-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    float: right;
}

.dot-edit-contributor-rating {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    padding: 40px 10px 20px 10px;
}

.dot-edit-contributor-physicality {
    display: inline-block;
    vertical-align: middle;
    width: 170px;
    padding: 40px 10px 20px 10px;
}

#dot-edit-contributor-button {
    margin-top: 10px;
}

#dot-edit-contributor-button:hover {
    opacity: 1.0;
}

/*
============================================================================================
    Media
============================================================================================
*/

#dot-edit-upload-gallery-add-image {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#dot-edit-upload-gallery-add-video {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* 
============================================================================================
    Preview
============================================================================================
*/

#dot-edit-title {
    width: 100%;
    margin-bottom: 16px;
    padding: 10px 20px;
}

#dot-edit-overview {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
    resize: none;
    text-align: left;
}

/* 
============================================================================================
    Curation
============================================================================================
*/

#dot-edit-todos-container,
#dot-edit-history-container,
#dot-edit-stories-container {
    width: 100%;
    padding: 0px 10px;
    min-width: 320px;
    max-width: 600px;
    margin: 0px auto;
    text-align: center;
}

#dot-edit-todos-title {
    margin: 16px 0px;
}

#dot-edit-history-title,
#dot-edit-stories-title {
    margin-bottom: 16px;
}

#dot-edit-todos,
#dot-edit-history,
#dot-edit-stories {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
    resize: none;
    text-align: left;
}


/* 
============================================================================================
    Location
============================================================================================
*/

#dot-edit-location-container {
    width: 100%;
    min-width: 320px;
    max-width: 480px;
    padding: 20px 10px;
    margin: 0px auto;
}

#dot-edit-location-text {
    text-align: center;
    margin-bottom: 16px;
}

#dot-edit-name, 
#dot-edit-area {
    width: 100%;
}


/*
============================================================================================
   Map
============================================================================================
*/

#dot-edit-map-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}


/*
============================================================================================
    Location Components
============================================================================================
*/

#dot-edit-location-access-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#dot-edit-location-access-wide {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    text-align: center;
    white-space: normal;
}

#dot-edit-location-access-narrow {
    display: inline-block;
    width: calc(100% - 80px);
    text-align: center;
}

#dot-edit-drivable-button,
#dot-edit-undrivable-button,
#dot-edit-park-and-walk-button {
    vertical-align: middle;
    margin: 3px 3px;
}

#dot-edit-drivable-button:hover, 
#dot-edit-undrivable-button:hover,
#dot-edit-park-and-walk-button:hover {
}


#dot-edit-location-preferred-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#dot-edit-location-preferred {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    text-align: center;
}

#dot-edit-drive-button,
#dot-edit-public-transportation-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#dot-edit-drive-button:hover,
#dot-edit-public-transportation-button:hover {
}

#dot-edit-location-type {
    width: 100%;
    /*border-bottom: 1px solid #464646;*/
}

#dot-edit-location-type-actual, 
#dot-edit-location-type-parking,
#dot-edit-location-type-start, 
#dot-edit-location-type-end {
    display: inline-block;
    width: 50%;
    padding: 3px;
    border-radius: 0px;
}

#dot-edit-location-type-actual:hover, 
#dot-edit-location-type-parking:hover,
#dot-edit-location-type-start:hover, 
#dot-edit-location-type-end:hover {
}

#dot-edit-location,
#dot-edit-location-lat,
#dot-edit-location-lng {
}

#dot-edit-location-search-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
}

.dot-edit-location-search-title-text-single {
    text-align: right;
}

.dot-edit-location-search-title-text-double {
    text-align: right;
    line-height: 20px;
}

#dot-edit-location {
    width: 100%;
    padding: 4px 12px;
    position: relative;
    resize: none;
    overflow: hidden;
}

#dot-edit-location-gps {
    width: 100%;
}

#dot-edit-location-gps-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#dot-edit-location-lat, 
#dot-edit-location-lng {
    display: inline-block;
    vertical-align: middle;
    width: calc(50% - 28px);
    margin-right: 8px;
}

#dot-edit-location-gps-button {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
}

#dot-edit-location-gps-button:hover {
}

#dot-edit-location:focus, 
#dot-edit-location-lat:focus, 
#dot-edit-location-lng:focus {  
}


/*
============================================================================================
    Write Mode
============================================================================================
*/

#dot-edit-write-mode-container {
    margin-top: 15px;
}


/*
============================================================================================
   Everyday Mode
============================================================================================
*/

#dot-edit-everyday-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#dot-edit-everyday-mode:hover {
}

#dot-edit-everyday-mode-icon {
    display: inline-block;
    width: 42px;
    height: 38px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dot-edit-everyday-mode-icon {
    opacity: 0.75;
}

#dot-edit-everyday-mode-text {
}

.dot-edit-everyday-mode-text {
    opacity: 0.75;
}

#dot-edit-everyday-mode:hover > .dot-edit-everyday-mode-icon,
#dot-edit-everyday-mode:hover > .dot-edit-everyday-mode-text {
    opacity: 1.0;
}


/*
============================================================================================
   Author Mode
============================================================================================
*/

#dot-edit-author-mode {
    display: inline-block;
    vertical-align: bottom;
    cursor: pointer;
}

#dot-edit-author-mode:hover {
}

#dot-edit-author-mode-icon {
    display: inline-block;
    width: 44px;
    height: 42px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dot-edit-author-mode-icon {
    opacity: 0.75;
}

#dot-edit-author-mode-text {
}

.dot-edit-author-mode-text {
    opacity: 0.75;
}

#dot-edit-author-mode:hover > .dot-edit-author-mode-text,
#dot-edit-author-mode:hover > .dot-edit-author-mode-icon {
    opacity: 1.0;
}


/*
============================================================================================
   Contributor Mode
============================================================================================
*/

#dot-edit-contributor-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#dot-edit-contributor-mode:hover {
}

#dot-edit-contributor-mode-icon {
    display: inline-block;
    width: 54px;
    height: 32px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dot-edit-contributor-mode-icon {
    opacity: 0.75;
}

#dot-edit-contributor-mode-text {
}

.dot-edit-contributor-mode-text {
    opacity: 0.75;
}

#dot-edit-contributor-mode:hover > .dot-edit-contributor-mode-icon,
#dot-edit-contributor-mode:hover > .dot-edit-contributor-mode-text {
    opacity: 1.0;
}


/* 
============================================================================================
    Edit Save Modal
============================================================================================
*/

.dot-edit-save-modal-fixed-bottom-day, 
.dot-edit-save-modal-fixed-bottom-night {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 20;
    text-align: center;
    padding: 10px 0px;
}

.dot-edit-save-modal-fixed-bottom-day {
    background-color: rgba(248, 248, 248, 0.8);
}

.dot-edit-save-modal-fixed-bottom-night {
    background-color: rgba(0, 0, 0, 0.8);
}

.dot-edit-save-modal-content-nav {
    &__left {
        flex: 1;          
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

#dot-edit-save-modal-text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

#dot-edit-save-modal-button {
    display: inline-block;
    vertical-align: middle;
    width: 64px;
    height: 64px;
    text-decoration: none;
    cursor: pointer;
}

#dot-edit-save-modal-button:hover {
}
