@import "./_variables";
@import "./_mixins";


/* 
============================================================================================
   Interaction (like / save / complete / favorite)
============================================================================================
*/

.interaction-stat-box {
    display: block;
    overflow: hidden;
    float: left;
}

.interaction-stat-box-float {
    float: left;
}


.interaction-stat-box-like-small,
.interaction-stat-box-save-small, 
.interaction-stat-box-complete-small, 
.interaction-stat-box-favorite-small {
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    float: left;
}

.interaction-stat-box-like-small {
    width: 16px;
}

.interaction-stat-box-save-small {
    width: 20px;
}

.interaction-stat-box-complete-small {
    width: 14px;
}

.interaction-stat-box-favorite-small {
    width: 20px;
}

.interaction-stat-box-number-small {
    height: 20px;
    padding: 1px 10px 1px 5px;
    float: left;
    font-size: 18px;
    line-height: 20px;
}


.interaction-stat-box-like-medium,
.interaction-stat-box-save-medium, 
.interaction-stat-box-complete-medium, 
.interaction-stat-box-favorite-medium {
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    float: left;
}

.interaction-stat-box-like-medium {
    width: 20px;
}

.interaction-stat-box-save-medium {
    width: 25px;
}

.interaction-stat-box-complete-medium {
    width: 18px;
}

.interaction-stat-box-favorite-medium {
    width: 25px;
}

.interaction-stat-box-number-medium {
    height: 24px;
    padding: 1px 10px 1px 5px;
    float: left;
    font-size: 20px;
    line-height: 24px;
}


.interaction-stat-box-like-large,
.interaction-stat-box-save-large, 
.interaction-stat-box-complete-large, 
.interaction-stat-box-favorite-large {
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    float: left;
}

.interaction-stat-box-like-large {
    width: 24px;
}

.interaction-stat-box-save-large {
    width: 30px;
}

.interaction-stat-box-complete-large {
    width: 21px;
}

.interaction-stat-box-favorite-large {
    width: 30px;
}

.interaction-stat-box-number-large {
    height: 30px;
    padding: 1px 10px 1px 5px;
    float: left;
    font-size: 24px;
    line-height: 30px;
}


/*
============================================================================================
   InteractionButtonBox (like / save)
============================================================================================
*/

.interaction-button-box {
    display: block;
    overflow: hidden;
    float: right;
}

.interaction-button-box-like-small, 
.interaction-button-box-save-small, 
.interaction-button-box-help-small {
    padding: 2px 7px 2px 7px;
    margin: 0px 7px 0px 0px;
    border-radius: 4px;
    opacity: 0.30;
    background-color: #FFFFFF;
    color: #000000;
    float: right;
    font-size: 14px;
    cursor: pointer;
}

.interaction-button-box-help-small {
    margin: 0px 14px 0px 0px;
}


.interaction-button-box-like-medium, 
.interaction-button-box-save-medium, 
.interaction-button-box-help-medium {
    padding: 2px 8px 2px 8px;
    margin: 0px 0px 0px 8px;
    border-radius: 4px;
    opacity: 0.30;
    background-color: #FFFFFF;
    color: #000000;
    float: right;
    font-size: 16px;
    cursor: pointer;
}

.interaction-button-box-help-medium {
    margin: 0px 0px 0px 8px;
}



.interaction-button-box-like:hover, 
.interaction-button-box-save:hover {
}

.interaction-button-box-help:hover {
    opacity: 0.5;
}
