@import "../../css/_variables";
@import "../../css/_mixins";

/* 
============================================================================================
    Dots Home
============================================================================================
*/

#dots-home-container {
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 70px;
}

#dots-home-control-container {
    width: 100%;
    height: 0px;
}

.dots-home-container {
    padding-top: 68px;
}

.dots-home-container-small {
    padding-top: 58px;
}

#dots-home-save-bucket-hover {
    width: 100px;
    height: 100%;
    position: fixed;
    z-index: 10;
    right: 0px;
}

.dots-home-item {
}

.dots-home-item-wrapper {
}

.dots-home-item-container-day, 
.dots-home-item-container-night {
    display: inline-block;
    margin: 0px auto 0px auto;
    border-radius: 12px;
    text-align: center;
}

.dots-home-item-container-day {
    background-color: $white;
    box-shadow: 0px 0px 4px $gray;
}

.dots-home-item-container-night {
    background-color: $closer-to-black;
    border: solid 1px $closer-to-black;
    /* box-shadow: 0px 0px 4px $black; */
}

.dots-home-item-board-container {
}

/* 
============================================================================================
    Dot Home Header
============================================================================================
*/

.dots-home-item-header {
    border-radius: 12px 12px 0px 0px;
}

.dots-home-item-header-left {
    padding: 6px 0px 6px 12px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.dots-home-item-header-right {
    padding: 6px 12px 6px 0px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.dots-home-item-editor-column, .dots-home-item-staff-column {
    display: inline-block;
    vertical-align: middle;
}

.dots-home-item-editor-profile-pic {
    display: inline-block;
    vertical-align: middle;
}

.dots-home-item-editor-name {
    display: inline-block;
    vertical-align: middle;
    padding: 8px 4px 8px 8px;
}

/*
============================================================================================
    Staff (Contributors and Helpers)
============================================================================================
*/

.dots-home-item-staff {
    text-align: center;
}

.dots-home-item-staff-text, .dots-home-item-staff-item {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.dots-home-item-staff-text {
    padding: 8px;
}

.dots-home-item-staff-item {
    padding: 0px 4px;
}

.dots-home-item-contributors, .dots-home-item-helpers {

}

.dots-home-item-contributors-count, .dots-home-item-helpers-count {
    padding: 0px 0px 6px 0px;
    white-space: nowrap;
    text-align: center;
}

.dots-home-item-contributor-profile-pics, .dots-home-item-helper-profile-pics {
    padding: 0px 0px 0px 0px;
}

.dots-home-item-contributor-profile-pic, .dots-home-item-helper-profile-pic {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #464646;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0px 2px 0px 2px;
    cursor: pointer;
}

.dots-home-item-contributors-more, .dots-home-item-helpers-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

.dots-home-item-contributors-short, .dots-home-item-helpers-short {
    padding: 6px;
    white-space: nowrap;
    text-align: center;
}


/* 
============================================================================================
    Dot Like / Save / Share Buttons 
============================================================================================
*/

.dots-home-item-like-container {
    float: right;
    padding: 2px 0px 0px 8px;
    text-align: right;
}

.dots-home-item-like-button-on, 
.dots-home-item-like-button-off,
.dots-home-item-share-button,
.dots-home-item-detail-button,
.dots-home-item-save-button-on,
.dots-home-item-save-button-off {
    vertical-align: middle;
}

.dots-home-item-share-button,
.dots-home-item-detail-button,
.dots-home-item-like-button-on,
.dots-home-item-like-button-off {
    margin-left: 6px;
}

.dots-home-item-like-button-on,
.dots-home-item-save-button-on {
    opacity: 0.8 !important;
}

.dots-home-item-share-button,
.dots-home-item-detail-button,
.dots-home-item-like-button-off,
.dots-home-item-save-button-off {
    opacity: 0.4;
}

.dots-home-item-share-button:hover,
.dots-home-item-detail-button:hover,
.dots-home-item-like-button-off:hover,
.dots-home-item-save-button-off:hover {
    opacity: 0.8;
}

.dots-home-item-save-button-text {
    display: inline-block;
    vertical-align: middle;
    padding-right: 6px;
    line-height: 32px;
}

.dots-home-item-like-count {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    opacity: 0.8;
    padding-right: 2px;
}

.dots-home-item-save-count {
    text-align: center;
}


/* 
============================================================================================
    Dot Home Media
============================================================================================
*/

.dots-home-item-media-container-day,
.dots-home-item-media-container-night {
}

.dots-home-item-media-container-day {
    /* border-top: solid 1px $light-gray; */
    /* border-bottom: solid 1px $light-gray; */
    background-color: $almost-white;
}

.dots-home-item-media-container-night {
    /* border-top: solid 1px $darkest-gray; */
    /* border-bottom: solid 1px $darkest-gray; */
    background-color: $black;
}

.dots-home-item-media-wrapper {
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}

.dots-home-item-media-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    pointer-events: none;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.dots-home-item-video > video {
    background-color: transparent !important; 
}

.dots-home-item-video > div {
    background-color: transparent !important; 
}

.dots-home-item-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.dots-home-item-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Media Buttons (User Tag / Enlarge)
============================================================================================
*/

.dots-home-item-dot-tag-image, 
.dots-home-item-user-tag-image,
.dots-home-item-unsave-image,
.dots-home-item-enlarge-image,
.dots-home-item-dot-tag-video,
.dots-home-item-user-tag-video,
.dots-home-item-unsave-video,
.dots-home-item-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.dots-home-item-dot-tag-video {
    top: 10px;
    left: 80px;
}

.dots-home-item-user-tag-video {
    top: 10px;
    left: 45px;
}

.dots-home-item-unsave-video {
    top: 10px;
    right: 10px;
}

.dots-home-item-enlarge-video {
    top: 10px;
    left: 10px;
}

.dots-home-item-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.dots-home-item-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.dots-home-item-unsave-image {
    bottom: 10px;
    right: 10px;
}

.dots-home-item-enlarge-image {
    top: 10px;
    left: 10px;
}

.dots-home-item-dot-tag-image:hover,
.dots-home-item-user-tag-image:hover,
.dots-home-item-unsave-image:hover,
.dots-home-item-enlarge-image:hover,
.dots-home-item-dot-tag-video:hover,
.dots-home-item-user-tag-video:hover,
.dots-home-item-unsave-video:hover,
.dots-home-item-enlarge-video:hover {
    opacity: 1.0 !important;
}

.dots-home-item-video:hover ~ .dots-home-item-dot-tag-video,
.dots-home-item-video:hover ~ .dots-home-item-user-tag-video,
.dots-home-item-video:hover ~ .dots-home-item-unsave-video,
.dots-home-item-video:hover ~ .dots-home-item-enlarge-video,
.dots-home-item-image:hover ~ .dots-home-item-dot-tag-image,
.dots-home-item-image:hover ~ .dots-home-item-user-tag-image,
.dots-home-item-image:hover ~ .dots-home-item-unsave-image,
.dots-home-item-image:hover ~ .dots-home-item-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Dot Home Media Navigation
============================================================================================
*/

.dots-home-item-media-prev-arrow, 
.dots-home-item-media-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
}

.dots-home-item-media-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.dots-home-item-media-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.dots-home-item-media-prev-arrow:hover,
.dots-home-item-media-next-arrow:hover {
    opacity: 1.0;
}

.dots-home-item-video:hover ~ .dots-home-item-media-prev-arrow,
.dots-home-item-video:hover ~ .dots-home-item-media-next-arrow,
.dots-home-item-image:hover ~ .dots-home-item-media-prev-arrow,
.dots-home-item-image:hover ~ .dots-home-item-media-next-arrow {    
    opacity: 0.75;
}

.dots-home-item-nav-dot-on,
.dots-home-item-nav-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.dots-home-item-nav-dot-on{
    opacity: 0.8;
}

.dots-home-item-nav-dot-off {
    opacity: 0.4;
}

.dots-home-item-nav-dot-off:hover {
    opacity: 0.8;
}

.dots-home-item-nav-dots {
    position: absolute;
    top: 2px;
    width: 100%;
    text-align: center;
}


/* 
============================================================================================
    Dot Home Item Content
============================================================================================
*/

.dots-home-item-footer {
    display: inline-block;
    position: relative;
    text-align: left;
}

.dots-home-item-footer-right {
    display: inline-block;
    vertical-align: top;
}

.dots-home-item-location-name {
    display: inline-block;
    vertical-align: middle;
    padding-top: 4px;
}

.dots-home-item-map-container {
    float: right;
    padding: 16px;
}

.dots-home-item-map-wrapper {
    border-radius: 10px;
    overflow: hidden;
}

.dots-home-item-map-static {
    position: absolute;
    top: -220px;
    left: calc(50% - 360px);
}

.dots-home-item-map-static-wrapper {
    position: relative;
    width: 100%;
    height: 280px;
    border-radius: 10px;
    overflow: hidden;
}

.dots-home-item-map-location {
    padding: 0px 8px 8px 0px;
    text-align: right;
}

.dots-home-item-curation-capitalize {
    float: left;
    padding-right: 6px;
}

.dots-home-item-curation-highlight {
    padding: 0px 16px 0px 16px;
    clear: left;
    text-align: center;
}

.dots-home-item-curation-paragraph {
    line-height: 20px;
}

.dots-home-item-space-paragraph {
    height: 20px;
}

.dots-home-item-curation-text {
    white-space: pre-wrap;
}

.dots-home-item-curation-title {
    display: inline-block;
    margin: 20px 0px 6px 0px;
    padding: 0px 20px;
}

.dots-home-item-curation-title-text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}

.dots-home-item-curation-divider {
    text-align: right;
    clear: left;
    padding: 0px 20px;
}

.dots-home-item-curation-divider-text {
    padding-right: 12px;
    color: #3284B0;
}

.dots-home-item-overview,
.dots-home-item-overview-folded,
.dots-home-item-overview-expanded {
    padding: 0px 20px;
}

.dots-home-item-overview {
}

.dots-home-item-overview-folded {
    overflow: hidden;
}

.dots-home-item-curation-more,
.dots-home-item-curation-less {
    width: 100%;
    height: 14px;
    margin-top: 8px;
    cursor: pointer;
}

.dots-home-item-curation-less {
    background-size: auto 6px !important;
}

.dots-home-item-curation-more {
    background-size: auto 14px !important;
}

.dots-home-item-curation-overview {
}

.dots-home-item-curation-todos {
}

.dots-home-item-curation-history {
}

.dots-home-item-curation-stories {
}

.dots-home-item-footer-map {
}

.dots-home-item-footer-spacer {
    height: 24px;
}


/* 
============================================================================================
    Map
============================================================================================
*/
.dots-home-item-map-marker {
    width: 32px;
    height: 32px;
}


/*
============================================================================================
    Bottle
============================================================================================
*/

.dots-home-item-bottle {
    text-align: center;
    margin-top: 10px;
    padding: 0px 16px;
    white-space: nowrap;
}

.dots-home-item-bottle-user-count {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    white-space: nowrap;
    text-align: center;
}

.dots-home-item-bottle-user-profile-pics {
    display: inline-block;
    vertical-align: middle;
}

.dots-home-item-bottle-user-left,
.dots-home-item-bottle-user-middle,
.dots-home-item-bottle-user-right {
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    background-repeat: no-repeat;
    background-size: auto 60px;
}

.dots-home-item-bottle-user-left {
    width: 93px;
    padding: 8px 3px 8px 46px;
    background-position: right;
}
.dots-home-item-bottle-user-middle {
    width: 50px;
    padding: 8px 3px;
    background-position: center;
}
.dots-home-item-bottle-user-right {
    width: 55px;
    padding: 8px 8px 8px 3px;
    background-position: left;
}

.dots-home-item-bottle-speakerphone {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -4px;
    right: -4px;
    opacity: 1.0;
    cursor: pointer;
}

.dots-home-item-bottle-user-profile-pic {
    position: relative;
}


/* 
============================================================================================
    Dot Home Bucket Out
============================================================================================
*/

.dots-home-item-bucketed-out {
    width: 110px;
    height: 54px;
    margin: 5px 0px 10px 15px;
    float: right;
}

.dots-home-item-bucketed-out-small {
    width: 90px;
    height: 48px;
    margin: 2px 0px 8px 8px;
    float: right;
}


/* 
============================================================================================
    Dot Home Genesis
============================================================================================
*/

.dots-home-loader {
    width: 100%;
    height: 20px;
    margin-bottom: 25px;
    background-size: 80px 80px;
    opacity: 0.4;
    background-position: center;
    background-repeat: no-repeat;
}

.dots-home-genesis {
    width: 100%;
    height: 72px;
    margin-bottom: 10px;
}

.dots-home-genesis-title {
    padding-bottom: 30px;
}


/* 
============================================================================================
    Bottom Menu
============================================================================================
*/

#dots-home-bottom-menu-container {
    display: block;
    position: fixed;
    width: 100%;
    min-width: 320px;
    padding: 8px 0px 10px 0px;
    left: 0px;
    bottom: 0px;
    z-index: 3;
    -webkit-transition: bottom 0.5s ease-in-out;
    -moz-transition: bottom 0.5s ease-in-out;
    -o-transition: bottom 0.5s ease-in-out;
    transition: bottom 0.5s ease-in-out;
}

#dots-home-bottom-menu-modes {
    width: 100%;
    white-space: nowrap;
}

#dots-home-bottom-menu-mode-contribute,
#dots-home-bottom-menu-mode-everyday {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.dots-home-bottom-menu-mode-small {
    font-size: 16px;
    padding: 2px 0px;
    margin: 2px 8px;
}

.dots-home-bottom-menu-mode {
    font-size: 18px;
    padding: 3px 0px;
    margin: 2px 10px;
}

.dots-home-bottom-menu-mode-selected-day {
    border-bottom: solid 2px $light-blue;
}

.dots-home-bottom-menu-mode-selected-night {
    border-bottom: solid 2px $blue;
}


/*
============================================================================================
    Animations
============================================================================================
*/

@-webkit-keyframes buzz {
    50% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    100% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
}
@keyframes buzz {
    50% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    100% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
}

.buzz:hover, .buzz:focus, .buzz:active {
    -webkit-animation-name: buzz;
    animation-name: buzz;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes wobble-vertical {
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
    }
    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes wobble-vertical {
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
    }
    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.wobble-vertical:hover, .wobble-vertical:focus, .wobble-vertical:active {
    -webkit-animation-name: wobble-vertical;
    animation-name: wobble-vertical;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}