@import "../../css/_variables";
@import "../../css/_mixins";


.alert-reset-password-email-sent,
.alert-reset-password-token-expired {
    @include alert-basic-top;    
    padding: 20px 30px;
}

.alert-reset-password-day {
    @include alert-basic-top-day;
}

.alert-reset-password-night {
    @include alert-basic-top-night;
}
