@import "../../css/_variables";
@import "../../css/_mixins";

.share-modal {
    @include modal-background;
}

.share-modal-content {
    @include modal-basic;
    width: 200px;
    padding-bottom: 30px;
}

.share-modal-content__item {
	padding: 5px 30px;
}
