@import "../../css/_variables";
@import "../../css/_mixins";

/*
============================================================================================
    National Park Users
============================================================================================
*/

#national-park-user {
    width: 100%;
}


/*
============================================================================================
    Header and Tabs
============================================================================================
*/

#national-park-user-header {
    display: block;
    width: 100%;
    padding: 0px 16px;
    text-align: center;
}

.national-park-user-header-tab,
.national-park-user-header-tab-hover-day,
.national-park-user-header-tab-hover-night {
    min-width: 142px;
    width: 40%;
    max-width: 180px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 6px;
}
.national-park-user-header-tab-hover-day {
    border-bottom: 4px solid $light-blue;
}

.national-park-user-header-tab-hover-night {
    border-bottom: 4px solid $blue;
}


/*
============================================================================================
    User List
============================================================================================
*/
#national-park-user-list {
    direction: rtl;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0px 0px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#national-park-user-list::-webkit-scrollbar {
  display: none;
}

.national-park-user-item {
    display: inline-block;
    vertical-align: middle;
}

.national-park-user-row {
    direction: ltr;
    padding: 6px 0px;
}


/*
============================================================================================
    User Profile
============================================================================================
*/

.national-park-user-item-profile {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 10px;
}


/*
============================================================================================
    User Content
============================================================================================
*/

.national-park-user-item-content {
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}


/*
============================================================================================
    User Name and Username
============================================================================================
*/

.national-park-user-item-name, 
.national-park-user-item-username {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 3px;
}


/*
============================================================================================
    User Stats
============================================================================================
*/

.national-park-user-item-dot-icon {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    opacity: 0.3;
    margin-right: 4px;
}

.national-park-user-item-badge-icon {
    vertical-align: middle;
    width: 20px;
    height: 26px;
    opacity: 0.3;
    margin-right: 4px;
}

.national-park-user-item-dot-count, 
.national-park-user-item-badge-count {
    display: inline-block;
    vertical-align: middle;
}

.national-park-user-item-stats-space {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 4px;
} 

/*
============================================================================================
    Body
============================================================================================
*/

