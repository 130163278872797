@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Notification
============================================================================================
*/

.notifications {
	position: absolute;
	width: 366px;
    top: 18px;
    right: -183px;
    z-index: 31;
}

.notifications-small {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    margin: 0px auto;
    min-width: 300px;
    max-width: 366px;
    z-index: 31;
}

#notifications-shadow {
    margin-top: 16px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.8);
    overflow: hidden;
}

#notifications-triangle {
	position: absolute;
	width: 300px;
	height: 16px;
    background-repeat: no-repeat;
    background-position: 250px;
    background-size: contain;
}

#notifications-header {
	width: 100%;
    padding: 6px 16px;
    text-align: left;
    position: relative;
}

#notifications-footer {
    width: 100%;
    height: 20px;
}

.notifications-header-day,
.notifications-footer-day {
    background-color: $menu-frame-day;
}

.notifications-header-night,
.notifications-footer-night {
    background-color: $menu-frame-night;
}

#notifications-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

#notifications-container {
	width: 100%;
    overflow-x: hidden;
	overflow-y: auto;
}

.notifications-container-short {
    max-height: 400px;
}

.notifications-container-long {
    max-height: 800px;
}


.notifications-container-day {
    background-color: $almost-white;
    border-left: 1px solid $menu-frame-day;
    border-right: 1px solid $menu-frame-day;
}

.notifications-container-night {
    background-color: $black;
    border-left: 1px solid $menu-frame-night;
    border-right: 1px solid $menu-frame-night;
}


/* 
============================================================================================
    Notification Item
============================================================================================
*/

#notification-item-blank {
    padding: 8px;
    text-align: center;
}

.notification-item-unchecked-day,
.notification-item-unchecked-night,
.notification-item-checked-day,
.notification-item-checked-night {
	width: 100%;
	text-align: left;
}

.notification-item-unchecked-day:hover,
.notification-item-checked-day:hover {
    background-color: $close-to-white;
}

.notification-item-unchecked-night:hover,
.notification-item-checked-night:hover {
	background-color: $close-to-black;
}

.notification-item-user,
.notification-item-dot {
	vertical-align: middle;
	margin: 4px 8px;
}

.notification-item-user {
}

.notification-item-dot {
    width: 36px;
    height: 36px;
    border-radius: 10px;	
}

.notification-item-description-long, 
.notification-item-description-long-scroll, 
.notification-item-description-short,
.notification-item-description-short-scroll {
	display: inline-block;
	vertical-align: middle;
    white-space: normal;
    text-align: left;
    margin: 4px 2px;    
}

.notification-item-description-long {
    width: calc(100% - 56px);
}

.notification-item-description-long-scroll {
    width: calc(100% - 56px);
}

.notification-item-description-short {
    width: calc(100% - 108px);
}

.notification-item-description-short-scroll {
    width: calc(100% - 108px);
}


.notification-item-user-name, 
.notification-item-dot-name {
}

.notification-item-text {
}

.notification-item-timestamp {
    margin-left: 8px;
}
