@import "../../css/_variables";
@import "../../css/_mixins";


#nav-container {
    width: 100%;
    position: fixed;
    margin: 0px auto;
    z-index: 30;
}

.nav-container-day {
    background-color: $almost-white;
    box-shadow: 0px 0px 3px 0px $almost-white;
}

.nav-container-night {
    background-color: $black;
    box-shadow: 0px 0px 3px 0px $black;
}

#nav {
    display: table;
    width: 100%;
    height: 65px;
    padding: 15px 20px 10px 20px;
}

#nav-small {
    display: table;
    width: 100%;
    min-width: 320px;
    height: 55px;
    padding: 8px 20px 7px 20px;
}


/* 
============================================================================================
    Logo and Mode Buttons
============================================================================================
*/

#nav-logo {
    display: table-cell;
    vertical-align: middle;
    width: 0.1%;
    height: 40px;
    white-space: nowrap;
}

#logo {
    display: inline-block;
    vertical-align: bottom;
    width: 140px;
    height: 36px;
    margin-bottom: 2px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#logo-small {
    display: inline-block;
    vertical-align: bottom;
    width: 120px;
    height: 32px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#main-mode-dots {
    display: inline-block;
    vertical-align: bottom;
    height: 24px;
    width: 24px;
    margin: 0px 1px 0px 0px;
    background-size: cover;
}

#main-mode-dots-small {
    display: inline-block;
    vertical-align: bottom;
    height: 22px;
    width: 22px;
    margin: 0px 1px -2px 0px;
    background-size: cover;
}

#main-mode-trips {
    display: inline-block;
    vertical-align: bottom;
    width: 40px;
    height: 24px;
    margin: 0px 0px 0px 2px;
    background-size: cover;
}

#main-mode-trips-small {
    display: inline-block;
    vertical-align: bottom;
    width: 37px;
    height: 22px;
    margin: 0px 0px -2px 2px;
    background-size: cover;
}


/* 
============================================================================================
    Status
============================================================================================
*/

#nav-status {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 99.5%;
}

.nav-status {
    
}

.nav-status-small-day,
.nav-status-small-night,
.nav-status-medium-day,
.nav-status-medium-night {
    position: fixed;
    width: 100vw;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    min-width: 320px;
}

.nav-status-small-day,
.nav-status-small-night {
    padding: 8px 0px 10px 0px;
}

.nav-status-medium-day,
.nav-status-medium-night {
    padding: 10px 0px 12px 0px;
}

.nav-status-small-day,
.nav-status-medium-day {
    background-color: $almost-white;
    opacity: 0.9;
}

.nav-status-small-night, 
.nav-status-medium-night {
    background-color: $black;
    opacity: 0.9;
}

#itinerary-animation {
    display: inline-block;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
 
    -o-transition: opacity 0.3s ease-in-out;
}
#itinerary-animation-small {
    display: inline-block;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

#itinerary-animation-title {
    display: inline-block;
    vertical-align: middle;
    padding: 6px 10px 0px 0px;
    letter-spacing: -1px;
    text-align: left;
}

#itinerary-animation-image {
    vertical-align: middle;
    width: 85px;
    height: 40px;
    margin-left: 8px;
    background-position: left;
    opacity: 0.8;
}

#itinerary-animation-image-medium {
    vertical-align: middle;
    width: 100px;
    height: 50px;
    background-position: left;
    opacity: 0.8;
}


/* 
============================================================================================
    Search
============================================================================================
*/

#nav-search {
    display: table-cell;
    vertical-align: middle;
    width: 0.1%;
}


/* 
============================================================================================
    User Info
============================================================================================
*/

#nav-user {
    display: table-cell;
    vertical-align: middle;
    width: 0.1%;
}

#user-info-container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
}

#nav-user-info {
    display: inline-block;        
    vertical-align: middle;
    white-space: nowrap;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

#nav-user-info-picture {
    display: inline-block;
    vertical-align: middle;
}

#nav-user-info-name {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-bottom: 2px;
    margin-left: 8px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}



/* 
============================================================================================
    User Menu Background
============================================================================================
*/

.nav-user-info-menus-background {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
} 


/* 
============================================================================================
    User Menu Buttons
============================================================================================
*/

#nav-user-info-menus {
    display: inline-block;
    vertical-align: middle;
    width: 0px;
    position: relative;
}

#nav-user-info-buttons {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    margin-left: 8px;
}

#nav-user-info-friend-button,
#nav-user-info-chat-button,
#nav-user-info-itinerary-button,
#nav-user-info-notification-button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 22px;
    cursor: pointer;
    text-align: center;
}

#nav-user-info-friend-image,
#nav-user-info-chat-image,
#nav-user-info-itinerary-image,
#nav-user-info-notification-image {
    width: 22px;
    height: 22px;
}

#nav-user-info-friend-image:hover,
#nav-user-info-chat-image:hover,
#nav-user-info-itinerary-image:hover,
#nav-user-info-notification-image:hover {
}

#nav-user-info-friend-image {

}

#nav-user-info-chat-image {
}

#nav-user-info-itinerary-image {

}

#nav-user-info-notification-image {
}

#nav-user-info-friend-counter,
#nav-user-info-chat-counter,
#nav-user-info-itinerary-counter,
#nav-user-info-notfication-counter {
    position: absolute;
    top: -3px;
    left: 15px;
    padding: 0px 4px;
    font-size: 10px;
    color: #FFF;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px #000;
    z-index: 31;
}

.nav-user-info-notification-day {
    background-color: $notification-day;
}

.nav-user-info-notification-night {
    background-color: $notification-night;
}


/* 
============================================================================================
    Auth
============================================================================================
*/

#nav-auth {
    display: table-cell;
    vertical-align: middle;
    width: 0.1%;
}

#nav-auth-buttons {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.nav-auth-text {
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    padding: 1px 0px 1px 0px;
    opacity: 0.6;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.nav-auth-text:hover {
    opacity: 1.0;
}

#nav-auth-sign-up,
#nav-auth-log-in,
#nav-auth-create,
#nav-auth-log-out {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}


/* 
============================================================================================
    Main Menu
============================================================================================
*/

#nav-menu {
    display: table-cell;
    vertical-align: middle;
    width: 0.1%;
    position: relative;
}

#nav-main-menu-button {
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 26px;
    margin: 7px 0px 7px 10px;
}

#nav-main-menu {
    position: absolute;
    width: 220px;
    top: 39px;
    right: -20px;
    z-index: 34;
}

#nav-main-menu-shadow {
    margin-top: 16px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.8);
    overflow: hidden;
}

#nav-main-menu-triangle {
    position: absolute;
    width: 220px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 180px;
    background-size: contain;
}

#nav-main-menu-header {
    width: 100%;
    height: 29px;
    padding: 6px 16px;
    text-align: left;
}

#nav-main-menu-footer {
    width: 100%;
    height: 20px;
}

.nav-main-menu-header-day,
.nav-main-menu-footer-day {
    background-color: $menu-frame-day;
}

.nav-main-menu-header-night,
.nav-main-menu-footer-night {
    background-color: $menu-frame-night;
}

#nav-main-menu-container {
}

.nav-main-menu-container-day {
    background-color: $almost-white;
    border-left: 1px solid $menu-frame-day;
    border-right: 1px solid $menu-frame-day;
}

.nav-main-menu-container-night {
    background-color: $black;
    border-left: 1px solid $menu-frame-night;
    border-right: 1px solid $menu-frame-night;
}

.nav-main-menu-items {
    padding: 4px 0px;
}

#nav-main-menu-scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.nav-main-menu-item {
    text-align: center;
    padding: 4px 20px;
    cursor: pointer;
    display: block;
}

.nav-main-menu-item:hover {
    opacity: 1.0;
}

.nav-main-menu-item-search {
    text-align: center;
    padding: 8px 16px;
    cursor: pointer;
}

.nav-main-menu-line-day {
    width: 100%;
    height: 2px;
    border-top: solid 1px $menu-frame-day;
    margin: 4px auto;
}

.nav-main-menu-line-night {
    width: 100%;
    height: 2px;
    border-top: solid 1px $menu-frame-night;
    margin: 4px auto;
}

.nav-main-menu-text {
    cursor: pointer;
    white-space: nowrap;
    text-align: left;
    padding: 1px 0px 1px 0px;
    opacity: 0.6;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.nav-main-menu-text:hover {
    opacity: 1.0;
}
