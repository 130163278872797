@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Trips Home
============================================================================================
*/
#trips-home-container {
    box-sizing: border-box;
    min-width: 320px;
}

.trips-home-container {
    padding: 66px 12px 12px 12px;
}

.trips-home-container-small {
    padding: 56px 12px 12px 12px;
}


/* 
============================================================================================
    React-Slick Slider Settings
============================================================================================
  - overides the attributes of react-slick
*/

.slick-prev {
    width: 30px;
    height: 30px;
    left: 6px;
    z-index: 2;
}

.slick-next {
    width: 30px;
    height: 30px;
    right: 6px;  
    z-index: 2;
}

.slick-next:before,
.slick-prev:before {
    font-size: 30px;
    line-height: 1;
    opacity: 0.50;
    color: #DDD;
}


/* 
============================================================================================
    Featued Trips and TripLists
============================================================================================
  - control overall height -> featured-trip-slider:height
  - control width of the text section -> featured-trip-text:width
  - control paddings of the text section -> featured-trip-text:padding
  - change title attributes -> featured-trip-text h1
  - change description attributes -> featured-trip-text p
  - change color gradient -> featured-trip-gradient
*/

.trips-home-featured-slider {
    height: 460px;
}

.trips-home-featured-slider-medium {
    height: 400px;
}

.trips-home-featured-slider-small {
    height: 340px;
}

.trips-home-featured-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.trips-home-featured-item-loader {
    position: relative;
    background-size: 80px 80px;
    overflow: hidden;
    border-radius: 12px;
}

.trips-home-featured-item-gradient {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.35) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.35) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.35) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.35) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%); /* Standard syntax (must be last) */  
}

.trips-home-featured-item-text {
    width: 35%;
    min-width: 300px;
    height: 100%;
    padding: 30px 20px 20px 36px;
    display: table-cell;
    vertical-align: top;
}

.trips-home-featured-item-spacer {
    width: 65%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.trips-home-featured-item-text-title {}

.trips-home-featured-item-text-description {}

.trips-home-featured-item-text-rating{
    margin: 4px 0px 0px 0px;
    float: left;
    display: inline-block;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
}


/*
============================================================================================
    Stars for Ratings
============================================================================================
  - images are located at /public/images/icons/stars
*/

.trips-home-featured-item-star-bottom {
    width: 125px;
    height: 25px;
    margin: 8px 0px 0px 8px;
    float: left;
    display: inline-block;
    opacity: 1.0;  background-repeat: true;
    background-size: 25px 25px;
    background-position: left;
}

.trips-home-featured-item-star-top {
    height: 25px;
    opacity: 0.4;
    background-repeat: true;
    background-size: 25px 25px;
    background-position: left;
}

.trips-home-item-star-bottom {
    width: 80px;
    height: 16px;
    margin: 3px 6px 0px 6px;
    float: left;
    display: inline-block;
    opacity: 1.0;  background-repeat: true;
    background-size: 16px 16px;
    background-position: left;
}

.trips-home-item-star-top {
    height: 16px;
    opacity: 0.4;
    background-repeat: true;
    background-size: 16px 16px;
    background-position: left;
}


/* 
============================================================================================
    Regular Trips and TripLists
============================================================================================
    - control overall height -> trip-slider:height
    - main title and rating -> trip-info
    - area / level / 4wd -> trip-detail => visibility controled by trip-detail-switch
*/

.trips-home-slider-wrapper {
    overflow: hidden !important;
}

.trips-home-slider {
    height: 200px;
}

.trips-home-slider-title {
    font-size: 20px;
    padding: 15px 10px 10px 10px;
}

.trips-home-item-loader {
    position: relative;
    width: calc(100% - 6px) !important;
    border-radius: 12px;
    overflow: hidden;
}

.trips-home-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.trips-home-item-gradient {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.35) 70%, rgba(0,0,0,0.7) 100%); /* Standard syntax (must be last) */  
}

.trips-home-item-info {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 8px 8px 10px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}

.trips-home-item-info-title {}

.trips-home-item-info-description {
    font-size: 12px;
    color: #999999;
}

.trips-home-item-info-rating {
    margin: 2px 0px 0px 0px;
    float: left;
    display: inline-block;
}

.trips-home-item-detail-switch {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  opacity: 0.0;
  z-index: 100;
}

.trips-home-item-detail-switch:hover {
  opacity: 1.0;
}

.trips-home-item-info:hover ~ .trips-home-item-detail-switch {
  opacity: 1.0;
}

.trips-home-item-detail {
  padding: 6px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.trips-home-item-detail-offroad {
  float: right;
  border-radius: 4px;
  margin: 6px;
  padding: 1px 3px 1px 3px;
  font-size: 12px;
  color: #f0f0f0;
  background-color: #aaaaaa;
  opacity: 0.4;
}

.trips-home-item-detail-level {
  float: right;
  border-radius: 4px;
  padding: 1px 4px 1px 4px;
  font-size: 12px;
  color: #ffb8b8;
  background-color: #d55c5c;
  opacity: 0.55;
}

.trips-home-item-detail-area {
  float: right;
  border-radius: 4px;
  margin: 0px 0px 0px 4px;
  padding: 1px 3px 1px 3px;
  font-size: 12px;
  color: #b8ddff;
  background-color: #5c9bd5;
  opacity: 0.55;
}