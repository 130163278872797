@import "../../css/_variables";
@import "../../css/_mixins";


#reset-password-container {
    display: block;
    padding-top: 10px;
    width: 100%;
}

.reset-password-box {
    margin: 80px auto;
    padding: 30px 30px;
    width: 400px;
    border: solid 1px #464646;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #000;
    box-shadow: 0px 0px 4px #000000;
    &__title {
        text-align: center;
    }

    &__desc {
        padding: 8px 0px;
        margin-bottom: 12px;
        text-align: center;
    }

    &__text {
        margin: 20px 5px 5px 5px;
    }

    &__wrap {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    &__input {
        width: 300px;
        margin: 1px 0px;
    }

    &__subtitle {
        padding: 10px 0px;
        text-align: center;
    }

    &__warning {
        display: inline-block;
        vertical-align: middle;
    }

    &__warning-password-match {
        margin-top: 10px;
    }

    &__cross {
        vertical-align: middle;
        width: 12px;
        height: 12px;
        margin: 1px 6px 0px 0px;
    }

    &__button-container {
        width: 100%;
        text-align: center;
        padding-top: 10px;
    }

    &__button {
        display: inline-block;
        padding: 6px 12px;
        border-radius: 10px;
        background-color: #888888;
        color: #000000;
        text-decoration: none;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        cursor: pointer;
        opacity: 0.4;
    }

    &__button:hover {
        background-color: #3284B0;
        opacity: 1.0;
    }
}
