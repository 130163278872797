/* 
============================================================================================
    Open Map
============================================================================================
*/

.open-map {
    width: 100%;
}

.open-map-container {
    width: 100%;
}


/* 
============================================================================================
    Height Buttons
======================================= =====================================================
*/

.open-map-height-buttons {
    bottom: .5em;
    left: .5em;
}

.open-map-height-buttons button {
    line-height: 0.0em;

}


/*
============================================================================================
    Marker Factors
============================================================================================
*/

.open-map-marker-information {
    opacity: 0.0;
    width: 88px;
    background-color: rgba(0,0,0, 0.65);
    padding: 6px 8px 6px 8px;
    border: 1px solid #464646;
    border-radius: 10px;
    margin-left: 8.0em;
}

.open-map-marker-information-name {
    font-size: 13px;
    text-align: center;
    color: #FFFFFF;
}

.open-map-marker-information-factors {
    width: 72px;
    padding: 0px;
    margin: 0px;
    opacity: 1.0;
}

.open-map-marker-information-rating {
    height: 6px;
    padding: 0px;
    margin: 0px;
    float: left;
}

/*
background-color: #249CCE / #C5833B
*/

.open-map-marker-information-rating-container {
    display: inline-block;
    width: 72px;
    border: solid 1px #CCC;
    padding: 0px;
    margin: 6px 0px 3px 0px;
    background: #404040;
    border-radius: 4px;  
}

.open-map-marker-information-difficulty {
    height: 6px;
    padding: 0px;
    margin: 0px;
    float: left;
    background-color: #C72723;
}

.open-map-marker-information-difficulty-container {
    display: inline-block;
    width: 72px;
    border: solid 1px #CCC;
    padding: 0px;
    margin: 0px 0px 3px 0px;
    background: #404040;
    border-radius: 4px;
}


/*
============================================================================================
    Marker Hover Black
============================================================================================
*/

.open-map-marker-hover-black {
    width: 26px;
    height: 26px;
    opacity: 1.0;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0px 6px rgba(0, 0, 0, 0.5);
}


/*
============================================================================================
    Temporary Markers
============================================================================================
*/

.open-map-temp-marker-black {
    width: 10px;
    height: 10px;
    opacity: 1.0;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.6);
}

.open-map-temp-marker-white {
    width: 10px;
    height: 10px;
    opacity: 1.0;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0px 8px rgba(255, 255, 255, 0.6);
}


/*
============================================================================================
    Marker Pulse Black
============================================================================================
*/

.open-map-marker-pulse-black {
    width: 28px;
    height: 28px;
    opacity: 1.0;    
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: open-map-marker-pulse-black;
    -webkit-animation-duration: 4.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: open-map-marker-pulse-black;  
    -moz-animation-duration: 4.0s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes open-map-marker-pulse-black {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 21px rgba(0,0,0, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 7px rgba(0,0,0, 0.75), 0 0 0 28px rgba(0,0,0, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 14px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 21px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 28px rgba(0,0,0, 0.0), 0 0 0 7px rgba(0,0,0, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 14px rgba(0,0,0, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 21px rgba(0,0,0, 0.25);}
}

@keyframes open-map-marker-pulse-black {
    0% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 21px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 28px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 7px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 14px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0,1.0);
        box-shadow: 0 0 0 21px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0,1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 7px rgba(0,0,0, 0.75);
        box-shadow: 0 0 0 28px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 14px rgba(0,0,0, 0.5);
        box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 21px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}


/*
============================================================================================
    Marker Hover Red
============================================================================================
*/

.open-map-marker-hover-red {
    width: 26px;
    height: 26px;
    opacity: 1.0;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0px 6px rgba(175,20,16, 0.4);
}


/*
============================================================================================
    Marker Pulse Red
============================================================================================
*/

.open-map-marker-pulse-red {
    width: 28px;
    height: 28px;
    opacity: 1.0;    
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: open-map-marker-pulse-red;
    -webkit-animation-duration: 4.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: open-map-marker-pulse-red;
    -moz-animation-duration: 4.0s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes open-map-marker-pulse-red {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 21px rgba(175,20,16, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 7px rgba(175,20,16, 0.75), 0 0 0 28px rgba(175,20,16, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 14px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 21px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 28px rgba(175,20,16, 0.0), 0 0 0 7px rgba(175,20,16, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 14px rgba(175,20,16, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 21px rgba(175,20,16, 0.25);}
}

@keyframes open-map-marker-pulse-red {
    0% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 21px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 28px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 7px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 14px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16,1.0);
        box-shadow: 0 0 0 21px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16,1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 7px rgba(175,20,16, 0.75);
        box-shadow: 0 0 0 28px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 14px rgba(175,20,16, 0.5);
        box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 21px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}


/*
Red -> 175,20,16
Gray -> 98,98,98,
Blue -> 43,85,112
*/



/*
============================================================================================
    Open Layers
============================================================================================
*/
.ol-attribution {
    background: rgba(255, 255, 255, 0.5) !important;
    max-width: 120px !important;
}
.ol-attribution ul {
    padding-inline-start: 0px !important;
}

.ol-attribution li a {
    font-size: 14px !important;
    max-width: 50% !important;
}

.ol-attribution li a {
    color: #000 !important;
    text-decoration: none !important;
}
