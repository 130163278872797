@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    PlanGallery
============================================================================================
*/

.plan-gallery-prev-arrow,
.plan-gallery-next-arrow {
    font-family: "Poppins-Regular";  
    margin: 0px;
    padding: 0px;
    border: none;
    position: absolute;
    top: 50%; 
    opacity: 0.6;
    font-size: 90px;
    line-height: 90px;
    color: $white;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;  
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
}

.plan-gallery-prev-arrow {
    left: 10px;
}

.plan-gallery-next-arrow {
    right: 10px;
}

.plan-gallery-prev-arrow:hover,
.plan-gallery-next-arrow:hover {
    opacity: 0.9;
}

.plan-gallery-wrapper {
    position: relative;
    outline: 0px solid $black;
    margin: 0px 0px 0px 0px;
    overflow: hidden;
}

.plan-gallery-track {
    overflow: visible;
    white-space: nowrap;  
    position: relative;
    /* decides how fast track moves */
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}
/*
  min-height: 800px;
*/

.plan-gallery-track:hover {
}

.plan-gallery-item-location-wrapper {
    width: 100%;
    margin: 0px 0px 8px 0px;
    display:table;
}

.plan-gallery-item-location {
    width: 100%;
    display: table-cell;
    vertical-align: bottom;
    height: 90px;
}

.plan-gallery-item-location-text {
    width: 160px;
    margin: 0px auto;
    text-align: center;
}

.plan-gallery-item-marker {
    position: absolute;
    top: -6px;
    right: -22px;
    height: 44px;
    width: 44px;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.plan-gallery-item-pulse {
    width: 26px;
    height: 26px;
    margin: 9px;
}

.plan-gallery-item-line {
    width: 100%;
    display: table;
    height: 32px;
    margin: 0px 0px 0px 0px;
    padding: 0px;
}

.plan-gallery-item-left {
    width: 50%;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    height: 32px;
    margin: 0px 0px;
    padding: 0px;  
    background-repeat: no-repeat;
    background-position: left;
    background-size: 600px 30px;
    position: relative;
}

.plan-gallery-item-right {
    width: 50%;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    height: 32px;
    margin: 0px 0px;
    padding: 0px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 600px 30px;
    position: relative;
}

.plan-gallery-item-time {
    width: 100%;
    margin-bottom: 3px;  
    line-height: 23px;
    text-align: center;
}


.plan-gallery-item-time-value-day, 
.plan-gallery-item-time-value-night {
    padding: 1px 8px;
    border-radius: 15px;
    font-size: 14px;
    opacity: 0.8;
}

.plan-gallery-item-time-value-day {
    color: $white;
    background-color: $light-blue;
}

.plan-gallery-item-time-value-night {
    color: $black;
    background-color: $blue;
}

.plan-gallery-item-activity-time {
    width: 100%;
    padding: 6px 10px 12px 10px;
    text-align: center;
}


.plan-gallery-item-transit-mode {
    top: -26px;
    right: -22px;
    width: 40px;
    height: 40px;
    opacity: 0.5;
    position: absolute;
}

.plan-gallery-item-transit-time {
    bottom: -10px;
    right: -60px;
    width: 120px;
    text-align: center;
    position: absolute;
}

.plan-gallery-item-column {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    /* decides how fast item column scales up in size */
    /* needs to be in sync with media transition */  
    -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;  
    cursor: pointer;
}

.plan-gallery-media {
    margin: 0px 0px;
    border-radius: 11px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    /* decides how fast media scales up in size */
    /* needs to be in sync with item-column transition */  
    -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}

.plan-gallery-item-textbox-wrapper {
    overflow: hidden;
    /*
    -webkit-transition: height 2.0s ease-in-out;
    -moz-transition: height 2.0s ease-in-out;
    -ms-transition: height 2.0s ease-in-out;
    -o-transition: height 2.0s ease-in-out;
    transition: height 2.0s ease-in-out;
    */
}

.plan-gallery-item-textbox {
    opacity: 0.0;
    /* decides how fast text box appears and disappears */
    /* needs to be smaller than testBoxTansitionTime specified in plan-gallery.js */
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
}

.plan-gallery-item-title {
    padding: 10px 10px 0px 10px;
}

.plan-gallery-item-description {
    padding: 6px 10px 10px 10px;  
}

.plan-gallery-item-description-highlight {
}

.plan-gallery-item-description-regular {
}

.plan-gallery-media-loader {  
    background-size: 56px 56px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.plan-gallery-media-ghost-loader {
    position: absolute;
    opacity: 0.0;
}

.plan-gallery-pulse {
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: plan-gallery-pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: plan-gallery-pulse;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}

@-webkit-keyframes plan-gallery-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33%  {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}

@keyframes plan-gallery-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);
        box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}    
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);
        box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);
        box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}
