@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    General
============================================================================================
*/

.body {
    padding-bottom: 50px;
}


/*
============================================================================================
    Layout
============================================================================================
*/

#trip-container {
    width: 100%;
    min-width: 320px;
}

.trip-container {
    padding-top: 68px;
}

.trip-container-small {
    padding-top: 58px;
}

#trip-more-cotainer {
    float: right;
}

#trip-preview {
    width: 100%;
    padding-top: 8px;
    text-align: center;
    display: block;
    overflow: visible;
}

#trip-preview-left {
    width: 160px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

#trip-preview-left-mobile {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.trip-preview-left-mobile-column {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#trip-preview-middle {
    margin: 0px 10px;
    display: inline-block;
    vertical-align: top;
}

#trip-preview-right {
    width: 160px;
    display: inline-block;
    vertical-align: top;
}

#trip-preview-right-mobile {
    display: inline-block;
    vertical-align: middle;
}

.trip-preview-right-mobile-column {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

#trip-preview-narrow {
    width: 100%;
    margin-top: 16px;
    white-space: nowrap;
    overflow: visible;
}

#trip-go-container {
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 20;
    padding: 10px 0px;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.trip-go-container-day {
    background-color: rgba(248, 248, 248, 0.9);
}

.trip-go-container-night {
    background-color: rgba(0, 0, 0, 0.9);
}


/*
============================================================================================
    Trip Numbers
============================================================================================
*/

.trip-preview-numbers-container {
    margin-bottom: 12px;
}

.trip-preview-numbers-title {
    padding: 6px;
    text-align: center;
}

#trip-preview-numbers-rating {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#trip-preview-numbers-rating-mobile {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#trip-preview-numbers-rating-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}

#trip-preview-numbers-difficulty {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#trip-preview-numbers-difficulty-mobile {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#trip-preview-numbers-difficulty-tbd {
    font-size: 40px;
    line-height: 36px;
    text-align: center;
}

#trip-preview-numbers-difficulty-tbd-mobile {
    font-size: 36px;
    line-height: 32px;
    text-align: center;
}

#trip-preview-numbers-difficulty-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}


/*
============================================================================================
    Completed Users
============================================================================================
*/

.trip-preview-completed-users-title {
}

.trip-preview-completed-users {
}

.trip-preview-completed-users-count {
}

.trip-preview-completed-user-profile-pics {
}

.trip-preview-completed-user-profile-pic {
}

.trip-preview-completed-users-more {
}

.trip-preview-completed-users-short {
}


/*
============================================================================================
    Trip Intro
============================================================================================
*/

#trip-preview-intro {
}

#trip-preview-title-container {
    margin-bottom: 12px;
}

#trip-preview-title {
    display: inline-block;
    vertical-align: middle;
    padding: 2px 0px;
    margin: 0px 4px;
}

#trip-preview-area {
    display: inline-block;
    vertical-align: middle;
    padding: 2px 0px;
    margin: 0px 4px;
}

#trip-preview-overview {
    text-align: left;
}


/* 
============================================================================================
    Trip Like / Share / Save Buttons
============================================================================================
*/

#trip-interaction-box {
    width: 100%;
    margin-top: 16px;
}

#trip-like-share-container {
    margin-left: 4px;
    float: left;
}

#trip-save-container {
    margin-right: 4px;
    float: right;
}

#trip-like-button-on, 
#trip-like-button-off,
#trip-share-button,
#trip-save-button-on,
#trip-save-button-off {
    vertical-align: middle;
}

#trip-like-button-on,
#trip-like-button-off, 
#trip-share-button {
    margin-right: 6px;
}

#trip-save-button-on,
#trip-save-button-off {
}

#trip-like-button-on,
#trip-save-button-on {
    opacity: 0.8 !important;
}

#trip-like-button-off,
#trip-share-button,
#trip-save-button-off {
}

#trip-like-button-off:hover,
#trip-share-button:hover,
#trip-save-button-off:hover {
}

#trip-save-button-text {
    display: inline-block;
    vertical-align: middle;
    padding-right: 6px;
    line-height: 32px;
}

#trip-like-count {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    margin-right: 8px;
}

#trip-save-count {
}


/*
============================================================================================
    Open Itineraries
============================================================================================
*/

#trip-go-button-groups-container {
    position: relative;
    margin: 0px auto;
    padding: 16px 0px;
}

#trip-go {
    width: auto;
    min-width: 160px;
    vertical-align: middle;
}


/*
============================================================================================
    Go Button and Go Info Container
============================================================================================
*/

#trip-go-info-container {
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

#trip-go-info {
    position: relative;
    display: inline-block;
    padding-bottom: 20px;
}

#trip-go-button-container {
    margin: 0px auto;
    text-align: center;
}

#trip-go-button-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

#trip-go-button-title-groups {
    padding-bottom: 4px;
    margin: 0px auto;
}

#trip-go-button, 
#trip-go-button-groups {
    display: inline-block;
    vertical-align: middle;
    width: 59px;
    height: 68px;
}

#trip-go-button {
}

#trip-go-button-groups {
    margin: 0px auto;
}

#trip-go-button:hover, 
#trip-go-button-groups:hover {
    opacity: 1.0;
}

#trip-go-cancel-button {
    position: absolute;
    top: -10px;
    right: 6px;
}

#trip-go-cancel-button:hover {
}


/*
============================================================================================
    Calendar / DayInfo / StartLocation
============================================================================================
*/

#trip-calendar-dayinfo-container {
    width: 100%;
}

#trip-calendar-dayinfo {
    width: 100%;
    padding: 0px 40px;
    margin-bottom: 10px;
}

#trip-calendar-column, #trip-dayinfo-column {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    text-align: center;
}

#trip-calendar-title, #trip-dayinfo-title {
    padding-bottom: 6px;
    text-align: center;
}

#trip-calendar-container {
    height: 42px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
}

#trip-calendar-icon {
    vertical-align: middle;
    width: 26px;
    height: 26px;
    margin: 2px 10px 0px 0px;
}

/* Need to implement this to the DatePicker library */
.trip-calendar-input-day, 
.trip-calendar-input-night {
    width: 210px !important;
    text-align: center !important;
    border: none !important;
    cursor: pointer !important;
    background-color: transparent !important;
    text-align: left !important;
    font-size: 34px !important;
}

.trip-calendar-input-day {
    color: $light-blue !important;
}

.trip-calendar-input-night {
    color: $blue !important;
}

#trip-calendar {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

#trip-dayinfo {
    text-align: center;
}


/*
============================================================================================
    Time Slider
============================================================================================
*/

#trip-time {
    width: 100%;
}

#trip-time-slider-title {
    padding: 0px 0px 24px 0px;
    text-align: center;
}

#trip-time-slider-container {
    width: 80%;
    max-width: 600px;
    margin: 0px auto;
    padding: 0px 0px 32px 0px;
    text-align: center;
}

#trip-time-slider-icon {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

#trip-time-slider {
    width: calc(100% - 38px);
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.trip-time-slider-dot-day,
.trip-time-slider-dot-night {
    border-radius: 100%;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem; 
}

.trip-time-slider-dot-day {
    background: $light-gray;
    border: 2px solid $light-gray;
}

.trip-time-slider-dot-night {
    background: $dark-gray;
    border: 2px solid $dark-gray;
}

.trip-time-slider-text-day,
.trip-time-slider-text-night {
    width: 100px;
    display: block;
    position: absolute;
    bottom: -20px;
    margin-left: -50px;
    text-align: center;
}

.trip-time-slider-text-day {
    color: $light-gray;
}

.trip-time-slider-text-night {
    color: $dark-gray;
}


/*
============================================================================================
    Start and End Locations
============================================================================================
*/

#trip-locations-container {
    width: 100%;
}

#trip-locations-title {
    width: 100%;
    padding: 0px 0px 6px 0px;
    text-align: center;
}

#trip-locations {
    width: 100%;
    padding: 0px 15px;
    text-align: center;
    white-space: nowrap;
}

#trip-locations-inputs {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    white-space: normal;
}

#trip-locations-inputs-mobile {
    width: 256px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    white-space: normal;
}

#trip-locations-icon {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-right: 4px;
}

#trip-start-location-input, 
#trip-end-location-input {
    display: inline-block;
    vertical-align: middle;
    width: 240px;
    margin: 4px 8px;
}

#trip-start-location-input:focus,
#trip-end-location-input:focus {
}

#trip-more-button {
    cursor: pointer;
    width: 20px;
    height: 30px;
    margin-left: 6px;
}


/*
============================================================================================
    Curation
============================================================================================
*/

.trip-preview-overview-container,
.trip-preview-todos-container,
.trip-preview-history-container,
.trip-preview-stories-container,
.trip-preview-overview-container-expanded,
.trip-preview-todos-container-expanded,
.trip-preview-history-container-expanded,
.trip-preview-stories-container-expanded,
.trip-preview-overview-container-folded,
.trip-preview-todos-container-folded,
.trip-preview-history-container-folded,
.trip-preview-stories-container-folded {

}

.trip-preview-overview-container,
.trip-preview-todos-container,
.trip-preview-history-container,
.trip-preview-stories-container {
}

.trip-preview-overview-container-expanded,
.trip-preview-todos-container-expanded,
.trip-preview-history-container-expanded,
.trip-preview-stories-container-expanded {
}

.trip-preview-overview-container-folded,
.trip-preview-todos-container-folded,
.trip-preview-history-container-folded,
.trip-preview-stories-container-folded {
    overflow: hidden;
}

.trip-preview-overview-container-less,
.trip-preview-todos-container-less,
.trip-preview-history-container-less,
.trip-preview-stories-container-less,
.trip-preview-overview-container-more,
.trip-preview-todos-container-more,
.trip-preview-history-container-more,
.trip-preview-stories-container-more {
    width: 100%;
    height: 14px;
    cursor: pointer;
    margin-top: 8px;
}

.trip-preview-overview-container-less,
.trip-preview-todos-container-less,
.trip-preview-history-container-less,
.trip-preview-stories-container-less {
    background-size: auto 6px !important;
}

.trip-preview-overview-container-more,
.trip-preview-todos-container-more,
.trip-preview-history-container-more,
.trip-preview-stories-container-more {
    background-size: auto 14px !important;
}


/*
============================================================================================
    Bottle
============================================================================================
*/

.trip-bottle {
    text-align: center;
    margin-bottom: 30px;
    white-space: nowrap;
}

.trip-bottle-user-count {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    white-space: nowrap;
    text-align: center;
}

.trip-bottle-user-profile-pics {
    display: inline-block;
    vertical-align: middle;
}

.trip-bottle-user-left,
.trip-bottle-user-middle,
.trip-bottle-user-right {
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    background-repeat: no-repeat;
    background-size: auto 60px;
}

.trip-bottle-user-left {
    width: 93px;
    padding: 8px 3px 8px 46px;
    background-position: right;
}
.trip-bottle-user-middle {
    width: 50px;
    padding: 8px 3px;
    background-position: center;
}
.trip-bottle-user-right {
    width: 55px;
    padding: 8px 8px 8px 3px;
    background-position: left;
}

.trip-bottle-speakerphone {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -4px;
    right: -4px;
    opacity: 1.0;
    cursor: pointer;
}

.trip-bottle-user-profile-pic {
    position: relative;
}


/*
============================================================================================
    Map
============================================================================================
*/

#trip-map-container {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto;
}

#trip-map-wrapper {
    border-radius: 10px;
    overflow: hidden;
}

#trip-map-title {
    padding-bottom: 16px;
    text-align: center;
}


/*
============================================================================================
    Editor
============================================================================================
*/

#trip-preview-editor {
}

#trip-preview-editor-title {
    padding-bottom: 8px;
    text-align: center;
}

#trip-preview-editor-profile-pic-loader {
    width: 120px;
    height: 120px;
    margin: 0px auto 2px auto;
}

#trip-preview-editor-profile-pic-loader-medium {
    width: 80px;
    height: 80px;
}

#trip-preview-editor-profile-pic-loader-small {
    width: 64px;
    height: 64px;
}

#trip-preview-editor-profile-pic {
    width: 100%;
    height: 100%;
}

#trip-preview-editor-name {
    padding-bottom: 6px;
    text-align: center;
    cursor: pointer;
}

#trip-preview-editor-follow-button-container {
}

#trip-preview-editor-follow-button-on,
#trip-preview-editor-follow-button-off {
    width: 72px;
    margin: 0px auto 6px auto;
}

#trip-preview-editor-follow-button-on {
    opacity: 1.0 !important;
}

#trip-preview-editor-followers {
    padding-bottom: 4px;
    text-align: center;
}

#trip-preview-editor-follow-button:hover {
    opacity: 1.0;
}

#trip-preview-editor-buttons-container {
    margin-bottom: 15px;
    position: relative;
}

#trip-preview-editor-buttons-container-mobile {
    position: relative;
}

#trip-preview-editor-buttons {
    width: 69px;
    height: 20px;
    margin: 0px auto;
}

#trip-preview-editor-chat-button-on,
#trip-preview-editor-chat-button-off,
#trip-preview-editor-calendar-button-on,
#trip-preview-editor-calendar-button-off,
#trip-preview-editor-info-button-on,
#trip-preview-editor-info-button-off {
    width: 23px;
    height: 20px;
}

#trip-preview-editor-chat-button-on,
#trip-preview-editor-calendar-button-on,
#trip-preview-editor-info-button-on {
    opacity: 0.8 !important;
}


/*
============================================================================================
    Editor Information
============================================================================================
*/

#trip-preview-editor-info {
    width: 140px;
    height: 200px;
    position: absolute;
    top: 100px;
    right: -140px;
    border-radius: 10px;
    text-align: center;
    z-index: 5;
    transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
}

.trip-preview-editor-info-day {
    background-color: $menu-frame-day;
}

.trip-preview-editor-info-night {
    background-color: $menu-frame-night;
}

#trip-preview-editor-info-triangle {
    width: 50px;
    height: 20px;
    position: absolute;
    top: 0px;
    right: -10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
}


/*
============================================================================================
    Editor Information Window
============================================================================================
*/
#trip-preview-editor-info-published, 
#trip-preview-editor-info-guided {
    padding: 16px 0px 6px 0px;
    margin-bottom: 6px;
}

.trip-preview-editor-info-published-day, 
.trip-preview-editor-info-guided-day {
    border-bottom: solid 1px $white;
}

.trip-preview-editor-info-published-night, 
.trip-preview-editor-info-guided-night {
    border-bottom: solid 1px $black;
}

#trip-preview-editor-info-published-dots {
}

.trip-preview-editor-info-published-number {
}

.trip-preview-editor-info-published-title {
}


/*
============================================================================================
    Staff (Contributors and Helpers)
============================================================================================
*/

#trip-preview-staff {
    margin: 0px auto;
}

#trip-preview-staff-spacer {
    width: 100%;
    height: 15px;
}

.trip-preview-contributors, 
.trip-preview-helpers {
    text-align: center;
}

.trip-preview-contributors-count, .trip-preview-helpers-count {
    padding: 0px 6px 6px 6px;
    white-space: nowrap;
    text-align: center;
}

.trip-preview-contributor-profile-pics, .trip-preview-helper-profile-pics {
}

.trip-preview-contributor-profile-pic, .trip-preview-helper-profile-pic {
    margin: 0px 2px;
}

.trip-preview-contributors-more, .trip-preview-helpers-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

.trip-preview-contributors-short, .trip-preview-helpers-short {
    padding: 6px;
    white-space: nowrap;
    text-align: center;
}

.trip-preview-helper-simple-list {
    padding: 6px;
    white-space: nowrap;
    text-align: center;
}


/*
============================================================================================
    Curation
============================================================================================
*/

.trip-curation-capitalize {
    float: left;
    padding-right: 0px;
}

.trip-curation-highlight {
    width: 80%;
    margin: 0px auto;
    padding: 0px 15px;
    clear: left;
    text-align: center;
}

.trip-curation-paragraph {
}

.trip-space-paragraph {
    height: 20px;
}

.trip-curation-text {
    white-space: pre-wrap;
    line-height: 20px;
}


/*
============================================================================================
    Overview
============================================================================================
*/

#trip-overview-title {
    margin: 32px 0px 16px 0px;
    text-align: center;
}


/*
============================================================================================
    Overview Gallery
============================================================================================
*/

.trip-overview-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.trip-overview-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.trip-overview-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.trip-overview-gallery-video {}

.trip-overview-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.trip-overview-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Overview Gallery Navigation
============================================================================================
*/

.trip-overview-gallery-prev-arrow, 
.trip-overview-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-overview-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.trip-overview-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.trip-overview-gallery-prev-arrow:hover,
.trip-overview-gallery-next-arrow:hover {
    opacity: 0.85;
}

.trip-overview-gallery-video:hover ~ .trip-overview-gallery-prev-arrow,
.trip-overview-gallery-video:hover ~ .trip-overview-gallery-next-arrow,
.trip-overview-gallery-image:hover ~ .trip-overview-gallery-prev-arrow,
.trip-overview-gallery-image:hover ~ .trip-overview-gallery-next-arrow {    
    opacity: 0.5;
}

.trip-overview-gallery-dot-on,
.trip-overview-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-overview-gallery-dot-on {
    opacity: 0.8;
}

.trip-overview-gallery-dot-off {
    opacity: 0.4;
}

.trip-overview-gallery-trip-off:hover {
    opacity: 0.8;
}

.trip-overview-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Overview Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.trip-overview-gallery-dot-tag-image, 
.trip-overview-gallery-user-tag-image,
.trip-overview-gallery-unsave-image,
.trip-overview-gallery-enlarge-image,
.trip-overview-gallery-dot-tag-video,
.trip-overview-gallery-user-tag-video,
.trip-overview-gallery-unsave-video,
.trip-overview-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.trip-overview-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.trip-overview-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.trip-overview-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.trip-overview-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.trip-overview-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.trip-overview-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.trip-overview-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.trip-overview-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.trip-overview-gallery-dot-tag-image:hover,
.trip-overview-gallery-user-tag-image:hover,
.trip-overview-gallery-unsave-image:hover,
.trip-overview-gallery-enlarge-image:hover,
.trip-overview-gallery-dot-tag-video:hover,
.trip-overview-gallery-user-tag-video:hover,
.trip-overview-gallery-unsave-video:hover,
.trip-overview-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.trip-overview-gallery-video:hover ~ .trip-overview-gallery-dot-tag-video,
.trip-overview-gallery-video:hover ~ .trip-overview-gallery-user-tag-video,
.trip-overview-gallery-video:hover ~ .trip-overview-gallery-unsave-video,
.trip-overview-gallery-video:hover ~ .trip-overview-gallery-enlarge-video,
.trip-overview-gallery-image:hover ~ .trip-overview-gallery-dot-tag-image,
.trip-overview-gallery-image:hover ~ .trip-overview-gallery-user-tag-image,
.trip-overview-gallery-image:hover ~ .trip-overview-gallery-unsave-image,
.trip-overview-gallery-image:hover ~ .trip-overview-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Todos 
============================================================================================
*/

#trip-todos-title {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto 10px auto;
    text-align: center;
}

#trip-todos {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 0px auto 16px auto;
    color: #999999;
    text-align: left;
}


/*
============================================================================================
    Todos Gallery
============================================================================================
*/

.trip-todos-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.trip-todos-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.trip-todos-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.trip-todos-gallery-video {}

.trip-todos-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.trip-todos-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Todos Gallery Navigation
============================================================================================
*/

.trip-todos-gallery-prev-arrow, 
.trip-todos-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-todos-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.trip-todos-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.trip-todos-gallery-prev-arrow:hover,
.trip-todos-gallery-next-arrow:hover {
    opacity: 0.85;
}

.trip-todos-gallery-video:hover ~ .trip-todos-gallery-prev-arrow,
.trip-todos-gallery-video:hover ~ .trip-todos-gallery-next-arrow,
.trip-todos-gallery-image:hover ~ .trip-todos-gallery-prev-arrow,
.trip-todos-gallery-image:hover ~ .trip-todos-gallery-next-arrow {    
    opacity: 0.5;
}

.trip-todos-gallery-trip-on,
.trip-todos-gallery-trip-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-todos-gallery-trip-on {
    opacity: 0.8;
}

.trip-todos-gallery-trip-off {
    opacity: 0.4;
}

.trip-todos-gallery-trip-off:hover {
    opacity: 0.8;
}

.trip-todos-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Todos Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.trip-todos-gallery-dot-tag-image, 
.trip-todos-gallery-user-tag-image,
.trip-todos-gallery-unsave-image,
.trip-todos-gallery-enlarge-image,
.trip-todos-gallery-dot-tag-video,
.trip-todos-gallery-user-tag-video,
.trip-todos-gallery-unsave-video,
.trip-todos-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.trip-todos-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.trip-todos-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.trip-todos-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.trip-todos-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.trip-todos-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.trip-todos-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.trip-todos-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.trip-todos-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.trip-todos-gallery-dot-tag-image:hover,
.trip-todos-gallery-user-tag-image:hover,
.trip-todos-gallery-unsave-image:hover,
.trip-todos-gallery-enlarge-image:hover,
.trip-todos-gallery-dot-tag-video:hover,
.trip-todos-gallery-user-tag-video:hover,
.trip-todos-gallery-unsave-video:hover,
.trip-todos-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.trip-todos-gallery-video:hover ~ .trip-todos-gallery-dot-tag-video,
.trip-todos-gallery-video:hover ~ .trip-todos-gallery-user-tag-video,
.trip-todos-gallery-video:hover ~ .trip-todos-gallery-unsave-video,
.trip-todos-gallery-video:hover ~ .trip-todos-gallery-enlarge-video,
.trip-todos-gallery-image:hover ~ .trip-todos-gallery-dot-tag-image,
.trip-todos-gallery-image:hover ~ .trip-todos-gallery-user-tag-image,
.trip-todos-gallery-image:hover ~ .trip-todos-gallery-unsave-image,
.trip-todos-gallery-image:hover ~ .trip-todos-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    History
============================================================================================
*/

#trip-history-title {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto 10px auto;
    text-align: center;
}

#trip-history {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 0px auto 16px auto;
    color: #999999;
    text-align: left;
}


/*
============================================================================================
    History Gallery
============================================================================================
*/

.trip-history-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.trip-history-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.trip-history-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.trip-history-gallery-video {}

.trip-history-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.trip-history-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    History Gallery Navigation
============================================================================================
*/

.trip-history-gallery-prev-arrow, 
.trip-history-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-history-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.trip-history-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.trip-history-gallery-prev-arrow:hover,
.trip-history-gallery-next-arrow:hover {
    opacity: 0.85;
}

.trip-history-gallery-video:hover ~ .trip-history-gallery-prev-arrow,
.trip-history-gallery-video:hover ~ .trip-history-gallery-next-arrow,
.trip-history-gallery-image:hover ~ .trip-history-gallery-prev-arrow,
.trip-history-gallery-image:hover ~ .trip-history-gallery-next-arrow {    
    opacity: 0.5;
}

.trip-history-gallery-trip-on,
.trip-history-gallery-trip-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-history-gallery-trip-on {
    opacity: 0.8;
}

.trip-history-gallery-trip-off {
    opacity: 0.4;
}

.trip-history-gallery-trip-off:hover {
    opacity: 0.8;
}

.trip-history-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    History Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.trip-history-gallery-dot-tag-image, 
.trip-history-gallery-user-tag-image,
.trip-history-gallery-unsave-image,
.trip-history-gallery-enlarge-image,
.trip-history-gallery-dot-tag-video,
.trip-history-gallery-user-tag-video,
.trip-history-gallery-unsave-video,
.trip-history-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.trip-history-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.trip-history-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.trip-history-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.trip-history-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.trip-history-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.trip-history-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.trip-history-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.trip-history-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.trip-history-gallery-dot-tag-image:hover,
.trip-history-gallery-user-tag-image:hover,
.trip-history-gallery-unsave-image:hover,
.trip-history-gallery-enlarge-image:hover,
.trip-history-gallery-dot-tag-video:hover,
.trip-history-gallery-user-tag-video:hover,
.trip-history-gallery-unsave-video:hover,
.trip-history-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.trip-history-gallery-video:hover ~ .trip-history-gallery-dot-tag-video,
.trip-history-gallery-video:hover ~ .trip-history-gallery-user-tag-video,
.trip-history-gallery-video:hover ~ .trip-history-gallery-unsave-video,
.trip-history-gallery-video:hover ~ .trip-history-gallery-enlarge-video,
.trip-history-gallery-image:hover ~ .trip-history-gallery-dot-tag-image,
.trip-history-gallery-image:hover ~ .trip-history-gallery-user-tag-image,
.trip-history-gallery-image:hover ~ .trip-history-gallery-unsave-image,
.trip-history-gallery-image:hover ~ .trip-history-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Stories
============================================================================================
*/

#trip-stories-title {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto 10px auto;
    text-align: center;
}

#trip-stories {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 0px auto 16px auto;
    color: #999999;
    text-align: left;
}


/*
============================================================================================
    Stories Gallery
============================================================================================
*/

.trip-stories-gallery-container {
    position: relative;
    text-align: center;
    margin-top: 16px;
}

.trip-stories-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.trip-stories-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.trip-stories-gallery-video {}

.trip-stories-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.trip-stories-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/* 
============================================================================================
    Stories Gallery Navigation
============================================================================================
*/

.trip-stories-gallery-prev-arrow, 
.trip-stories-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-stories-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.trip-stories-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.trip-stories-gallery-prev-arrow:hover,
.trip-stories-gallery-next-arrow:hover {
    opacity: 0.85;
}

.trip-stories-gallery-video:hover ~ .trip-stories-gallery-prev-arrow,
.trip-stories-gallery-video:hover ~ .trip-stories-gallery-next-arrow,
.trip-stories-gallery-image:hover ~ .trip-stories-gallery-prev-arrow,
.trip-stories-gallery-image:hover ~ .trip-stories-gallery-next-arrow {    
    opacity: 0.5;
}

.trip-stories-gallery-trip-on,
.trip-stories-gallery-trip-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.trip-stories-gallery-trip-on {
    opacity: 0.8;
}

.trip-stories-gallery-trip-off {
    opacity: 0.4;
}

.trip-stories-gallery-trip-off:hover {
    opacity: 0.8;
}

.trip-stories-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Stories Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.trip-stories-gallery-dot-tag-image, 
.trip-stories-gallery-user-tag-image,
.trip-stories-gallery-unsave-image,
.trip-stories-gallery-enlarge-image,
.trip-stories-gallery-dot-tag-video,
.trip-stories-gallery-user-tag-video,
.trip-stories-gallery-unsave-video,
.trip-stories-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.trip-stories-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.trip-stories-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.trip-stories-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.trip-stories-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.trip-stories-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.trip-stories-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.trip-stories-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.trip-stories-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.trip-stories-gallery-dot-tag-image:hover,
.trip-stories-gallery-user-tag-image:hover,
.trip-stories-gallery-unsave-image:hover,
.trip-stories-gallery-enlarge-image:hover,
.trip-stories-gallery-dot-tag-video:hover,
.trip-stories-gallery-user-tag-video:hover,
.trip-stories-gallery-unsave-video:hover,
.trip-stories-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.trip-stories-gallery-video:hover ~ .trip-stories-gallery-dot-tag-video,
.trip-stories-gallery-video:hover ~ .trip-stories-gallery-user-tag-video,
.trip-stories-gallery-video:hover ~ .trip-stories-gallery-unsave-video,
.trip-stories-gallery-video:hover ~ .trip-stories-gallery-enlarge-video,
.trip-stories-gallery-image:hover ~ .trip-stories-gallery-dot-tag-image,
.trip-stories-gallery-image:hover ~ .trip-stories-gallery-user-tag-image,
.trip-stories-gallery-image:hover ~ .trip-stories-gallery-unsave-image,
.trip-stories-gallery-image:hover ~ .trip-stories-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/* 
============================================================================================
    Bucketed Out
============================================================================================
*/

.trip-bucketed-out {
    width: 100px;
    height: 48px;
}

.trip-bucketed-out-small {
    width: 84px;
    height: 44px;
}


/* 
============================================================================================
    Board
============================================================================================
*/

#trip-board-title {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
}

#trip-board-container {
    min-width: 320px;
    margin: 16px 0px 0px 0px;
}


/* 
============================================================================================
    Date Picker
============================================================================================
*/

.react-datepicker__current-month {
    font-family: cabin-regular !important;
}


/* 
============================================================================================
    Input Range (Need to Fix Library Source Later)
============================================================================================
*/

.input-range__label--value span {
    color: #207DA3;
    font-family: cabin;
}
