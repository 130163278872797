/* 
============================================================================================
    Modal
============================================================================================
*/

@mixin modal-background {
    display: block;
    position: fixed;
    z-index: 40;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

@mixin modal-minimal {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-bottom: 30px;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
    transform: translate(-50%, -50%);

    &__title {
        display: block;
        text-align: center;
        padding: 20px 50px 10px 50px;
    }

    &__cancel {
        width: 25px !important;
        height: 25px !important;
        float: right;
        margin: 15px 15px 0px 0px;
    }
}

@mixin modal-basic {
    top: 50%;
    left: 50%;
    padding-bottom: 20px;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    position: absolute;
    overflow: auto;
    box-shadow: 0px 0px 2px $black;

    &__title {
        display: block;
        text-align: center;
        padding: 20px 50px 10px 50px;
    }

    &__cancel {
        width: 25px !important;
        height: 25px !important;
        float: right;
        margin: 15px 15px 0px 0px;
    }

    &__sub-title {
        display: block;
        text-align: center;
        padding: 10px 0px;
    }

    &__item {
        padding: 5px 0px;
        opacity: 0.75;
        cursor: pointer;
    }

    &__item:hover {
        opacity: 1.0;
    }

    &__item-image {
        display: inline-block;
        vertical-align: middle;
        height: 26px;
        width: 26px;
        margin-right: 10px;
    }
    
    &__item-text {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        word-break: break-word;
    }

    &__row-right {
        width: 100%;
        text-align: right;
    }

    &__row-left {
        width: 100%;
        text-align: left;
    }

    &__row-center {
        width: 100%;
        text-align: center;
    }
}

@mixin modal-basic-binary {
    @include modal-basic;

    &__cancel {
        top: 0px;
        right: 0px;
        position: absolute;
        width: 25px !important;
        height: 25px !important;
        margin: 15px 15px 0px 0px;
    }

    &__text {
        padding: 0px 0px 10px 0px;
        text-align: center;
    }

    &__confirm-select {
        margin: auto;
        text-align: center;

        &-yes,
        &-no {
            width: 90px;
            margin: 0px 5px;
        }
    }
}


@mixin modal-follow {
    @include modal-basic;

    &__items {
        padding: 0px 20px;
        max-height: 412px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__item {
        display: block;
        opacity: 1.0;
    }

    &__item-image {
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        border-radius: 50%;
    }

    &__item-action {
        display: inline-block;
        float: right;
        margin: 12px 0px;
        opacity: 0.0 !important;
    }

    &__item-text {
        display: inline-block;
        vertical-align: middle;
        opacity: 0.75;
    }

    &__item:hover &__item-action {
        opacity: 0.6 !important;
    }

    &__item:hover &__item-text {
        opacity: 1.0;
    }

    &__item-action:hover {
        opacity: 1.0 !important;
    }
}


@mixin modal-dot-tag {
    @include modal-basic;

    &__title {
    }

    &__sub-title {
    }

    &__shadow {
        width: 100%;
        height: 100%;
    }

    &__image,
    &__video {
        width: 100%;
        height: 100%;
        margin: 5px 0px;
        overflow: hidden;
    }

    &__video {
        background-size: 48px !important;
    }

    &__input {
        display: block !important;
        width: calc(100% - 60px);
        margin: 10px 30px;
        text-align: center;
    }

    &__items {
        padding: 0px 30px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__item {
        opacity: 1.0 !important;
    }

    &__item-name {
        opacity: 0.75;
    }

    &__item-image {
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        width: 22px;
    }

    &__item-remove {
        display: inline-block;
        vertical-align: middle;
        margin-left: 6px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        opacity: 0.0;
    }

    &__item:hover &__item-remove {
        opacity: 1.0;
    }

    &__item-add {
        vertical-align: middle;
        padding-left: 10px;
        cursor: pointer;
    }

    &__expand {
        padding: 0px 0px 0px 0px;
    }

    &__expand-image {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    &__expand-content {
        padding: 10px 0px 0px 0px;
    }
}

@mixin modal-user-tag {
    @include modal-basic;

    &__title {
    }

    &__sub-title {
    }

    &__shadow {
        width: 100%;
        height: 100%;
    }

    &__image,
    &__video {
        width: 100%;
        height: 100%;
        margin: 5px 0px;
        overflow: hidden;
    }

    &__video {
        background-size: 48px !important;
    }

    &__input {
        display: block !important;
        width: calc(100% - 60px);
        margin: 10px 30px;
        text-align: center;
    }

    &__items {
        padding: 0px 20px;
        max-height: 412px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__item {
        opacity: 1.0 !important;
    }

    &__item-name {
        opacity: 0.75;
    }

    &__item-image {
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
        margin: 0px 10px 0px 0px;
        border-radius: 50%;
    }

    &__item-remove,
    &__item-add {
        display: inline-block;
        float: right;
        height: 24px;
        width: 24px;
        margin: 10px 0px;
        opacity: 0.0 !important;
    }

    &__item:hover &__item-name {
        opacity: 1.0;
    }

    &__item:hover &__item-remove,
    &__item:hover &__item-add {
        opacity: 0.6 !important;
    }

    &__item-remove:hover,
    &__item-add:hover {
        opacity: 1.0 !important;
    }
}

@mixin modal-posting {
    @include modal-basic;

    &__item-action-button {
        display: inline-block;
        float: right;
        opacity: 0.0 !important;
    }

    &__items {
        padding: 0px 20px;
        max-height: 400px;
        margin-bottom: 30px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__item {
        padding: 8px 0px;
        opacity: 1.0;
    }

    &__item-text {
        opacity: 0.75;
    }

    &__item-time-container {
        padding-bottom: 6px;
    }

    &__item-time {

    }

    &__item:hover &__item-action-button {
        opacity: 0.6 !important;
    }

    &__item:hover &__item-text {
        opacity: 1.0;
    }

    &__item:hover &__item-time {
        opacity: 1.0;
    }

    &__item-action-button:hover {
        opacity: 1.0 !important;
    }
}


@mixin modal-participant {
    @include modal-basic;

    &__date {
        display: block;
        text-align: center;
        padding: 20px 50px 10px 50px;
        font-size: 24px;
    }

    &__area {
        display: block;
        text-align: center;
        padding: 0px 10px 15px 10px;
    }

    &__title {
    }

    &__sub-title {
    }

    &__shadow {
        width: 100%;
        height: 100%;
    }

    &__image,
    &__video {
        width: 100%;
        height: 100%;
        margin: 5px 0px;
        overflow: hidden;
    }

    &__video {
        background-size: 48px !important;
    }

    &__input {
        display: block !important;
        width: calc(100% - 60px);
        margin: 10px 30px;
        text-align: center;
    }

    &__items {
        padding: 0px 20px;
        max-height: 412px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__item {
        opacity: 1.0 !important;
    }

    &__item-name {
        opacity: 0.75;
    }

    &__item-image {
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
        margin: 0px 10px 0px 0px;
        border-radius: 50%;
    }

    &__item-remove,
    &__item-add {
        display: inline-block;
        float: right;
        height: 24px;
        width: 24px;
        margin: 10px 0px;
        opacity: 0.0 !important;
    }

    &__item:hover &__item-name {
        opacity: 1.0;
    }

    &__item:hover &__item-remove,
    &__item:hover &__item-add {
        opacity: 0.6 !important;
    }

    &__item-remove:hover,
    &__item-add:hover {
        opacity: 1.0 !important;
    }
}


/* 
============================================================================================
    Alert Center
============================================================================================
*/

@mixin alert-basic-center {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    z-index: 50;
    text-align: center;

    &__title {
        padding: 0px 0px 10px 0px;
    }

    &__message {
        padding: 0px 0px 0px 0px;
    }

    &__cancel {
        padding: 0px 0px 0px 0px;
    }

    &__cancel-image {
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

@mixin alert-warning-center-day {
    color: $white;
    /* background-color: rgba(78, 163, 208, 0.8); */
    background-color: rgba(179, 0, 0, 0.7);
}

@mixin alert-warning-center-night {
    color: $white;
    /* background-color: rgba(50, 132, 176, 0.8); */
    background-color: rgba(132, 0, 0, 0.7);
}

@mixin alert-notification-center-day {
    color: $white;
    background-color: rgba(78, 163, 208, 0.8);
}

@mixin alert-notification-center-night {
    color: $white;
    background-color: rgba(50, 132, 176, 0.8);
}

/* 
============================================================================================
    Alert Top
============================================================================================
*/

@mixin alert-basic-top {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 0px 0px 12px 12px;
    min-width: 300px;
    max-width: 600px;
    z-index: 51;
    text-align: center;

    &__title {
        padding: 0px 0px 10px 0px;
    }

    &__message {
        padding: 0px 0px 0px 0px;
    }

    &__cancel {
        padding: 0px 0px 0px 0px;
    }

    &__cancel-image {
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

@mixin alert-basic-top-day {
    color: $white;
    background-color: rgba(78, 163, 208, 0.65);
    /* background-color: rgba(179, 0, 0, 0.7); */
}

@mixin alert-basic-top-night {
    color: $white;
    background-color: rgba(50, 132, 176, 0.7);
    /* background-color: rgba(132, 0, 0, 0.7); */
}
