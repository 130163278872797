@import "../../css/_variables";
@import "../../css/_mixins";

.gallery-modal {
    @include modal-background;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.gallery-modal-content {
    @include modal-basic;
    min-width: 320px;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 0px !important;
    margin: 0px !important;
    overflow: visible !important;
}


/*
============================================================================================
    Gallery
============================================================================================
*/

.gallery-simple-gallery-container {
    position: relative;
    text-align: center;
}

.gallery-simple-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    border: none !important;
    margin: 0px auto;
    overflow: hidden;
}

.gallery-simple-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.gallery-simple-gallery-video {}

.gallery-simple-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.gallery-simple-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Gallery Navigation
============================================================================================
*/

.gallery-simple-gallery-prev-arrow, 
.gallery-simple-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.gallery-simple-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.gallery-simple-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.gallery-simple-gallery-prev-arrow:hover,
.gallery-simple-gallery-next-arrow:hover {
    opacity: 0.85;
}

.gallery-simple-gallery-video:hover ~ .gallery-simple-gallery-prev-arrow,
.gallery-simple-gallery-video:hover ~ .gallery-simple-gallery-next-arrow,
.gallery-simple-gallery-image:hover ~ .gallery-simple-gallery-prev-arrow,
.gallery-simple-gallery-image:hover ~ .gallery-simple-gallery-next-arrow {    
    opacity: 0.5;
}

.gallery-simple-gallery-dot-on,
.gallery-simple-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.gallery-simple-gallery-dot-on {
    opacity: 0.8;
}

.gallery-simple-gallery-dot-off {
    opacity: 0.4;
}

.gallery-simple-gallery-dot-off:hover {
    opacity: 0.8;
}

.gallery-simple-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.gallery-simple-gallery-dot-tag-image, 
.gallery-simple-gallery-user-tag-image,
.gallery-simple-gallery-unsave-image,
.gallery-simple-gallery-enlarge-image,
.gallery-simple-gallery-dot-tag-video,
.gallery-simple-gallery-user-tag-video,
.gallery-simple-gallery-unsave-video,
.gallery-simple-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.gallery-simple-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.gallery-simple-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.gallery-simple-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.gallery-simple-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.gallery-simple-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.gallery-simple-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.gallery-simple-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.gallery-simple-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.gallery-simple-gallery-dot-tag-image:hover,
.gallery-simple-gallery-user-tag-image:hover,
.gallery-simple-gallery-unsave-image:hover,
.gallery-simple-gallery-enlarge-image:hover,
.gallery-simple-gallery-dot-tag-video:hover,
.gallery-simple-gallery-user-tag-video:hover,
.gallery-simple-gallery-unsave-video:hover,
.gallery-simple-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.gallery-simple-gallery-video:hover ~ .gallery-simple-gallery-dot-tag-video,
.gallery-simple-gallery-video:hover ~ .gallery-simple-gallery-user-tag-video,
.gallery-simple-gallery-video:hover ~ .gallery-simple-gallery-unsave-video,
.gallery-simple-gallery-video:hover ~ .gallery-simple-gallery-enlarge-video,
.gallery-simple-gallery-image:hover ~ .gallery-simple-gallery-dot-tag-image,
.gallery-simple-gallery-image:hover ~ .gallery-simple-gallery-user-tag-image,
.gallery-simple-gallery-image:hover ~ .gallery-simple-gallery-unsave-image,
.gallery-simple-gallery-image:hover ~ .gallery-simple-gallery-enlarge-image {
    opacity: 0.8 !important;
}

