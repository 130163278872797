@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    RouteOverview
============================================================================================
*/

.route-overview-list-container {
    display: table;
    margin: 0px auto;
}

.route-overview-list {
    display: table-cell;
}

.route-overview-item-container {
}

.route-overview-item {
    display: table;
}

.route-overview-item-row {
    display: table-row;
}

.route-overview-number-container,
.route-overview-end-container {
    width: 100%;
    height: 44px;
    text-align: center;
}

.route-overview-number,
.route-overview-end {
    position: relative;
    height: 44px;
    width: 44px;
    margin: auto;
}

.route-overview-timestamp,
#route-overview-start-timestamp,
#route-overview-end-timestamp {
    position: absolute;
    width: 100px;
    top: 0px;
    text-align: right;
    left: -108px;
    line-height: 44px;
}

.route-overview-media-left-column {
    display: table-cell;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 2400px;
}

.route-overview-media-column {
    display: table-cell;
    vertical-align: middle;
}

.route-overview-media-right-column {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}

.route-overview-description-left-column {
    display: table-cell;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 2400px;
}

.route-overview-description-column {
    display: table-cell;
}

.route-overview-description-right-column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.route-overview-pulse {
    border-radius: 50%;
    -webkit-animation-name: route-overview-pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: route-overview-pulse;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}


/*
@-webkit-keyframes route-overview-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33%  {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}

@keyframes route-overview-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);
        box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}    
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);
        box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);
        box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}
*/

@-webkit-keyframes route-overview-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33%  {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}

@keyframes route-overview-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);
        box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}    
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);
        box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);
        box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}


.route-overview-transit-container {
    position: relative;
    right: 65px;
}

.route-overview-transit-mode {
    width: 120px;
    height: 40px;
    opacity: 0.5;
}

.route-overview-transit-time {
    width: 120px;
    text-align: center;
}


/*
============================================================================================
    Gallery
============================================================================================
*/
.route-overview-item-gallery {
    display: inline-block;
    margin: 0px 20px;
    float: right;
}

.route-overview-item-gallery-small {
    display: block;
    text-align: center;
    margin-bottom: 15px;
}

.route-overview-item-gallery-container {
    display: inline-block;
    position: relative;
    text-align: center;
}

.route-overview-item-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.route-overview-item-gallery-wrapper-square {
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}

.route-overview-item-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    pointer-events: none;
}

.route-overview-item-gallery-video {}

.route-overview-item-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.route-overview-item-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Gallery Navigation
============================================================================================
*/

.route-overview-item-gallery-prev-arrow, 
.route-overview-item-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.route-overview-item-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.route-overview-item-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.route-overview-item-gallery-prev-arrow:hover,
.route-overview-item-gallery-next-arrow:hover {
    opacity: 0.85;
}

.route-overview-item-gallery-video:hover ~ .route-overview-item-gallery-prev-arrow,
.route-overview-item-gallery-video:hover ~ .route-overview-item-gallery-next-arrow,
.route-overview-item-gallery-image:hover ~ .route-overview-item-gallery-prev-arrow,
.route-overview-item-gallery-image:hover ~ .route-overview-item-gallery-next-arrow {    
    opacity: 0.5;
}

.route-overview-item-gallery-dot-on,
.route-overview-item-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.route-overview-item-gallery-dot-on {
    opacity: 0.8;
}

.route-overview-item-gallery-dot-off {
    opacity: 0.4;
}

.route-overview-item-gallery-dot-off:hover {
    opacity: 0.8;
}

.route-overview-item-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    text-align: center;
    z-index: 1;
}


/*
============================================================================================
    Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.route-overview-item-gallery-dot-tag-image, 
.route-overview-item-gallery-user-tag-image,
.route-overview-item-gallery-unsave-image,
.route-overview-item-gallery-enlarge-image,
.route-overview-item-gallery-dot-tag-video,
.route-overview-item-gallery-user-tag-video,
.route-overview-item-gallery-unsave-video,
.route-overview-item-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.route-overview-item-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.route-overview-item-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.route-overview-item-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.route-overview-item-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.route-overview-item-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.route-overview-item-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.route-overview-item-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.route-overview-item-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.route-overview-item-gallery-dot-tag-image:hover,
.route-overview-item-gallery-user-tag-image:hover,
.route-overview-item-gallery-unsave-image:hover,
.route-overview-item-gallery-enlarge-image:hover,
.route-overview-item-gallery-dot-tag-video:hover,
.route-overview-item-gallery-user-tag-video:hover,
.route-overview-item-gallery-unsave-video:hover,
.route-overview-item-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.route-overview-item-gallery-video:hover ~ .route-overview-item-gallery-dot-tag-video,
.route-overview-item-gallery-video:hover ~ .route-overview-item-gallery-user-tag-video,
.route-overview-item-gallery-video:hover ~ .route-overview-item-gallery-unsave-video,
.route-overview-item-gallery-video:hover ~ .route-overview-item-gallery-enlarge-video,
.route-overview-item-gallery-image:hover ~ .route-overview-item-gallery-dot-tag-image,
.route-overview-item-gallery-image:hover ~ .route-overview-item-gallery-user-tag-image,
.route-overview-item-gallery-image:hover ~ .route-overview-item-gallery-unsave-image,
.route-overview-item-gallery-image:hover ~ .route-overview-item-gallery-enlarge-image {
    opacity: 0.8 !important;
}



/*
============================================================================================
    Thumbnails
============================================================================================
*/

.route-overview-thumbnails {
}

.route-overview-thumbnails-small {
    text-align: center;
    margin-bottom: 5px;
}

.route-overview-thumbnail-row {
    width: 100%;
}

.route-overview-thumbnail-column {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 5px;
}

.route-overview-thumbnail-on,
.route-overview-thumbnail-off {
    position: relative;
    display: inline-block;
    margin: 5px 0px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.route-overview-thumbnail-on {
    opacity: 1.0;
}

.route-overview-thumbnail-off {
    opacity: 0.85;
}

.route-overview-thumbnail-off:hover,
.route-overview-thumbnail-off:focus {
    opacity: 1.0;
    transform: scale(1.05);
    -ms-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.route-overview-thumbnail-prev,
.route-overview-thumbnail-next {
    width: 100%;
    height: 30px;
    margin: 5px 0px;
    opacity: 0.2 !important;
}

.route-overview-thumbnail-prev-small,
.route-overview-thumbnail-next-small {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 0px 5px;
    opacity: 0.2 !important;
}

.route-overview-thumbnail-prev:hover,
.route-overview-thumbnail-next:hover,
.route-overview-thumbnail-prev-small:hover,
.route-overview-thumbnail-next-small:hover {
    opacity: 0.4 !important;
}

.route-overview-thumbnail-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    pointer-events: none;
}

.route-overview-description {
    margin-bottom: 20px;
}


/*
============================================================================================
    First Description
============================================================================================
*/

.route-overview-description {
}

.route-overview-first-description {
}

.route-overview-first-description-header {
    width: 100%;
    padding: 12px 0px 8px 0px;
}
.route-overview-first-description-title {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    word-wrap: break-word;
    padding: 2px 0px;
    margin-right: 8px;
}

.route-overview-first-description-location {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    word-wrap: break-word;
    padding: 2px 0px;
}

.route-overview-first-description-status {
    float: right;
    white-space: nowrap;
}

.route-overview-status-open-day,
.route-overview-status-open-night,
.route-overview-status-closed-day,
.route-overview-status-closed-night,
.route-overview-status-seasonal-day,
.route-overview-status-seasonal-night {
    float: right;
    font-size: 13px;
    padding: 1px 8px 2px 8px;
    margin: 2px 0px 2px 8px;
    border-radius: 15px;
    opacity: 0.75;
}

.route-overview-status-open-day {
    color: $white;
    background-color: $light-blue;
}

.route-overview-status-open-night {
    color: $black;
    background-color: $dark-blue;
}

.route-overview-status-closed-day {
    color: $white;
    background-color: $light-red;
}

.route-overview-status-closed-night {
    color: $black;
    background-color: $dark-red;
}

.route-overview-status-seasonal-day {
    color: $white;
    background-color: $light-orange;
}

.route-overview-status-seasonal-night {
    color: $black;
    background-color: $dark-orange;
}

.route-overview-status-container {
    display: inline;
    white-space: nowrap;
}

.route-overview-first-description-curation {
    width: 100%;
    padding: 0px;
}

.route-overview-first-description-curation-highlight {
}

.route-overview-first-description-curation-regular {
}

.route-overview-first-description-footer {
    width: 100%;
    padding: 8px 0px 16px 0px;
    display: block;
}

.route-overview-first-description-footer-left {
    display: inline-block;
    float: left;
}

.route-overview-first-description-footer-right {
    display: inline-block;
    float: right;
}

.route-overview-first-description-footer-more {
    float: right;
    margin: 1px 0px 1px 8px;
}

.route-overview-first-description-footer-more:hover {
}


/* 
============================================================================================
    Like / Save Buttons
============================================================================================
*/

.route-overview-interaction-box {
    width: 100%;
}

.route-overview-like-container {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 200px);
    padding-left: 32px;
    text-align: left;
}

.route-overview-save-container {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    padding-right: 32px;
    text-align: right;
}

.route-overview-share-button-on,
.route-overview-share-button-off, 
.route-overview-like-button-on, 
.route-overview-like-button-off, 
.route-overview-save-button-on,
.route-overview-save-button-off {
    vertical-align: middle;
}

.route-overview-like-button-on, 
.route-overview-save-button-on {
    opacity: 0.8 !important;
}

.route-overview-share-button-on, 
.route-overview-share-button-off, 
.route-overview-like-button-off, 
.route-overview-save-button-off {
}

.route-overview-share-button-on:hover, 
.route-overview-share-button-off:hover,
.route-overview-like-button-off:hover, 
.route-overview-save-button-off:hover {
}

.route-overview-save-button-text {
    display: inline-block;
    vertical-align: middle;
    padding-right: 6px;
    line-height: 32px;
}

.route-overview-like-button-on,
.route-overview-like-button-off,
.route-overview-share-button-on,
.route-overview-share-button-off {
    margin-right: 6px;
}

.route-overview-save-button-on,
.route-overview-save-button-off {
}

.route-overview-like-count {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    line-height: 30px;
}

.route-overview-save-count {
}


/*
============================================================================================
    Second Description
============================================================================================
*/

.route-overview-second-description {
    text-align: left;
    padding: 15px 0px 0px 0px;
    border-top: dotted 1px #464646;
}

.route-overview-second-description-left {
    display: inline-block;
    vertical-align: top;
}

.route-overview-second-description-middle {
    display: inline-block;
    vertical-align: top;
}

.route-overview-second-description-right {
    float: right;
}

.route-overview-second-description-rating, 
.route-overview-second-description-difficulty, 
.route-overview-second-description-hours,
.route-overview-second-description-time {
    display: inline-block;
    vertical-align: top;
    padding: 0px 12px 15px 0px;
}

.route-overview-second-description-rating {
}

.route-overview-second-description-difficulty {
}

.route-overview-second-description-hours {
}

.route-overview-second-description-time {
}

/*
============================================================================================
    Diffuclty / Rating / Times - Text and Values
============================================================================================
*/

.route-overview-second-description-rating-text, 
.route-overview-second-description-difficulty-text, 
.route-overview-second-description-hours-text, 
.route-overview-second-description-time-text {
    text-align: center;
}

.route-overview-second-description-rating-value, 
.route-overview-second-description-difficulty-value, 
.route-overview-second-description-hours-value,
.route-overview-second-description-hours-line,
.route-overview-second-description-time-value,
.route-overview-second-description-rating-value-small,
.route-overview-second-description-difficulty-value-small,
.route-overview-second-description-hours-value-small,
.route-overview-second-description-hours-line-small,
.route-overview-second-description-time-value-small {
    text-align: center;
}

.route-overview-second-description-rating-value,
.route-overview-second-description-difficulty-value {
    font-size: 40px;
}

.route-overview-second-description-rating-value-small,
.route-overview-second-description-difficulty-value-small {
    font-size: 36px;
}

.route-overview-second-description-hours-value {
    font-size: 20px;
}

.route-overview-second-description-hours-value-small {
    font-size: 16px;
}

.route-overview-second-description-hours-line,
.route-overview-second-description-hours-line-small {
    font-weight: 900;
    padding: 0px 0px 2px 0px;
}

.route-overview-second-description-hours-line {
    font-size: 12px;
}

.route-overview-second-description-hours-line-small {
    font-size: 10px;
}

.route-overview-second-description-time-value {
    font-size: 30px;
}

.route-overview-second-description-time-value-small {
    font-size: 24px;
}


/*
============================================================================================
    Diffuclty / Rating Bars
============================================================================================
*/

.route-overview-second-description-rating-bar-day,
.route-overview-second-description-difficulty-bar-day,
.route-overview-second-description-rating-bar-night,
.route-overview-second-description-difficulty-bar-night {
    margin: 0px auto;
}

.route-overview-second-description-rating-bar-front-regular-day,
.route-overview-second-description-difficulty-bar-front-regular-day,
.route-overview-second-description-rating-bar-front-regular-night,
.route-overview-second-description-difficulty-bar-front-regular-night {
    height: 100%;
    border-radius: 3px 0px 0px 3px;
}

.route-overview-second-description-rating-bar-front-round-day,
.route-overview-second-description-difficulty-bar-front-round-day,
.route-overview-second-description-rating-bar-front-round-night,
.route-overview-second-description-difficulty-bar-front-round-night {
    height: 100%;
    border-radius: 3px;
}

.route-overview-second-description-rating-bar-front-regular-day,
.route-overview-second-description-rating-bar-front-round-day {
    background-color: $light-blue;
}

.route-overview-second-description-rating-bar-front-regular-night,
.route-overview-second-description-rating-bar-front-round-night {
    background-color: $blue;
}

.route-overview-second-description-difficulty-bar-front-regular-day,
.route-overview-second-description-difficulty-bar-front-round-day {
    background-color: $light-red;
}

.route-overview-second-description-difficulty-bar-front-regular-night,
.route-overview-second-description-difficulty-bar-front-round-night {
    background-color: $red;
}

.route-overview-second-description-rating-bar-back-day,
.route-overview-second-description-difficulty-bar-back-day,
.route-overview-second-description-rating-bar-back-night,
.route-overview-second-description-difficulty-bar-back-night {
    padding: 1px;
    height: 100%;
    border-radius: 4px;
}

.route-overview-second-description-rating-bar-back-day,
.route-overview-second-description-difficulty-bar-back-day {
    background-color: $light-gray;
}

.route-overview-second-description-rating-bar-back-night,
.route-overview-second-description-difficulty-bar-back-night {
    background-color: $darkest-gray;
}


/*
============================================================================================
    Editor
============================================================================================
*/

.route-overview-second-description-editor {
    width: 72px;
    padding: 0px 0px 15px 0px;
    float: right;
    overflow: visible;
    text-align: center;
}

.route-overview-second-description-editor-header {
    padding-bottom: 4px;
    text-align: center;
}

.route-overview-second-description-editor-profile-pic {
    width: 54px;
    height: 54px;
}

.route-overview-second-description-editor-profile-pic-small {
    width: 48px;
    height: 48px;
}

.route-overview-second-description-editor-name {
    width: 200px;
    margin-left: -64px;
    text-align: center;
    cursor: pointer;
}


/*
============================================================================================
    Staff (Contributors and Helpers)
============================================================================================
*/

.route-overview-second-description-staff {
    float: right;
    padding: 0px 0px 15px 0px;
    margin-right: 2px;
}

.route-overview-contributors, .route-overview-helpers {
    margin: 0px 0px 0px 0px;
    text-align: center;
}

.route-overview-contributors-count, .route-overview-helpers-count {
    font-size: 16px;
    padding-bottom: 6px;  
    white-space: nowrap;
    text-align: center;
}

.route-overview-contributor-profile-pics, .route-overview-helper-profile-pics {
    padding: 0px 0px 0px 0px;
}

.route-overview-contributor-profile-pic, .route-overview-helper-profile-pic {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0px 2px 0px 2px;
    cursor: pointer;
}

.route-overview-contributors-more, .route-overview-helpers-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

.route-overview-contributors-short, .route-overview-helpers-short {
    padding: 6px;
    font-size: 18px;
    white-space: nowrap;
    text-align: center;
}

.route-overview-helper-simple-list {
    text-align: center;
    padding-top: 6px;
    font-size: 16px;
}


/*
============================================================================================
    Third Description
============================================================================================
*/

.route-overview-third-description {
    padding: 0px 0px 20px 0px;
}
