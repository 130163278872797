@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Log In Menu
============================================================================================
*/

#log-in-modal-container {
    @include modal-background;
}

#log-in-modal {
    @include modal-minimal;
    width: 520px;
}

#log-in-modal-mobile {
    @include modal-minimal;
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 320px;
}

.log-in-row {
    width: 100%;
    box-sizing: border-box;
}

#log-in-or {
    padding: 10px;
    text-align: center;
}

#log-in-facebook-container {
    display: inline-block;
    box-sizing: border-box;
}

#log-in-google-container {
    display: inline-block;
    box-sizing: border-box;
}

#log-in-form {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

#log-in-facebook-container-active {
    width: 600px;
    margin: 0px auto;
    text-align: center;
}

#log-in-google-container-active {
    width: 600px;
    margin: 0px auto;
    text-align: center;  
}

#log-in-form-active {
    width: 600px;
    margin: 0px auto;
    text-align: center;  
}

#log-in-facebook-button {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    float: right;
}

#log-in-facebook-image {
    width: 100%;
    height: 100%;
}

#log-in-google-image {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    float: left;
}

#log-in-inputs-mobile {
    width: 215px;
    display: inline-block;
    vertical-align: middle;
}

#log-in-inputs {
    display: inline-block;
    vertical-align: middle;
}

#log-in-forgot-password {
    text-align: center;
    cursor: pointer;
    opacity: 0.9;
}

#log-in-forgot-password:hover {
    opacity: 1.0;
}

#log-in-email {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    margin: 0px 15px 10px 0px;
}

#log-in-email:focus {
}

#log-in-password {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    margin: 0px 15px 10px 0px;
}

#log-in-password:focus {
}

#log-in-submit-button {
    display: inline-block;
    vertical-align: middle;
    border: none;
    margin-bottom: 10px;
}

#log-in-submit-button:hover {
  opacity: 1.0;
}

#log-in-warning, #log-in-facebook-google-warning {
    margin-top: 6px;
}


/* 
============================================================================================
    Reset Password
============================================================================================
*/

#reset-password-modal-container {
    @include modal-background;
}

#reset-password-modal {
    @include modal-minimal;
    width: 480px;
}

#reset-password-modal-mobile {
    @include modal-minimal;
    width: 95%;
    min-width: 320px;
    max-width: 480px;
}

#reset-password-description {
    margin: 0px auto;
    padding: 0px 20px;
    text-align: center;
}

#reset-password-email {
    margin-top: 20px;
    text-align: center;
}

#reset-password-email-input {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.reset-password-email-input {
    width: 240px;
}

.reset-password-email-input-mobile {
    width: 190px;
}

#reset-password-send-link-button {
    display: inline-block;
    vertical-align: middle;
}

#reset-password-send-link-button:hover {
}

#reset-password-back {
    padding-top: 12px;
    text-align: center;
}

#reset-password-back-button {
    cursor: pointer;
    font-size: 16px;
    opacity: 0.9;
}

#reset-password-back-button:hover {
    opacity: 1.0;
}

.reset-password-warning {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding-top: 12px;
    text-align: center;
    white-space: pre-line;
}
