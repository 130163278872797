@import "../../css/_variables";
@import "../../css/_mixins";

.posting-modal {
    @include modal-background;
}

.posting-modal-content {
    @include modal-posting;
    width: 400px;
}
