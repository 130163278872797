@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    Search Results Location
============================================================================================
*/

#search-results-container-location {
    width: 100%;
    min-width: 320px;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
    padding-top: 72px;
}
