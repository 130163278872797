@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Chat
============================================================================================
*/

.chats {
	position: absolute;
	width: 366px;
    top: 18px;
    right: -131px;
    z-index: 31;
}

.chats-small {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    margin: 0px auto;
    min-width: 300px;
    max-width: 366px;
    z-index: 31;
}

#chats-shadow {
    margin-top: 16px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.8);
    overflow: hidden;
}

#chats-new-chat-button, 
#chats-new-group-chat-button {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 1px;
    opacity: 0.6;
}

.chats-new-chat-button {
    right: 4px;
}

.chats-new-chat-button-small {
    right: 28px;
}

.chats-new-group-chat-button {
    right: 34px;
}

.chats-new-group-chat-button-small {
    right: 56px;
}

#chats-new-chat-button:hover,
#chats-new-group-chat-button:hover {
    opacity: 1.0;
}

#chats-triangle {
    position: absolute;
    width: 300px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 250px;
    background-size: contain;
}

#chats-header {
    position: relative;
    width: 100%;
    padding: 6px 16px;
    text-align: left;
}

#chats-footer {
	width: 100%;
	height: 20px;
}

.chats-header-day,
.chats-footer-day {
    background-color: $menu-frame-day;
}

.chats-header-night,
.chats-footer-night {
    background-color: $menu-frame-night;
}

#chats-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
}

#chats-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.chats-container-short {
    max-height: 400px;
}

.chats-container-long {
    max-height: 800px;
}

.chats-container-day {
    background-color: $almost-white;
    border-left: 1px solid $menu-frame-day;
    border-right: 1px solid $menu-frame-day;
}

.chats-container-night {
    background-color: $black;
    border-left: 1px solid $menu-frame-night;
    border-right: 1px solid $menu-frame-night;
}


/* 
============================================================================================
    Chat Item
============================================================================================
*/

#chat-item-blank {
    padding: 8px;
    text-align: center;
}

.chat-item-unchecked-day,
.chat-item-unchecked-night,
.chat-item-checked-day,
.chat-item-checked-night {
	width: 100%;
	text-align: left;
}

.chat-item-unchecked-day:hover,
.chat-item-checked-day:hover {
    background-color: $close-to-white;
}

.chat-item-unchecked-night:hover,
.chat-item-checked-night:hover {
    background-color: $close-to-black;
}

.chat-item-left {
    width: 52px;
    display: inline-block;
    vertical-align: middle;
}

.chat-item-middle, 
.chat-item-middle-scroll {
    padding: 4px 2px;
    display: inline-block;
    vertical-align: middle;
}

.chat-item-middle {
    width: calc(100% - 132px);
    //width: 232px;
}

.chat-item-middle-scroll {
    width: calc(100% - 132px);
    //width: 224px;
}

.chat-item-right {
    width: 80px;
    padding: 10px 10px 10px 4px;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
}


.chat-item-user-profile-pic,
.chat-item-user-profile-pic-left,
.chat-item-user-profile-pic-right,
.chat-item-user-profile-pic-left-top,
.chat-item-user-profile-pic-left-bottom,
.chat-item-user-profile-pic-right-top,
.chat-item-user-profile-pic-right-bottom {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #464646;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
}

.chat-item-user-profile-pic {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 4px 8px;
}

.chat-item-user-profile-pic-left {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 36px;
    border-radius: 18px 0px 0px 18px;
    margin: 4px 2px 4px 8px;
}

.chat-item-user-profile-pic-right {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 36px;
    border-radius: 0px 18px 18px 0px;
    margin: 4px 8px 4px 2px;
}

.chat-item-user-profile-pic-left-container {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 44px;
    margin: 4px 2px 4px 8px;
} 

.chat-item-user-profile-pic-right-container {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 44px;
    margin: 4px 8px 4px 2px;
}

.chat-item-user-profile-pic-left-top {
    display: block;
    width: 22px;
    height: 20px;
    border-radius: 22px 0px 0px 0px;
    margin-bottom: 4px;
}

.chat-item-user-profile-pic-left-bottom {
    display: block;
    width: 22px;
    height: 20px;
    border-radius: 0px 0px 0px 22px;
}

.chat-item-user-profile-pic-right-top {
    display: block;
    width: 22px;
    height: 20px;
    border-radius: 0px 22px 0px 0px;
    margin-bottom: 4px;
}

.chat-item-user-profile-pic-right-bottom {
    display: block;
    width: 22px;
    height: 20px;
    border-radius: 0px 0px 22px 0px;
}

.chat-item-user-names {
    white-space: normal;
}

.chat-item-user-names a:link, 
.chat-item-user-names a:visited, 
.chat-item-user-names a:hover, 
.chat-item-user-names a:active {
    text-decoration: none;
}

.chat-item-last-message {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    font-size: 14px;
}

.chat-item-time {
    cursor: pointer;
}


/* 
============================================================================================
    Chat Window
============================================================================================
*/

.chat-window {
    position: fixed;
    z-index: 33;
    bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.8);
}

.chat-window-header-day, 
.chat-window-header-night {
    width: 100%;
    text-align: left;
    border-radius: 10px 10px 0px 0px;
}

.chat-window-header-day {
    background-color: $menu-frame-day;
}

.chat-window-header-night {
    background-color: $menu-frame-night;
}

.chat-window-body-day,
.chat-window-body-night {
    width: 100%;
    height: 300px;
    padding: 4px 0px;
    overflow-x: hidden;
    overflow-y: auto;
}

.chat-window-body-day {
    background-color: $almost-white;
}

.chat-window-body-night {
    background-color: $black;
}

.chat-window-footer-day {
    width: 100%;
    background-color: $menu-frame-day;
}

.chat-window-footer-night {
    width: 100%;
    background-color: $menu-frame-night;
}

.chat-window-close-button {
    float: right;
    margin: 6px;
    opacity: 0.2;
}

.chat-window-close-button:hover {
    opacity: 0.5;
}


/* 
============================================================================================
    Chat Window Users
============================================================================================
*/

.chat-window-users {
    display: inline-block;
    vertical-align: middle;
    padding: 4px 0px 0px 4px;
}

.chat-window-user-names {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 4px;
    font-size: 16px;
}

.chat-window-users-count {
    font-size: 14px;
    padding: 0px 0px 6px 0px;
    white-space: nowrap;
    text-align: center;
}

.chat-window-user-profile-pics {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 0px 0px 0px;
}

.chat-window-user-profile-pic {
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #464646;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0px 2px 0px 2px;
    cursor: pointer;
}


/*
============================================================================================
    Chat Window Input
============================================================================================
*/

.chat-window-input-day, 
.chat-window-input-night {
    display: inline-block;
    vertical-align: middle;
    font-family: "Century-Gothic", "NanumSquareRoundR";
    letter-spacing: -0.3px;
    width: calc(100% - 8px);
    margin: 4px;
    border-radius: 8px;
    padding: 4px 12px;
    resize: none;
    text-align: center;
    cursor: default;
    overflow: hidden;
    transition: border 0.3s ease-in-out;
    -webkit-transition: border 0.3s ease-in-out;
    -moz-transition: border 0.3s ease-in-out;
    -o-transition: border 0.3s ease-in-out;
}

.chat-window-input-day {
    color: $black;
    border: solid 1px $border-day;
    background-color: $white;
}

.chat-window-input-night {
    color: $white;
    border: solid 1px $border-night;
    background-color: $black;
}


/* 
============================================================================================
    Chat Message
============================================================================================
*/

.chat-my-message {
    width: 100%;
    text-align: right;
    padding: 3px 8px;
}

.chat-my-message-content-day,
.chat-my-message-content-night {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 40px);
    padding: 4px 12px;
    text-align: left;
    border-radius: 10px;
}

.chat-my-message-content-day {
    background-color: $faint-blue;
}

.chat-my-message-content-night {
    background-color: $dark-blue;
}

.chat-message-sender-profile-pic {
    margin: 4px 8px;
}

.chat-message {
    width: 100%;
}

.chat-message {
    width: 100%;
    text-align: left;
    padding: 3px 0px;
}

.chat-message-sender {
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    text-align: center;
}

.chat-message-content-day,
.chat-message-content-night {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 56px);
    padding: 4px 12px;
    text-align: left;
    border-radius: 10px;
}

.chat-message-content-day {
    background-color: $close-to-white;
}

.chat-message-content-night {
    background-color: $dark-gray;
}

.chat-message-date-bar {
    padding: 6px;
    text-align: center;
    font-size: 14px;
    color: #3284B0;
}


/* 
============================================================================================
    New Chat
============================================================================================
*/

#new-chat-shadow {
    margin-top: 16px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.8);
    overflow: hidden;
}

#new-chat-header {
    width: 100%;
    padding: 6px 16px;
    text-align: left;
}

#new-chat-footer {
    width: 100%;
    height: 20px;
}

.new-chat-header-day,
.new-chat-footer-day {
    background-color: $menu-frame-day;
}

.new-chat-header-night,
.new-chat-footer-night {
    background-color: $menu-frame-night;
}

#new-chat-container {
    position: absolute;
    top: 0px;
    left: -210px;
    width: 200px;
    z-index: 1;
}

#new-chat-container-small {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    z-index: 1;
}

.new-chat-container-day {
    background-color: $almost-white;
    border-left: 1px solid $menu-frame-day;
    border-right: 1px solid $menu-frame-day;
}

.new-chat-container-night {
    background-color: $black;
    border-left: 1px solid $menu-frame-night;
    border-right: 1px solid $menu-frame-night;
}

#new-chat-search-input {
    display: inline-block;
    width: 100%;
    border: none;
    padding: 8px;
    resize: none;
    text-align: center;
    border-radius: 0px;
}

#new-chat-search-input-container {
    display: block;
    width: 100%;
    text-align: center;
}

.new-chat-search-input-container-day {
    border-left: 1px solid $menu-frame-day;
    border-right: 1px solid $menu-frame-day;
}

.new-chat-search-input-container-night {
    border-left: 1px solid $menu-frame-night;
    border-right: 1px solid $menu-frame-night;
}


#new-chat-search-results {
    width: 100%;
    max-height: 365px;
    overflow-x: hidden;
    overflow-y: auto;
}

.new-chat-search-results-day {
    background-color: $almost-white;
    border-left: 1px solid $menu-frame-day;
    border-right: 1px solid $menu-frame-day;
}

.new-chat-search-results-night {
    background-color: $black;
    border-left: 1px solid $menu-frame-night;
    border-right: 1px solid $menu-frame-night;
}

.new-chat-search-results-item-day,
.new-chat-search-results-item-night {
    text-align: left;
    cursor: pointer;    
}

.new-chat-search-results-item-day:hover {
    background-color: $close-to-white;
}    

.new-chat-search-results-item-night:hover {
    background-color: $close-to-black;
}

.new-chat-search-results-item-profile-pic {
    vertical-align: middle;
    margin: 4px 8px;
}

.new-chat-search-results-item-name {
    display: inline-block;
    vertical-align: middle;
}


