@import "./_variables";
@import "./_mixins";


/* 
============================================================================================
   Open Itineraries
============================================================================================
*/

#open-itineraries {
    width: auto;
    min-width: 240px;
    vertical-align: top;
}

#open-itineraries-title {
    margin: 0px auto;
    text-align: center;
    padding: 5px 0px 10px 0px;
}

#open-itinerary-participants {
    margin: 0px 0px 0px 0px;
    text-align: center;
    white-space: nowrap;
}

#open-itinerary-participant-profile-pics {
    padding: 0px 0px 0px 0px;
}

.open-itinerary-participant-profile-pic {
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 18px;
    border: 1px solid #464646;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0px 2px 0px 2px;
    cursor: pointer;    
}

.open-itinerary-participants-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

#open-itinerary-prev-button,
#open-itinerary-next-button {
    width: 30px;
    display: table-cell;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

#open-itinerary-prev-button {
    background-position: left;
}

#open-itinerary-next-button {
    background-position: right;
}

#open-itinerary-prev-button:hover,
#open-itinerary-next-button:hover {
    opacity: 0.9;
}

.open-itinerary-sub-buttons {
}

.open-itinerary-sub-prev-button,
.open-itinerary-sub-next-button {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    opacity: 0.4;
    vertical-align: middle;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.open-itinerary-sub-button-spacer {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.open-itinerary-sub-prev-button:hover,
.open-itinerary-sub-next-button:hover {
    opacity: 0.9;
}

#open-itinerary-list {
    display: table;
    margin: 0px auto;
    padding-bottom: 5px;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.open-itinerary-item,
.open-itinerary-item-end {
    display: table-cell;
    text-align: center;
    vertical-align: top;
    padding: 0px 15px;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.open-itinerary-item {
    border-right: 1px dashed #464646;
}

.open-itinerary-item-end {
}

.open-itinerary-item:hover {
}

.open-itinerary-item-date {
    padding: 0px;
    text-align: center;
    white-space: nowrap;
}

.open-itinerary-item-time {
    padding: 2px 0px 6px 0px;
    text-align: center;
    white-space: nowrap;
}

.open-itinerary-item-buttons {
    padding: 8px;
    margin: 0px auto;
    text-align: center;
}

.open-itinerary-item-join-button {
    display: inline-block;
    padding: 2px 12px;
    margin: 0px 8px;
}

.open-itinerary-item-join-button:hover {
}


/* 
============================================================================================
   Drop Down Menu
============================================================================================
*/

.drop-down-placeholder {
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.drop-down {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
}

.drop-down-selected-day, 
.drop-down-selected-night {
    display: block;
    margin: 0px auto;
    border-radius: 8px;
    padding: 1px 6px;
    opacity: 1.0;
    cursor: pointer;
}

.drop-down-selected-day {
    background-color: $light-blue;
}

.drop-down-selected-night {
    background-color: $blue;
}

.drop-down-selected:hover {
    opacity: 1.0;
}

.drop-down-selected-spacer {
    display: block;
    margin: 0px auto;
    cursor: pointer;
}

.drop-down-item,
.drop-down-item-top,
.drop-down-item-bottom {
    margin: 0px auto;
    padding: 2px 6px;
    opacity: 1.0;
    font-size: 12px;
    text-align: center;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    cursor: pointer;
}

.drop-down-item-day {
    background-color: $close-to-white;
}

.drop-down-item-day:hover {
    opacity: 1.0;
    background-color: $almost-white;
}

.drop-down-item-night {
    background-color: $close-to-black;
}

.drop-down-item-night:hover {
    opacity: 1.0;
    background-color: $darkest-gray;
}

.drop-down-item-top {
    border-radius: 8px 8px 0px 0px;
}
.drop-down-item-bottom {
    border-radius: 0px 0px 8px 8px;
}

.drop-down-item-selected-day {
}

.drop-down-item-selected-night {
}

.drop-down-items-container {
    position: relative;
    display: none;
    margin-top: 10px;
    cursor: pointer;
}

.drop-down-item:hover,
.drop-down-item-top:hover,
.drop-down-item-bottom:hover {
}

.drop-down:hover .drop-down-items-container {
    display: block;
}

.drop-down:hover .drop-down-selected {
}
