@import "../../css/_variables";
@import "../../css/_mixins";


.upload-indicator {
    text-align: center;
    padding: 16px 4px 8px 4px;
}

.upload-indicator-title {
	display: block;
	text-align: center;
}

.upload-indicator-progress-slider-container-day,
.upload-indicator-progress-slider-container-night,
.upload-indicator-progress-slider-container-mobile-day,
.upload-indicator-progress-slider-container-mobile-night {
	display: inline-block;
    position: relative;
    padding: 1px;
    border-radius: 4px;
}

.upload-indicator-progress-slider-container-day,
.upload-indicator-progress-slider-container-night {
    width: 72px;
    height: 8px;
}

.upload-indicator-progress-slider-container-mobile-day,
.upload-indicator-progress-slider-container-mobile-night {
    width: 50px;
    height: 6px;
}

.upload-indicator-progress-slider-container-day,
.upload-indicator-progress-slider-container-mobile-day {
    background: $close-to-white;
}

.upload-indicator-progress-slider-container-night,
.upload-indicator-progress-slider-container-mobile-night {
    background: $close-to-black;
}

.upload-indicator-progress-slider-day,
.upload-indicator-progress-slider-night,
.upload-indicator-progress-slider-error-day,
.upload-indicator-progress-slider-error-night {
	float: left;
    height: 6px;
    border-radius: 3px;
}

.upload-indicator-progress-slider-day {
    background: $light-blue;
}

.upload-indicator-progress-slider-night {
    background: $blue;
}

.upload-indicator-progress-slider-error-day {
    background: $light-red;
}

.upload-indicator-progress-slider-error-night {
    background: $red;
}

.upload-indicator-progress-percentage {
	display: inline-block;
	margin-left: 4px;
	text-align: left;
}

.upload-indicator-progress-percentage-mobile {
    display: inline-block;
    margin-left: 2px;
    text-align: left;
}
