@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
   Partitions
============================================================================================
*/

#create-connect-wrapper {
    text-align: center;
}

#create-connect-left, 
#create-connect-right {
    display: inline-block;
    vertical-align: top;
}

#create-connect-left {
    width: 540px;
    margin-right: 16px;
}

#create-connect-right {
    width: 630px;
}

/*
============================================================================================
   Main Mode
============================================================================================
*/

#create-connect-mode {
    text-align: center;
    margin-bottom: 12px;
}

#create-connect-mode-user, 
#create-connect-mode-general {
    margin: 0px 3px;
}

.create-connect-mode-on {
    opacity: 1.0 !important;
}

/*
============================================================================================
   Search Results
============================================================================================
*/

#create-connect-search {
    text-align: center;
    margin-bottom: 12px;
}

.create-connect-search-row {
    text-align: center;
}

#create-connect-input-general-keyword,
#create-connect-input-general-user,
#create-connect-input-user-keyword
 {
    vertical-align: middle;
    margin: 3px 0px;
}

#create-connect-search-button {
    vertical-align: middle;
}


/*
============================================================================================
   Search Results
============================================================================================
*/

.create-connect-object-item-container {
    width: 540px;
    padding: 6px;
    opacity: 0.9;
    border-radius: 12px;
    margin-bottom: 8px;
}

.create-connect-object-item-container:hover {
    opacity: 1.0;
}

.create-connect-object-item-left {
    width: 152px;
    display: inline-block;
    vertical-align: middle;
}

.create-connect-object-item-center {
    width: 318px;
    display: inline-block;
    vertical-align: top;
}

.create-connect-object-item-content {
    padding: 0px 12px;
}

.create-connect-object-item-content-more {
    width: 100%;
    height: 12px;
    opacity: 0.4;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center 5px;
    background-size: auto 4px;
}

.create-connect-object-item-content-more:hover {
    opacity: 0.8;
}

.create-connect-object-item-content-less {
    height: 12px;
    margin-top: 2px;
    opacity: 0.3;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.create-connect-object-item-content-less:hover {
    opacity: 0.6;
}


.create-connect-object-item-right {
    text-align: center;
    width: 58px;
    padding: 12px 6px 12px 0px;
    display: inline-block;
    vertical-align: top;
}

.create-connect-object-title {
    text-align: center;
    padding: 8px 16px;
}

.create-connect-object-overview {
    text-align: left;
}

.create-connect-object-add-button, 
.create-connect-object-remove-button {
    margin-bottom: 8px;
    opacity: 0.3 !important;
}

.create-connect-object-editor {
}

.create-connect-object-add-button:hover, 
.create-connect-object-remove-button:hover {
    opacity: 0.6 !important;
}

.create-connect-object-editor:hover {
}


/*
============================================================================================
   Search Pagination
============================================================================================
*/

.create-connect-pages-container {
    text-align: center;
    margin-bottom: 32px;
}

.create-connect-pages {
    display: inline-block;
    vertical-align: middle;
}

.create-connect-page {
}

.create-connect-page:hover {
}

.create-connect-previous, 
.create-connect-next {
    vertical-align: middle;
    margin: 0px 4px;
    opacity: 0.3;
}

.create-connect-previous:hover,
.create-connect-next:hover {
    opacity: 0.6;
}


/*
============================================================================================
    Selected Route
============================================================================================
*/

#create-connect-selected-route-container {
    padding: 16px 0px 16px 10px;
    text-align: left;
}

.create-connect-selected-route-container-day {
    border-left: 1px solid $light-gray;
}

.create-connect-selected-route-container-night {
    border-left: 1px solid $darkest-gray;
}

#create-connect-selected-route {
    text-align: left;
}

#create-connect-selected-route-numbers {
    display: inline-block;
    vertical-align: top;
    width: 52px;
    text-align: right;
}

.create-connect-selected-route-number {
    width: 32px;
    height: 240px;
    display: inline-block;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 32px 32px;
    cursor: pointer;
}

#create-connect-selected-route-number-start, 
#create-connect-selected-route-number-end {
    width: 32px;
    height: 120px;
    display: inline-block;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    cursor: pointer;

}

#create-connect-selected-route-number-start {
    background-position: 0px 30px;
}

#create-connect-selected-route-number-end {
    background-position: 0px 62px;
}

#create-connect-selected-route-dots {
    display: inline-block;
    vertical-align: top;
    width: 152px;
}

#create-connect-selected-route-lines {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    position: relative;
    left: -6px;
    opacity: 0.5;
}

.create-connect-selected-route-line, 
#create-connect-selected-route-line-start,
#create-connect-selected-route-line-end {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 30px 240px;
    cursor: pointer;
}

.create-connect-selected-route-line {
    height: 240px;
    background-position: center;
}

#create-connect-selected-route-line-start, 
#create-connect-selected-route-line-end {
    height: 120px;
}

#create-connect-selected-route-line-start {
    background-position: bottom;
}

#create-connect-selected-route-line-end {
    background-position: top;
}

.create-connect-selected-route-item-container {
    width: 152px;
    overflow: hidden;
    height: 240px;
    padding: 44px 0px;
    display: inline-block;
    position: relative;
    opacity: 0.9;
    text-align: center;
}

.create-connect-selected-route-item-remove-button {
    display: inline-block;
    position: absolute;
    top: 158px;
    right: 10px;
}

.create-connect-selected-route-item-container:hover {
    opacity: 1.0;
}

.create-connect-selected-route-item-container:hover .create-connect-selected-route-item-remove-button {
    opacity: 1.0;
}

#create-connect-selected-route-item-container-start, 
#create-connect-selected-route-item-container-end {
    width: 152px;
    height: 120px;
    display: inline-block;
    position: relative;
    opacity: 0.9;
    text-align: right;
}

#create-connect-selected-route-item-container-start:hover,
#create-connect-selected-route-item-container-end:hover {
    opacity: 1.0;
}

#create-connect-selected-route-item-container-start-button, 
#create-connect-selected-route-item-container-end-button {
    position: relative;
    opacity: 0.85;
}

#create-connect-selected-route-item-container-start-button {
    top: 32px;
    right: 6px;
}

#create-connect-selected-route-item-container-end-button {
    top: 63px;
    right: 6px;
}

#create-connect-selected-route-transits {
    display: inline-block;
    vertical-align: top;
    width: 368px;
}

.create-connect-selected-route-transit {
    width: 100%;
    height: 240px;
    text-align: left;
}

.create-connect-transit-mode {
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    padding: 98px 0px;
    position: relative;
}

.create-connect-transit-mode-image {
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 0px 4px;
    opacity: 0.85;
}

.create-connect-transit-mode-duration {
    font-size: 16px;
    left: 4px;
    bottom: 120px;
    position: absolute;
}

.create-connect-transit-mode-drop-down {
    left: 4px;
    top: 120px;
    position: absolute;
}

.create-connect-transit-summary {
    width: 120px;
    position: absolute;
    top: 102px;
    left: -156px;
    text-align: right;
}

.create-connect-transit-summary-duration {
}

.create-connect-transit-summary-distance {
}

.create-connect-transit-sliders {
    display: inline-block;
    vertical-align: middle;
    width: 316px;
    height: 240px;
}

.create-connect-transit-sliders-wrapper {
    display: inline-block;
    vertical-align: middle;    
}

.create-connect-transit-slider-title {
    width: 90px;
    font-size: 18px;
    text-align: center;
}

.create-connect-transit-duration {
    width: 100%;
    height: 64px;
    margin-top: 45px;
}

.create-connect-transit-physicality {
    width: 100%;
    height: 64px;
}

.create-connect-transit-distance {
    width: 100%;
    height: 64px;
}


/*
============================================================================================
    Selected
============================================================================================
*/

#create-connect-selected-trip {
    padding: 2px 10px 16px 10px;
    text-align: left;
}

.create-connect-selected-trip-day {
    padding: 2px 10px 16px 10px;
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
}

.create-connect-selected-trip-night {
    padding: 2px 10px 16px 10px;
    border-left: 1px solid $darkest-gray;
    border-right: 1px solid $darkest-gray;
}

#create-connect-selected-title {
    padding-bottom: 10px;
    text-align: center;
}

#create-connect-selected-stats {
    padding: 10px 6px 0px 0px;
    text-align: right;
}

.create-connect-selected-item-container {
    width: 152px;
    display: inline-block;
    position: relative;
    opacity: 0.9;
    text-align: center;
}

.create-connect-selected-item-remove-button {
    position: absolute;
    top: 110px;
    right: 14px;
    opacity: 0.0 !important;
}

.create-connect-selected-item-container:hover {
    opacity: 1.0;
}

.create-connect-selected-item-container:hover 
.create-connect-selected-item-remove-button {
    opacity: 0.9  !important;
}

.create-connect-selected-highlight-on-day,
.create-connect-selected-highlight-on-night,
.create-connect-selected-highlight-off-day,
.create-connect-selected-highlight-off-night {
    display: inline-block;
    padding: 1px 8px;
    margin: 0px 0px 4px 0px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
}

.create-connect-selected-highlight-on-day {
    color: $white;
    background-color: $light-blue;
}

.create-connect-selected-highlight-on-night {
    color: $black;
    background-color: $light-blue;
}

.create-connect-selected-highlight-off-day {
    color: $white;
    background-color: $light-gray;
}

.create-connect-selected-highlight-off-day:hover {
    color: $white;
    background-color: $light-blue;
}

.create-connect-selected-highlight-off-night {
    color: $black;
    background-color: $darkest-gray;
}

.create-connect-selected-highlight-off-night:hover {
    color: $black;
    background-color: $blue;
}

/*
============================================================================================
    Selected Map
============================================================================================
*/

#create-connect-map-container {
    border-radius: 10px;
    margin-bottom: 12px;
    overflow: hidden;
}


/*
============================================================================================
   Sliders (Rating / Difficulty / Best Time)
============================================================================================
*/

.create-connect-transit-duration,
.create-connect-transit-physicality,
.create-connect-transit-distance {
    display: inline-block;
    vertical-align: middle;
    width: 316px;
}

.create-connect-transit-duration-title,
.create-connect-transit-physicality-title,
.create-connect-transit-distance-title {
    display: inline-block;
    vertical-align: middle;
    width: 90px;
    margin-right: 16px;
    text-align: right;
}

.create-connect-transit-slider-duration,
.create-connect-transit-slider-physicality,
.create-connect-transit-slider-distance {
    display: inline-block;
    vertical-align: middle;
    width: 210px;
}

.create-connect-transit-slider-duration,
.create-connect-transit-slider-distance {
    position: relative;
}

.create-connect-transit-duration-increase,
.create-connect-transit-duration-decrease, 
.create-connect-transit-distance-increase,
.create-connect-transit-distance-decrease {
    position: absolute;
    top: 18px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.create-connect-transit-duration-increase:hover,
.create-connect-transit-duration-decrease:hover, 
.create-connect-transit-distance-increase:hover,
.create-connect-transit-distance-decrease:hover {
    opacity: 0.8;
}

.create-connect-transit-duration-increase,
.create-connect-transit-distance-increase {
    right: 0px;
}

.create-connect-transit-duration-decrease, 
.create-connect-transit-distance-decrease {
    right: 25px;
}
