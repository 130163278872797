@import "../../css/_variables";
@import "../../css/_mixins";


#edit-user-info-modal-container {
    @include modal-background;
}

#edit-user-info-modal {
    @include modal-minimal;
    width: 620px;
}

#edit-user-info-modal-small {
    @include modal-minimal;
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 350px;
}


/* 
============================================================================================
    User Pace and Profile Pic
============================================================================================
*/

#edit-user-info-profile {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 20px;
}

#edit-user-info-profile-level-text {
    padding-bottom: 10px;
    vertical-align: middle;
    text-align: center;
}

#edit-user-info-profile-level {
    margin: 20px;
}

#edit-user-info-profile-image-loader {
    width: 180px !important;
    height: 180px !important;
    margin: 0px auto 0px auto;
}

#edit-user-info-profile-image {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    text-align: right;
}

#edit-user-info-profile-image-upload-button-container {
    margin: 2px 8px 0px 0px;
    opacity: 0.4;
}

#edit-user-info-profile-image-upload-button-container:hover {
    opacity: 0.8;
}

#edit-user-info-profile-image-upload-button {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;    
}

#edit-user-info-profile-image-warning {
    width: 100%;
    margin: 6px 0px 0px 0px;
    padding: 0px;
    font-size: 16px;
    text-align: center;
}


/* 
============================================================================================
    Sign Up Forms
============================================================================================
*/

#edit-user-info-form-container {
    width: 100%;
}

#edit-user-info-form,
#edit-user-info-form-small {
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 5px;
}

#edit-user-info-form {
    margin-left: 30px;
}

.edit-user-info-row {
    width: 100%;
    height: 40px;
    padding: 2px 0px 2px 0px;
    text-align: center;
}

.edit-user-info-text {
    width: 110px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-right: 16px;
}

.edit-user-info-input {
    width: 200px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.edit-user-info-input-small {
    width: 180px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}


#edit-user-info-email, 
#edit-user-info-password-once, 
#edit-user-info-password-twice, 
#edit-user-info-first-name, 
#edit-user-info-last-name, 
#edit-user-info-username, 
#edit-user-info-area {
    width: 100%;
    margin: 1px 0px 1px 0px;
}

.edit-user-info-warning {
}

#edit-user-info-username {
}

#edit-user-info-password-once {
}

#edit-user-info-password-twice {
}

#edit-user-info-first-name {

}
#edit-user-info-last-name {
}

#edit-user-info-email {
}

#edit-user-info-area {
    position: relative;
    height: "auto";
    resize: none;
}

#edit-user-info-email:focus, 
#edit-user-info-password-once:focus,
#edit-user-info-password-twice:focus, 
#edit-user-info-first-name:focus, 
#edit-user-info-last-name:focus, 
#edit-user-info-username:focus, 
#edit-user-info-area:focus {}

#edit-user-info-submit-button-container {
    width: 100%;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

#edit-user-info-submit-button-form {
    width: 100%;
    text-align: center;    
}

#edit-user-info-submit-button-active-day, 
#edit-user-info-submit-button-inactive-day,
#edit-user-info-submit-button-active-night, 
#edit-user-info-submit-button-inactive-night {
    border: none;
    text-decoration: none;
}

#edit-user-info-submit-button-active-day {
    color: $white;
    background-color: $light-blue;
}

#edit-user-info-submit-button-inactive-day {
    color: $white;
    background-color: $light-gray;
}

#edit-user-info-submit-button-active-night {
    background-color: $blue;
}

#edit-user-info-submit-button-inactive-night {
    background-color: $dark-gray;
}

#edit-user-info-submit-button-inactive:hover {
    opacity: 0.6;
}

.edit-user-info-bucket-mode-text {
    width: 170px;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    padding-right: 16px;
}

.edit-user-info-bucket-mode-menu-small {
    display: inline-block;
    position: relative;
    width: 70px;
    vertical-align: top;
    top: -9px;
}

.edit-user-info-bucket-mode-menu {
    display: inline-block;
    position: relative;
    width: 70px;
    vertical-align: top;
    top: -9px;
}
