@import "../../css/_variables";
@import "../../css/_mixins";

#create-modal-container {
    @include modal-background;
}

#create-modal,
#create-modal-small {
    @include modal-basic;
    position: relative;
    width: calc(100% - 30px);
    min-width: 320px;
    max-width: 600px;
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 30px);
}

#create-modal::-webkit-scrollbar,
#create-modal-small::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


#create-modal {
    padding: 0px 0px 0px 0px;
}

#create-modal-small {
    padding: 0px 0px 0px 0px;
}

#create-modal-cancel {
    width: 25px !important;
    height: 25px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    opacity: 1.0;
}

#create-modal-detail-container, 
#create-modal-curation-container, 
#create-modal-connect-container {
    width: 100%;
    text-align: center;
    padding: 0px 0px 0px 0px;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-modal-curation-container {
    padding: 0px 10px;
}


/*
============================================================================================
   Create Form
============================================================================================
*/

.create-modal-form {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.create-modal-warning {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding-bottom: 20px;
    text-align: center;
    white-space: pre-line;
}


/*
============================================================================================
   Object Type
============================================================================================
*/

#create-modal-object-type-container {
    text-align: center;
    padding-bottom: 20px;
}

.create-modal-object-type-float {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    opacity: 0.3;
    cursor: pointer;
}

.create-modal-object-type-float:hover {
    opacity: 0.6;
}

.create-modal-object-type-title {
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out;   
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-modal-object-type-dot, 
#create-modal-object-type-trip {
    height: 38px;
    margin: 0px auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-modal-object-type-dot {
    width: 38px;
    background-size: auto 38px;
} 

#create-modal-object-type-trip {
    width: 60px;
    background-size: auto 38px;
}


/*
============================================================================================
    Write Mode
============================================================================================
*/

#create-modal-write-mode-container {
    margin: 5px 0px 15px 0px;
}


/*
============================================================================================
   Everyday Mode
============================================================================================
*/

#create-modal-everyday-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#create-modal-everyday-mode:hover {
}

#create-modal-everyday-mode-icon {
    display: inline-block;
    width: 42px;
    height: 38px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.create-modal-everyday-mode-icon {
    opacity: 0.75;
}

#create-modal-everyday-mode-text {
}

.create-modal-everyday-mode-text {
    opacity: 0.75;
}

#create-modal-everyday-mode:hover > .create-modal-everyday-mode-icon,
#create-modal-everyday-mode:hover > .create-modal-everyday-mode-text {
    opacity: 1.0;
}


/*
============================================================================================
   Author Mode
============================================================================================
*/

#create-modal-author-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#create-modal-author-mode:hover {
}

#create-modal-author-mode-icon {
    display: inline-block;
    width: 44px;
    height: 42px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.create-modal-author-mode-icon {
    opacity: 0.75;
}

#create-modal-author-mode-text {
}

.create-modal-author-mode-text {
    opacity: 0.75;
}

#create-modal-author-mode:hover > .create-modal-author-mode-text,
#create-modal-author-mode:hover > .create-modal-author-mode-icon {
    opacity: 1.0;
}


/*
============================================================================================
   Contributor Mode
============================================================================================
*/

#create-modal-contributor-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#create-modal-contributor-mode:hover {
}

#create-modal-contributor-mode-icon {
    display: inline-block;
    width: 54px;
    height: 32px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.create-modal-contributor-mode-icon {
    opacity: 0.75;
}

#create-modal-contributor-mode-text {
}

.create-modal-contributor-mode-text {
    opacity: 0.75;
}

#create-modal-contributor-mode:hover > .create-modal-contributor-mode-icon,
#create-modal-contributor-mode:hover > .create-modal-contributor-mode-text {
    opacity: 1.0;
}


/*
============================================================================================
   Partition
============================================================================================
*/

#create-modal-bottom {
    width: 100%;
    max-width: 320px;
    display: inline-block;
    vertical-align: top;
}

#create-modal-top {
    width: 100%;
    display: inline-block;
}

#create-modal-location {
    width: 100%;
    display: inline-block;
}

.create-modal-content {
    width: 100%;
    margin: 0px auto;
    max-width: 480px;
}


/*
============================================================================================
    Contributor
============================================================================================
*/

#create-modal-contributor-only {
    width: 100%;
    padding: 20px 10px;
}


/*
============================================================================================
    Detail
============================================================================================
*/

.create-modal-detail-row {
    width: 100%;
    padding-bottom: 16px;
    text-align: center;
}

.create-modal-detail-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 16px;
    text-align: center;
}

.create-modal-detail-input {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}


/*
============================================================================================
    Media
============================================================================================
*/

#create-modal-upload-gallery-add-image {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#create-modal-upload-gallery-add-video {
    opacity: 0.0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}


/*
============================================================================================
   Dot Type
============================================================================================
*/

#create-modal-dot-type-container {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 150px;
}

.create-modal-dot-type-float {
    display: inline-block;
    width: 48px;
    padding: 0px;
    opacity: 0.4;
}

.create-modal-dot-type-float:hover {
    opacity: 0.6;
}

.create-modal-dot-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 16px;
    text-align: center;
}

#create-modal-dot-type-scenic, 
#create-modal-dot-type-experience,
#create-modal-dot-type-dine {
    width: 36px;
    height: 36px;
    margin: 0px auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;    
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-modal-dot-type-scenic {
} 

#create-modal-dot-type-experience {
} 

#create-modal-dot-type-dine {
}


/*
============================================================================================
   Trip Type
============================================================================================
*/

#create-modal-trip-type-container {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 160px;
}

.create-modal-trip-type-float {
    display: inline-block;
    width: 52px;
    padding: 0px;
    opacity: 0.4;
}

.create-modal-trip-type-float:hover {
    opacity: 0.6;
}

.create-modal-trip-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 16px;
    text-align: center;
}

#create-modal-trip-type-smart, 
#create-modal-trip-type-route {
    width: 40px;
    height: 40px;
    margin: 0px auto;
}

#create-modal-trip-type-smart {
} 

#create-modal-trip-type-route {
}


/*
============================================================================================
    Travel Type
============================================================================================
*/

#create-modal-travel-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 16px;
    text-align: center;
}

#create-modal-travel-type {
    display: inline-block;
    width: 214px;
    text-align: center;
}

#create-modal-roundtrip-button,
#create-modal-oneway-button,
#create-modal-loop-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-modal-roundtrip-button:hover, 
#create-modal-oneway-button:hover,
#create-modal-loop-button:hover {
}


/*
============================================================================================
    Reversible
============================================================================================
*/

#create-modal-location-reversible-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    text-align: right;
}

#create-modal-location-reversible {
    display: inline-block;
    width: 150px;
    text-align: center;
}

#create-modal-reversible-yes-button,
#create-modal-reversible-no-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-modal-reversible-yes-button:hover,
#create-modal-reversible-no-button:hover {
}


/*
============================================================================================
    Basic
============================================================================================
*/

#create-modal-basic-container {
    width: 100%;
    text-align: center;
}

.create-modal-basic-row {
    width: 100%;
    padding-bottom: 16px;
    text-align: center;
    white-space: nowrap;
}

.create-modal-basic-title {
    display: inline-block;
    vertical-align: middle;
    width: 0px;
    margin-right: 0px;
    text-align: center;
}

.create-modal-basic-input {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}


/*
============================================================================================
    Name / Area / Title / Overview
============================================================================================
*/

#create-modal-name,
#create-modal-area,
#create-modal-title {
    padding: 4px 12px;
}

#create-modal-name, 
#create-modal-area {
    min-width: 240px;
    width: 70%;
}

#create-modal-title {
    width: 100%;
}

#create-modal-name:blur,
#create-modal-area:blur,
#create-modal-title:blur {
}

#create-modal-name:focus,
#create-modal-area:focus,
#create-modal-title:focus {  
}


/*
============================================================================================
    Basic Curation Overview
============================================================================================
*/

#create-modal-overview {
    width: 100%;
    position: relative;
    text-align: center;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#create-modal-overview::-webkit-scrollbar{
    display: none;
}


/*
============================================================================================
    Location Components
============================================================================================
*/

#create-modal-location-options {
    white-space: nowrap;
    margin-bottom: 10px;
    cursor: pointer;
}

#create-modal-location-options-image {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 28px;
    margin-right: 10px;
    opacity: 0.4;
}

#create-modal-location-options-text {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.75;
}

#create-modal-location-options-text:hover {
    opacity: 1.0;
}

.create-modal-location-feature-row {
    width: 100%;
    padding-bottom: 6px;
    text-align: center;
    white-space: nowrap;
}

#create-modal-location-access-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#create-modal-location-access-wide {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    max-width: 300px;
    text-align: center;
    white-space: normal;
}

#create-modal-location-access-narrow {
    display: inline-block;
    width: calc(100% - 80px);
    max-width: 300px;
    text-align: center;
}

#create-modal-drivable-button,
#create-modal-undrivable-button,
#create-modal-park-and-walk-button {
    vertical-align: middle;
    margin: 3px 3px;
}

#create-modal-drivable-button:hover, 
#create-modal-undrivable-button:hover,
#create-modal-park-and-walk-button:hover {
}


#create-modal-location-preferred-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#create-modal-location-preferred {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    max-width: 300px;
    text-align: center;
}

#create-modal-drive-button,
#create-modal-public-transportation-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-modal-drive-button:hover,
#create-modal-public-transportation-button:hover {
}

#create-modal-location-type {
    width: 100%;
    /*border-bottom: 1px solid #464646;*/
}

#create-modal-location-type-actual, 
#create-modal-location-type-parking,
#create-modal-location-type-start, 
#create-modal-location-type-end {
    display: inline-block;
    width: 50%;
    padding: 6px;
    border-radius: 0px;
}

#create-modal-location-type-actual:hover, 
#create-modal-location-type-parking:hover,
#create-modal-location-type-start:hover, 
#create-modal-location-type-end:hover {
}

#create-modal-location-input,
#create-modal-location-lat,
#create-modal-location-lng {
}

#create-modal-location-search-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
}

.create-modal-location-search-title-text-single {
    text-align: right;
}

.create-modal-location-search-title-text-double {
    text-align: right;
    line-height: 20px;
}

#create-modal-location-search {
    width: calc(100% - 30px);
    padding: 4px 12px;
    position: relative;
    resize: none;
    overflow: hidden;
}

#create-modal-location-search-short {
    width: calc(80% - 80px);
    min-width: 220px;
    padding: 4px 12px;
    position: relative;
    resize: none;
    overflow: hidden;
}

#create-modal-location-gps {
    width: 100%;
}

#create-modal-location-gps-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#create-modal-location-lat, 
#create-modal-location-lng {
    display: inline-block;
    vertical-align: middle;
    width: 110px;
    padding: 2px 6px 1px 6px;
    margin-right: 8px;
}

#create-modal-location-gps-button {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 10px;
}

#create-modal-location-gps-button:hover {
}

#create-modal-location:focus, 
#create-modal-location-lat:focus, 
#create-modal-location-lng:focus {  
}


/*
============================================================================================
    Location Modal
============================================================================================
*/

.create-modal-location-detect-modal {
    @include modal-background;
}

.create-modal-location-detect-modal-content {
    @include modal-basic-binary;
    width: 300px;
    text-align: center;
}

.create-modal-location-detect-modal-content__item {
    padding: 5px 30px;
}


/*
============================================================================================
   Map
============================================================================================
*/

#create-modal-map-container {
    width: 100%;
    overflow: hidden;
}


/*
============================================================================================
    Advanced Curation (todos / history / stories)
============================================================================================
*/

#create-modal-curation-type {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

#create-modal-curation-type-todos, 
#create-modal-curation-type-history,
#create-modal-curation-type-stories {
    width: 60px;
    margin: 0px 3px;
    text-align: center;
}

#create-modal-curation-type-todos:hover, 
#create-modal-curation-type-history:hover,
#create-modal-curation-type-stories:hover {
}

#create-modal-curation-container {
}

.create-modal-curation-row {
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
}

.create-modal-curation-title {
    width: 90px;
    padding-right: 16px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.create-modal-curation-title-text {
    padding-bottom: 6px;
}

.create-modal-curation-optional-title {
    width: 90px;
    padding: 30px 16px 0px 0px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.create-modal-curation-input {
    width: 100%;
    max-width: 600px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.create-modal-curation-title-small {
    width: 100%;
    margin-bottom: 4px;
    text-align: center;
}

#create-modal-todos,
#create-modal-history,
#create-modal-stories {
    width: 100%;
    position: relative;
}


/*
============================================================================================
   Sliders (Rating / Difficulty / Best Time)
============================================================================================
*/

#create-modal-rating-title,
#create-modal-physicality-title,
#create-modal-duration-title,
#create-modal-best-time-title {
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    margin-right: 16px;
    text-align: right;
}

#create-modal-rating,
#create-modal-physicality,
#create-modal-duration {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 100px);
    margin: 16px 0px;
}

#create-modal-best-time {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    margin-bottom: 16px;
}

#create-modal-duration {
    position: relative;
}

#create-modal-duration-slider-increase,
#create-modal-duration-slider-decrease {
    position: absolute;
    top: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#create-modal-duration-slider-increase:hover,
#create-modal-duration-slider-decrease:hover {
    opacity: 0.8;
}

#create-modal-duration-slider-increase {
    right: 0px;
}
#create-modal-duration-slider-decrease {
    right: 25px;
}


#create-modal-best-time {
    width: calc(100% - 100px);
    margin: 0px 0px 0px 0px;
}

#create-modal-best-time-first,
#create-modal-best-time-second {
    width: 100%;
}

#create-modal-best-time-first, 
#create-modal-best-time-second {
    margin: 20px 0px 12px 0px;
}

#create-modal-best-time-buttons {
    text-align: center;
}

#create-modal-best-time-all-day,
#create-modal-best-time-single,
#create-modal-best-time-double,
{

}

.create-modal-best-time-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

.create-modal-best-time-button:hover {
    opacity: 0.6;
}

/*
============================================================================================
   Hours
============================================================================================
*/

#create-modal-hours-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 0px;
    text-align: center;
}

#create-modal-hours-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}

#create-modal-hours-picker-container {
    padding-bottom: 16px;
}

#create-modal-hours-arrow {
    position: absolute;
    top: 104px;
    left: 168px;
    width: 28px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.3;
    z-index: 1;
}

#create-modal-start-hour, 
#create-modal-end-hour {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

#create-modal-start-hour-tooltip-area, #create-modal-end-hour-tooltip-area {
    position: absolute;
    width: 100%;
    height: 60px;
}

#create-modal-hours-type {
    text-align: center;
    display: inline-block;
    width: 200px;
}

#create-modal-hours-fixed-button,
#create-modal-hours-allday-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-modal-hours-fixed-button:hover, 
#create-modal-hours-allday-button:hover {
}


/*
============================================================================================
   Season Closure
============================================================================================
*/

#create-modal-season-closure-dates-container {
    display: inline-block;
    vertical-align: middle;
}

#create-modal-season-closure-dates {
    text-align: center;
    padding-bottom: 6px;
}

#create-modal-season-closure-date-type {
    width: 230px;
    text-align: center;
}

#create-modal-season-closure-date-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 0px;
    text-align: right;
}

.create-modal-season-closure-date-title-text {
}

#create-modal-season-closure-date-arrow {
    vertical-align: middle;
    width: 32px;
    padding: 10px;
    opacity: 0.3;
}

#create-modal-season-closure-start-date, 
#create-modal-season-closure-end-date {
    width: 100px;
    display: inline-block;
    vertical-align: middle;
}

.create-modal-season-closure-date-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

.create-modal-season-closure-date-button:hover {
    opacity: 0.6;
}


/*
============================================================================================
   Dining options
============================================================================================
*/


#create-modal-dining-options-container {
    display: inline-block;
    vertical-align: middle;
}

#create-modal-dining-options-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    text-align: right;
}

.create-modal-dining-options-title-text {
}

#create-modal-dining-options-type {
    text-align: center;
    display: inline-block;
    width: 90px;
}

#create-modal-dining-options-yes-button,
#create-modal-dining-options-no-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

#create-modal-dining-options-yes-button:hover,
#create-modal-dining-options-no-button:hover {
    opacity: 0.6;
}


/*
============================================================================================
   Progress Bar and Buttons
============================================================================================
*/

#create-modal-progress-container {
    padding: 0px 0px 0px 0px;
    text-align: center;
}

#create-modal-progress-first-section, 
#create-modal-progress-second-section {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 90px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: 80px 48px;
    background-position: center;
    overflow: visible;
}

#create-modal-progress-first-section {
}

#create-modal-progress-second-section {
}

#create-modal-progress-first,
#create-modal-progress-second,
#create-modal-progress-third {
    position: absolute;
    width: 48px;
    height: 48px;
    opacity: 1.0;
    cursor: pointer;
    z-index: 1;
}

#create-modal-progress-first {
    top: 0px;
    left: -26px;
}

#create-modal-progress-second, 
#create-modal-progress-third {
    top: 0px;
    right: -26px;
}

#create-modal-progress-first:hover,
#create-modal-progress-second:hover,
#create-modal-progress-third:hover {
    opacity: 1.0;
}

#create-modal-progress-first-number, 
#create-modal-progress-second-number,
#create-modal-progress-third-number {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#create-modal-progress-first-title, 
#create-modal-progress-second-title, 
#create-modal-progress-third-title {
    position: absolute;
    width: 160px;
    top: -32px;
    left: -54px;
    padding: 6px 0px;
    text-align: center;
}

#create-modal-progress-publish-container {
    display: inline-block;
    vertical-align: middle;
    width: 72px;
    height: 48px;
    margin-bottom: 16px;
    text-align: left;
    overflow: visible;
}

#create-modal-progress-publish {
    display: inline-block;
    width: 108px;
    height: 48px;
    opacity: 1.0;
}

#create-modal-progress-publish:hover {
    opacity: 1.0;
}

/*
============================================================================================
   Publish Modal
============================================================================================
*/

#create-modal-complete-modal {
    @include modal-background;
}


#create-modal-complete-publish {
    @include modal-basic-binary;
    width: 320px;
    padding: 20px 30px 25px 30px;
    overflow: visible;
    text-align: center;
}

#create-modal-complete-title {
    text-align: center;
    padding-bottom: 6px;
}

#create-modal-complete-message {
    text-align: center;
    padding-bottom: 20px;
}

#create-modal-complete-public,
#create-modal-complete-personal {
    margin: 0px 5px;
}

#create-modal-complete-slider-container {
    width: 100%;
    margin-top: 20px;
}

#create-modal-complete-slider-title {
    text-align: center;
    margin-bottom: 22px;
}

#create-modal-complete-slider {
    width: 180px;
    margin: 0px auto;
}

#create-modal-complete-button-container {
    margin: 20px auto 0px auto;
}

#create-modal-complete-button {
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

#create-modal-complete-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    opacity: 0.6;
}
