@import "../../css/_variables";
@import "../../css/_mixins";


.more-modal {
    @include modal-background;
}

.more-modal-content {
    @include modal-basic;
    width: 300px;
}

.more-modal-content__item {
	padding: 5px 30px;
}

.delete-modal {
    @include modal-background;
}

.delete-modal-content {
    @include modal-basic-binary;
    width: 240px;
}

.delete-modal-content__item {
	padding: 5px 30px;
}
