@import "../../css/_mixins";
@import "../../css/_variables";


/*
============================================================================================
    Modal
============================================================================================
*/

.dot-edit-contributor-modal {
    @include modal-background;
}

.dot-edit-contributor-modal-content {
    @include modal-basic;
    width: 100%;
    min-width: 320px;
    max-width: 400px;
    padding: 30px 10px;
    overflow: hidden;
    text-align: center;
}


/*
============================================================================================
   Structure
============================================================================================
*/

#dot-edit-contributor {
    display: inline-block;
    width: 100%;
    min-width: 300px;
    max-width: 320px;
    margin: 0px auto;
}

.dot-edit-contributor-row {
    width: 100%;
    margin: 15px 0px;
}


/*
============================================================================================
   Dot Type
============================================================================================
*/

#dot-edit-type-container {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 160px;
}

.dot-edit-type-float {
    display: inline-block;
    width: 48px;
    padding: 0px;
    opacity: 0.4;
}

.dot-edit-type-float:hover {
    opacity: 0.6;
}

.dot-edit-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 16px;
    text-align: center;
}

#dot-edit-type-scenic, 
#dot-edit-type-experience,
#dot-edit-type-dine {
    width: 36px;
    height: 36px;
    margin: 0px auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;    
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#dot-edit-type-scenic {
} 

#dot-edit-type-experience {
} 

#dot-edit-type-dine {
}



/*
============================================================================================
   Sliders (Rating / Difficulty / Best Time)
============================================================================================
*/

#dot-edit-rating-title,
#dot-edit-physicality-title,
#dot-edit-duration-title,
#dot-edit-best-time-title {
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    margin-right: 16px;
    text-align: right;
}

#dot-edit-rating,
#dot-edit-physicality,
#dot-edit-duration {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 100px);
    margin: 16px 0px;
}

#dot-edit-best-time {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    margin-bottom: 16px;
}

#dot-edit-duration {
    position: relative;
}

#dot-edit-duration-slider-increase,
#dot-edit-duration-slider-decrease {
    position: absolute;
    top: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#dot-edit-duration-slider-increase:hover,
#dot-edit-duration-slider-decrease:hover {
    opacity: 0.8;
}

#dot-edit-duration-slider-increase {
    right: 0px;
}
#dot-edit-duration-slider-decrease {
    right: 25px;
}


#dot-edit-best-time {
    width: calc(100% - 100px);
    margin: 0px 0px 0px 0px;
}

#dot-edit-best-time-first,
#dot-edit-best-time-second {
    width: 100%;
}

#dot-edit-best-time-first, 
#dot-edit-best-time-second {
    margin: 20px 0px 12px 0px;
}

#dot-edit-best-time-buttons {
    text-align: center;
}

#dot-edit-best-time-all-day,
#dot-edit-best-time-single,
#dot-edit-best-time-double,
{

}

.dot-edit-best-time-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

.dot-edit-best-time-button:hover {
    opacity: 0.6;
}

/*
============================================================================================
   Hours
============================================================================================
*/

#dot-edit-hours-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 0px;
    text-align: center;
}

#dot-edit-hours-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}

#dot-edit-hours-picker-container {
    padding-bottom: 16px;
}

#dot-edit-hours-arrow {
    position: absolute;
    top: 104px;
    left: 168px;
    width: 28px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.3;
    z-index: 1;
}

#dot-edit-start-hour, 
#dot-edit-end-hour {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

#dot-edit-start-hour-tooltip-area, #dot-edit-end-hour-tooltip-area {
    position: absolute;
    width: 100%;
    height: 60px;
}

#dot-edit-hours-type {
    text-align: center;
    display: inline-block;
    width: 200px;
}

#dot-edit-hours-fixed-button,
#dot-edit-hours-allday-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#dot-edit-hours-fixed-button:hover, 
#dot-edit-hours-allday-button:hover {
}


/*
============================================================================================
   Season Closure
============================================================================================
*/

#dot-edit-season-closure-dates-container {
    display: inline-block;
    vertical-align: middle;
}

#dot-edit-season-closure-dates {
    text-align: center;
    padding-bottom: 6px;
}

#dot-edit-season-closure-date-type {
    width: 230px;
    text-align: center;
}

#dot-edit-season-closure-date-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 0px;
    text-align: right;
}

.dot-edit-season-closure-date-title-text {
}

#dot-edit-season-closure-date-arrow {
    vertical-align: middle;
    width: 32px;
    padding: 10px;
    opacity: 0.3;
}

#dot-edit-season-closure-start-date, 
#dot-edit-season-closure-end-date {
    width: 100px;
    display: inline-block;
    vertical-align: middle;
}

.dot-edit-season-closure-date-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

.dot-edit-season-closure-date-button:hover {
    opacity: 0.6;
}


/*
============================================================================================
   Dining options
============================================================================================
*/


#dot-edit-dining-options-container {
    display: inline-block;
    vertical-align: middle;
}

#dot-edit-dining-options-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    text-align: right;
}

.dot-edit-dining-options-title-text {
}

#dot-edit-dining-options-type {
    text-align: center;
    display: inline-block;
    width: 90px;
}

#dot-edit-dining-options-yes-button,
#dot-edit-dining-options-no-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

#dot-edit-dining-options-yes-button:hover,
#dot-edit-dining-options-no-button:hover {
    opacity: 0.6;
}
