@import "../../css/_variables";
@import "../../css/_mixins";

.user-tag-modal {
    @include modal-background;
}

.user-tag-modal-content {
    @include modal-user-tag;
    width: 310px;
}

.user-tag-modal-content__image,
.user-tag-modal-content__video {
    width: 100%;
    height: 200px;
}

.user-tag-modal-content__input {
}
