@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    General
============================================================================================
*/

.body {
    padding-bottom: 50px;
}


/*
============================================================================================
    Layout
============================================================================================
*/

#itinerary-container {
    min-width: 320px;
}

.itinerary-container {
    padding-top: 62px;
}

.itinerary-container-small {
    padding-top: 52px;
}

#itinerary-more-cotainer {
    float: right;
}

#itinerary-preview {
    width: 100%;
    padding-top: 8px;
    text-align: center;
    display: block;
    overflow: visible;
}

#itinerary-preview-left {
    width: 160px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

#itinerary-preview-middle {
    margin: 0px 16px;
    display: inline-block;
    vertical-align: top;
}

#itinerary-preview-right {
    width: 160px;
    display: inline-block;
    vertical-align: top;
}


/*
============================================================================================
    Title / Name
============================================================================================
*/

#itinerary-header {
    width: 100%;
    text-align: center;
}

#itinerary-title-container {
    display: inline-block;
    padding: 0px 10px;
    margin: 10px 0px;
}


#itinerary-title {
    display: inline-block;
    padding: 2px 0px;
    margin: 0px 4px;
}

#itinerary-area {
    display: inline-block;
    padding: 2px 0px;
    margin: 0px 4px;
}

#itinerary-more-button {
    cursor: pointer;
    width: 20px;
    height: 30px;
    margin-left: 6px;
}


/*
============================================================================================
    Date / DayInfo
============================================================================================
*/

#itinerary-date-dayinfo-container {
    width: 100%;
    padding: 10px 10px 0px 10px;
}

#itinerary-date-dayinfo {
    margin: 0px auto;
    text-align: center;
}

#itinerary-date-column,
#itinerary-dayinfo-column {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 5px;
}

#itinerary-date-column {
    font-size: 28px;
}

#itinerary-date-column-small {
    font-size: 26px;
}

#itinerary-date-title, 
#itinerary-dayinfo-title {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 6px;
    text-align: center;
}

#itinerary-dayinfo {
    text-align: center;
}

#itinerary-spacer {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
}

/*
============================================================================================
    Bottom Menu (Customize / Share / Delete)
============================================================================================
*/

#itinerary-bottom-menu {
    text-align: center;
    margin: 0px auto 10px auto;
}

.itinerary-bottom-menu-column {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 15px 10px 15px;
}

.itinerary-bottom-menu-button {
    opacity: 0.75;
    cursor: pointer;
}

.itinerary-bottom-menu-button-inline {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.75;
    cursor: pointer;
}

.itinerary-bottom-menu-button:hover,
.itinerary-bottom-menu-button-inline:hover {
    opacity: 1.0;
}


/*
============================================================================================
    Guests
============================================================================================
*/

#itinerary-guests-button-icon {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
}

#itinerary-guests-button-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
}

#itinerary-guests-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
}

.itinerary-guests-container {
    margin-top: 6px;
}

.itinerary-guests-container-inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
}

.itinerary-guest-count {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    font-size: 30px;
}

.itinerary-guests {
    display: inline-block;
    vertical-align: middle;
    padding-top: 4px;
}

.itinerary-guest-profile-pics {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 3px 0px 3px;
}

.itinerary-guests-count {
    display: inline-block;
    vertical-align: middle;
    margin: 6px 3px 6px 0px;
}

.itinerary-guest-spacer {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.itinerary-guests-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

.itinerary-guest-profile-pic {
    margin: 0px 2px;
}


/*
============================================================================================
    Checklist
============================================================================================
*/

#itinerary-checklist-button-icon {
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    height: 38px;
}

#itinerary-checklist-button-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 34px;
}

#itinerary-checklist-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
}

#itinerary-share-button-icon {
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    height: 38px;
}

#itinerary-share-button-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 34px;
}

#itinerary-share-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
}


/*
============================================================================================
    Stats (Rating / Difficulty / Duration / Distance)
============================================================================================
*/

#itinerary-stats {
    width: 100%;
    text-align: center;
}

#itinerary-stats-title {
    margin-bottom: 16px;
}

.itinerary-stats-block {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 6px 20px 6px;
}

.itinerary-stats-number {
    margin-bottom: 12px;
}

.itinerary-stats-title {
    padding-bottom: 6px;
    text-align: center;
}

#itinerary-stats-rating, 
#itinerary-stats-editor-rating {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#itinerary-stats-rating-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}

#itinerary-stats-difficulty, 
#itinerary-stats-editor-difficulty {
    font-size: 48px;
    line-height: 40px;
    text-align: center;
}

#itinerary-stats-difficulty-count {
    text-align: center;
    padding-top: 4px;
    opacity: 0.8;
}

#itinerary-stats-distance, 
#itinerary-stats-duration {
    font-size: 32px;
    text-align: center;
    line-height: 26px;
}


/*
============================================================================================
    Map
============================================================================================
*/

#itinerary-map-container {
    width: 100%;
    min-width: 320px;
    max-width: 720px;
    padding: 0px 10px;
    margin: 32px auto;
}

#itinerary-map-wrapper {
    border-radius: 10px;
    overflow: hidden;
}

#itinerary-map-title {
    padding-bottom: 16px;
    text-align: center;
}


/*
============================================================================================
    Board
============================================================================================
*/

#itinerary-board-title {
    margin-bottom: 16px;
    text-align: center;
}

#itinerary-board-container {
    margin: 16px 0px 120px 0px;
}


/*
============================================================================================
    Main Menu
============================================================================================
*/

#itinerary-main-menu {
    width: 100%;
    min-width: 320px;
    position: fixed;
    text-align: center;
    padding: 10px 0px 10px 0px;
    bottom: 0px;
    z-index: 20;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.itinerary-main-menu-day {
    background-color: rgba(248, 248, 248, 0.9);
}

.itinerary-main-menu-night {
    background-color: rgba(0, 0, 0, 0.9);
}

.itinerary-main-menu-button {
    display: inline-block;
    vertical-align: middle;
    padding: 4px 8px;
    opacity: 0.75;
    cursor: pointer;
}

.itinerary-main-menu-button:hover {
    opacity: 1.0;
}

.itinerary-main-menu-button-small {
    display: inline-block;
    vertical-align: middle;
    padding: 4px 8px;
    opacity: 0.75;
    cursor: pointer;
}

.itinerary-main-menu-button-small:hover {
    opacity: 1.0;
}

#itinerary-modify-button-icon {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    cursor: pointer;
}

#itinerary-modify-button-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    height: 38px;
    cursor: pointer;
}

#itinerary-modify-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    cursor: pointer;
}

#itinerary-schedule-button-icon {
    display: inline-block;
    vertical-align: middle;
    width: 46px;
    height: 46px;
    cursor: pointer;
}

#itinerary-schedule-button-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    cursor: pointer;
}

#itinerary-schedule-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    cursor: pointer;
}


/*
============================================================================================
    Route Planning
============================================================================================
*/

#itinerary-route-go-info-container {
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

#itinerary-route-go-info {
    position: relative;
    display: inline-block;
    padding-bottom: 20px;
}

#itinerary-route-go-cancel-button {
    position: absolute;
    top: -10px;
    right: 6px;
}

#itinerary-route-go-cancel-button:hover {
}

#itinerary-route-go-container {
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 20;
    padding: 10px 0px;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.itinerary-route-go-container-day {
    background-color: rgba(248, 248, 248, 0.9);
}

.itinerary-route-go-container-night {
    background-color: rgba(0, 0, 0, 0.9);
}

#itinerary-route-calendar-dayinfo-container {
    width: 100%;
}

#itinerary-route-calendar-dayinfo {
    width: 100%;
    padding: 0px 40px;
    margin-bottom: 10px;
}

#itinerary-route-calendar-column, #itinerary-route-dayinfo-column {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    text-align: center;
}

#itinerary-route-calendar-title, #itinerary-route-dayinfo-title {
    padding-bottom: 6px;
    text-align: center;
}

#itinerary-route-calendar-container {
    height: 42px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
}

#itinerary-route-calendar-icon {
    vertical-align: middle;
    width: 26px;
    height: 26px;
    margin: 2px 10px 0px 0px;
}

/* Need to implement this to the DatePicker library */
.itinerary-route-calendar-input-day, 
.itinerary-route-calendar-input-night {
    width: 210px !important;
    text-align: center !important;
    border: none !important;
    cursor: pointer !important;
    background-color: transparent !important;
    text-align: left !important;
    font-size: 34px !important;
}

.itinerary-route-calendar-input-day {
    color: $light-blue !important;
}

.itinerary-route-calendar-input-night {
    color: $blue !important;
}

#itinerary-route-calendar {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

#itinerary-route-dayinfo {
    text-align: center;
}

#itinerary-route-time {
    width: 100%;
}

#itinerary-route-time-slider-title {
    padding: 0px 0px 24px 0px;
    text-align: center;
}

#itinerary-route-time-slider-container {
    width: 80%;
    max-width: 600px;
    margin: 0px auto;
    padding: 0px 0px 32px 0px;
    text-align: center;
}

#itinerary-route-time-slider-icon {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

#itinerary-route-time-slider {
    width: calc(100% - 38px);
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.itinerary-route-time-slider-dot-day,
.itinerary-route-time-slider-dot-night {
    border-radius: 100%;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem; 
}

.itinerary-route-time-slider-dot-day {
    background: $light-gray;
    border: 2px solid $light-gray;
}

.itinerary-route-time-slider-dot-night {
    background: $dark-gray;
    border: 2px solid $dark-gray;
}

.itinerary-route-time-slider-text-day,
.itinerary-route-time-slider-text-night {
    width: 100px;
    display: block;
    position: absolute;
    bottom: -20px;
    margin-left: -50px;
    text-align: center;
}

.itinerary-route-time-slider-text-day {
    color: $light-gray;
}

.itinerary-route-time-slider-text-night {
    color: $dark-gray;
}
