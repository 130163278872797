@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    Comments
============================================================================================
*/
.comments-container {
    width: 100%;
    min-width: 320px;
    max-width: 600px;
    margin: 16px auto 32px auto;
    display: block;
    text-align: center;
}

/*
============================================================================================
    Comment Count
============================================================================================
*/
.comment-count {
    margin: 0px auto 10px auto;
    text-align: center;
    cursor: pointer;
}


/*
============================================================================================
    Comment Input
============================================================================================
*/

.comment-input {
    width: calc(100% - 100px);
    height: auto;
    padding: 4px 12px;
    margin: 4px 40px 4px 60px;
    resize: none;
    overflow: hidden;
}


/*
============================================================================================
    Comment
============================================================================================
*/

.comment-container {
    display: block;
}

.comment-upper {
    width: 100%;
    padding-bottom: 8px;
}

.comment-left {
    width: 44px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 8px;
}

.comment-middle-day, 
.comment-middle-night {
    width: calc(100% - 100px);
    display: inline-block;
    vertical-align: middle;
    padding: 6px 12px;
    border-radius: 10px;
    text-align: left;
}

.comment-middle-day {
    background-color: $white;
}

.comment-middle-night {
    background-color: $almost-black;
}

.comment-middle-day a:link, 
.comment-middle-day a:visited, 
.comment-middle-day a:hover, 
.comment-middle-day a:active {
    color: $black;
    text-decoration: none;
}

.comment-middle-night a:link, 
.comment-middle-night a:visited, 
.comment-middle-night a:hover, 
.comment-middle-night a:active {
    color: $white;
    text-decoration: none;
}

.comment-right {
    width: 24px;
    margin: 0px 8px;    
    display: inline-block;
    vertical-align: middle;
    opacity: 0.0;
}

.comment-upper:hover .comment-right {
    opacity: 1.0;
}

.comment-lower {
    width: 100%;
    white-space: nowrap;
}

.comment-user-profile-pic {
}

.comment-user-name {
    margin-right: 10px;
    vertical-align: middle;    
}

.comment-content {
    margin-right: 10px;
    vertical-align: middle;
    word-break: break-all;
    line-height: 24px;
}

.comment-lower {
    position: relative;
    padding: 2px 0px 0px 0px;
}

.comment-timestamp {
    vertical-align: middle;
}

.comment-interactions {
    text-align: center;
}

/* 
============================================================================================
    Edit / Delete Buttons
============================================================================================
*/

.comment-edit-button,
.comment-delete-button {
    opacity: 0.0;
    margin-left: 6px;
    vertical-align: middle;
}

.comment-middle:hover .comment-edit-button,
.comment-middle:hover .comment-delete-button {
    opacity: 0.5;
}


/* 
============================================================================================
    Like / Reply Buttons
============================================================================================
*/

.comment-like-button-on {
    opacity: 1.0;
}

.comment-like-button-off {
    opacity: 0.4;
}

.comment-like-button-off:hover {
    opacity: 0.8;
}

.comment-like-button-text {
}

.comment-like-count {
}

.comment-reply-button {
    vertical-align: middle;
    opacity: 0.4;
}

.comment-reply-button:hover {
    opacity: 0.8;
}

/*
============================================================================================
    Reply Input
============================================================================================
*/

.reply-input {
    width: calc(100% - 140px);
    height: auto;
    margin: 4px 40px 4px 100px;
    resize: none;
    overflow: hidden;
}

.reply-count {
    margin: 0px auto 10px auto;
    text-align: center;
    cursor: pointer;
}

.reply-container {
    display: block;
    padding: 0px 0px 8px 0px;
}

.reply-upper {
    width: 100%;
}

.reply-left {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 8px 0px 32px;
}

.reply-middle-day, 
.reply-middle-night {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 124px);
    padding: 6px 12px;
    border-radius: 10px;
    text-align: left;
}

.reply-middle-day {
    background-color: $white;
}

.reply-middle-night {
    background-color: $almost-black;
}

.reply-middle-day a:link, 
.reply-middle-day a:visited, 
.reply-middle-day a:hover, 
.reply-middle-day a:active {
    color: $black;
    text-decoration: none;
}

.reply-middle-night a:link, 
.reply-middle-night a:visited, 
.reply-middle-night a:hover, 
.reply-middle-night a:active {
    color: $white;
    text-decoration: none;
}

.reply-right {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    margin: 0px 8px;
    opacity: 0.0;
}

.reply-upper:hover .reply-right {
    opacity: 1.0;
}

.reply-lower {
    width: 100%;
}

.reply-user-profile-pic {
}

.reply-user-name {
    margin-right: 10px;
    vertical-align: middle;
}

.reply-content {
    margin-right: 10px;
    vertical-align: middle;
    word-break: break-all;
    line-height: 24px;
}

.reply-lower {
    position: relative;
    padding: 2px 0px 0px 0px;
}

.reply-timestamp {
    vertical-align: middle;
}

.reply-interactions {
    text-align: center;
}


/* 
============================================================================================
    Edit / Delete Buttons
============================================================================================
*/

.reply-edit-button,
.reply-delete-button {
    opacity: 0.0;
    margin-left: 6px;
    vertical-align: middle;
}

.reply-middle:hover .reply-edit-button,
.reply-middle:hover .reply-delete-button {
    opacity: 0.5;
}


/* 
============================================================================================
    Like / Reply Buttons
============================================================================================
*/

.reply-like-button-on {
    opacity: 1.0;
}

.reply-like-button-off {
    opacity: 0.4;
}

.reply-like-button-off:hover {
    opacity: 0.8;
}

.reply-like-button-text {
}

.reply-like-count {
}

.reply-reply-button {
    vertical-align: middle;
    opacity: 0.4;
}

.reply-reply-button:hover {
    opacity: 0.8;
}


/* 
============================================================================================
    Delete Modals
============================================================================================
*/

.comment-delete-modal, .reply-delete-modal {
    @include modal-background;
}

.comment-delete-modal-content-image, .reply-delete-modal-content-image {
    width: 24px;
    height: 28px;
}

.comment-delete-modal-content, .reply-delete-modal-content {
    @include modal-basic;
    width: 300px;
    padding: 20px 30px 30px 30px;
    transform: translate(-50%, -50%);

    &__title {
        display: block;
        text-align: center;
        padding: 20px 20px 10px 20px;
    }

    &__text {
        padding: 0px 0px 10px 0px;
    }

    &__confirm__select {
        margin: auto;
        text-align: center;

        &__yes,
        &__no {
            width: 90px;
            margin: 0px 5px;
        }

        &__yes:hover,
        &__no:hover {
            background-color: #3284B0;
        }        
    }
}
