@import "../../css/_variables";
@import "../../css/_mixins";

.memo-modal {
    @include modal-background;
}

.memo-modal-content {
    @include modal-basic;
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 400px;
}

.memo-modal-content__name {
	display: inline-block;
	vertical-align: bottom;
	margin-bottom: 3px;
}

.memo-modal-content__area {
	display: inline-block;
	vertical-align: bottom;
	margin: 0px 0px 3px 8px;
}

.memo-modal-content__input {
	width: calc(100% - 40px);
	margin: 0px 20px 0px 20px;
	text-align: center;
}

.memo-modal-content__submit {
	margin-top: 10px;
}
