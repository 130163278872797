@import "../../css/_variables";
@import "../../css/_mixins";


.alert-notification {
    @include alert-basic-center;
    padding: 20px 50px 20px 30px;
}

.alert-notification-day {
    @include alert-notification-center-day;
}

.alert-notification-night {
    @include alert-notification-center-night;
}
