@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    User container
============================================================================================
*/

#user-container {
    width: 100%;
    min-width: 320px;
    padding-bottom: 85px;
}

.user-container {
    padding-top: 68px;
}

.user-container-small {
    padding-top: 58px;
}
