@import "./_variables";
@import "./_mixins";


/* Range */
.input-range {
    height: 1rem;
    position: relative;
    width: 100%;
}

.input-range__label-container {
    font-family: "Century-Gothic" !important;
    font-size: 16px !important;
    letter-spacing: -0.3px;
}


/* Tracks */
.input-range__track,
.input-range-track-day,
.input-range-track-night {
    border-radius: 0.2rem;
    cursor: pointer;
    display: block;
    height: 0.2rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out; 
}

.input-range__track--background {
    left: 0;
    margin-top: -0.1rem;
    position: absolute;
    right: 0;
    top: 50%; 
}


/* Slider */
.input-range__slider,
.input-range-slider-day,
.input-range-slider-night {
    appearance: none;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.6rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;
}

.input-range__slider:active,
.input-range-slider-day:active,
.input-range-slider-night:active {
    transform: scale(1.3);
}

.input-range__slider-container {
    transition: left 0.3s ease-out;
}


/* Label container */
.input-range__label-container {
    font-size: 20px;
    left: -50%;
    position: relative;
}

/* Label */
.input-range__label {
    font-size: 1.0rem;
    transform: translateZ(0);
    white-space: nowrap;
}


/* Label min / max */
.input-range__label--min,
.input-range__label--max {
    bottom: -1.6rem;
    position: absolute;
}

.input-range__label--min {
    left: 0;
}

.input-range__label--max {
    right: 0;
}

.input-range__label--max .input-range__label-container {
    left: 50%; 
}


/* Label value */
.input-range__label--value {
    position: absolute;
    top: -2.0rem;
}


/* Label container -> text above slider */
.input-range__label-container,
.input-range-label-container-day,
.input-range-label-container-night {
    left: -50%;
    position: relative;    
}


/*
============================================================================================
    Customization
============================================================================================
*/

/*
inputRange: "input-range",
disabledInputRange: "input-range input-range--disabled",

slider: "input-range__slider",
sliderContainer: "input-range__slider-container",

track: "input-range__track input-range__track--background",
activeTrack: "input-range__track input-range__track--active",

labelContainer: "input-range__label-container",
maxLabel: "input-range__label input-range__label--max",
minLabel: "input-range__label input-range__label--min",
valueLabel: "input-range__label input-range__label--value"
*/


/* Track */
.input-range__track {
    background: #393939;
}

.input-range__track--active {
    background: #207DA3;
}

.input-range-track-day {
    background: $light-gray;
}

.input-range-track-active-day {
    background: $light-blue;
}

.input-range-track-night {
    background: $dark-gray
}

.input-range-track-active-night {
    background: $blue;
}


/* Slider */
.input-range__slider {
    background: #207DA3;
    border: 2px solid #207DA3;
}

.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range-slider-day {
    background: $light-blue;
    border: 2px solid $light-blue;
}

.input-range-slider-day:focus {
    box-shadow: 0 0 0 5px rgba(78, 163, 208, 0.2);
}

.input-range-slider-night {
    background: $blue;
    border: 2px solid $blue;
}

.input-range-slider-night:focus {
    box-shadow: 0 0 0 5px rgba(50, 132, 176, 0.2);
}


/* Disabled */
.input-range--disabled .input-range__track {
    background: #393939;
}

.input-range--disabled .input-range__slider {
    background: #393939;
    border: 1px solid #393939;
    box-shadow: none;
    transform: none;
}

.input-range--disabled .input-range-track-day {
    background: $light-gray;
}

.input-range--disabled .input-range-slider-day {
    background: $light-gray;
    border: 1px solid $light-gray;
    box-shadow: none;
    transform: none;
}

.input-range--disabled .input-range-track-night {
    background: $dark-gray;
}

.input-range--disabled .input-range-slider-night {
    background: $dark-gray;
    border: 1px solid $dark-gray;
    box-shadow: none;
    transform: none;
}


/* Label container -> text above slider */
.input-range__label-container {
    font-family: "Century-Gothic" !important;
    color: #207DA3 !important;
}

.input-range-label-container-day {
    font-family: "Century-Gothic" !important;
    color: $light-blue !important;
}

.input-range-label-container-night {
    font-family: "Century-Gothic" !important;
    color: $blue !important;
}
