@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
   Create
============================================================================================
*/

#create-container {
    width: 100%;
    min-width: 320px;
    text-align: center;
    font-color: white;
}

.create-container {
    padding: 72px 0px 40px 0px;
}

.create-container-small {
    padding: 62px 0px 40px 0px;
}

#create-detail-container, 
#create-curation-container, 
#create-connect-container {
    width: 100%;
    text-align: center;
    padding: 0px 0px 0px 0px;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-curation-container {
    padding: 0px 10px;
}


/*
============================================================================================
   Create Form
============================================================================================
*/

.create-form {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.create-warning {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding-bottom: 20px;
    text-align: center;
    white-space: pre-line;
}


/*
============================================================================================
   Object Type
============================================================================================
*/

#create-object-type-container {
    text-align: center;
    padding-bottom: 20px;
}

.create-object-type-float {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    opacity: 0.3;
    cursor: pointer;
}

.create-object-type-float:hover {
    opacity: 0.6;
}

.create-object-type-title {
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out;   
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-object-type-dot, 
#create-object-type-trip {
    height: 38px;
    margin: 0px auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-object-type-dot {
    width: 38px;
    background-size: auto 38px;
} 

#create-object-type-trip {
    width: 60px;
    background-size: auto 38px;
}


/*
============================================================================================
    Write Mode
============================================================================================
*/

#create-write-mode-container {
    margin: 5px 0px 15px 0px;
}


/*
============================================================================================
   Everyday Mode
============================================================================================
*/

#create-everyday-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#create-everyday-mode:hover {
}

#create-everyday-mode-icon {
    display: inline-block;
    width: 42px;
    height: 38px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.create-everyday-mode-icon {
    opacity: 0.75;
}

#create-everyday-mode-text {
}

.create-everyday-mode-text {
    opacity: 0.75;
}

#create-everyday-mode:hover > .create-everyday-mode-icon,
#create-everyday-mode:hover > .create-everyday-mode-text {
    opacity: 1.0;
}


/*
============================================================================================
   Author Mode
============================================================================================
*/

#create-author-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#create-author-mode:hover {
}

#create-author-mode-icon {
    display: inline-block;
    width: 44px;
    height: 42px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.create-author-mode-icon {
    opacity: 0.75;
}

#create-author-mode-text {
}

.create-author-mode-text {
    opacity: 0.75;
}

#create-author-mode:hover > .create-author-mode-text,
#create-author-mode:hover > .create-author-mode-icon {
    opacity: 1.0;
}


/*
============================================================================================
   Contributor Mode
============================================================================================
*/

#create-contributor-mode {
    display: inline-block;
    vertical-align: bottom;
    padding: 0px 15px;
    cursor: pointer;
}

#create-contributor-mode:hover {
}

#create-contributor-mode-icon {
    display: inline-block;
    width: 54px;
    height: 32px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.create-contributor-mode-icon {
    opacity: 0.75;
}

#create-contributor-mode-text {
}

.create-contributor-mode-text {
    opacity: 0.75;
}

#create-contributor-mode:hover > .create-contributor-mode-icon,
#create-contributor-mode:hover > .create-contributor-mode-text {
    opacity: 1.0;
}


/*
============================================================================================
   Partition
============================================================================================
*/

#create-left {
    width: 100%;
    max-width: 320px;
    display: inline-block;
    vertical-align: top;
}

#create-right {
    width: 100%;
    max-width: 360px;
    margin-left: 20px;
    display: inline-block;
    vertical-align: top;
}

#create-right-small {
    width: 100%;
    max-width: 380px;
    padding: 0px 10px;
    display: inline-block;
    vertical-align: top;
}


/*
============================================================================================
    Contributor
============================================================================================
*/

#create-contributor-only {
    width: 100%;
    padding: 20px 10px;
}


/*
============================================================================================
    Detail
============================================================================================
*/

.create-detail-row {
    width: 100%;
    padding-bottom: 16px;
    text-align: center;
}

.create-detail-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 16px;
    text-align: center;
}

.create-detail-input {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}


/*
============================================================================================
   Dot Type
============================================================================================
*/

#create-dot-type-container {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 150px;
}

.create-dot-type-float {
    display: inline-block;
    width: 48px;
    padding: 0px;
    opacity: 0.4;
}

.create-dot-type-float:hover {
    opacity: 0.6;
}

.create-dot-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 16px;
    text-align: center;
}

#create-dot-type-scenic, 
#create-dot-type-experience,
#create-dot-type-dine {
    width: 36px;
    height: 36px;
    margin: 0px auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;    
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#create-dot-type-scenic {
} 

#create-dot-type-experience {
} 

#create-dot-type-dine {
}


/*
============================================================================================
   Trip Type
============================================================================================
*/

#create-trip-type-container {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 160px;
}

.create-trip-type-float {
    display: inline-block;
    width: 52px;
    padding: 0px;
    opacity: 0.4;
}

.create-trip-type-float:hover {
    opacity: 0.6;
}

.create-trip-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 16px;
    text-align: center;
}

#create-trip-type-smart, 
#create-trip-type-route {
    width: 40px;
    height: 40px;
    margin: 0px auto;
}

#create-trip-type-smart {
} 

#create-trip-type-route {
}


/*
============================================================================================
    Travel Type
============================================================================================
*/

#create-travel-type-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 16px;
    text-align: center;
}

#create-travel-type {
    display: inline-block;
    width: 214px;
    text-align: center;
}

#create-roundtrip-button,
#create-oneway-button,
#create-loop-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-roundtrip-button:hover, 
#create-oneway-button:hover,
#create-loop-button:hover {
}


/*
============================================================================================
    Reversible
============================================================================================
*/

#create-location-reversible-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    text-align: right;
}

#create-location-reversible {
    display: inline-block;
    width: 150px;
    text-align: center;
}

#create-reversible-yes-button,
#create-reversible-no-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-reversible-yes-button:hover,
#create-reversible-no-button:hover {
}


/*
============================================================================================
    Basic
============================================================================================
*/

#create-basic-container {
    width: 100%;
    text-align: center;
}

.create-basic-row {
    width: 100%;
    padding-bottom: 16px;
    text-align: center;
    white-space: nowrap;
}

.create-basic-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

.create-basic-input {
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}


/*
============================================================================================
    Name / Area / Title / Overview
============================================================================================
*/

#create-name,
#create-area,
#create-title {
    padding: 4px 12px;
}

#create-name, 
#create-area {
    width: 100%;
}

#create-title {
    width: 100%;
}

#create-name:blur,
#create-area:blur,
#create-title:blur {
}

#create-name:focus,
#create-area:focus,
#create-title:focus {  
}


/*
============================================================================================
    Basic Curation Overview
============================================================================================
*/

#create-overview {
    width: 100%;
    position: relative;
    text-align: center;
}


/*
============================================================================================
    Location Components
============================================================================================
*/

#create-location-access-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#create-location-access-wide {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    text-align: center;
    white-space: normal;
}

#create-location-access-narrow {
    display: inline-block;
    width: calc(100% - 80px);
    text-align: center;
}

#create-drivable-button,
#create-undrivable-button,
#create-park-and-walk-button {
    vertical-align: middle;
    margin: 3px 3px;
}

#create-drivable-button:hover, 
#create-undrivable-button:hover,
#create-park-and-walk-button:hover {
}


#create-location-preferred-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#create-location-preferred {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    text-align: center;
}

#create-drive-button,
#create-public-transportation-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-drive-button:hover,
#create-public-transportation-button:hover {
}

#create-location-type {
    width: 100%;
    /*border-bottom: 1px solid #464646;*/
}

#create-location-type-actual, 
#create-location-type-parking,
#create-location-type-start, 
#create-location-type-end {
    display: inline-block;
    width: 50%;
    padding: 3px;
    border-radius: 0px;
}

#create-location-type-actual:hover, 
#create-location-type-parking:hover,
#create-location-type-start:hover, 
#create-location-type-end:hover {
}

#create-location,
#create-location-lat,
#create-location-lng {
}

#create-location-search-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
}

.create-location-search-title-text-single {
    text-align: right;
}

.create-location-search-title-text-double {
    text-align: right;
    line-height: 20px;
}

#create-location {
    width: 100%;
    padding: 4px 12px;
    position: relative;
    resize: none;
    overflow: hidden;
}

#create-location-gps {
    width: 100%;
}

#create-location-gps-title {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    margin-right: 10px;
    text-align: right;
}

#create-location-lat, 
#create-location-lng {
    display: inline-block;
    vertical-align: middle;
    width: calc(50% - 28px);
    margin-right: 8px;
}

#create-location-gps-button {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
}

#create-location-gps-button:hover {
}

#create-location:focus, 
#create-location-lat:focus, 
#create-location-lng:focus {  
}


/*
============================================================================================
    Location Modal
============================================================================================
*/

.create-location-detect-modal {
    @include modal-background;
}

.create-location-detect-modal-content {
    @include modal-basic-binary;
    width: 300px;
    text-align: center;
}

.create-location-detect-modal-content__item {
    padding: 5px 30px;
}


/*
============================================================================================
   Map
============================================================================================
*/

#create-map-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}


/*
============================================================================================
    Advanced Curation (todos / history / stories)
============================================================================================
*/

#create-curation-type {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

#create-curation-type-todos, 
#create-curation-type-history,
#create-curation-type-stories {
    width: 60px;
    margin: 0px 3px;
    text-align: center;
}

#create-curation-type-todos:hover, 
#create-curation-type-history:hover,
#create-curation-type-stories:hover {
}

#create-curation-container {
}

.create-curation-row {
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
}

.create-curation-title {
    width: 90px;
    padding-right: 16px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.create-curation-title-text {
    padding-bottom: 6px;
}

.create-curation-optional-title {
    width: 90px;
    padding: 30px 16px 0px 0px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.create-curation-input {
    width: 100%;
    max-width: 600px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.create-curation-title-small {
    width: 100%;
    margin-bottom: 4px;
    text-align: center;
}

#create-todos,
#create-history,
#create-stories {
    width: 100%;
    position: relative;
}


/*
============================================================================================
   Sliders (Rating / Difficulty / Best Time)
============================================================================================
*/

#create-rating-title,
#create-physicality-title,
#create-duration-title,
#create-best-time-title {
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    margin-right: 16px;
    text-align: right;
}

#create-rating,
#create-physicality,
#create-duration {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 100px);
    margin: 16px 0px;
}

#create-best-time {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    margin-bottom: 16px;
}

#create-duration {
    position: relative;
}

#create-duration-slider-increase,
#create-duration-slider-decrease {
    position: absolute;
    top: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#create-duration-slider-increase:hover,
#create-duration-slider-decrease:hover {
    opacity: 0.8;
}

#create-duration-slider-increase {
    right: 0px;
}
#create-duration-slider-decrease {
    right: 25px;
}


#create-best-time {
    width: calc(100% - 100px);
    margin: 0px 0px 0px 0px;
}

#create-best-time-first,
#create-best-time-second {
    width: 100%;
}

#create-best-time-first, 
#create-best-time-second {
    margin: 20px 0px 12px 0px;
}

#create-best-time-buttons {
    text-align: center;
}

#create-best-time-all-day,
#create-best-time-single,
#create-best-time-double,
{

}

.create-best-time-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

.create-best-time-button:hover {
    opacity: 0.6;
}

/*
============================================================================================
   Hours
============================================================================================
*/

#create-hours-title {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    margin-right: 0px;
    text-align: center;
}

#create-hours-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
}

#create-hours-picker-container {
    padding-bottom: 16px;
}

#create-hours-arrow {
    position: absolute;
    top: 104px;
    left: 168px;
    width: 28px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.3;
    z-index: 1;
}

#create-start-hour, 
#create-end-hour {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

#create-start-hour-tooltip-area, #create-end-hour-tooltip-area {
    position: absolute;
    width: 100%;
    height: 60px;
}

#create-hours-type {
    text-align: center;
    display: inline-block;
    width: 200px;
}

#create-hours-fixed-button,
#create-hours-allday-button {
    vertical-align: middle;
    margin: 0px 3px;
}

#create-hours-fixed-button:hover, 
#create-hours-allday-button:hover {
}


/*
============================================================================================
   Season Closure
============================================================================================
*/

#create-season-closure-dates-container {
    display: inline-block;
    vertical-align: middle;
}

#create-season-closure-dates {
    text-align: center;
    padding-bottom: 6px;
}

#create-season-closure-date-type {
    width: 230px;
    text-align: center;
}

#create-season-closure-date-title {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 0px;
    text-align: right;
}

.create-season-closure-date-title-text {
}

#create-season-closure-date-arrow {
    vertical-align: middle;
    width: 32px;
    padding: 10px;
    opacity: 0.3;
}

#create-season-closure-start-date, 
#create-season-closure-end-date {
    width: 100px;
    display: inline-block;
    vertical-align: middle;
}

.create-season-closure-date-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

.create-season-closure-date-button:hover {
    opacity: 0.6;
}


/*
============================================================================================
   Dining options
============================================================================================
*/


#create-dining-options-container {
    display: inline-block;
    vertical-align: middle;
}

#create-dining-options-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    text-align: right;
}

.create-dining-options-title-text {
}

#create-dining-options-type {
    text-align: center;
    display: inline-block;
    width: 90px;
}

#create-dining-options-yes-button,
#create-dining-options-no-button {
    vertical-align: middle;
    margin: 0px 3px;
    opacity: 0.4;
}

#create-dining-options-yes-button:hover,
#create-dining-options-no-button:hover {
    opacity: 0.6;
}


/*
============================================================================================
   Progress Bar and Buttons
============================================================================================
*/

#create-progress-container {
    padding: 30px 0px 20px 0px;
    text-align: center;
}

#create-progress-first-section, 
#create-progress-second-section {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 90px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: 80px 48px;
    background-position: center;
    overflow: visible;
}

#create-progress-first-section {
}

#create-progress-second-section {
}

#create-progress-first,
#create-progress-second,
#create-progress-third {
    position: absolute;
    width: 48px;
    height: 48px;
    opacity: 1.0;
    cursor: pointer;
    z-index: 1;
}

#create-progress-first {
    top: 0px;
    left: -26px;
}

#create-progress-second, 
#create-progress-third {
    top: 0px;
    right: -26px;
}

#create-progress-first:hover,
#create-progress-second:hover,
#create-progress-third:hover {
    opacity: 1.0;
}

#create-progress-first-number, 
#create-progress-second-number,
#create-progress-third-number {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#create-progress-first-title, 
#create-progress-second-title, 
#create-progress-third-title {
    position: absolute;
    width: 160px;
    top: -32px;
    left: -54px;
    padding: 6px 0px;
    text-align: center;
}

#create-progress-publish-container {
    display: inline-block;
    vertical-align: middle;
    width: 72px;
    height: 48px;
    margin-left: 40px;
    text-align: left;
    overflow: visible;
}

#create-progress-publish {
    display: inline-block;
    width: 108px;
    height: 48px;
    opacity: 1.0;
}

#create-progress-publish:hover {
    opacity: 1.0;
}

/*
============================================================================================
   Publish Modal
============================================================================================
*/

#create-complete-modal {
    @include modal-background;
}


#create-complete-publish {
    @include modal-basic-binary;
    width: 320px;
    padding: 20px 30px 25px 30px;
    overflow: visible;
}

#create-complete-title {
    text-align: center;
    padding-bottom: 6px;
}

#create-complete-message {
    text-align: center;
    padding-bottom: 20px;
}

#create-complete-public,
#create-complete-personal {
    margin: 0px 5px;
}

#create-complete-slider-container {
    width: 100%;
    margin-top: 20px;
}

#create-complete-slider-title {
    text-align: center;
    margin-bottom: 22px;
}

#create-complete-slider {
    width: 180px;
    margin: 0px auto;
}

#create-complete-button-container {
    margin: 20px auto 0px auto;
}

#create-complete-button {
    width: 26px;
    height: 26px;
    vertical-align: middle;
}

#create-complete-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    opacity: 0.6;
}
