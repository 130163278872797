@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    Profile
============================================================================================
*/

#user-profile {
    width: 100%;
    padding: 5px 10px 0px 10px;
}

#user-profile-left {
    display: inline-block;
    vertical-align: middle;
}

#user-profile-right,
#user-profile-right-medium,
#user-profile-right-small {
    display: inline-block;
    vertical-align: middle;
}

#user-profile-right {
    width: 400px;
    margin-left: 20px;
}

#user-profile-right-medium {
    width: calc(100% - 135px);
    max-width: 400px;
    margin-left: 10px;
}

#user-profile-right-small {
    width: calc(100% - 110px);
    margin-left: 10px;
}

#user-profile-pic-loader {
    width: 150px !important;
    height: 150px !important;
}

#user-profile-pic-loader-medium {
    width: 125px !important;
    height: 125px !important;
}

#user-profile-pic-loader-small {
    width: 100px !important;
    height: 100px !important;
}

#user-profile-pic {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

#user-profile-basic {
    display: block;
    position: relative;
    padding-right: 30px;
    text-align: left;
}

#user-profile-name {
    display: inline-block;
    vertical-align: bottom;
    padding-right: 10px;
    line-height: 20px;
}

#user-profile-username {
    display: inline-block;
    vertical-align: bottom;
    margin-top: 4px;
    line-height: 16px;
}

.user-profile-posting {
    position: relative;
    width: 100%;
    display: block;
    text-align: left;
    padding: 7px 0px 6px 0px;
    line-height: 0px;
}

#user-profile-posting-text {
    width: 100%;
    min-height: 30px;
    line-height: 18px;
    text-align: left;
    white-space: pre-wrap;
    padding: 6px 16px;
}

#user-profile-posting-text:hover {
    border: 1px solid $blue;
}

#user-profile-posting-input {
    width: 100%;
    min-height: 30px;
    line-height: 18px;
    resize: none;
    overflow: hidden;
    padding: 6px 16px;
}

#user-profile-posting-history-button {
    position: absolute;
    right: -36px;
    bottom: -41px;
    opacity: 1.0;
}

#user-profile-posting-history-button-small {
    position: absolute;
    right: 0px;
    bottom: -6px;
    opacity: 1.0;
}

#user-profile-posting-edit-button {
    position: absolute;
    right: 6px;
    bottom: 9px;
    opacity: 1.0;
}

#user-profile-posting-confirm-button {
    position: absolute;
    right: 6px;
    bottom: 9px;
    opacity: 1.0;
}

#user-profile-edit-button-container {
    display: inline-block;
    float: right;
    cursor: pointer;
    opacity: 0.8;
}

#user-profile-edit-button-container:hover {
    opacity: 1.0;
}

#user-profile-edit-button {
    display: inline-block;
    vertical-align: middle;
    height: 22px;
    width: 22px;
    margin-right: 6px;
    cursor: pointer;
    opacity: 0.75;
}

#user-profile-edit-text {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

#user-profile-level-container {
    display: block;
    text-align: left;
}

#user-profile-level-experience, 
#user-profile-level-creation {
    display: inline-block;
    border-radius: 4px;
    font-size: 12px;
    padding: 1px 8px;
    margin-bottom: 6px;
}

.user-profile-level-experience-day {
    color: $white;
    background-color: $light-blue;
    opacity: 0.75;
}

.user-profile-level-experience-night {
    color: $black;
    background-color: $blue;
    opacity: 0.75;
}

.user-profile-level-creation-day {
    color: $white;
    background-color: $light-gray;
    margin-left: 6px;
}

.user-profile-level-creation-night {
    color: $black;
    background-color: $gray;
    margin-left: 6px;
}


/*
============================================================================================
    Follow
============================================================================================
*/

#user-info-follow-button {

}

#user-info-follow-button-on, 
#user-info-follow-button-off {
    width: 72px;
    margin: 8px auto 6px auto;
    text-align: center;
}

#user-info-follow-button-on {
    opacity: 1.0;    
}

#user-info-follow {
    padding-top: 6px;
    text-align: left;
}

#user-info-follow-small {
    padding-top: 4px;
    text-align: center;
}

.user-info-follows {
    text-align: left;
}

.user-info-follow-title {
    padding-bottom: 2px;
    text-align: left;
}

.user-info-follow-text {
    display: inline-block;
    vertical-align: middle;
    padding-right: 6px;
    cursor: pointer;
}

.user-info-follow-number {
    display: inline-block;
    vertical-align: middle;
}

#user-info-followers {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
}

#user-info-followers-small {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    width: calc(50% - 10px);
    white-space: nowrap;
    overflow: visible;
}

#user-info-following {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
}

#user-info-following-small {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    width: calc(50% - 10px);
    white-space: nowrap;
    overflow: visible;
}

#user-info-follow-spacer {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.user-info-follow-profile-pic {
    margin: 0px 2px;
}