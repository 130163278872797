@import "../../css/_variables";
@import "../../css/_mixins";

/*
============================================================================================
    National Park
============================================================================================
*/

#national-park-container {
    width: 100%;
    min-width: 320px;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
}

.national-park-container-small {
    padding-top: 55px;
}

.national-park-container {
    padding-top: 72px;
}


/*
============================================================================================
    Title
============================================================================================
*/

.national-park-title-container-small, 
.national-park-title-container,
.national-park-title-container-large {
    display: block;
    width: 100%;
    text-align: center;
    letter-spacing: -0.3px;
}

.national-park-title-container-small {
    margin-bottom: 8px;
}

.national-park-title-container {
    padding-bottom: 12px;
}

.national-park-title-container-large {
    padding-bottom: 16px;
}

.national-park-title-small, 
.national-park-title,
.national-park-title-large {
    display: inline-block;
    vertical-align: middle;
}

.national-park-title-small {
    font-size: 20px;
    line-height: 20px;
}

.national-park-title {
    font-size: 28px;
    line-height: 28px;
}

.national-park-title-large {
    font-size: 36px;
    line-height: 36px;
}

#national-park-set-add {
    display: inline-block;
    vertical-align: middle;
    opacity: 0.8;
    cursor: pointer;
}

#national-park-set-add:hover {
    opacity: 1.0;
}

#national-park-set-add-button {
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
}

#national-park-set-add-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
}


/*
============================================================================================
    Partitions
============================================================================================
*/

#national-park-left,
#national-park-right {
    display: inline-block;
    vertical-align: top;
    min-width: 320px;
    margin: 0px 8px;
    overflow: hidden;
}

.national-park-left-double,
.national-park-right-double {
    width: calc(50% - 16px);
}

.national-park-left-single,
.national-park-right-single {
    width: calc(100% - 16px);
}


#national-park-map-container {
    display: block;
    border-radius: 10px;
    overflow: hidden;
}

#national-park-dot-count {
    display: block;
    width: 100%;
    text-align: center;
    padding: 12px 10px 0px 10px;
}

#national-park-content {
    white-space: nowrap;
    width: 100%;
    height: 100%;
}


/*
============================================================================================
    Posts
============================================================================================
*/

#national-park-posts-container {
    text-align: center;
}

#national-park-posts-header {
    display: block;
    width: 100%;
    padding: 0px 16px;
    text-align: center;
}

.national-park-posts-header-tab,
.national-park-posts-header-tab-hover-day,
.national-park-posts-header-tab-hover-night {
    width: 25%;
    max-width: 100px;
    cursor: pointer;
    display: inline-block;
    vertical-align: bottom;
    text-align: center;
    padding: 6px;
    white-space: pre-line;
}

.national-park-posts-header-tab-hover-day {
    border-bottom: 4px solid $light-blue;
}

.national-park-posts-header-tab-hover-night {
    border-bottom: 4px solid $blue;
}

#national-park-posts-body {
    position: relative;
}

.national-park-posts-day,
.national-park-posts-night {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: #fff;
}

.national-park-posts-day {
    background: $almost-white;
}

.national-park-posts-night {
    background: $black;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.national-park-posts-day::-webkit-scrollbar,
.national-park-posts-night::-webkit-scrollbar {
  display: none;
}

.national-park-posts-loader {
    width: 100%;
    height: 20px;
    margin: 10px 0px;
    background-size: 80px 80px;
    opacity: 0.4;
    background-position: center;
    background-repeat: no-repeat;
}

.national-park-posts-genesis {
    width: 100%;
    height: 72px;
    margin: 10px 0px;
}

.national-park-posts-genesis-title {
    padding-bottom: 10px;
}


/*
============================================================================================
    Board
============================================================================================
*/

#national-park-board-container-day,
#national-park-board-container-night {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 16px 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    white-space: normal;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#national-park-board-container-day::-webkit-scrollbar,
#national-park-board-container-night::-webkit-scrollbar {
    display: none;
}

#national-park-board-container-day {
    background: $almost-white;
}

#national-park-board-container-night {
    background: $black;
}


/*
============================================================================================
    Post
============================================================================================
*/

.national-park-post-container {
}

.national-park-post-day,
.national-park-post-night {
    padding: 16px 0px 8px 0px;
}

.national-park-post-day {
    border-bottom: dashed 1px $lightest-gray;
}

.national-park-post-night {
    border-bottom: dashed 1px $darkest-gray;
}

/*
============================================================================================
    Post Patitions
============================================================================================
*/

.national-park-post-top {
}

.national-park-post-middle {
    display: table;
    width: 100%;
    margin: 6px auto 0px auto;
}

.national-park-post-bottom {
    display: block;
    text-align: center;
    white-space: normal;
}


/*
============================================================================================
    Post Media Gallery
============================================================================================
*/

.national-park-post-gallery-container {
    position: relative;
    text-align: center;
}

.national-park-post-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.national-park-post-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.national-park-post-gallery-video {}

.national-park-post-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.national-park-post-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Post Media Gallery Navigation
============================================================================================
*/

.national-park-post-gallery-prev-arrow,
.national-park-post-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.national-park-post-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.national-park-post-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.national-park-post-gallery-prev-arrow:hover,
.national-park-post-gallery-next-arrow:hover {
    opacity: 0.85;
}

.national-park-post-gallery-video:hover ~ .national-park-post-gallery-prev-arrow,
.national-park-post-gallery-video:hover ~ .national-park-post-gallery-next-arrow,
.national-park-post-gallery-image:hover ~ .national-park-post-gallery-prev-arrow,
.national-park-post-gallery-image:hover ~ .national-park-post-gallery-next-arrow {
    opacity: 0.5;
}

.national-park-post-gallery-dot-on,
.national-park-post-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.national-park-post-gallery-dot-on {
    opacity: 0.8;
}

.national-park-post-gallery-dot-off {
    opacity: 0.4;
}

.national-park-post-gallery-dot-off:hover {
    opacity: 0.8;
}

.national-park-post-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/*
============================================================================================
    Post Media Gallery (User Tag / Dot Tag / Unsave)
============================================================================================
*/

.national-park-post-gallery-dot-tag-image,
.national-park-post-gallery-user-tag-image,
.national-park-post-gallery-unsave-image,
.national-park-post-gallery-enlarge-image,
.national-park-post-gallery-dot-tag-video,
.national-park-post-gallery-user-tag-video,
.national-park-post-gallery-unsave-video,
.national-park-post-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.national-park-post-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.national-park-post-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.national-park-post-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.national-park-post-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.national-park-post-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.national-park-post-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.national-park-post-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.national-park-post-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.national-park-post-gallery-dot-tag-image:hover,
.national-park-post-gallery-user-tag-image:hover,
.national-park-post-gallery-unsave-image:hover,
.national-park-post-gallery-enlarge-image:hover,
.national-park-post-gallery-dot-tag-video:hover,
.national-park-post-gallery-user-tag-video:hover,
.national-park-post-gallery-unsave-video:hover,
.national-park-post-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.national-park-post-gallery-video:hover ~ .national-park-post-gallery-dot-tag-video,
.national-park-post-gallery-video:hover ~ .national-park-post-gallery-user-tag-video,
.national-park-post-gallery-video:hover ~ .national-park-post-gallery-unsave-video,
.national-park-post-gallery-video:hover ~ .national-park-post-gallery-enlarge-video,
.national-park-post-gallery-image:hover ~ .national-park-post-gallery-dot-tag-image,
.national-park-post-gallery-image:hover ~ .national-park-post-gallery-user-tag-image,
.national-park-post-gallery-image:hover ~ .national-park-post-gallery-unsave-image,
.national-park-post-gallery-image:hover ~ .national-park-post-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/*
============================================================================================
    Post Text Content
============================================================================================
*/

.national-park-post-title {
    display: inline-block;
    max-width: 100%;
    white-space: normal;
    padding: 0px 8px;
    margin: 4px 0px 8px 0px;
    text-align: center;
}

.national-park-post-overview,
.national-park-post-overview-folded,
.national-park-post-overview-expanded {
    display: inline-block;
    max-width: 100%;
    white-space: pre-line;
    line-height: 1.2;
    text-align: left;
    padding: 0px 8px;
    margin-bottom: 8px;
}

.national-park-post-overview {
}

.national-park-post-overview-folded {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.national-park-post-expand-button {
    width: 100%;
    height: 12px;
    margin-bottom: 4px;
    cursor: pointer;
}


/*
============================================================================================
    Post Editor
============================================================================================
*/

.national-park-post-editor {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-left: 6px;
}

.national-park-post-editor-media {
    vertical-align: middle;
    margin-right: 8px;
    cursor: pointer;
}

.national-park-post-editor-name {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

#national-park-comments-container {
    vertical-align: top;
    text-align: center;
    border: 3px solid rgba(255, 255, 255, 0.3);
}

#national-park-comments-header {
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    padding-top: 8px;
}


/*
============================================================================================
    Post Interaction Buttons
============================================================================================
*/

.national-park-post-interaction {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-right: 6px;
}

.national-park-post-more-menu {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0%, -50%);
    border-radius: 10px !important;
    padding: 4px 8px !important;
    box-shadow: 0px 0px 4px $gray !important;
}

.national-park-post-like-button-on,
.national-park-post-like-button-off,
.national-park-post-share-button,
.national-park-post-more-button,
.national-park-post-edit-button,
.national-park-post-delete-button {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.national-park-post-like-button-on,
.national-park-post-like-button-off {
    margin-right: 3px;
}

.national-park-post-like-button-on {
    opacity: 0.8 !important;
}

.national-park-post-more-button {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}

.national-park-post-edit-button {
    width: 30px;
    height: 30px;
    margin: 0px 3px 0px 1px;
}

.national-park-post-delete-button {
    width: 28px;
    height: 28px;
}

.national-park-post-like-count {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}


/*
============================================================================================
    Delete Modal
============================================================================================
*/

.national-park-post-delete-modal {
    @include modal-background;
}

.national-park-post-delete-modal-content {
    @include modal-basic-binary;
    width: 240px;
}

.national-park-post-delete-modal-content__item {
    padding: 5px 30px;
}


/* 
============================================================================================
    Bottom Menu
============================================================================================
*/

#national-park-bottom-menu-container {
    display: block;
    position: fixed;
    width: 100%;
    min-width: 320px;
    left: 0px;
    bottom: 0px;
    z-index: 3;
    text-align: center;
    -webkit-transition: bottom 0.5s ease-in-out;
    -moz-transition: bottom 0.5s ease-in-out;
    -o-transition: bottom 0.5s ease-in-out;
    transition: bottom 0.5s ease-in-out;
}

#national-park-bottom-menu-modes {
    display: inline-block;
    white-space: nowrap;
    padding: 4px 12px 5px 12px;
    border-radius: 20px 20px 0px 0px;
    border-bottom: none;
}

#national-park-bottom-menu-mode-content-icon-small,
#national-park-bottom-menu-mode-community-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
}

#national-park-bottom-menu-mode-content-icon,
#national-park-bottom-menu-mode-community-icon {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
}

#national-park-bottom-menu-mode-content-selected,
#national-park-bottom-menu-mode-content {
    display: inline-block;
    vertical-align: middle;
}

#national-park-bottom-menu-mode-content-selected {
    opacity: 1.0;
}

#national-park-bottom-menu-mode-content {
    opacity: 0.4;
}

#national-park-bottom-menu-mode-content:hover {
    opacity: 0.6;
}


#national-park-bottom-menu-mode-community-selected,
#national-park-bottom-menu-mode-community {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
}

#national-park-bottom-menu-mode-community-selected {
    opacity: 1.0;
}

#national-park-bottom-menu-mode-community {
    opacity: 0.4;
}

#national-park-bottom-menu-mode-community:hover {
    opacity: 0.6;
}

#national-park-bottom-menu-mode-content-text,
#national-park-bottom-menu-mode-community-text {
    display: inline-block;
    vertical-align: middle;
}

#national-park-bottom-menu-mode-content-text,
#national-park-bottom-menu-mode-community-text {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 6px;
}

.national-park-bottom-menu-mode-small {
    font-size: 16px;
    padding: 2px 0px;
    margin: 2px 8px;
}

.national-park-bottom-menu-mode {
    font-size: 18px;
    padding: 3px 0px;
    margin: 2px 10px;
}
