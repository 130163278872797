@import "./_variables";
@import "./_mixins";

/*
============================================================================================
    General Settings
============================================================================================
*/

* {
    outline: 0px solid $black;
    margin: 0px;
    padding: 0px;
    font-family: "Questrial-Regular", "Manrope-Regular", "Quicksand-Regular", "NanumSquareRoundR", "Didact-Gothic";
    letter-spacing: 0.1px;
}

.outline, .outline * {
    outline: 1px solid $black;
}

a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}

.a-black {
    color: $black;
}

.a-white {
    color: $white;
}

.medium {
    font-weight: 600;
}

.bold {
    font-weight: 900;
}

.text {
    font-size: 16px;
    line-height: 20px;
}

.text-xsmall {
    font-size: 12px;
    line-height: 16px;
}

.text-small {
    font-size: 14px;
    line-height: 18px;
}

.text-large {
    font-size: 18px;
    line-height: 22px;
}

.text-xlarge {
    font-size: 20px;
    line-height: 24px;
}

.content-box {
    box-sizing: content-box !important;
}

.border-box {
    box-sizing: border-box !important;
}


/*
============================================================================================
    Fonts
============================================================================================
*/

.font-avenir {
    font-family: "Avenir", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.font-century {
    font-family: "Century-Gothic", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.font-century-bold {
    font-family: "Century-Gothic", "NanumSquareRoundR";
    font-weight: 900;
    letter-spacing: -0.3px;
}

.font-cabin {
    font-family: "Cabin-Regular", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.font-cabin-medium {
    font-family: "Cabin-Medium", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.font-cabin-bold {
    font-family: "Cabin-Bold", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.font-didact {
    font-family: "Didact-Gothic", "NanumSquareRoundR";
}


/*
============================================================================================
    Korean Fonts
============================================================================================
*/

.font-nanum-barun-gothic {
    font-family: "NanumBarunGothic", "Questrial-Regular";
}

.font-nanum-barun-gothic-bold {
    font-family: "NanumBarunGothicBold", "Questrial-Regular";
}

.font-nanum-gothic {
    font-family: "NanumGothic", "Questrial-Regular";
}

.font-nanum-gothic-bold {
    font-family: "NanumGothicBold", "Questrial-Regular";
}

.font-nanum-square-round {
    font-family: "NanumSquareRoundR", "Questrial-Regular";
}

.font-nanum-square-round-bold {
    font-family: "NanumSquareRoundB", "Questrial-Regular";
}

.font-nanum-square-round-extra-bold {
    font-family: "NanumSquareRoundEB", "Questrial-Regular";
}

.font-nanum-square-round-light {
    font-family: "NanumSquareRoundL", "Questrial-Regular";
}


/*
============================================================================================
    Headers (Century Gothic)
============================================================================================
*/

.w0, .w1, .w2, .w3, .w4, .w5, .w6, .w7
.k0, .k1, .k2, .k3, .k4, .k5, .k6, .k7
.r0, .r1, .r2, .r3, .r4, .r5, .r6, .r7
.dr0, .dr1, .dr2, .dr3, .dr4, .dr5, .dr6, .dr7
.b0, .b1, .b2, .b3, .b4, .b5, .b6, .b7
.lb0, .lb1, .lb2, .lb3, .lb4, .lb5, .lb6, .lb7
.db0, .db1, .db2, .db3, .db4, .db5, .db6, .db7
.g0, .g1, .g2, .g3, .g4, .g5, .g6, .g7,
.lg0, .lg1, .lg2, .lg3, .lg4, .lg5, .lg6, .lg7,
.dg0, .dg1, .dg2, .dg3, .dg4, .dg5, .dg6, .dg7 {
    font-family: "Century-Gothic", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.w0, .w1, .w2, .w3, .w4, .w5, .w6, .w7 {
    color: $white;
}

.k0, .k1, .k2, .k3, .k4, .k5, .k6, .k7 {
    color: $black;
}

.r0, .r1, .r2, .r3, .r4, .r5, .r6, .r7 {
    font-weight: 600;
    color: $red;
}

.dr0, .dr1, .dr2, .dr3, .dr4, .dr5, .dr6, .dr7 {
    color: $dark-red;
}

.b0, .b1, .b2, .b3, .b4, .b5, .b6, .b7 {
    color: $blue;
}

.lb0, .lb1, .lb2, .lb3, .lb4, .lb5, .lb6, .lb7 {
    color: $light-blue;
}

.db0, .db1, .db2, .db3, .db4, .db5, .db6, .db7 {
    color: $dark-blue;
}

.g0, .g1, .g2, .g3, .g4, .g5, .g6, .g7 {
    color: $gray;
}

.lg0, .lg1, .lg2, .lg3, .lg4, .lg5, .lg6, .lg7 {
    color: $light-gray;
}

.dg0, .dg1, .dg2, .dg3, .dg4, .dg5, .dg6, .dg7 {
    color: $dark-gray;
}

.w0, .k0, .r0, .dr0, .b0, .lb0, .db0, .g0, .lg0, .dg0 {
    font-size: 24px;
}

.w1, .k1, .r1, .dr1, .b1, .lb1, .db1, .g1, .lg1, .dg1 {
    font-size: 22px;
}

.w2, .k2, .r2, .dr2, .b2, .lb2, .db2, .g2, .lg2, .dg2 {
    font-size: 20px;
}

.w3, .k3, .r3, .dr3, .b3, .lb3, .db3, .g3, .lg3, .dg3 {
    font-size: 18px;
}

.w4, .k4, .r4, .dr4, .b4, .lb4, .db4, .g4, .lg4, .dg4 {
    font-size: 16px;
}

.w5, .k5, .r5, .dr5, .b5, .lb5, .db5, .g5, .lg5, .dg5 {
    font-size: 14px;
}

.w6, .k6, .r6, .dr6, .b6, .lb6, .db6, .g6, .lg6, .dg6 {
    font-size: 12px;
}


/*
============================================================================================
    Curation Capitalize
============================================================================================
*/

.capitalize-day {
    font-family: arial, "Arial";
    color: $light-blue;
}

.capitalize-night {
    font-family: arial, "Arial";
    color: $blue;
}


/*
============================================================================================
    Curation Highlight
============================================================================================
*/

.highlight-b0, .highlight-b1, .highlight-b2, .highlight-b3, .highlight-b4, .highlight-b5, .highlight-b6, .highlight-b7,
.highlight-lb0, .highlight-lb1, .highlight-lb2, .highlight-lb3, .highlight-lb4, .highlight-lb5, .highlight-lb6, .highlight-lb7 {
    font-family: "Questrial-Regular", "Manrope-Regular", "Quicksand-Regular", "NanumSquareRoundR", "Didact-Gothic";
    letter-spacing: 0.1px;
}

.highlight-b0, .highlight-b1, .highlight-b2, .highlight-b3, .highlight-b4, .highlight-b5, .highlight-b6, .highlight-b7 {
    color: $blue;
}

.highlight-lb0, .highlight-lb1, .highlight-lb2, .highlight-lb3, .highlight-lb4, .highlight-lb5, .highlight-lb6, .highlight-lb7 {
    color: $light-blue;
}

.highlight-b0,
.highlight-lb0 {
    font-size: 24px;
    line-height: 28px;
}

.highlight-b1,
.highlight-lb1 {
    font-size: 22px;
    line-height: 26px;
}

.highlight-b2,
.highlight-lb2 {
    font-size: 20px;
    line-height: 24px;
}

.highlight-b3,
.highlight-lb3 {
    font-size: 18px;
    line-height: 22px;
}

.highlight-b4,
.highlight-lb4 {
    font-size: 16px;
    line-height: 20px;
}

.highlight-b5,
.highlight-lb5 {
    font-size: 14px;
    line-height: 18px;
}

.highlight-b6,
.highlight-lb6 {
    font-size: 12px;
    line-height: 16px;
}

.highlight-b7,
.highlight-lb7 {
    font-size: 10px;
    line-height: 14px;
}


/*
============================================================================================
    Numbers (Cabin Medium)
============================================================================================
*/

.number-w0, .number-w1, .number-w2, .number-w3, .number-w4, .number-w5, .number-w6, .number-w7,
.number-k0, .number-k1, .number-k2, .number-k3, .number-k4, .number-k5, .number-k6, .number-k7,
.number-b0, .number-b1, .number-b2, .number-b3, .number-b4, .number-b5, .number-b6, .number-b7,
.number-lb0, .number-lb1, .number-lb2, .number-lb3, .number-lb4, .number-lb5, .number-lb6, .number-lb7,
.number-r0, .number-r1, .number-r2, .number-r3, .number-r4, .number-r5, .number-r6, .number-r7,
.number-lr0, .number-lr1, .number-lr2, .number-lr3, .number-lr4, .number-lr5, .number-lr6, .number-lr7,
.number-dr0, .number-dr1, .number-dr2, .number-dr3, .number-dr4, .number-dr5, .number-dr6, .number-dr7,
.number-g0, .number-g1, .number-g2, .number-g3, .number-g4, .number-g5, .number-g6, .number-g7,
.number-lg0, .number-lg1, .number-lg2, .number-lg3, .number-lg4, .number-lg5, .number-lg6, .number-lg7 {
    font-family: "Cabin-Medium", "NanumSquareRoundR";
    letter-spacing: -0.3px;
}

.number-w0,
.number-w1,
.number-w2,
.number-w3,
.number-w4,
.number-w5,
.number-w6,
.number-w7 {
    color: $white;
}

.number-k0,
.number-k1,
.number-k2,
.number-k3,
.number-k4,
.number-k5,
.number-k6,
.number-k7 {
    color: $black;
}

.number-b0,
.number-b1,
.number-b2,
.number-b3,
.number-b4,
.number-b5,
.number-b6,
.number-b7 {
    color: $blue;
}

.number-lb0,
.number-lb1,
.number-lb2,
.number-lb3,
.number-lb4,
.number-lb5,
.number-lb6,
.number-lb7 {
    color: $light-blue;
}

.number-r0,
.number-r1,
.number-r2,
.number-r3,
.number-r4,
.number-r5,
.number-r6,
.number-r7 {
    color: $red;
}

.number-lr0,
.number-lr1,
.number-lr2,
.number-lr3,
.number-lr4,
.number-lr5,
.number-lr6,
.number-lr7 {
    color: $light-red;
}

.number-dr0,
.number-dr1,
.number-dr2,
.number-dr3,
.number-dr4,
.number-dr5,
.number-dr6,
.number-dr7 {
    color: $dark-red;
}

.number-g0,
.number-g1,
.number-g2,
.number-g3,
.number-g4,
.number-g5,
.number-g6,
.number-g7 {
    color: $gray;
}

.number-lg0,
.number-lg1,
.number-lg2,
.number-lg3,
.number-lg4,
.number-lg5,
.number-lg6,
.number-lg7 {
    color: $light-gray;
}

.number-w0,
.number-k0,
.number-b0,
.number-lb0,
.number-r0,
.number-lr0,
.number-dr0,
.number-g0,
.number-lg0 {
    font-size: 24px;
}

.number-w1,
.number-k1,
.number-b1,
.number-lb1,
.number-r1,
.number-lr1,
.number-dr1,
.number-g1,
.number-lg1 {
    font-size: 22px;
}

.number-w2,
.number-k2,
.number-b2,
.number-lb2,
.number-r2,
.number-lr2,
.number-dr2,
.number-g2,
.number-lg2 {
    font-size: 20px;
}

.number-w3,
.number-k3,
.number-b3,
.number-lb3,
.number-r3,
.number-lr3,
.number-dr3,
.number-g3,
.number-lg3 {
    font-size: 18px;
}

.number-w4,
.number-k4,
.number-b4,
.number-lb4,
.number-r4,
.number-lr4,
.number-dr4,
.number-g4,
.number-lg4 {
    font-size: 16px;
}

.number-w5,
.number-k5,
.number-b5,
.number-lb5,
.number-r5,
.number-lr5,
.number-dr5,
.number-g5,
.number-lg5 {
    font-size: 14px;
}

.number-w6,
.number-k6,
.number-b6,
.number-lb6,
.number-r6,
.number-lr6,
.number-dr6,
.number-g6,
.number-lg6 {
    font-size: 12px;
}

.number-w7,
.number-k7,
.number-b7,
.number-lb7,
.number-r7,
.number-lr7,
.number-dr7,
.number-g7,
.number-lg7 {
    font-size: 10px;
}


/*
============================================================================================
    Inputs
============================================================================================
*/

.input-s1,
.input-s2,
.input-s3 {
    display: inline-block;
    font-family: "Questrial-Regular", "Manrope-Regular", "Quicksand-Regular", "NanumSquareRoundR", "Didact-Gothic";
    letter-spacing: 0.1px;
    text-align: center;
    cursor: default;
    transition: border 0.3s ease-in-out;
    -webkit-transition: border 0.3s ease-in-out;
    -moz-transition: border 0.3s ease-in-out;
    -o-transition: border 0.3s ease-in-out;
}

.input-s1 {
    font-size: 18px;
    line-height: 22px;
    border-radius: 10px;
    padding: 4px 20px;
}

.input-s2 {
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
    padding: 2px 16px;
}

.input-s3 {
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    padding: 3px 12px;
}

.input-day {
    color: $black;
    border: solid 1px $border-day;
    background-color: $white;
}

.input-white:hover, .input-white:focus {
    border: solid 1px $gray;
}

.input-night {
    color: $white;
    border: solid 1px $border-night;
    background-color: $black;
}

.input-day:hover, .input-night:focus {
    border: solid 1px $gray;
}


/*
============================================================================================
    Warnings (Century Gothic)
============================================================================================
*/

.warning-s1,
.warning-s2,
.warning-s3,
.warning-day-s1,
.warning-day-s2,
.warning-day-s3,
.warning-night-s1,
.warning-night-s2,
.warning-night-s3 {
    display: block;
    width: 100%;
    font-weight: 600;
    text-align: center;
    cursor: default;
}

.warning-day-s1,
.warning-day-s2,
.warning-day-s3 {
    color: $light-red;
}

.warning-s1,
.warning-s2,
.warning-s3,
.warning-night-s1,
.warning-night-s2,
.warning-night-s3 {
    color: $red;
}

.warning-s1,
.warning-day-s1,
.warning-night-s1 {
    font-size: 14px;
    line-height: 18px;
}

.warning-s2,
.warning-day-s2,
.warning-night-s2 {
    font-size: 16px;
    line-height: 20px;
}

.warning-s3,
.warning-day-s3,
.warning-night-s3 {
    font-size: 18px;
    line-height: 22px;
}

/*
============================================================================================
    Text Input (Century Gothic)
============================================================================================
*/

.text-input {
    display: inline-block;
    font-family: "Questrial-Regular", "Manrope-Regular", "Quicksand-Regular", "NanumSquareRoundR", "Didact-Gothic";
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 20px;
    padding: 10px 16px;
    border-radius: 10px;
    text-align: center;
    cursor: default;
    overflow: hidden;
    resize: none;
    transition: border 0.3s ease-in-out;
    -webkit-transition: border 0.3s ease-in-out;
    -moz-transition: border 0.3s ease-in-out;
    -o-transition: border 0.3s ease-in-out;
}

.text-input:blur {
    text-align: center;
}

.text-input:focus {
    text-align: left;
}


/*
============================================================================================
    Text Buttons (Cabin Medium)
============================================================================================
*/

.button-light-blue-gray-base,
.button-light-blue-gray-s1,
.button-light-blue-gray-s2,
.button-light-blue-gray-s2h,
.button-light-blue-gray-s3,
.button-blue-gray-base,
.button-blue-gray-s1,
.button-blue-gray-s2,
.button-blue-gray-s2h,
.button-blue-gray-s3,
.button-light-red-gray-base,
.button-light-red-gray-s1,
.button-light-red-gray-s2,
.button-light-red-gray-s2h,
.button-light-red-gray-s3,
.button-red-gray-base,
.button-red-gray-s1,
.button-red-gray-s2,
.button-red-gray-s2h,
.button-red-gray-s3,
.button-light-blue-base,
.button-light-blue-s1,
.button-light-blue-s2,
.button-light-blue-s2h,
.button-light-blue-s3,
.button-blue-base,
.button-blue-s1,
.button-blue-s2,
.button-blue-s2h,
.button-blue-s3,
.button-light-gray-base,
.button-light-gray-s1,
.button-light-gray-s2,
.button-light-gray-s2h,
.button-light-gray-s3,
.button-gray-base,
.button-gray-s1,
.button-gray-s2,
.button-gray-s2h,
.button-gray-s3 {
    display: inline-block;
    opacity: 0.6;
    font-family: "Century-Gothic", "NanumSquareRoundR";
    letter-spacing: -0.3px;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.button-light-blue-gray-base:hover,
.button-light-blue-gray-s1:hover,
.button-light-blue-gray-s2:hover,
.button-light-blue-gray-s2h:hover,
.button-light-blue-gray-s3:hover,
.button-blue-gray-base:hover,
.button-blue-gray-s1:hover,
.button-blue-gray-s2:hover,
.button-blue-gray-s2h:hover,
.button-blue-gray-s3:hover,
.button-light-red-gray-base:hover,
.button-light-red-gray-s1:hover,
.button-light-red-gray-s2:hover,
.button-light-red-gray-s2h:hover,
.button-light-red-gray-s3:hover,
.button-red-gray-base:hover,
.button-red-gray-s1:hover,
.button-red-gray-s2:hover,
.button-red-gray-s2h:hover,
.button-red-gray-s3:hover,
.button-light-blue-base:hover,
.button-light-blue-s1:hover,
.button-light-blue-s2:hover,
.button-light-blue-s2h:hover,
.button-light-blue-s3:hover,
.button-blue-base:hover,
.button-blue-s1:hover,
.button-blue-s2:hover,
.button-blue-s2h:hover,
.button-blue-s3:hover,
.button-light-gray-base:hover,
.button-light-gray-s1:hover,
.button-light-gray-s2:hover,
.button-light-gray-s2h:hover,
.button-light-gray-s3:hover,
.button-gray-base:hover,
.button-gray-s1:hover,
.button-gray-s2:hover,
.button-gray-s2h:hover,
.button-gray-s3:hover {
    opacity: 1.0;
}


.button-light-blue-gray-base,
.button-light-blue-gray-s1,
.button-light-blue-gray-s2,
.button-light-blue-gray-s2h,
.button-light-blue-gray-s3 {
    opacity: 1.0;
    background-color: $light-gray;
    color: $white;
}

.button-light-blue-gray-base:hover,
.button-light-blue-gray-s1:hover,
.button-light-blue-gray-s2:hover,
.button-light-blue-gray-s2h:hover,
.button-light-blue-gray-s3:hover {
    background-color: $light-blue;
    color: $white;
}

.button-blue-gray-base,
.button-blue-gray-s1,
.button-blue-gray-s2,
.button-blue-gray-s2h,
.button-blue-gray-s3 {
    opacity: 1.0;
    background-color: $dark-gray;
    color: $black;
}

.button-blue-gray-base:hover,
.button-blue-gray-s1:hover,
.button-blue-gray-s2:hover,
.button-blue-gray-s2h:hover,
.button-blue-gray-s3:hover {
    background-color: $dark-blue;
    color: $black;
}

.button-light-red-gray-base,
.button-light-red-gray-s1,
.button-light-red-gray-s2,
.button-light-red-gray-s2h,
.button-light-red-gray-s3 {
    opacity: 1.0;
    background-color: $light-gray;
    color: $white;
}

.button-light-red-gray-base:hover,
.button-light-red-gray-s1:hover,
.button-light-red-gray-s2:hover,
.button-light-red-gray-s2h:hover,
.button-light-red-gray-s3:hover {
    background-color: $light-red;
    color: $white;
}

.button-red-gray-base,
.button-red-gray-s1,
.button-red-gray-s2,
.button-red-gray-s2h,
.button-red-gray-s3 {
    opacity: 1.0;
    background-color: $dark-gray;
    color: $black;
}

.button-red-gray-base:hover,
.button-red-gray-s1:hover,
.button-red-gray-s2:hover,
.button-red-gray-s2h:hover,
.button-red-gray-s3:hover {
    background-color: $dark-red;
    color: $black;
}

.button-light-blue-base,
.button-light-blue-s1,
.button-light-blue-s2,
.button-light-blue-s2h,
.button-light-blue-s3 {
    background-color: $light-blue;
    color: $white;
}

.button-blue-base,
.button-blue-s1,
.button-blue-s2,
.button-blue-s2h,
.button-blue-s3 {
    background-color: $blue;
    color: $black;
}

.button-light-gray-base,
.button-light-gray-s1,
.button-light-gray-s2,
.button-light-gray-s2h,
.button-light-gray-s3 {
    background-color: $light-gray;
    color: $white;
}

.button-gray-base,
.button-gray-s1,
.button-gray-s2,
.button-gray-s2h,
.button-gray-s3 {
    background-color: $gray;
    color: $black;
}

.button-light-blue-gray-s2,
.button-blue-gray-s2,
.button-light-red-gray-s2,
.button-red-gray-s2,
.button-light-blue-s2,
.button-blue-s2,
.button-light-gray-s2,
.button-gray-s2 {
    padding: 3px 12px 3px 12px;
    border-radius: 6px;
    font-size: 15px;
}

.button-light-blue-gray-s2h,
.button-blue-gray-s2h,
.button-light-red-gray-s2h,
.button-red-gray-s2h,
.button-light-blue-s2h,
.button-blue-s2h,
.button-light-gray-s2h,
.button-gray-s2h {
    padding: 4px 12px 4px 12px;
    border-radius: 6px;
    font-size: 15px;
}

.button-light-blue-gray-s3,
.button-blue-gray-s3,
.button-light-red-gray-s3,
.button-red-gray-s3,
.button-light-blue-s3,
.button-blue-s3,
.button-light-gray-s3,
.button-gray-s3 {
    padding: 2px 8px 2px 8px;
    border-radius: 6px;
    font-size: 13px;
}


/*
============================================================================================
    Text Buttons without Containers
============================================================================================
*/

.text-button-light-blue-gray-base,
.text-button-light-blue-gray-s1,
.text-button-light-blue-gray-s2,
.text-button-light-blue-gray-s3,
.text-button-blue-gray-base,
.text-button-blue-gray-s1,
.text-button-blue-gray-s2,
.text-button-blue-gray-s3,
.text-button-light-blue-base,
.text-button-light-blue-s1,
.text-button-light-blue-s2,
.text-button-light-blue-s3,
.text-button-blue-base,
.text-button-blue-s1,
.text-button-blue-s2,
.text-button-blue-s3,
.text-button-light-gray-base,
.text-button-light-gray-s1,
.text-button-light-gray-s2,
.text-button-light-gray-s3,
.text-button-gray-base,
.text-button-gray-s1,
.text-button-gray-s2,
.text-button-gray-s3 {
    display: inline-block;
    opacity: 0.8;
    font-family: "Century-Gothic", "NanumSquareRoundR";
    letter-spacing: -0.3px;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.text-button-light-blue-gray-base:hover,
.text-button-light-blue-gray-s1:hover,
.text-button-light-blue-gray-s2:hover,
.text-button-light-blue-gray-s3:hover,
.text-button-blue-gray-base:hover,
.text-button-blue-gray-s1:hover,
.text-button-blue-gray-s2:hover,
.text-button-blue-gray-s3:hover,
.text-button-light-blue-base:hover,
.text-button-light-blue-s1:hover,
.text-button-light-blue-s2:hover,
.text-button-light-blue-s3:hover,
.text-button-blue-base:hover,
.text-button-blue-s1:hover,
.text-button-blue-s2:hover,
.text-button-blue-s3:hover,
.text-button-light-gray-base:hover,
.text-button-light-gray-s1:hover,
.text-button-light-gray-s2:hover,
.text-button-light-gray-s3:hover,
.text-button-gray-base:hover,
.text-button-gray-s1:hover,
.text-button-gray-s2:hover,
.text-button-gray-s3:hover {
    opacity: 1.0;
}


.text-button-light-blue-gray-base,
.text-button-light-blue-gray-s1,
.text-button-light-blue-gray-s2,
.text-button-light-blue-gray-s3 {
    color: $light-gray;
}

.text-button-light-blue-gray-base:hover,
.text-button-light-blue-gray-s1:hover,
.text-button-light-blue-gray-s2:hover,
.text-button-light-blue-gray-s3:hover {
    color: $light-blue;
}

.text-button-blue-gray-base,
.text-button-blue-gray-s1,
.text-button-blue-gray-s2,
.text-button-blue-gray-s3 {
    color: $gray;
}

.text-button-blue-gray-base:hover,
.text-button-blue-gray-s1:hover,
.text-button-blue-gray-s2:hover,
.text-button-blue-gray-s3:hover {
    color: $blue;
}

.text-button-light-blue-base,
.text-button-light-blue-s1,
.text-button-light-blue-s2,
.text-button-light-blue-s3 {
    color: $light-blue;
}

.text-button-blue-base,
.text-button-blue-s1,
.text-button-blue-s2,
.text-button-blue-s3 {
    color: $blue;
}

.text-button-light-gray-base,
.text-button-light-gray-s1,
.text-button-light-gray-s2,
.text-button-light-gray-s3 {
    color: $light-gray;
}

.text-button-gray-base,
.text-button-gray-s1,
.text-button-gray-s2,
.text-button-gray-s3 {
    color: $gray;
}

.text-button-light-blue-gray-s1,
.text-button-blue-gray-s1,
.text-button-light-blue-s1,
.text-button-blue-s1,
.text-button-light-gray-s1,
.text-button-gray-s1 {
    padding: 0px 10px;
    font-size: 18px;
}

.text-button-light-blue-gray-s2,
.text-button-blue-gray-s2,
.text-button-light-blue-s2,
.text-button-blue-s2,
.text-button-light-gray-s2,
.text-button-gray-s2 {
    padding: 0px 8px;
    font-size: 16px;
}

.text-button-light-blue-gray-s3,
.text-button-blue-gray-s3,
.text-button-light-blue-s3,
.text-button-blue-s3,
.text-button-light-gray-s3,
.text-button-gray-s3 {
    padding: 0px 6px;
    font-size: 14px;
}


/*
============================================================================================
    Image Borders
============================================================================================
*/

.border-day {
    border: 1px solid $border-day;
}

.border-night {
    border: 1px solid $border-night;
}

.border-day-top-bottom {
    border-top: 1px solid $border-day;
    border-bottom: 1px solid $border-day;
}

.border-night-top-bottom {
    border-top: 1px solid $border-night;
    border-bottom: 1px solid $border-night;
}


/*
============================================================================================
    Image Buttons
============================================================================================
*/

.image-button-base,
.image-button-weak-base,
.image-button-weaker-base,
.image-button-weakest-base,
.image-button-strong-base,
.image-button-strong-reverse-base,
.image-button-s0,
.image-button-s1,
.image-button-s2,
.image-button-s3,
.image-button-s4,
.image-button-s5,
.image-button-s6,
.image-button-s7,
.image-button-s8,
.image-button-s9,
.image-button-weak-s0,
.image-button-weak-s1,
.image-button-weak-s2,
.image-button-weak-s3,
.image-button-weak-s4,
.image-button-weak-s5,
.image-button-weak-s6,
.image-button-weak-s7,
.image-button-weak-s8,
.image-button-weak-s9,
.image-button-weak-base,
.image-button-weak-s0,
.image-button-weak-s1,
.image-button-weak-s2,
.image-button-weak-s3,
.image-button-weak-s4,
.image-button-weak-s5,
.image-button-weak-s6,
.image-button-weak-s7,
.image-button-weak-s8,
.image-button-weak-s9,
.image-button-weaker-s0,
.image-button-weaker-s1,
.image-button-weaker-s2,
.image-button-weaker-s3,
.image-button-weaker-s4,
.image-button-weaker-s5,
.image-button-weaker-s6,
.image-button-weaker-s7,
.image-button-weaker-s8,
.image-button-weaker-s9,
.image-button-strong-s0,
.image-button-strong-s1,
.image-button-strong-s2,
.image-button-strong-s3,
.image-button-strong-s4,
.image-button-strong-s5,
.image-button-strong-s6,
.image-button-strong-s7,
.image-button-strong-s8,
.image-button-strong-s9,
.image-button-strong-reverse-s0,
.image-button-strong-reverse-s1,
.image-button-strong-reverse-s2,
.image-button-strong-reverse-s3,
.image-button-strong-reverse-s4,
.image-button-strong-reverse-s5,
.image-button-strong-reverse-s6,
.image-button-strong-reverse-s7,
.image-button-strong-reverse-s8,
.image-button-strong-reverse-s9 {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.image-button-base,
.image-button-s0,
.image-button-s1,
.image-button-s2,
.image-button-s3,
.image-button-s4,
.image-button-s5,
.image-button-s6,
.image-button-s7,
.image-button-s8,
.image-button-s9 {
    opacity: 0.6;
}

.image-button-weak-base,
.image-button-weak-s0,
.image-button-weak-s1,
.image-button-weak-s2,
.image-button-weak-s3,
.image-button-weak-s4,
.image-button-weak-s5,
.image-button-weak-s6,
.image-button-weak-s7,
.image-button-weak-s8,
.image-button-weak-s9 {
    opacity: 0.5;
}

.image-button-weaker-base,
.image-button-weaker-s0,
.image-button-weaker-s1,
.image-button-weaker-s2,
.image-button-weaker-s3,
.image-button-weaker-s4,
.image-button-weaker-s5,
.image-button-weaker-s6,
.image-button-weaker-s7,
.image-button-weaker-s8,
.image-button-weaker-s9 {
    opacity: 0.4;
}

.image-button-weakest-base,
.image-button-weakest-s0,
.image-button-weakest-s1,
.image-button-weakest-s2,
.image-button-weakest-s3,
.image-button-weakest-s4,
.image-button-weakest-s5,
.image-button-weakest-s6,
.image-button-weakest-s7,
.image-button-weakest-s8,
.image-button-weakest-s9 {
    opacity: 0.3;
}

.image-button-strong-base,
.image-button-strong-s0,
.image-button-strong-s1,
.image-button-strong-s2,
.image-button-strong-s3,
.image-button-strong-s4,
.image-button-strong-s5,
.image-button-strong-s6,
.image-button-strong-s7,
.image-button-strong-s8,
.image-button-strong-s9 {
    opacity: 0.75;
}

.image-button-strong-reverse-base,
.image-button-strong-reverse-s0,
.image-button-strong-reverse-s1,
.image-button-strong-reverse-s2,
.image-button-strong-reverse-s3,
.image-button-strong-reverse-s4,
.image-button-strong-reverse-s5,
.image-button-strong-reverse-s6,
.image-button-strong-reverse-s7,
.image-button-strong-reverse-s8,
.image-button-strong-reverse-s9 {
    opacity: 1.0;
}

.image-button-base:hover,
.image-button-black-base:hover,
.image-button-s0:hover,
.image-button-s1:hover,
.image-button-s2:hover,
.image-button-s3:hover,
.image-button-s4:hover,
.image-button-s5:hover,
.image-button-s6:hover,
.image-button-s7:hover,
.image-button-s8:hover,
.image-button-s9:hover {
    opacity: 1.0;
}

.image-button-weak-base:hover,
.image-button-weak-s0:hover,
.image-button-weak-s1:hover,
.image-button-weak-s2:hover,
.image-button-weak-s3:hover,
.image-button-weak-s4:hover,
.image-button-weak-s5:hover,
.image-button-weak-s6:hover,
.image-button-weak-s7:hover,
.image-button-weak-s8:hover,
.image-button-weak-s9:hover {
    opacity: 0.75;
}

.image-button-weaker-base:hover,
.image-button-weaker-s0:hover,
.image-button-weaker-s1:hover,
.image-button-weaker-s2:hover,
.image-button-weaker-s3:hover,
.image-button-weaker-s4:hover,
.image-button-weaker-s5:hover,
.image-button-weaker-s6:hover,
.image-button-weaker-s7:hover,
.image-button-weaker-s8:hover,
.image-button-weaker-s9:hover {
    opacity: 0.6;
}

.image-button-weakest-base:hover,
.image-button-weakest-s0:hover,
.image-button-weakest-s1:hover,
.image-button-weakest-s2:hover,
.image-button-weakest-s3:hover,
.image-button-weakest-s4:hover,
.image-button-weakest-s5:hover,
.image-button-weakest-s6:hover,
.image-button-weakest-s7:hover,
.image-button-weakest-s8:hover,
.image-button-weakest-s9:hover {
    opacity: 0.5;
}

.image-button-strong-base:hover,
.image-button-strong-s0:hover,
.image-button-strong-s1:hover,
.image-button-strong-s2:hover,
.image-button-strong-s3:hover,
.image-button-strong-s4:hover,
.image-button-strong-s5:hover,
.image-button-strong-s6:hover,
.image-button-strong-s7:hover,
.image-button-strong-s8:hover,
.image-button-strong-s9:hover {
    opacity: 1.0;
}

.image-button-strong-reverse-base:hover,
.image-button-strong-reverse-s0:hover,
.image-button-strong-reverse-s1:hover,
.image-button-strong-reverse-s2:hover,
.image-button-strong-reverse-s3:hover,
.image-button-strong-reverse-s4:hover,
.image-button-strong-reverse-s5:hover,
.image-button-strong-reverse-s6:hover,
.image-button-strong-reverse-s7:hover,
.image-button-strong-reverse-s8:hover,
.image-button-strong-reverse-s9 {
    opacity: 0.9;
}

.image-button-s0,
.image-button-weak-s0,
.image-button-weaker-s0,
.image-button-weakest-s0,
.image-button-strong-s0,
.image-button-strong-reverse-s0 {
    width: 36px;
    height: 36px;
}

.image-button-s1,
.image-button-weak-s1,
.image-button-weaker-s1,
.image-button-weakest-s1,
.image-button-strong-s1,
.image-button-strong-reverse-s1 {
    width: 34px;
    height: 34px;
}

.image-button-s2,
.image-button-weak-s2,
.image-button-weaker-s2,
.image-button-weakest-s2,
.image-button-strong-s2,
.image-button-strong-reverse-s2 {
    width: 32px;
    height: 32px;
}

.image-button-s3,
.image-button-weak-s3,
.image-button-weaker-s3,
.image-button-weakest-s3,
.image-button-strong-s3,
.image-button-strong-reverse-s3 {
    width: 30px;
    height: 30px;
}

.image-button-s4,
.image-button-weak-s4,
.image-button-weaker-s4,
.image-button-weakest-s4,
.image-button-strong-s4,
.image-button-strong-reverse-s4 {
    width: 28px;
    height: 28px;
}

.image-button-s5,
.image-button-weak-s5,
.image-button-weaker-s5,
.image-button-weakest-s5,
.image-button-strong-s5,
.image-button-strong-reverse-s5 {
    width: 26px;
    height: 26px;
}

.image-button-s6,
.image-button-weak-s6,
.image-button-weaker-s6,
.image-button-weakest-s6,
.image-button-strong-s6,
.image-button-strong-reverse-s6 {
    width: 24px;
    height: 24px;
}

.image-button-s7,
.image-button-weak-s7,
.image-button-weaker-s7,
.image-button-weakest-s7,
.image-button-strong-s7,
.image-button-strong-reverse-s7 {
    width: 22px;
    height: 22px;
}

.image-button-s8,
.image-button-weak-s8,
.image-button-weaker-s8,
.image-button-weakest-s8,
.image-button-strong-s8,
.image-button-strong-reverse-s8 {
    width: 20px;
    height: 20px;
}

.image-button-s9,
.image-button-weak-s9,
.image-button-weaker-s9,
.image-button-weakest-s9,
.image-button-strong-s9,
.image-button-strong-reverse-s9 {
    width: 18px;
    height: 18px;
}


/*
============================================================================================
    Image
============================================================================================
*/

.image-actual,
.image-cover,
.image-contain,
.image-loader-s0,
.image-loader-s1,
.image-loader-s2,
.image-loader-s3,
.image-loader-s4 {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.image-gallery {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.image-cover {
    background-size: cover;
}

.image-contain {
    background-size: contain;
}

.image-gallery {
    width: 100%;
    height: 100%;
    background-size: cover;
}

.image-loader-s0,
.image-loader-s1,
.image-loader-s2,
.image-loader-s3,
.image-loader-s4 {
    width: 100%;
    height: 100%;
}

.image-loader-s0 {
    background-size: 64px 64px;
}

.image-loader-s1 {
    background-size: 60px 60px;
}

.image-loader-s2 {
    background-size: 56px 56px;
}

.image-loader-s3 {
    background-size: 52px 52px;
}

.image-loader-s4 {
    background-size: 48px 48px;
}


/*
============================================================================================
    Profile Image
============================================================================================
*/

.profile-image-base,
.profile-image-s0,
.profile-image-s1,
.profile-image-s2,
.profile-image-s3,
.profile-image-s4,
.profile-image-s5,
.profile-image-s6 {
    display: inline-block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.profile-image-s0 {
    width: 60px;
    height: 60px;
}

.profile-image-s1 {
    width: 56px;
    height: 56px;
}

.profile-image-s2 {
    width: 52px;
    height: 52px;
}

.profile-image-s3 {
    width: 48px;
    height: 48px;
}

.profile-image-s4 {
    width: 44px;
    height: 44px;
}

.profile-image-s5 {
    width: 40px;
    height: 40px;
}

.profile-image-s6 {
    width: 36px;
    height: 36px;
}


/*
============================================================================================
    Gray Scale
============================================================================================
*/

.grayscale {
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"grayscale\"><feColorMatrix type=\"matrix\" values=\"0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\"/></filter></svg>#grayscale");
    -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"grayscale\"><feColorMatrix type=\"matrix\" values=\"0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\"/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"grayscale\"><feColorMatrix type=\"matrix\" values=\"0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\"/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
}


/*
============================================================================================
    Comment
============================================================================================
*/

.comment-w0,
.comment-w1,
.comment-w2,
.comment-w3,
.comment-w4,
.comment-w5,
.comment-w6,
.comment-k0,
.comment-k1,
.comment-k2,
.comment-k3,
.comment-k4,
.comment-k5,
.comment-k6,
.comment-r0,
.comment-r1,
.comment-r2,
.comment-r3,
.comment-r4,
.comment-r5,
.comment-r6,
.comment-b0,
.comment-b1,
.comment-b2,
.comment-b3,
.comment-b4,
.comment-b5,
.comment-b6,
.comment-g0,
.comment-g1,
.comment-g2,
.comment-g3,
.comment-g4,
.comment-g5,
.comment-g6,
.comment-lg0,
.comment-lg1,
.comment-lg2,
.comment-lg3,
.comment-lg4,
.comment-lg5,
.comment-lg6,
.comment-dg0,
.comment-dg1,
.comment-dg2,
.comment-dg3,
.comment-dg4,
.comment-dg5,
.comment-dg6 {
    font-family: "Questrial-Regular", "Manrope-Regular", "Quicksand-Regular", "NanumSquareRoundR", "Didact-Gothic";
    letter-spacing: 0.1px;
}

.comment-w0,
.comment-w1,
.comment-w2,
.comment-w3,
.comment-w4,
.comment-w5,
.comment-w6 {
    color: $white;
}

.comment-k0,
.comment-k1,
.comment-k2,
.comment-k3,
.comment-k4,
.comment-k5,
.comment-k6 {
    color: $black;
}

.comment-b0,
.comment-b1,
.comment-b2,
.comment-b3,
.comment-b4,
.comment-b5,
.comment-b6 {
    color: $blue;
}

.comment-r0,
.comment-r1,
.comment-r2,
.comment-r3,
.comment-r4,
.comment-r5,
.comment-r6 {
    color: $red;
}

.comment-g0,
.comment-g1,
.comment-g2,
.comment-g3,
.comment-g4,
.comment-g5,
.comment-g6 {
    color: $gray;
}

.comment-lg0,
.comment-lg1,
.comment-lg2,
.comment-lg3,
.comment-lg4,
.comment-lg5,
.comment-lg6 {
    color: $light-gray;
}

.comment-dg0,
.comment-dg1,
.comment-dg2,
.comment-dg3,
.comment-dg4,
.comment-dg5,
.comment-dg6 {
    color: $dark-gray;
}

.comment-w0,
.comment-k0,
.comment-r0,
.comment-b0,
.comment-g0,
.comment-lg0,
.comment-dg0 {
    font-size: 24px;
    line-height: 28px;
}

.comment-w1,
.comment-k1,
.comment-r1,
.comment-b1,
.comment-g1,
.comment-lg1,
.comment-dg1 {
    font-size: 22px;
    line-height: 26px;
}

.comment-w2,
.comment-k2,
.comment-r2,
.comment-b2,
.comment-g2,
.comment-lg2,
.comment-dg2 {
    font-size: 20px;
    line-height: 24px;
}

.comment-w3,
.comment-k3,
.comment-r3,
.comment-b3,
.comment-g3,
.comment-lg3,
.comment-dg3 {
    font-size: 18px;
    line-height: 22px;
}

.comment-w4,
.comment-k4,
.comment-r4,
.comment-b4,
.comment-g4,
.comment-lg4,
.comment-dg4 {
    font-size: 16px;
    line-height: 20px;
}

.comment-w5,
.comment-k5,
.comment-r5,
.comment-b5,
.comment-g5,
.comment-lg5,
.comment-dg5 {
    font-size: 14px;
    line-height: 18px;
}

.comment-w6,
.comment-k6,
.comment-r6,
.comment-b6,
.comment-g6,
.comment-lg6,
.comment-dg6 {
    font-size: 12px;
    line-height: 16px;
}


/*
============================================================================================
    Tooltip
============================================================================================
*/
.tooltip-s1, .tooltip-s1 span, .tooltip-s1 p, .tooltip-s1 div,
.tooltip-s2, .tooltip-s2 span, .tooltip-s2 p, .tooltip-s2 div,
.tooltip-s3, .tooltip-s3 span, .tooltip-s3 p, .tooltip-s3 div {
    font-family: "Questrial-Regular", "Manrope-Regular", "Quicksand-Regular", "NanumSquareRoundR", "Didact-Gothic" !important;
    letter-spacing: 0.1px !important;
}

.tooltip-s1, .tooltip-s1 span, .tooltip-s1 p, .tooltip-s1 div {
    font-size: 12px !important;
    line-height: 16px !important;
}

.tooltip-s2, .tooltip-s2 span, .tooltip-s2 p, .tooltip-s2 div {
    font-size: 14px !important;
    line-height: 18px !important;
}

.tooltip-s3, .tooltip-s3 span, .tooltip-s3 p, .tooltip-s3 div {
    font-size: 16px !important;
    line-height: 20px !important;
}


/*
============================================================================================
    Pagination
============================================================================================
*/

.page-s1,
.page-s2,
.page-s3,
.page-day-s1,
.page-day-s2,
.page-day-s3,
.page-night-s1,
.page-night-s2,
.page-night-s3,
.page-selected-s1,
.page-selected-s2,
.page-selected-s3,
.page-selected-day-s1,
.page-selected-day-s2,
.page-selected-day-s3,
.page-selected-night-s1,
.page-selected-night-s2,
.page-selected-night-s3 {
    font-family: "Cabin-Regular", "NanumSquareRoundR";
    display: inline-block;
    vertical-align: middle;
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.page-selected-s1,
.page-selected-s2,
.page-selected-s3 {
    color: $blue;
    opacity: 1.0;
}

.page-selected-day-s1,
.page-selected-day-s2,
.page-selected-day-s3 {
    color: $light-blue;
    opacity: 1.0;
}

.page-selected-night-s1,
.page-selected-night-s2,
.page-selected-night-s3 {
    color: $blue;
    opacity: 1.0;
}

.page-s1,
.page-s2,
.page-s3 {
    opacity: 0.6;
    color: $gray;
}

.page-s1:hover,
.page-s2:hover,
.page-s3:hover {
    opacity: 1.0;
}

.page-day-s1,
.page-day-s2,
.page-day-s3 {
    opacity: 0.6;
    color: $light-gray;
}

.page-day-s1:hover,
.page-day-s2:hover,
.page-day-s3:hover {
    opacity: 1.0;
}

.page-night-s1,
.page-night-s2,
.page-night-s3 {
    opacity: 0.6;
    color: $dark-gray;
}

.page-night-s1:hover,
.page-night-s2:hover,
.page-night-s3:hover {
    opacity: 1.0;
}
.page-s1,
.page-day-s1,
.page-night-s1,
.page-selected-s1,
.page-selected-day-s1,
.page-selected-night-s1 {
    font-size: 24px;
}

.page-s2,
.page-day-s2,
.page-night-s2,
.page-selected-s2,
.page-selected-day-s2,
.page-selected-night-s2 {
    font-size: 22px;
}

.page-s3,
.page-day-s3,
.page-night-s3,
.page-selected-s3,
.page-selected-day-s3,
.page-selected-night-s3 {
    font-size: 20px;
}


/*
============================================================================================
    Modal
============================================================================================
*/

.modal-background {
    display: block;
    position: fixed;
    z-index: 40;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.modal-day {
    //border: solid 1px $light-gray;
    background-color: $almost-white;
    box-shadow: 0px 0px 2px $gray;
}

.modal-day-no-shadow {
    background-color: $almost-white;
}

.modal-night {
    border: 1px solid $closer-to-black;
    //border: solid 1px $darkest-gray;
    background-color: $black;
    box-shadow: 0px 0px 2px $black;
}

.modal-night-no-shadow {
    background-color: $black;
}


/*
============================================================================================
    Menu
============================================================================================
*/

.menu-day {
    //border: solid 1px $light-gray;
    background-color: $white;
    box-shadow: 0px 0px 1px $gray;
}

.menu-night {
    //border: solid 1px $darkest-gray;
    background-color: $almost-black;
    box-shadow: 0px 0px 1px $black;
}


/*
============================================================================================
    ETC
============================================================================================
*/

.light-gray {
    color: $light-gray;
}

.lightest-gray {
    color: $lightest-gray;
}

.gray {
    color: $gray;
}

.dim-gray {
    color: $dim-gray;
}

.dark-gray {
    color: $dark-gray;
}

.darkest-gray {
    color: $darkest-gray;
}

.white {
    color: $white;
}

.red {
    color: $red;
}

.dark-red {
    color: $dark-red;
}

.light-red {
    color: $light-red;
}

.blue {
    color: $blue;
}

.light-blue {
    color: $light-blue;
}

.gold {
    color: $gold;
}

.gray-light-blue {
    color: $gray !important;
}

.gray-light-blue:hover {
    color: $light-blue !important;
}

.gray-blue {
    color: $gray !important;
}

.gray-blue:hover {
    color: $blue !important;
}


/*
============================================================================================
    Fix Firefox Input Validate Warning (Red Box Shadows)
============================================================================================
*/

input:-moz-placeholder {
    box-shadow:none !important;
}

input:invalid {
    box-shadow:0 0 0px $black;
}

textarea:-moz-placeholder {
    box-shadow:none !important;
}

textarea:invalid {
    box-shadow:0 0 0px $black;
}


/*
============================================================================================
    Website
============================================================================================
*/
.website {
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}


/*
============================================================================================
    Body Width
============================================================================================
*/
.body-wide, .body-regular, .body-narrow {
    width : 100%;
    margin: 0px auto;
    overflow: visible;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.body-narrow {
    max-width: 1200px;
}

.body-regular {
    max-width: 1400px;
}


/*
============================================================================================
    ClearFix (apply this to the container of floats)
    https://css-tricks.com/snippets/css/clear-fix/
============================================================================================
*/

.clear-fix:before,
.clear-fix:after {
    content: "";
    display: table;
}
.clear-fix:after {
    clear: both;
}
.clear-fix {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}


/*
============================================================================================
    Table
============================================================================================
*/

.body-table {
    width: 100%;
    display: table;
    box-sizing: content-box;
    border-spacing: 0px;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.body-table-row {
    width: 100%;
    display: table-row;
    box-sizing: content-box;
    border: none;
}


/*
============================================================================================
    Responsive Grid
============================================================================================
*/

* {
    box-sizing: border-box;
}

/*-- clearfix hack for IE < 9 --*/
.row:after {
    content: "";
    display: table;
    clear: both;
}
.row-triplist:after {
    content: "";
    display: table;
    clear: both;
    margin: 20px 0px 10px 0px;
}

[class*="col-"] {
    padding: 0px;
    margin: 0px;
    float: left;
    min-height: 1px;
    width: 8.33%;
}

.col-1 { width: 8.33%; }
.col-2 { width: 16.66%; }
.col-3 { width: 25%; }
.col-4 { width: 33.33%; }
.col-5 { width: 41.66%; }
.col-6 { width: 50%; }
.col-7 { width: 58.33%; }
.col-8 { width: 66.66%; }
.col-9 { width: 75%; }
.col-10 { width: 83.33%; }
.col-11 { width: 91.66%; }
.col-12 { width: 100%; }

/*-- reponsive layout for mobile --*/
@media all and (max-width:800px) {
    .col-1{ width: 16.66%; }
    .col-2{ width: 33.33%; }
    .col-3{ width: 50%; }
    .col-4{ width: 100%; }
    .col-5{ width: 100%; }
    .col-6{ width: 100%; }
    .col-7{ width: 100%; }
    .col-8{ width: 100%; }
    .col-9{ width: 100%; }
    .col-10{ width: 100%; }
    .col-11{ width: 100%; }
    .col-12{ width: 100%; }
    .square{ width: 50%; padding-bottom: 50%; }
}


/*
============================================================================================
    Scroll Bar
============================================================================================
*/
::-webkit-scrollbar {
    width: 8px;
    border: 0px solid #000;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-track-piece {
}

::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.25);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.4);
}


/*
============================================================================================
    Google Autocomplete
============================================================================================
*/

.pac-container {
    border-top: none;
    text-align: center;
    color: $gray;
    background-color: $black;
}

.pac-item {
    border-top: 1px dotted $close-to-black;
    font-size: 14px !important;
}

.pac-item:hover {
    background-color: $close-to-black;
}

.pac-item span {
    color: $dim-gray;
}

.pac-matched {
    color: $blue !important;
}

.pac-item-query {
    color: $light-gray !important;
}


/*
============================================================================================
    Static Map
============================================================================================
*/

.map-static {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 720px 720px;
    background-color: transparent;
    outline: none;
}

.map-static-drag-button {
    position: absolute;
    bottom: 8px;
    right: 2px;
    z-index: 1;
    opacity: 0.50 !important;
}

.map-static-drag-button:hover {
    opacity: 0.50 !important;
}

.map-static-marker {
    display: inline-block;
    position: absolute;
    left: calc(50% - 18px);
    top: calc(50% - 18px);
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 38px 38px;
    background-color: transparent;
}


.map-static-marker-pulse {
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-name: map-static-marker-pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: map-static-marker-pulse;
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes map-static-marker-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}

@keyframes map-static-marker-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0,1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(0,0,0,0.25), 0 0 0 0px rgba(0,0,0, 1.0);
        box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);
        box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);
        box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}
