@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Selected Small
============================================================================================
*/

.plan-selected-small {
}

.plan-selected-small-item {
	width: 320px;
    margin: 0px auto;
   	text-align: center;
}

.plan-selected-small-dot {
	display: inline-block;
	vertical-align: middle;
	width: 160px;
	height: 160px;
	padding: 4px;
}

.plan-selected-small-dot-time {
	display: inline-block;
	vertical-align: middle;
	width: 160px;
	height: 160px;
	position: relative;
	background-size: 60px 1200px;
	background-position: left;
	background-repeat: no-repeat;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.plan-selected-small-start-item, 
.plan-selected-small-end-item {
	display: table;
	width: 320px;
	//height: 100%;
	margin: 0px auto;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.plan-selected-small-start-name,
.plan-selected-small-end-name {
	display: table-cell;
	vertical-align: middle;
	width: 160px;
	padding: 20px 0px;
	text-align: center;
}

.plan-selected-small-start-time, 
.plan-selected-small-end-time {
	display: table-cell;
	vertical-align: middle;
	width: 160px;
	height: 100%;
	position: relative;
	background-size: 60px 1200px;
	background-position: left;
	background-repeat: no-repeat;
}

.plan-selected-small-line {
	width: 100px;
	height: 100%;
	background-size: 60px 1200px;
	background-position: center;
	background-repeat: no-repeat;
}

.plan-selected-small-number {
	position: absolute;
	display: inline-block;
	width: 44px;
    height: 44px;
    top: calc(50% - 22px);
    left: 7px;
   	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

}

.plan-selected-small-timestamp {
	position: absolute;
	display: inline-block;
	height: 30px;
	line-height: 30px;
    top: calc(50% - 15px);
    left: 50px;
    white-space: nowrap;
}


.plan-selected-small-transit-container,
.plan-selected-small-transit-start-container,
.plan-selected-small-transit-end-container {
	position: absolute;
	width: 100px;
	height: 100px;
	line-height: 100px;
	left: 40px;
	text-align: center;
}


.plan-selected-small-transit-container {
	bottom: -45px;
}

.plan-selected-small-transit-start-container {
	bottom: -55px;
}

.plan-selected-small-transit-end-container {
	bottom: -35px;
}

.plan-selected-small-transit {
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
	text-align: center;
}

.plan-selected-small-transit-mode {
	display: block;
	width: 40px;
	height: 40px;
	margin: 0px auto;
   	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.plan-selected-small-transit-time {
	display: block;
	text-align: center;
}

.plan-selected-small-item-remove-button {
    position: absolute;
    top: 116px;
    right: 16px;
}

.plan-selected-small-item-upper-button {
    position: absolute;
    top: 88px;
    left: 19px;
}

.plan-selected-small-item-lower-button {
    position: absolute;
    top: 118px;
    left: 19px;
}

.plan-selected-small-item-information-button {
	width: 30px;
	height: 30px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
    position: absolute;
    top: 60px;
    left: 40px;
    //pointer-events: none;
}

/*
.dot-object-wrapper:hover .plan-selected-small-item-remove-button,
.dot-object-wrapper:hover .plan-selected-small-item-information-button {
    opacity: 0.75 !important;
}

.dot-object-wrapper:hover .plan-selected-small-item-upper-button,
.dot-object-wrapper:hover .plan-selected-small-item-lower-button {
    opacity: 0.6 !important;
}
*/


/*
============================================================================================
    Optional Small
============================================================================================
*/

#plan-optional-small {
    width: 100%;
    min-width: 320px;
}

#plan-optional-small-buttons {
    text-align: center;
    padding-top: 19px;
}

#plan-optional-small-customize {
	display: inline-block;
    vertical-align: middle;
	text-align: center;
    padding: 0px 10px;
    opacity: 0.75;
}

#plan-optional-small-customize:hover {
    opacity: 1.0;
}

#plan-optional-small-customize-icon {
    display: inline-block;
    vertical-align: middle;
    width: 44px;
    height: 44px;
    cursor: pointer;
}

#plan-optional-small-customize-icon-small {
	display: inline-block;
	vertical-align: middle;
	width: 40px;
	height: 40px;
	cursor: pointer;
}

#plan-optional-small-customize-text {
	display: inline-block;
	vertical-align: middle;
    margin-left: 8px;
	cursor: pointer;
}

#plan-optional-small-finalize {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 0px 10px;
    opacity: 0.75;
}

#plan-optional-small-finalize:hover {
    opacity: 1.0;
}

#plan-optional-small-finalize-icon {
    display: inline-block;
    vertical-align: middle;
    width: 46px;
    height: 46px;
    cursor: pointer;
}

#plan-optional-small-finalize-icon-small {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    cursor: pointer;
}

#plan-optional-small-finalize-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    cursor: pointer;
}

.plan-optional-small-day,
.plan-optional-small-night {
	position: fixed;
	width: 100%;
	bottom: 0px;
	left: 0px;
    z-index: 20;
	padding-bottom: 20px;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.plan-optional-small-day {
    background-color: rgba(248, 248, 248, 0.9);

}

.plan-optional-small-night {
    background-color: rgba(0, 0, 0, 0.9);
}

.plan-optional-small-menu {
	text-align: center;
	padding: 10px 0px 6px 0px;
}

.plan-optional-small-cancel {
	position: absolute;
	top: 10px;
	right: 10px;
}

.plan-optional-small-scenic-icon,
.plan-optional-small-dine-icon,
.plan-optional-small-experience-icon,
.plan-optional-small-route-icon {
	margin: 0px 4px 0px 4px;
}

.plan-optional-small-menu-icon {
    opacity: 0.5 !important;
}

.plan-optional-small-menu-icon-on,
.plan-optional-small-menu-icon:hover {
    opacity: 1.0 !important;
}

.plan-optional-small-items {
	width: 320px;
    min-height: 320px;
   	margin: 0px auto 0px auto;
	text-align: left;
}

.plan-optional-small-pagination {
	width: 290px;
	margin: 4px auto 0px auto;
}

.plan-optional-small-pagination-prev-page,
.plan-optional-small-pagination-next-page {
    opacity: 0.5 !important;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.plan-optional-small-pagination-prev-page:hover,
.plan-optional-small-pagination-next-page:hover {
	opacity: 0.8 !important;
}

.plan-optional-small-pagination-prev-page {
	float: left;
}

.plan-optional-small-pagination-next-page {
	float: right;
}

.plan-optional-small-pagination-prev-page-text,
.plan-optional-small-pagination-next-page-text {
    display: inline-block;
    vertical-align: middle;
}

.plan-optional-small-pagination-prev-page-text {
    float: left;
    margin-left: 8px;
}

.plan-optional-small-pagination-next-page-text {
    float: right;
    margin-right: 8px;
}

.plan-optional-small-pagination-prev-page-arrow,
.plan-optional-small-pagination-next-page-arrow {
    vertical-align: middle;
}

.plan-optional-small-pagination-prev-page-arrow {
	float: left;
}

.plan-optional-small-pagination-next-page-arrow {
	float: right;
}

.plan-optional-small-pagination-prev-page:hover .plan-optional-small-pagination-prev-page-arrow {
    opacity: 0.8;
}

.plan-optional-small-pagination-next-page:hover .plan-optional-small-pagination-next-page-arrow {
    opacity: 0.8;
}


/*
============================================================================================
    Dot Preview
============================================================================================
*/

#plan-dot-preview-modal {
    @include modal-background;
}

#plan-dot-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 600px;
    max-height: calc(100% - 20px);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

#plan-dot-preview::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

#plan-dot-preview-map-wrapper {
	width: 100%;
	margin-bottom: 15px;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}

.plan-dot-preview-map-wrapper-day {
    border-bottom: 1px solid $border-day;
}

.plan-dot-preview-map-wrapper-night {
    border-bottom: 1px solid $border-night;
}    


/*
============================================================================================
    Content
============================================================================================
*/

#plan-dot-preview-content {
	width: 100%;
}

#plan-dot-preview-header {
	width: 100%;
	text-align: center;
	margin-bottom: 15px;
}

#plan-dot-preview-header-small {
	width: 100%;
	text-align: center;
	margin-bottom: 15px;
}

#plan-dot-preview-header-content-small {
	width: calc(100% - 80px);
	display: inline-block;
	vertical-align: middle;
	padding: 0px 10px;	
}

#plan-dot-preview-header-content {
	width: calc(100% - 100px);
	display: inline-block;
	vertical-align: middle;
	padding: 0px 20px;
}

.plan-dot-preview-prev-item {
	display: inline-block;
	vertical-align: middle;
}

.plan-dot-preview-prev-item-small {
	display: inline-block;
	vertical-align: middle;
}

.plan-dot-preview-next-item {
	display: inline-block;
	vertical-align: middle;
}

.plan-dot-preview-next-item-small {
	display: inline-block;
	vertical-align: middle;
}

#plan-dot-preview-name {
	margin-bottom: 0px;
	text-align: center;
    padding: 2px 0px;
}

#plan-dot-preview-title {
	text-align: center;	
    padding: 2px 0px;
}

#plan-dot-preview-overview {
	padding: 0px 20px;
}

#plan-dot-preview-overview-small {
	padding: 0px 10px;
}


/*
============================================================================================
    Gallery
============================================================================================
*/
#plan-dot-preview-gallery {
    text-align: center;
    margin-bottom: 15px;
}

.plan-dot-preview-gallery-container {
	display: inline-block;
    position: relative;
    text-align: center;
}

.plan-dot-preview-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.plan-dot-preview-gallery-wrapper-square {
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}

.plan-dot-preview-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    pointer-events: none;
}

.plan-dot-preview-gallery-video {}

.plan-dot-preview-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.plan-dot-preview-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Gallery Navigation
============================================================================================
*/

.plan-dot-preview-gallery-prev-arrow, 
.plan-dot-preview-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.plan-dot-preview-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.plan-dot-preview-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.plan-dot-preview-gallery-prev-arrow:hover,
.plan-dot-preview-gallery-next-arrow:hover {
    opacity: 0.85;
}

.plan-dot-preview-gallery-video:hover ~ .plan-dot-preview-gallery-prev-arrow,
.plan-dot-preview-gallery-video:hover ~ .plan-dot-preview-gallery-next-arrow,
.plan-dot-preview-gallery-image:hover ~ .plan-dot-preview-gallery-prev-arrow,
.plan-dot-preview-gallery-image:hover ~ .plan-dot-preview-gallery-next-arrow {    
    opacity: 0.5;
}

.plan-dot-preview-gallery-dot-on,
.plan-dot-preview-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.plan-dot-preview-gallery-dot-on {
    opacity: 0.8;
}

.plan-dot-preview-gallery-dot-off {
    opacity: 0.4;
}

.plan-dot-preview-gallery-dot-off:hover {
    opacity: 0.8;
}

.plan-dot-preview-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    text-align: center;
    z-index: 1;
}


/*
============================================================================================
    Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.plan-dot-preview-gallery-dot-tag-image, 
.plan-dot-preview-gallery-user-tag-image,
.plan-dot-preview-gallery-unsave-image,
.plan-dot-preview-gallery-enlarge-image,
.plan-dot-preview-gallery-dot-tag-video,
.plan-dot-preview-gallery-user-tag-video,
.plan-dot-preview-gallery-unsave-video,
.plan-dot-preview-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.plan-dot-preview-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.plan-dot-preview-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.plan-dot-preview-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.plan-dot-preview-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.plan-dot-preview-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.plan-dot-preview-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.plan-dot-preview-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.plan-dot-preview-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.plan-dot-preview-gallery-dot-tag-image:hover,
.plan-dot-preview-gallery-user-tag-image:hover,
.plan-dot-preview-gallery-unsave-image:hover,
.plan-dot-preview-gallery-enlarge-image:hover,
.plan-dot-preview-gallery-dot-tag-video:hover,
.plan-dot-preview-gallery-user-tag-video:hover,
.plan-dot-preview-gallery-unsave-video:hover,
.plan-dot-preview-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.plan-dot-preview-gallery-video:hover ~ .plan-dot-preview-gallery-dot-tag-video,
.plan-dot-preview-gallery-video:hover ~ .plan-dot-preview-gallery-user-tag-video,
.plan-dot-preview-gallery-video:hover ~ .plan-dot-preview-gallery-unsave-video,
.plan-dot-preview-gallery-video:hover ~ .plan-dot-preview-gallery-enlarge-video,
.plan-dot-preview-gallery-image:hover ~ .plan-dot-preview-gallery-dot-tag-image,
.plan-dot-preview-gallery-image:hover ~ .plan-dot-preview-gallery-user-tag-image,
.plan-dot-preview-gallery-image:hover ~ .plan-dot-preview-gallery-unsave-image,
.plan-dot-preview-gallery-image:hover ~ .plan-dot-preview-gallery-enlarge-image {
    opacity: 0.8 !important;
}



/*
============================================================================================
    Stats
============================================================================================
*/

.plan-dot-preview-stats {
    text-align: left;
    margin: 10px 0px 5px 0px;
}

.plan-dot-preview-stats-left {
    display: inline-block;
    vertical-align: top;
}

.plan-dot-preview-stats-middle {
    display: inline-block;
    vertical-align: top;
}

.plan-dot-preview-stats-right {
    float: right;
}

.plan-dot-preview-stats-rating, 
.plan-dot-preview-stats-difficulty, 
.plan-dot-preview-stats-hours,
.plan-dot-preview-stats-time {
    display: inline-block;
    vertical-align: top;
    padding: 0px 12px 15px 0px;
}

.plan-dot-preview-stats-rating {
}

.plan-dot-preview-stats-difficulty {
}

.plan-dot-preview-stats-hours {
}

.plan-dot-preview-stats-time {
}


/*
============================================================================================
    Diffuclty / Rating / Times - Text and Values
============================================================================================
*/

.plan-dot-preview-stats-rating-text, 
.plan-dot-preview-stats-difficulty-text, 
.plan-dot-preview-stats-hours-text, 
.plan-dot-preview-stats-time-text {
    text-align: center;
}

.plan-dot-preview-stats-rating-value, 
.plan-dot-preview-stats-difficulty-value, 
.plan-dot-preview-stats-hours-value,
.plan-dot-preview-stats-hours-line,
.plan-dot-preview-stats-time-value,
.plan-dot-preview-stats-rating-value-small,
.plan-dot-preview-stats-difficulty-value-small,
.plan-dot-preview-stats-hours-value-small,
.plan-dot-preview-stats-hours-line-small,
.plan-dot-preview-stats-time-value-small {
    text-align: center;
}

.plan-dot-preview-stats-rating-value,
.plan-dot-preview-stats-difficulty-value {
    font-size: 40px;
}

.plan-dot-preview-stats-rating-value-small,
.plan-dot-preview-stats-difficulty-value-small {
    font-size: 36px;
}

.plan-dot-preview-stats-hours-value {
    font-size: 20px;
}

.plan-dot-preview-stats-hours-value-small {
    font-size: 16px;
}

.plan-dot-preview-stats-hours-line,
.plan-dot-preview-stats-hours-line-small {
    font-weight: 900;
    padding: 0px 0px 2px 0px;
}

.plan-dot-preview-stats-hours-line {
    font-size: 12px;
}

.plan-dot-preview-stats-hours-line-small {
    font-size: 10px;
}

.plan-dot-preview-stats-time-value {
    font-size: 30px;
}

.plan-dot-preview-stats-time-value-small {
    font-size: 24px;
}


/*
============================================================================================
    Diffuclty / Rating Bars
============================================================================================
*/

.plan-dot-preview-stats-rating-bar-day,
.plan-dot-preview-stats-difficulty-bar-day,
.plan-dot-preview-stats-rating-bar-night,
.plan-dot-preview-stats-difficulty-bar-night {
    margin: 0px auto;
}

.plan-dot-preview-stats-rating-bar-front-regular-day,
.plan-dot-preview-stats-difficulty-bar-front-regular-day,
.plan-dot-preview-stats-rating-bar-front-regular-night,
.plan-dot-preview-stats-difficulty-bar-front-regular-night {
    height: 100%;
    border-radius: 3px 0px 0px 3px;
}

.plan-dot-preview-stats-rating-bar-front-round-day,
.plan-dot-preview-stats-difficulty-bar-front-round-day,
.plan-dot-preview-stats-rating-bar-front-round-night,
.plan-dot-preview-stats-difficulty-bar-front-round-night {
    height: 100%;
    border-radius: 3px;
}

.plan-dot-preview-stats-rating-bar-front-regular-day,
.plan-dot-preview-stats-rating-bar-front-round-day {
    background-color: $light-blue;
}

.plan-dot-preview-stats-rating-bar-front-regular-night,
.plan-dot-preview-stats-rating-bar-front-round-night {
    background-color: $blue;
}

.plan-dot-preview-stats-difficulty-bar-front-regular-day,
.plan-dot-preview-stats-difficulty-bar-front-round-day {
    background-color: $light-red;
}

.plan-dot-preview-stats-difficulty-bar-front-regular-night,
.plan-dot-preview-stats-difficulty-bar-front-round-night {
    background-color: $red;
}

.plan-dot-preview-stats-rating-bar-back-day,
.plan-dot-preview-stats-difficulty-bar-back-day,
.plan-dot-preview-stats-rating-bar-back-night,
.plan-dot-preview-stats-difficulty-bar-back-night {
    padding: 1px;
    height: 100%;
    border-radius: 4px;
}

.plan-dot-preview-stats-rating-bar-back-day,
.plan-dot-preview-stats-difficulty-bar-back-day {
    background-color: $light-gray;
}

.plan-dot-preview-stats-rating-bar-back-night,
.plan-dot-preview-stats-difficulty-bar-back-night {
    background-color: $darkest-gray;
}


/*
============================================================================================
    Add / Remove Buttons
============================================================================================
*/

#plan-dot-preview-bottom-menu {
    position: relative;
    width: 100%;
    padding: 0px 10px 10px 10px;
    text-align: center;
}

.plan-dot-preview-bottom-menu-day {
    background-color: rgba(248, 248, 248, 0.75);
}

.plan-dot-preview-bottom-menu-night {
    background-color: rgba(0, 0, 0, 0.75);
}

.plan-dot-preview-add-button {
    border-radius: 15px;
    padding: 2px 14px;
    margin: 3px 0px;
}

.plan-dot-preview-remove-button {
    border-radius: 15px;
    padding: 2px 14px;
    margin: 3px 0px;
}

#plan-dot-preview-cancel {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
