@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    ItineraryOverview
============================================================================================
*/

.itinerary-overview-list-container {
    display: table;
    margin: 0px auto 80px auto;
}

.itinerary-overview-list {
    display: table-cell;
}

.itinerary-overview-item-container {
}

.itinerary-overview-item {
    display: table;
}

.itinerary-overview-item-row {
    display: table-row;
}

.itinerary-overview-number-container,
.itinerary-overview-end-container {
    position: relative;
    width: 100%;
    height: 44px;
    text-align: center;
}

.itinerary-overview-number, 
.itinerary-overview-end {
    position: relative;
    height: 44px;
    width: 44px;
}

.itinerary-overview-timestamp-day,
.itinerary-overview-timestamp-night,
.itinerary-overview-start-timestamp-day,
.itinerary-overview-start-timestamp-night,
.itinerary-overview-end-timestamp-day,
.itinerary-overview-end-timestamp-night {
    position: absolute;
    display: inline-block;
    padding: 1px 10px 2px 10px;
    border-radius: 15px;
    z-index: 1;
    margin: 10px;
}

.itinerary-overview-timestamp-day,
.itinerary-overview-start-timestamp-day,
.itinerary-overview-end-timestamp-day {
    background: $almost-white;
}

.itinerary-overview-timestamp-night,
.itinerary-overview-start-timestamp-night,
.itinerary-overview-end-timestamp-night {
    background: $almost-black;
}

.itinerary-overview-media-left-column {
    display: table-cell;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 2400px;
}

.itinerary-overview-transit-container {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 14px);
    white-space: nowrap;
}

.itinerary-overview-transit-container-small {
    position: absolute;
    top: calc(70% - 20px);
    left: calc(50% - 14px);
    white-space: nowrap;
}

.itinerary-overview-transit-circle {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
}

.itinerary-overview-transit-mode {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background-size: 44px !important;
    margin-left: 12px;
}

.itinerary-overview-transit-time {
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
}

.itinerary-overview-media-column {
    display: table-cell;
    vertical-align: middle;
}

.itinerary-overview-media-right-column {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}

.itinerary-overview-description-left-column,
.itinerary-overview-transit-left-column,
.itinerary-overview-space-left-column {
    display: table-cell;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 2400px;
    overflow: visible;
}

.itinerary-overview-transit-left-column {
    position: relative;
    height: 160px;
}

.itinerary-overview-space-left-column {
    position: relative;
    height: 80px;
}

.itinerary-overview-description-column,
.itinerary-overview-transit-column, 
.itinerary-overview-space-column {
    display: table-cell;
}

.itinerary-overview-transit-column {
    position: relative;
    height: 60px;
}

.itinerary-overview-space-column {
}

.itinerary-overview-description-right-column,
.itinerary-overview-transit-right-column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.itinerary-overview-pulse {
    border-radius: 50%;
    -webkit-animation-name: itinerary-overview-pulse;
    -webkit-animation-duration: 2.4s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: itinerary-overview-pulse;  
    -moz-animation-duration: 2.4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear; 
    animation-timing-function: linear;
}


/*
@-webkit-keyframes itinerary-overview-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33%  {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}

@keyframes itinerary-overview-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);
        box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}    
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);
        box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);
        box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}
*/

@-webkit-keyframes itinerary-overview-pulse {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33%  {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}

@keyframes itinerary-overview-pulse {
    0% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 5px rgba(100,100,100, 0.75), 0 0 0 20px rgba(100,100,100, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);
        box-shadow: 0 0 0 10px rgba(100,100,100, 0.5), 0 0 0 0px rgba(100,100,100, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);
        box-shadow: 0 0 0 15px rgba(100,100,100, 0.25), 0 0 0 0px rgba(100,100,100,1.0);}    
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);
        box-shadow: 0 0 0 20px rgba(100,100,100, 0.0), 0 0 0 5px rgba(100,100,100, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);
        box-shadow: 0 0 0 0px rgba(100,100,100, 0.0), 0 0 0 10px rgba(100,100,100, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);
        box-shadow: 0 0 0 0px rgba(100,100,100, 1.0), 0 0 0 15px rgba(100,100,100, 0.25);}
}


/*
============================================================================================
    Gallery
============================================================================================
*/

.itinerary-overview-item-media {
    position: relative;
}

.itinerary-overview-item-gallery {
    display: inline-block;
    margin: 0px 20px;
    float: right;
}

.itinerary-overview-item-gallery-small {
    display: block;
    text-align: center;
    margin-bottom: 15px;
}

.itinerary-overview-item-gallery-container {
    display: inline-block;
    position: relative;
    text-align: center;
}

.itinerary-overview-item-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.itinerary-overview-item-gallery-wrapper-square {
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}

.itinerary-overview-item-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    pointer-events: none;
}

.itinerary-overview-item-gallery-video {}

.itinerary-overview-item-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.itinerary-overview-item-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/*
============================================================================================
    Gallery Navigation
============================================================================================
*/

.itinerary-overview-item-gallery-prev-arrow, 
.itinerary-overview-item-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.itinerary-overview-item-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.itinerary-overview-item-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.itinerary-overview-item-gallery-prev-arrow:hover,
.itinerary-overview-item-gallery-next-arrow:hover {
    opacity: 0.85;
}

.itinerary-overview-item-gallery-video:hover ~ .itinerary-overview-item-gallery-prev-arrow,
.itinerary-overview-item-gallery-video:hover ~ .itinerary-overview-item-gallery-next-arrow,
.itinerary-overview-item-gallery-image:hover ~ .itinerary-overview-item-gallery-prev-arrow,
.itinerary-overview-item-gallery-image:hover ~ .itinerary-overview-item-gallery-next-arrow {    
    opacity: 0.5;
}

.itinerary-overview-item-gallery-dot-on,
.itinerary-overview-item-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.itinerary-overview-item-gallery-dot-on {
    opacity: 0.8;
}

.itinerary-overview-item-gallery-dot-off {
    opacity: 0.4;
}

.itinerary-overview-item-gallery-dot-off:hover {
    opacity: 0.8;
}

.itinerary-overview-item-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    text-align: center;
    z-index: 1;
}


/*
============================================================================================
    Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/

.itinerary-overview-item-gallery-dot-tag-image, 
.itinerary-overview-item-gallery-user-tag-image,
.itinerary-overview-item-gallery-unsave-image,
.itinerary-overview-item-gallery-enlarge-image,
.itinerary-overview-item-gallery-dot-tag-video,
.itinerary-overview-item-gallery-user-tag-video,
.itinerary-overview-item-gallery-unsave-video,
.itinerary-overview-item-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.itinerary-overview-item-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.itinerary-overview-item-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.itinerary-overview-item-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.itinerary-overview-item-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.itinerary-overview-item-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.itinerary-overview-item-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.itinerary-overview-item-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.itinerary-overview-item-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.itinerary-overview-item-gallery-dot-tag-image:hover,
.itinerary-overview-item-gallery-user-tag-image:hover,
.itinerary-overview-item-gallery-unsave-image:hover,
.itinerary-overview-item-gallery-enlarge-image:hover,
.itinerary-overview-item-gallery-dot-tag-video:hover,
.itinerary-overview-item-gallery-user-tag-video:hover,
.itinerary-overview-item-gallery-unsave-video:hover,
.itinerary-overview-item-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.itinerary-overview-item-gallery-video:hover ~ .itinerary-overview-item-gallery-dot-tag-video,
.itinerary-overview-item-gallery-video:hover ~ .itinerary-overview-item-gallery-user-tag-video,
.itinerary-overview-item-gallery-video:hover ~ .itinerary-overview-item-gallery-unsave-video,
.itinerary-overview-item-gallery-video:hover ~ .itinerary-overview-item-gallery-enlarge-video,
.itinerary-overview-item-gallery-image:hover ~ .itinerary-overview-item-gallery-dot-tag-image,
.itinerary-overview-item-gallery-image:hover ~ .itinerary-overview-item-gallery-user-tag-image,
.itinerary-overview-item-gallery-image:hover ~ .itinerary-overview-item-gallery-unsave-image,
.itinerary-overview-item-gallery-image:hover ~ .itinerary-overview-item-gallery-enlarge-image {
    opacity: 0.8 !important;
}



/*
============================================================================================
    Thumbnails
============================================================================================
*/

.itinerary-overview-thumbnails {
}

.itinerary-overview-thumbnails-small {
    text-align: center;
    margin-bottom: 5px;
}

.itinerary-overview-thumbnail-row {
    width: 100%;
}

.itinerary-overview-thumbnail-column {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 5px;
}

.itinerary-overview-thumbnail-on,
.itinerary-overview-thumbnail-off {
    position: relative;
    display: inline-block;
    margin: 5px 0px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.itinerary-overview-thumbnail-on {
    opacity: 1.0;
}

.itinerary-overview-thumbnail-off {
    opacity: 0.85;
}

.itinerary-overview-thumbnail-off:hover,
.itinerary-overview-thumbnail-off:focus {
    opacity: 1.0;
    transform: scale(1.05);
    -ms-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.itinerary-overview-thumbnail-prev,
.itinerary-overview-thumbnail-next {
    width: 100%;
    height: 30px;
    margin: 5px 0px;
    opacity: 0.2 !important;
}

.itinerary-overview-thumbnail-prev-small,
.itinerary-overview-thumbnail-next-small {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 0px 5px;
    opacity: 0.2 !important;
}

.itinerary-overview-thumbnail-prev:hover,
.itinerary-overview-thumbnail-next:hover,
.itinerary-overview-thumbnail-prev-small:hover,
.itinerary-overview-thumbnail-next-small:hover {
    opacity: 0.4 !important;
}

.itinerary-overview-thumbnail-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    pointer-events: none;
}

.itinerary-overview-description {
}


/*
============================================================================================
    Curation
============================================================================================
*/

.itinerary-overview-header {
    width: 100%;
    padding: 16px 0px 8px 0px;
}

.itinerary-overview-title {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    word-wrap: break-word;
    padding: 2px 0px;
    margin-right: 8px;
}

.itinerary-overview-location {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    word-wrap: break-word;
    padding: 2px 0px;
}

.itinerary-overview-status {
    float: right;
    white-space: nowrap;
}

.itinerary-overview-status-open-day,
.itinerary-overview-status-open-night,
.itinerary-overview-status-closed-day,
.itinerary-overview-status-closed-night,
.itinerary-overview-status-seasonal-day,
.itinerary-overview-status-seasonal-night {
    float: right;
    font-size: 13px;
    padding: 1px 8px 2px 8px;
    margin: 2px 0px 2px 8px;
    border-radius: 15px;
    opacity: 0.75;
}

.itinerary-overview-status-open-day {
    color: $white;
    background-color: $light-blue;
}

.itinerary-overview-status-open-night {
    color: $black;
    background-color: $dark-blue;
}

.itinerary-overview-status-closed-day {
    color: $white;
    background-color: $light-red;
}

.itinerary-overview-status-closed-night {
    color: $black;
    background-color: $dark-red;
}

.itinerary-overview-status-seasonal-day {
    color: $white;
    background-color: $light-orange;
}

.itinerary-overview-status-seasonal-night {
    color: $black;
    background-color: $dark-orange;
}

.itinerary-overview-status-container {
    display: inline;
    white-space: nowrap;
}

.itinerary-overview-curation-overview {
    width: 100%;
    padding: 0px;
}

.itinerary-overview-curation-highlight {
}

.itinerary-overview-curation-regular {
}


/*
============================================================================================
    Stats
============================================================================================
*/

.itinerary-overview-stats {
    text-align: right;
    padding-top: 10px;
}

.itinerary-overview-stats-left {
    display: inline-block;
    vertical-align: top;
}

.itinerary-overview-stats-middle {
    display: inline-block;
    vertical-align: top;
}

.itinerary-overview-stats-right {
    float: right;
}

.itinerary-overview-stats-rating, 
.itinerary-overview-stats-difficulty, 
.itinerary-overview-stats-hours,
.itinerary-overview-stats-time {
    display: inline-block;
    vertical-align: top;
    padding: 0px 0px 15px 12px;
}

.itinerary-overview-stats-rating {
}

.itinerary-overview-stats-difficulty {
}

.itinerary-overview-stats-hours {
}

.itinerary-overview-stats-time {
}


/*
============================================================================================
    Diffuclty / Rating / Times - Text and Values
============================================================================================
*/

.itinerary-overview-stats-rating-text, 
.itinerary-overview-stats-difficulty-text, 
.itinerary-overview-stats-hours-text, 
.itinerary-overview-stats-time-text {
    text-align: center;
}

.itinerary-overview-stats-rating-value, 
.itinerary-overview-stats-difficulty-value, 
.itinerary-overview-stats-hours-value,
.itinerary-overview-stats-hours-line,
.itinerary-overview-stats-time-value,
.itinerary-overview-stats-rating-value-small,
.itinerary-overview-stats-difficulty-value-small,
.itinerary-overview-stats-hours-value-small,
.itinerary-overview-stats-hours-line-small,
.itinerary-overview-stats-time-value-small {
    text-align: center;
}

.itinerary-overview-stats-rating-value,
.itinerary-overview-stats-difficulty-value {
    font-size: 40px;
}

.itinerary-overview-stats-rating-value-small,
.itinerary-overview-stats-difficulty-value-small {
    font-size: 36px;
}

.itinerary-overview-stats-hours-value {
    font-size: 20px;
}

.itinerary-overview-stats-hours-value-small {
    font-size: 16px;
}

.itinerary-overview-stats-hours-line,
.itinerary-overview-stats-hours-line-small {
    font-weight: 900;
    padding: 0px 0px 2px 0px;
}

.itinerary-overview-stats-hours-line {
    font-size: 12px;
}

.itinerary-overview-stats-hours-line-small {
    font-size: 10px;
}

.itinerary-overview-stats-time-value {
    font-size: 30px;
}

.itinerary-overview-stats-time-value-small {
    font-size: 24px;
}


/*
============================================================================================
    Diffuclty / Rating Bars
============================================================================================
*/

.itinerary-overview-stats-rating-bar-day,
.itinerary-overview-stats-difficulty-bar-day,
.itinerary-overview-stats-rating-bar-night,
.itinerary-overview-stats-difficulty-bar-night {
    margin: 0px auto;
}

.itinerary-overview-stats-rating-bar-front-regular-day,
.itinerary-overview-stats-difficulty-bar-front-regular-day,
.itinerary-overview-stats-rating-bar-front-regular-night,
.itinerary-overview-stats-difficulty-bar-front-regular-night {
    height: 100%;
    border-radius: 3px 0px 0px 3px;
}

.itinerary-overview-stats-rating-bar-front-round-day,
.itinerary-overview-stats-difficulty-bar-front-round-day,
.itinerary-overview-stats-rating-bar-front-round-night,
.itinerary-overview-stats-difficulty-bar-front-round-night {
    height: 100%;
    border-radius: 3px;
}

.itinerary-overview-stats-rating-bar-front-regular-day,
.itinerary-overview-stats-rating-bar-front-round-day {
    background-color: $light-blue;
}

.itinerary-overview-stats-rating-bar-front-regular-night,
.itinerary-overview-stats-rating-bar-front-round-night {
    background-color: $blue;
}

.itinerary-overview-stats-difficulty-bar-front-regular-day,
.itinerary-overview-stats-difficulty-bar-front-round-day {
    background-color: $light-red;
}

.itinerary-overview-stats-difficulty-bar-front-regular-night,
.itinerary-overview-stats-difficulty-bar-front-round-night {
    background-color: $red;
}

.itinerary-overview-stats-rating-bar-back-day,
.itinerary-overview-stats-difficulty-bar-back-day,
.itinerary-overview-stats-rating-bar-back-night,
.itinerary-overview-stats-difficulty-bar-back-night {
    padding: 1px;
    height: 100%;
    border-radius: 4px;
}

.itinerary-overview-stats-rating-bar-back-day,
.itinerary-overview-stats-difficulty-bar-back-day {
    background-color: $light-gray;
}

.itinerary-overview-stats-rating-bar-back-night,
.itinerary-overview-stats-difficulty-bar-back-night {
    background-color: $darkest-gray;
}


/*
============================================================================================
    Diffuclty / Rating Bars
============================================================================================
*/

.itinerary-overview-end-time-day,
.itinerary-overview-end-time-night {
    display: inline-block;
    vertical-align: middle;
    padding: 1px 8px 1px 8px;
    margin: 8px 15px 8px 0px;
    opacity: 0.6;
    border-radius: 15px;
}

.itinerary-overview-end-time-day {
    background-color: $light-blue;
}

.itinerary-overview-end-time-night {
    background-color: $blue;
}

.itinerary-overview-end-name {
}

.itinerary-overview-lodging-container {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer !important;
    opacity: 0.6;
}

.itinerary-overview-lodging-container:hover {
    opacity: 0.8;
}

.itinerary-overview-lodging-image {
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    height: 24px;
    margin: 6px 0px 3px 0px;
}


.itinerary-overview-lodging-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
