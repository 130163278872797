@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Trip Plan - Main Structure
============================================================================================
*/

#plan-container {
    padding-top: 70px;
    padding-bottom: 80px;
}

#plan-container-small {
    min-width: 320px;
    padding-top: 60px;
    padding-bottom: 70px;
}

.plan-section {
	width : 100%;
	margin: 0px auto;
	overflow: hidden;
	padding: 0px 15px 0px 15px;
	min-width: 1058px;
	box-sizing: border-box;
    //transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out;
	//-webkit-transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out;
	//-moz-transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out;
	//-o-transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out;
}

.plan-table {
	width: 100%;
	display: table;
	box-sizing: content-box;
	border-spacing: 0px;
    //transition: opacity 0.3s ease-in-out;
	//-webkit-transition: opacity 0.3s ease-in-out;
	//-moz-transition: opacity 0.3s ease-in-out;
	//-o-transition: opacity 0.3s ease-in-out;
}

.plan-table-row {
	width: 100%;
	padding: 0px 0px 0px 0px;
	display: table-row;
	box-sizing: content-box;
	border: none;
}

.plan-left-menu {
	display: table-cell;
	box-sizing: content-box;
	width: 36px;
	border-right: solid 1px #464646;
	padding: 12px 12px 0px 0px;
	vertical-align: top;
}

.plan-right-menu {
    padding: 16px 0px 0px 12px;
    display: table-cell;
    box-sizing: content-box;
    width: 36px;
    height: 100%;
    border-left: solid 1px #464646;
    vertical-align: top;
}

.plan-rest {
	display: table-cell;
	box-sizing: content-box;
	vertical-align: top;
}

.plan-half {
	width: 50%;
	display: table-cell;
	box-sizing: content-box;
	vertical-align: top;
	border: none;
}


/*
============================================================================================
  Icons
============================================================================================
*/

#plan-basic-settings-icon,
#plan-custom-settings-icon,
#plan-map-icon,
#plan-customize-icon,
#plan-options-scenic-icon,
#plan-options-dine-icon,
#plan-options-experience-icon,
#plan-options-route-icon {
    margin: 0px 0px 6px 0px;
}

.plan-menu-icon-off,
.plan-menu-icon-off:hover,
.plan-menu-icon {
    opacity: 0.5;
}

.plan-menu-icon-on,
.plan-menu-icon-on:hover,
.plan-menu-icon:hover {
    opacity: 1.0;
}


/*
============================================================================================
  Calendar / DayInfo / StartLocation
============================================================================================
*/

#plan-calendar-dayinfo {
    width: 100%;
    margin-bottom: 10px;
}

.plan-calendar-dayinfo-row {
    width: 100%;
    white-space: nowrap;
}

#plan-calendar-column,
#plan-dayinfo-column {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

#plan-calendar-title,
#plan-dayinfo-title {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 6px;
    text-align: center;
}

#plan-calendar-container {
    height: 42px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
}

#plan-calendar-icon {
    vertical-align: middle;
    width: 26px;
    height: 26px;
    margin: 2px 10px 0px 0px;
}

/* Need to implement this to the DatePicker library */
.plan-calendar-input-day,
.plan-calendar-input-night {
    width: 210px !important;
    text-align: center !important;
    border: none !important;
    cursor: pointer !important;
    background-color: transparent !important;
    color: #FFFFFF !important;
    text-align: left !important;
    font-size: 34px !important;
}

.plan-calendar-input-day {
    color: $light-blue !important;
}

.plan-calendar-input-night {
    color: $blue !important;
}

#plan-calendar {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

#plan-dayinfo {
    text-align: center;
}


/*
============================================================================================
    Time Slider
============================================================================================
*/

#plan-time {
    width: 100%;
}

#plan-time-slider-title {
    padding: 0px 0px 24px 0px;
    text-align: center;
}

#plan-time-slider-container {
    padding: 0px 0px 24px 0px;
    text-align: center;
}

#plan-time-slider-icon {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

#plan-time-slider {
    width: calc(100% - 150px);
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.plan-time-slider-dot-day,
.plan-time-slider-dot-night {
    border-radius: 100%;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    position: absolute;
    top: 50%;
    width: 1rem; 
}

.plan-time-slider-dot-day {
    background: $light-gray;
    border: 2px solid $light-gray;
}

.plan-time-slider-dot-night {
    background: $dark-gray;
    border: 2px solid $dark-gray;
}

.plan-time-slider-text-day,
.plan-time-slider-text-night {
    width: 100px;
    display: block;
    position: absolute;
    bottom: -20px;
    margin-left: -50px;
    text-align: center;
}

.plan-time-slider-text-day {
    color: $light-gray;
}

.plan-time-slider-text-night {
    color: $darkest-gray;
}


/*
============================================================================================
  Start and End Locations
============================================================================================
*/

#plan-locations-container {
    width: 100%;
    padding-bottom: 16px;
}

#plan-locations {
    margin: 0px auto;
    text-align: center;
    white-space: nowrap;
}

#plan-location-title {
    padding: 0px 0px 6px 0px;
    text-align: center;
}

#plan-location-icon {
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

#plan-start-location-input,
#plan-end-location-input {
    vertical-align: middle;
    width: 280px;
    margin: 4px 8px;
}

#plan-start-location-input:focus,
#plan-end-location-input:focus {}


/*
============================================================================================
    Itinerary - Find and Connect Buttons
============================================================================================
*/

#plan-buttons {
  width: 150px;
  padding-right: 50px;
  display: table-cell;
  box-sizing: content-box;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.plan-buttons-title {
  padding: 0px 0px 2px 0px;
  text-align: center;
}

#plan-find-button,
#plan-connect-button, 
#plan-find-button-inactive,
#plan-connect-button-inactive {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-bottom: 8px;
}

#plan-find-button,
#plan-connect-button {
  opacity: 0.7;
  //transition: opacity 0.3s ease-in-out;  
  //-webkit-transition: opacity 0.3s ease-in-out;
  //-moz-transition: opacity 0.3s ease-in-out;
  //-o-transition: opacity 0.3s ease-in-out;
}

#plan-find-button:hover,
#plan-connect-button:hover {
    opacity: 1.0;
}

#plan-find-button-inactive,
#plan-connect-button-inactive {
  opacity: 0.2;
}


/* 
============================================================================================
    Itinerary - Custom Settings
============================================================================================
*/

#plan-fitness-level {
    text-align: center;
    padding: 16px;
}

#plan-fitness-level-text {
    display: inline-block;
    padding: 16px 16px 16px 0px;
    vertical-align: bottom;
}

#plan-fitness-level-slider {
    display: inline-block;
    width: 300px;
    vertical-align: bottom;
    padding: 16px 0px;
}


/* 
============================================================================================
    Itinerary - Map
============================================================================================
*/

#plan-map {
}

#plan-map-section {
	display: table-cell;
    padding: 12px 24px;
	box-sizing: content-box;
	vertical-align: top;
    border-radius: 10px;
    overflow: hidden;
    //transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;  
    //-webkit-transition: width 0.3s ease-in-out, padding 0.3s ease-in-out; 
    //-moz-transition: width 0.3s ease-in-out, padding 0.3s ease-in-out; 
    //-o-transition: width 0.3s ease-in-out, padding 0.3s ease-in-out; 
}

#plan-map-wrapper {
    border-radius: 10px;
    overflow: hidden;
}


/* 
============================================================================================
    Itinerary - Item Preview
============================================================================================
*/

#plan-item-preview-container {
	height: 100%;
    height: 100%;    
    padding: 12px 24px;
	vertical-align: top;
	border: none;
    //transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;  
    //-webkit-transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; 
    //-moz-transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; 
    //-o-transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out; 
}

#plan-item-preview-content {}


/* 
============================================================================================
    Item Preview
============================================================================================
*/

#plan-item-preview-name {
    padding-bottom: 8px;
}

#plan-item-preview-overview {
    padding-bottom: 16px;
}

#plan-item-preview-stats {
    text-align: left;
}

#plan-item-preview-rating, 
#plan-item-preview-difficulty, 
#plan-item-preview-hours,
#plan-item-preview-time {
    display: inline-block;
    vertical-align: top;
    padding: 0px 16px 12px 0px;
}

#plan-item-preview-rating {}

#plan-item-preview-difficulty {}

#plan-item-preview-hours {}

#plan-item-preview-time {}


/*
============================================================================================
    Diffuclty / Rating / Times - Text and Values
============================================================================================
*/

#plan-item-preview-rating-text, 
#plan-item-preview-difficulty-text,
#plan-item-preview-hours-text,
#plan-item-preview-time-text {
    text-align: center;
}

#plan-item-preview-rating-value, 
#plan-item-preview-difficulty-value, 
.plan-item-preview-hours-value,
#plan-item-preview-hours-line,
#plan-item-preview-time-value {
    text-align: center;
}

#plan-item-preview-rating-value {
    font-size: 40px;
}

#plan-item-preview-difficulty-value {
    font-size: 40px;
}

.plan-item-preview-hours-value {
    font-size: 20px;
}

#plan-item-preview-hours-line {
    font-weight: 900;
    font-size: 12px;
    padding: 0px 0px 2px 0px;
}

#plan-item-preview-time-value {
    font-size: 30px;
}


/*
============================================================================================
    Diffuclty / Rating Bars
============================================================================================
*/

#plan-item-preview-rating-bar, 
#plan-item-preview-difficulty-bar {
    width: 100%;
    height: 12px;
}

#plan-item-preview-rating-bar-front-regular-day,
#plan-item-preview-difficulty-bar-front-regular-day,
#plan-item-preview-rating-bar-front-regular-night,
#plan-item-preview-difficulty-bar-front-regular-night {
    height: 10px;
    border-radius: 3px 0px 0px 3px;
}

#plan-item-preview-rating-bar-front-round-day,
#plan-item-preview-difficulty-bar-front-round-day,
#plan-item-preview-rating-bar-front-round-night,
#plan-item-preview-difficulty-bar-front-round-night {
    height: 10px;
    border-radius: 3px;
}

#plan-item-preview-rating-bar-front-regular-day,
#plan-item-preview-rating-bar-front-round-day {
    background-color: $light-blue;
}

#plan-item-preview-rating-bar-front-regular-night,
#plan-item-preview-rating-bar-front-round-night {
    background-color: $blue;
}

#plan-item-preview-difficulty-bar-front-regular-day,
#plan-item-preview-difficulty-bar-front-round-day {
    background-color: $light-red;
}

#plan-item-preview-difficulty-bar-front-regular-night,
#plan-item-preview-difficulty-bar-front-round-night {
    background-color: $dark-red;
}

#plan-item-preview-rating-bar-back-day,
#plan-item-preview-difficulty-bar-back-day,
#plan-item-preview-rating-bar-back-night,
#plan-item-preview-difficulty-bar-back-night {
    padding: 1px;
    width: 62px;
    height: 12px;
    border-radius: 4px;
}

#plan-item-preview-rating-bar-back-day,
#plan-item-preview-difficulty-bar-back-day {
    background-color: $light-gray;
}

#plan-item-preview-rating-bar-back-night,
#plan-item-preview-difficulty-bar-back-night {
    background-color: $darkest-gray;
}


/* 
============================================================================================
    Preview Image
============================================================================================
*/

#plan-item-preview-image-container {
    border-radius: 10px;
    margin: 0px 0px 12px 16px;
    overflow: hidden;
    float: right;
}

#plan-item-preview-image-wrapper {
    position: relative;
    margin: 0px auto;
    overflow: hidden;
}

#plan-item-preview-image-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    opacity: 0.5;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#plan-item-preview-image {
    width: 100%;
    height: 100%;
}

#plan-item-preview-image-shadow:hover > #plan-item-preview-image-buttons,
#plan-item-preview-image-shadow:focus > #plan-item-preview-image-buttons {
    opacity: 1.0;
}


/* 
============================================================================================
    Preview Image Buttons
============================================================================================
*/

#plan-item-preview-image-buttons {
    width: 100%;
    height: 100%;
    opacity: 0.0;
    position: relative;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}


/* 
============================================================================================
    Preview Image Navigation
============================================================================================
*/

#plan-item-preview-image-prev-arrow,
#plan-item-preview-image-next-arrow {
    position: absolute;
    position: absolute;
    top: calc(50% - 18px);
    width: 36px;
    height: 36px;
    opacity: 0.75;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

#plan-item-preview-image-prev-arrow {
    left: 0px;
    margin-left: 12px;
}

#plan-item-preview-image-next-arrow {
    right: 0px;
    margin-right: 12px;
}

#plan-item-preview-image-prev-arrow:hover,
#plan-item-preview-image-next-arrow:hover {
    opacity: 1.0;
}

.plan-item-preview-image-nav-dot-on,
.plan-item-preview-image-nav-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.plan-item-preview-image-nav-dot-on{
    opacity: 0.8;
}

.plan-item-preview-image-nav-dot-off {
    opacity: 0.4;
}

.plan-item-preview-image-nav-dot-off:hover {
    opacity: 0.8;
}

#plan-item-preview-image-nav-dots {
    position: absolute;
    top: 6px;
    width: 100%;
    text-align: center;
}


/* 
============================================================================================
    Itinerary - Selection
============================================================================================
*/

#plan-selection-menu {
	width: 36px;
	border-right: solid 1px #464646;
	padding: 12px 12px 0px 0px;
	display: table-cell;
	box-sizing: content-box;
	vertical-align: top;
}

#plan-selection {
	padding: 6px 18px;
	display: table-cell;
	box-sizing: content-box;
	vertical-align: top;
	border: none;
}


/* 
============================================================================================
    Itinerary - Options
============================================================================================
*/

#plan-options-menu {
	display: table-cell;
	box-sizing: content-box;
	width: 36px;
	height: 100%;
	border-left: solid 1px #464646;
	padding: 12px 0px 0px 12px;
	vertical-align: top;
}

#plan-options {
	padding: 6px 18px;
	display: table-cell;
	box-sizing: content-box;
	height: 100%;
	border-left: solid 1px #464646;
}

#plan-options-content {}

#plan-options-pagination {
    width: 100%;
    padding: 4px 6px 0px 6px;
}

#plan-options-pagination-previous-page,
#plan-options-pagination-next-page {
    opacity: 0.4 !important;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

#plan-options-pagination-previous-page {
    float: left;
}

#plan-options-pagination-next-page {
    float: right;
}

#plan-options-pagination-previous-page:hover,
#plan-options-pagination-next-page:hover {
    opacity: 0.6 !important;
}

#plan-options-pagination-previous-page-arrow,
#plan-options-pagination-next-page-arrow {
    vertical-align: middle;
}

#plan-options-pagination-previous-page-text,
#plan-options-pagination-next-page-text {
    display: inline-block;
    vertical-align: middle;
}

#plan-options-pagination-previous-page-text {
    margin-left: 6px;
}

#plan-options-pagination-next-page-text {
    margin-right: 6px;
}

#plan-options-pagination-previous-page:hover #plan-options-pagination-previous-page-arrow {
    opacity: 0.8;
}

#plan-options-pagination-next-page:hover #plan-options-pagination-next-page-arrow {
    opacity: 0.8;
}


/* 
============================================================================================
    Itinerary Schedule
============================================================================================
*/

#plan-schedule {}

#plan-schedule-date {
    position: relative;
    width: 720px;
    margin: 0px auto;
    padding-top: 16px;
    text-align: center;
}

#plan-schedule-gallery {}

#plan-schedule-start {
    position: absolute;
    left: 0px;
    bottom: -8px;
    padding-left: 8px;
    text-align: right;
}

#plan-schedule-end {
    position: absolute;
    right: 0px;
    bottom: -8px;
    padding-right: 8px;
    text-align: left;
}


/* 
============================================================================================
    Finalize Bar
============================================================================================
*/

#plan-finalize-bar {
    text-align: center;
    margin-top: 15px;
}

#plan-finalize-bar-float {
    width: 100%;
    position: fixed;
    text-align: center;
    padding: 10px 0px 10px 0px;
    left: 0px;
    bottom: 0px;
    z-index: 20;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.plan-finalize-bar-day {
    background-color: rgba(248, 248, 248, 0.9);
}

.plan-finalize-bar-night {
    background-color: rgba(0, 0, 0, 0.9);
}

#plan-finalize-button {
    display: inline-block;
    opacity: 0.7;
    cursor: pointer;
}

#plan-finalize-button:hover {
    opacity: 1.0;
}

#plan-finalize-button-icon {
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    height: 48px;
}

#plan-finalize-button-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
}


/*
============================================================================================
   Finalize Modal
============================================================================================
*/

#plan-finalize-modal {
    @include modal-background;
}


#plan-finalize-itinerary {
    @include modal-basic-binary;
    width: 320px;
    padding: 20px 30px 25px 30px;
    text-align: center;
}

#plan-finalize-title {
    text-align: center;
    padding-bottom: 10px;
}

#plan-finalize-message {
    text-align: center;
    padding-bottom: 10px;
}

#plan-finalize-log-in,
#plan-finalize-sign-up {
    margin: 0px 5px;
}

#plan-finalize-log-in:hover,
#plan-finalize-sign-up:hover {
}
