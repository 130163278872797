@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
	Itineraries Home
============================================================================================
*/

#itineraries-home-container {
	min-width: 320px;
}

.itineraries-home-container  {
    padding-top: 70px;
}

.itineraries-home-container-small {
    padding-top: 60px;
}

.itineraries-home-item {
	width: calc(100% - 40px);
	max-width: 640px;
	height: calc(25vw - 10px);
	max-height: 140px;
	margin: 0px auto 15px auto;
}

.itineraries-home-item-small {
	width: calc(100% - 20px);
	min-width: 300px;
	height: calc(25vw - 5px);
	min-height: 90px;
	margin: 0px auto 10px auto;
}

.itineraries-home-item-gallery-container {
	width: 100%;
	height: 100%;
}

.itineraries-home-item-gallery-wrapper {
	position: relative;
	border-radius: 12px;
    overflow: hidden;
}

.itineraries-home-item-gallery-shadow {
	position: absolute;
    width: 100%;
    height: 100%;
	left: 0px;
    top: 0px;
}

.itineraries-home-item-gallery-gradient {
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0.8) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0.8) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0.8) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 40%, rgba(0,0,0,0.4) 70%, rgba(0,0,0,0.8) 100%); /* Standard syntax (must be last) */  
}

.itineraries-home-item-gallery-gradient-small {
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 30%, rgba(0,0,0,0.45) 65%, rgba(0,0,0,0.9) 100%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 30%, rgba(0,0,0,0.45) 65%, rgba(0,0,0,0.9) 100%); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 30%, rgba(0,0,0,0.45) 65%, rgba(0,0,0,0.9) 100%); /* For Firefox 3.6 to 15 */
    background: linear-gradient(rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0) 30%, rgba(0,0,0,0.45) 65%, rgba(0,0,0,0.9) 100%); /* Standard syntax (must be last) */  
}

.itineraries-home-item-left {
	display: inline-block;
	position: absolute;
	bottom: 0px;
	left: 0px;
	padding: 10px 15px;
}

.itineraries-home-item-right{
	display: inline-block;
	position: absolute;
	bottom: 0px;
	right: 0px;
	padding: 10px 15px;
}

.itineraries-home-item-column {
	display: inline-block;
	vertical-align: bottom;
	margin-left: 15px;
	white-space: nowrap;
}

.itineraries-home-item-column-small {
	display: inline-block;
	vertical-align: bottom;
	margin-left: 8px;
	white-space: nowrap;
}

.itineraries-home-item-date {
	font-size: 26px;
}

.itineraries-home-item-date-small {
	font-size: 20px;
}

.itineraries-home-item-area {
	min-width: 180px;
	max-width: 60vw;
}

.itineraries-home-item-time {
	display: inline-block;
	vertical-align: bottom;
}

.itineraries-home-item-time-icon {
	display: inline-block;
	vertical-align: bottom;
	width: 24px;
	height: 24px;
	margin-right: 6px;
	margin-bottom: 3px;
}

.itineraries-home-item-time-icon-small {
	display: inline-block;
	vertical-align: bottom;
	width: 20px;
	height: 20px;
	margin-right: 3px;
	margin-bottom: 3px;
}

.itineraries-home-item-time-start,
.itineraries-home-item-time-line,
.itineraries-home-item-time-end,
.itineraries-home-item-time-start-small,
.itineraries-home-item-time-line-small,
.itineraries-home-item-time-end-small {
	text-align: center;
}

.itineraries-home-item-time-line {
	width: 100%;
	height: 8px;
	margin: 1px 0px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px 600px;
	overflow: hidden;
}

.itineraries-home-item-time-line-small {
	width: 100%;
	height: 6px;
	margin-top: 1px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 30px 600px;
	overflow: hidden;
}

.itineraries-home-item-dots-icon {
	display: inline-block;
	vertical-align: bottom;
	background-position: bottom !important;
	width: 30px;
	height: 30px;
	margin-right: 6px;
	margin-bottom: 1px;
}

.itineraries-home-item-dots-icon-small {
	display: inline-block;
	vertical-align: bottom;
	background-position: bottom !important;
	width: 26px;
	height: 26px;
	margin-right: 3px;
	margin-bottom: 1px;
}

.itineraries-home-item-dots-text {
	display: inline-block;
	vertical-align: bottom;
}

.itineraries-home-item-dots-text-small {
	display: inline-block;
	vertical-align: bottom;
}

.itineraries-home-item-title {
}


.itineraries-home-item-dots {

}


/*
============================================================================================
   Pagination
============================================================================================
*/

.itineraries-home-pages-container {
    text-align: center;
    padding-bottom: 10px;
}

.itineraries-home-pages {
    display: inline-block;
    vertical-align: middle;
}

.itineraries-home-page {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
}

.itineraries-home-page:hover {
    opacity: 1.0;
}

.itineraries-home-previous, 
.itineraries-home-next {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 6px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.3;
}

.itineraries-home-previous:hover,
.itineraries-home-next:hover {
    opacity: 0.6;
}
