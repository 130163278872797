@import "../../css/_variables";
@import "../../css/_mixins";


/*
============================================================================================
    Bucket
============================================================================================
*/

#user-info-bucket {
    width: 100%;
    padding-top: 10px;
}

#user-info-bucket-header {
    width: 100%;
    text-align: center;
}

#user-info-bucket-image {
    vertical-align: bottom;
    width: 50px;
    height: 40px;
    margin-bottom: 2px;
}

#user-info-bucket-contributed-count {
    display: inline-block;
    vertical-align: bottom;
    width: 200px;
    text-align: right;
    padding: 0px 3px 4px 0px;
    cursor: pointer;
    opacity: 0.8;
}

#user-info-bucket-contributed-count:hover {
    opacity: 1.0;
}

#user-info-bucket-saved-count {
    display: inline-block;
    vertical-align: bottom;
    width: 200px;
    text-align: left;
    padding: 0px 0px 4px 3px;
    cursor: pointer;
    opacity: 0.8;
}

#user-info-bucket-saved-count:hover {
    opacity: 1.0;
}

#user-info-bucket-content {
    text-align: center;
    padding: 10px 0px;
    white-space: nowrap;
}

#user-info-right-menu-container {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
}

#user-info-right-menu-container {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
}

#user-info-bucket-container,
#user-info-bucket-container-small,
#user-info-bucket-container-medium,
#user-info-bucket-container-small {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-align: left;
}

#user-info-bucket-container {
    width: 760px;
}

#user-info-bucket-container-small {
    width: 380px;
}

#user-info-bucket-container-medium {
    width: 570px;
}

#user-info-bucket-container-small {
    width: 96vw;
}


/*
============================================================================================
   Bucket Item List
============================================================================================
*/

.user-info-bucket-item-loader {
    display: inline-block;
    width: 180px !important;
    height: 180px !important;
    margin: 5px;
}

.user-info-bucket-item-loader-small {
    display: inline-block;
    width: 44vw !important;
    height: 44vw !important;
    margin: 2vw;
}

.user-info-bucket-item-image, 
.user-info-bucket-item-video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.user-info-bucket-item-video {
    background-size: 48px !important;
}

.user-info-bucket-item-title {
    position: absolute;
    width: 100%;
    bottom: 0px;
    text-align: center;
}

.user-info-bucket-item-name-area-container {
    display: block;
    width: 100%;
}

.user-info-bucket-item-name {
    padding: 0px 10px 2px 10px;
}

.user-info-bucket-item-area {
    padding: 0px 10px 10px 10px;
}

.user-info-bucket-item-name-area {
    padding: 0px 10px 15px 10px;
} 

.user-info-bucket-item-shadow {
    width: 100%;
    height: 100%;
}

.user-info-bucket-item-shadow-day, 
.user-info-bucket-item-shadow-night,
.user-info-bucket-item-shadow-hover-day,
.user-info-bucket-item-shadow-hover-night {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out; 
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
}

.user-info-bucket-item-shadow-day {
    opacity: 0.0;
    background-color: rgba(0,0,0, 0.35);
}

.user-info-bucket-item-shadow-night {
    opacity: 0.0;
    background-color: rgba(0,0,0, 0.50);
}

.user-info-bucket-item-shadow-hover-day {
    opacity: 1.0;
    background-color: rgba(0,0,0, 0.35);
}

.user-info-bucket-item-shadow-hover-night {
    opacity: 1.0;
    background-color: rgba(0,0,0, 0.50);
}

.user-info-bucket-item-left-buttons {
    position: absolute;
    top: 8px;
    left: 8px;
}

.user-info-bucket-item-right-buttons {
    position: absolute;
    top: 8px;
    right: 8px;
}

.user-info-bucket-item-user-tag,
.user-info-bucket-item-user-tagged,
.user-info-bucket-item-dot-tag, 
.user-info-bucket-item-memo,
.user-info-bucket-item-unsave,
.user-info-bucket-item-enlarge {
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.40 !important;
}

.user-info-bucket-item-user-tagged-count {
    display: inline-block;
    vertical-align: bottom;
    padding-right: 4px;
}

.user-info-bucket-item-user-tag:hover,
.user-info-bucket-item-dot-tag:hover,
.user-info-bucket-item-memo:hover,
.user-info-bucket-item-unsave:hover,
.user-info-bucket-item-enlarge:hover {
    opacity: 0.80 !important;
}


/*
============================================================================================
   Timeline
============================================================================================
*/

.user-info-timeline {
    width: 100%;
    padding: 0px 10px 10px 10px;
    text-align: center;
}

.user-info-timeline-item {
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    margin: 0px auto 0px auto;
}

.user-info-timeline-item-gallery {
    position: relative;
    margin-bottom: 15px;
}

.user-info-timeline-item-time {
    width: 100%;
    margin: 10px 0px;
}

.user-info-timeline-item-time-line {
    width: 100%;
    height: 30px;
    text-align: center;
    background-position: center;
    background-size: 600px 30px;
}

.user-info-timeline-item-divider {
    width: 100%;
    margin: 10px 0px;
}

.user-info-timeline-item-divider-line {
    width: 100%;
    height: 30px;
    text-align: center;
    background-position: center;
    background-size: 600px 30px;
}

.user-info-timeline-item-time-value-day {
    display: inline-block;
    padding: 0px 15px;
    font-size: 24px;
    line-height: 30px;
    background-color: $almost-white;
}

.user-info-timeline-item-time-value-night {
    display: inline-block;
    padding: 0px 15px;
    font-size: 22px;
    line-height: 30px;
    background-color: $black;
}

.user-info-timeline-item-header {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
}

.user-info-timeline-item-header-small {
    width: 100%;
    text-align: left;
    padding: 0 10px;
    margin-bottom: 15px;
}

.user-info-timeline-item-content {
    width: 100%;
    text-align: left;
}

.user-info-timeline-item-content-small {
    width: 100%;
    text-align: left;
    padding: 0 10px;
}

.user-info-timeline-item-footer {
    width: 100%;
}

.user-info-timeline-item-title {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
}

.user-info-timeline-item-name {
    display: inline-block;
    vertical-align: bottom;
    padding-top: 2px;
}

.user-info-timeline-item-overview-container {
    margin-bottom: 10px;
}

.user-info-timeline-item-overview {
    line-height: 20px;
}

.user-info-timeline-item-overview-more {
    width: 100%;
    height: 12px;
    opacity: 0.4;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center 5px;
    background-size: auto 4px;
}

.user-info-timeline-item-overview-more:hover {
    opacity: 0.8;
}

.user-info-timeline-item-overview-less {
    height: 12px;
    margin-top: 2px;
    opacity: 0.3;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.user-info-timeline-item-overview-less:hover {
    opacity: 0.6;
}

.user-info-timeline-item-memo {
    width: 100%;
    text-align: center;
}

.user-info-timeline-item-memo-text-small,
.user-info-timeline-item-memo-text-medium,
.user-info-timeline-item-memo-text {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    padding: 10px 0px;
}

.user-info-timeline-item-memo-text-small {
    max-width: calc(100% - 40px);
}

.user-info-timeline-item-memo-text-medium {
    max-width: calc(90% - 40px);
}

.user-info-timeline-item-memo-text {
    max-width: calc(80% - 40px);
}

.user-info-timeline-item-memo-image {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    opacity: 0.7;
}

.user-info-timeline-item-taggees-container {
    text-align: right;
}

.user-info-timeline-item-taggees {
    display: inline-block;
    text-align: center;
    margin-top: 12px;
}

.user-info-timeline-item-taggee-profile-pics {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 3px 0px 3px;
}

.user-info-timeline-item-taggees-count {
    display: inline-block;
    vertical-align: middle;
    margin: 6px 3px 6px 0px;
}

.user-info-timeline-item-taggee-spacer {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.user-info-timeline-item-taggees-more {
    width: 100%;
    height: 11px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 26px auto;
}

.user-info-timeline-item-taggee-profile-pic {
    margin: 0px 2px;
}


/* 
============================================================================================
    Timeline Gallery
============================================================================================
*/

.user-info-timeline-item-gallery-container {
    position: relative;
    text-align: center;
}

.user-info-timeline-item-gallery-wrapper {
    position: relative;
    border-radius: 12px;
    margin: 0px auto;
    overflow: hidden;
}

.user-info-timeline-item-gallery-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    opacity: 0.5;
    pointer-events: none;
}

.user-info-timeline-item-gallery-video {}

.user-info-timeline-item-gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 2.0s ease-in-out;
    -webkit-transition: transform 2.0s ease-in-out;
    -moz-transition: transform 2.0s ease-in-out;
    -o-transition: transform 2.0s ease-in-out;
}

.user-info-timeline-item-gallery-image:hover {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
}


/* 
============================================================================================
    Timeline Gallery Navigation
============================================================================================
*/

.user-info-timeline-item-gallery-prev-arrow, 
.user-info-timeline-item-gallery-next-arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.0;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.user-info-timeline-item-gallery-prev-arrow {
    left: 0px;
    margin-left: 20px;
}

.user-info-timeline-item-gallery-next-arrow {
    right: 0px;
    margin-right: 20px;
}

.user-info-timeline-item-gallery-prev-arrow:hover,
.user-info-timeline-item-gallery-next-arrow:hover {
    opacity: 0.85;
}

.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-prev-arrow,
.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-next-arrow,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-prev-arrow,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-next-arrow {    
    opacity: 0.5;
}

.user-info-timeline-item-gallery-dot-on,
.user-info-timeline-item-gallery-dot-off {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;   
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;    
}

.user-info-timeline-item-gallery-dot-on {
    opacity: 0.8;
}

.user-info-timeline-item-gallery-dot-off {
    opacity: 0.4;
}

.user-info-timeline-item-gallery-dot-off:hover {
    opacity: 0.8;
}

.user-info-timeline-item-gallery-dots {
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    bottom: 4px;
    padding: 6px;
    z-index: 2;
    text-align: center;
}


/* 
============================================================================================
    Timeline Gallery (User Tag / Dot Tag / Unsave / Enlarge)
============================================================================================
*/


.user-info-timeline-item-gallery-dot-tag-image, 
.user-info-timeline-item-gallery-user-tag-image,
.user-info-timeline-item-gallery-unsave-image,
.user-info-timeline-item-gallery-enlarge-image,
.user-info-timeline-item-gallery-dot-tag-video,
.user-info-timeline-item-gallery-user-tag-video,
.user-info-timeline-item-gallery-unsave-video,
.user-info-timeline-item-gallery-enlarge-video {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.0 !important;
}

.user-info-timeline-item-gallery-dot-tag-video {
    top: 10px;
    left: 80px;
}

.user-info-timeline-item-gallery-user-tag-video {
    top: 10px;
    left: 45px;
}

.user-info-timeline-item-gallery-memo-video {
    top: 10px;
    left: 80px;
}

.user-info-timeline-item-gallery-unsave-video {
    top: 10px;
    right: 10px;
}

.user-info-timeline-item-gallery-enlarge-video {
    top: 10px;
    left: 10px;
}

.user-info-timeline-item-gallery-dot-tag-image {
    bottom: 10px;
    left: 45px;
}

.user-info-timeline-item-gallery-user-tag-image {
    bottom: 10px;
    left: 10px;
}

.user-info-timeline-item-gallery-memo-image {
    bottom: 10px;
    left: 80px;
}

.user-info-timeline-item-gallery-unsave-image {
    bottom: 10px;
    right: 10px;
}

.user-info-timeline-item-gallery-enlarge-image {
    top: 10px;
    left: 10px;
}

.user-info-timeline-item-gallery-dot-tag-image:hover,
.user-info-timeline-item-gallery-user-tag-image:hover,
.user-info-timeline-item-gallery-memo-image:hover,
.user-info-timeline-item-gallery-unsave-image:hover,
.user-info-timeline-item-gallery-enlarge-image:hover,
.user-info-timeline-item-gallery-dot-tag-video:hover,
.user-info-timeline-item-gallery-user-tag-video:hover,
.user-info-timeline-item-gallery-memo-video:hover,
.user-info-timeline-item-gallery-unsave-video:hover,
.user-info-timeline-item-gallery-enlarge-video:hover {
    opacity: 1.0 !important;
}

.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-dot-tag-video,
.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-user-tag-video,
.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-memo-video,
.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-unsave-video,
.user-info-timeline-item-gallery-video:hover ~ .user-info-timeline-item-gallery-enlarge-video,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-dot-tag-image,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-user-tag-image,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-memo-image,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-unsave-image,
.user-info-timeline-item-gallery-image:hover ~ .user-info-timeline-item-gallery-enlarge-image {
    opacity: 0.8 !important;
}


/*
============================================================================================
   Pagination
============================================================================================
*/

.user-info-bucket-pages-container {
    text-align: center;
    padding-bottom: 10px;
}

.user-info-bucket-pages {
    display: inline-block;
    vertical-align: middle;
}

.user-info-bucket-page {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    cursor: pointer;
    opacity: 0.8;
}

.user-info-bucket-page:hover {
    opacity: 1.0;
}

.user-info-bucket-previous, 
.user-info-bucket-next {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 6px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.3;
}

.user-info-bucket-previous:hover,
.user-info-bucket-next:hover {
    opacity: 0.6;
}


/*
============================================================================================
    Top Collection
============================================================================================
*/

#user-info-top-collection {
}

#user-info-top-collection-label {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
}

#user-info-top-collection-name {
    display: inline-block;
    vertical-align: bottom;
}

#user-info-top-collection-count {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
    margin-bottom: -3px;
    font-size: 24px;
}


/*
============================================================================================
    Bottom Collection
============================================================================================
*/

#user-info-bottom-collection {
    margin-bottom: 2px;
}

#user-info-bottom-collection-label {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 8px;
}

#user-info-bottom-collection-name {
    display: inline-block;
    vertical-align: bottom;
}

#user-info-bottom-collection-count {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
    margin-bottom: -3px;
    font-size: 24px;
}


/*
============================================================================================
    Top Menu
============================================================================================
*/

#user-info-top-menu-container-small {
    width: 100%;
}

#user-info-top-menu-container {
    width: 100%;
    min-width: 320px;
    padding: 0px 10px;
}

#user-info-top-menu-modes {
    width: 100%;
    white-space: nowrap;
    //-webkit-transition: height 0.5s ease-in-out;
    //-moz-transition: height 0.5s ease-in-out;
    //-o-transition: height 0.5s ease-in-out;
    //transition: height 0.5s ease-in-out;
    overflow: visible;
}

#user-info-top-menu-bucket-image {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 34px;
    margin-right: 10px;
}

#user-info-top-menu-bucket-image-small {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 26px;
    margin-right: 4px;
}

.user-info-top-menu-mode-small {
    font-size: 14px;
    padding: 2px 0px;
    margin: 2px 4px;
}

.user-info-top-menu-mode {
    font-size: 16px;
    padding: 3px 0px;
    margin: 2px 5px;
}

.user-info-top-menu-mode-selected-day {
    border-bottom: solid 2px $light-blue;
}

.user-info-top-menu-mode-selected-night {
    border-bottom: solid 2px $blue;
}

#user-info-top-menu-mode-saved,
#user-info-top-menu-mode-everyday,
#user-info-top-menu-mode-contributed {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

#user-info-top-menu-mode-display {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 34px;
    height: 34px;
    margin-left: 10px;
}

#user-info-top-menu-mode-display-small {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-left: 4px;
}


/*
============================================================================================
    Bottom Menu
============================================================================================
*/

#user-info-bottom-menu-container-small {
    display: block;
    position: fixed;
    width: 100%;
    min-width: 320px;
    padding: 10px 0px 12px 0px;
    left: 0px;
    bottom: 0px;
    z-index: 3;
    -webkit-transition: bottom 0.5s ease-in-out;
    -moz-transition: bottom 0.5s ease-in-out;
    -o-transition: bottom 0.5s ease-in-out;
    transition: bottom 0.5s ease-in-out;
}

#user-info-bottom-menu-container {
    display: block;
    position: fixed;
    width: 100%;
    min-width: 320px;
    padding: 10px 0px 12px 0px;
    left: 0px;
    bottom: 0px;
    z-index: 3;
    -webkit-transition: bottom 0.5s ease-in-out;
    -moz-transition: bottom 0.5s ease-in-out;
    -o-transition: bottom 0.5s ease-in-out;
    transition: bottom 0.5s ease-in-out;
}

#user-info-bottom-menu-modes {
    width: 100%;
    white-space: nowrap;
    /* display: inline-block; */
    /* border-radius: 20px; */
    /* margin: 0px auto 20px auto; */
}

#user-info-bottom-menu-bucket-image {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 34px;
    margin-right: 10px;
}

#user-info-bottom-menu-bucket-image-small {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 26px;
    margin-right: 4px;
}

.user-info-bottom-menu-mode-small {
    font-size: 14px;
    padding: 2px 0px;
    margin: 2px 4px;
}

.user-info-bottom-menu-mode {
    font-size: 16px;
    padding: 3px 0px;
    margin: 2px 5px;
}

.user-info-bottom-menu-mode-selected-day {
    border-bottom: solid 2px $light-blue;
}

.user-info-bottom-menu-mode-selected-night {
    border-bottom: solid 2px $blue;
}

#user-info-bottom-menu-mode-saved,
#user-info-bottom-menu-mode-everyday,
#user-info-bottom-menu-mode-contributed {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

#user-info-bottom-menu-mode-display {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 34px;
    height: 34px;
    margin-left: 10px;
}

#user-info-bottom-menu-mode-display-small {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-left: 4px;
}


/*
============================================================================================
    Collection Menu
============================================================================================
*/

.user-info-collection-top-menu-container,
.user-info-collection-top-menu-container-small {
    position: absolute;
    top: 100%;
    left: 50%;
    overflow: visible;
    z-index: 20;
}

.user-info-collection-top-menu,
.user-info-collection-top-menu-small {
    width: 100px;
    margin: 5px 0px 0px -50px;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    text-align: center;
}

.user-info-collection-bottom-menu-container,
.user-info-collection-bottom-menu-container-small {
    position: absolute;
    margin-top: -40px;
    bottom: 100%;
    left: 50%;
    overflow: visible;
    z-index: 20;
}

.user-info-collection-bottom-menu,
.user-info-collection-bottom-menu-small {
    width: 100px;
    margin: 0px 0px 0px -50px;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    text-align: center;
}

.user-info-collection-menu-tags-row {
    display: block;
    width: 100%;
    padding: 4px 0px;
    white-space: normal;
}

.user-info-collection-menu-tags-selected-item {
    display: block;
    width: 100%;
    cursor: pointer;
    opacity: 1.0;
}

.user-info-collection-menu-tags-item {
    display: block;
    width: 100%;
    cursor: pointer;
    opacity: 0.5;
}

.user-info-collection-menu-tags-item:hover {
    opacity: 0.75;
}


/*
============================================================================================
    Right Menu
============================================================================================
*/

#user-info-right-menu-container {
    display: inline-block;
    vertical-align: middle;
    width: 160px;
    margin: 0px 0px 40px 25px;
    text-align: right;
}


/*
============================================================================================
    Time Bar
============================================================================================
*/

#user-time-bar-container {
    width: 600px;
    margin: 10px auto 20px auto;
}

#user-time-bar-container-small {
    width: 80%;
    margin: 10px auto 20px auto;
}

#user-time-bar-line {
    position: relative;
    width: 100%;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2400px 60px;
}

.user-time-bar-dot-top {
    position: absolute;
    width: 120px;
    text-align: center;
    bottom: calc(50% - 11px);
    transform: translate(-50%, 0%);
}

.user-time-bar-dot-bottom {
    position: absolute;
    width: 120px;
    text-align: center;
    top: calc(50% - 13px);
    transform: translate(-50%, 0%);
}

.user-time-bar-dot-image {
    display: block !important;
    margin: 0px auto !important;
    width: 24px;
    height: 24px;
    margin: 0px auto;
}

#user-time-bar-image {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -36px;
    top: calc(50% - 15px);
    vertical-align: middle;
}

#user-time-bar-title-container {
    position: absolute;
    top: 0px;
    left: 0px;
}

#user-time-bar-title-image {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

#user-time-bar-title {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    margin-left: 8px;
}

