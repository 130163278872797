@import "../../css/_variables";
@import "../../css/_mixins";

.follow-modal {
    @include modal-background;
}

.follow-modal-content {
    @include modal-follow;
    width: 300px;
}

.follow-modal-content__user-myself {
	width: 130px;
}

.follow-modal-content__user {
	width: 200px;
}

.follow-modal-content__action {
	width: 70px;
}