/*
============================================================================================
    National Park Map
============================================================================================
*/

#national-park-map {
    /* This fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

#national-park-set-map {

}

/*
============================================================================================
    Map Height Buttons
============================================================================================
*/

.national-park-map-height-buttons {
    bottom: .5em;
    left: .5em;
}

.national-park-map-height-buttons button {
    line-height: 1.5em;
}

/*
============================================================================================
    Map Boundary Fit Buttons
============================================================================================
*/

.national-park-map-boundary-fit-buttons {
    top: .5em;
    right: .5em;
}

.national-park-map-boundary-fit-buttons button {
    line-height: 0.0em;
    background-position: 0.0em 0.0em;
}


/*
============================================================================================
    Map Dot Fit Buttons
============================================================================================
*/

.national-park-map-dot-fit-buttons {
    top: .5em;
    right: 2.75em;
}

.national-park-map-dot-fit-buttons button {
    line-height: 0.0em;
    background-position: 0.0em 0.05em;;
}


/*
============================================================================================
    Marker infomation
============================================================================================
*/
/*
.national-park-map-marker {
    position: absolute !important;
    width: 0px !important;
    height: 0px !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
*/
.national-park-map-marker-information-hover-off {
    z-index: 105;
}

.national-park-map-marker-information-hover-on {
    z-index: 106;
}

/*
============================================================================================
    Marker Media
============================================================================================
*/

.national-park-map-marker-media {
    position: absolute !important;
    border-radius: 50% !important;
    border: solid 1px #ddd;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transform: translate(-50%, -50%);
    -webkit-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    -moz-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    -o-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
}

.national-park-map-marker-media-off {
    width: 50px !important;
    height: 50px !important;
}

.national-park-map-marker-media-on {
    width: 100px !important;
    height: 100px !important;
}

/*
============================================================================================
    Marker Name
============================================================================================
*/

.national-park-map-marker-name {
    position: absolute !important;
    top: 55px;
    left: 0px;
    border-radius: 12px;
    padding: 5px 12px;
    margin: 0px auto;
    background-color: rgba(0, 0, 0, 0.65);
    transform: translate(-50%, 0%);
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
    white-space: pre;
}

.national-park-map-marker-name-off {
    opacity: 0.0;
}

.national-park-map-marker-name-on {
    opacity: 1.0;
}


/*
============================================================================================
    Marker Pulse Blue
============================================================================================
*/

.national-park-map-user-marker {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.national-park-map-marker-pulse-red,
.national-park-map-marker-pulse-blue,
.national-park-map-marker-pulse-black {
    position: relative;
    overflow: visible;
    width: 28px;
    height: 28px;
    opacity: 1.0;
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-duration: 4.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-duration: 4.0s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
}

.national-park-map-marker-pulse-red {
    -webkit-animation-name: national-park-map-marker-pulse-red;
    -moz-animation-name: national-park-map-marker-pulse-red;
}

.national-park-map-marker-pulse-blue {
    -webkit-animation-name: national-park-map-marker-pulse-blue;
    -moz-animation-name: national-park-map-marker-pulse-blue;
}

.national-park-map-marker-pulse-black {
    -webkit-animation-name: national-park-map-marker-pulse-black;
    -moz-animation-name: national-park-map-marker-pulse-black;
}

@-webkit-keyframes national-park-map-marker-pulse-red {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}

@keyframes national-park-map-marker-pulse-red {
    0% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16,1.0), 0 0 0 15px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(175,20,16,0.25), 0 0 0 0px rgba(175,20,16, 1.0);
        box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);
        box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);
        box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}

@-webkit-keyframes national-park-map-marker-pulse-blue {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 7px rgba(16,117,175, 0.75), 0 0 0 28px rgba(16,117,175, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 14px rgba(16,117,175, 0.5), 0 0 0 0px rgba(16,117,175, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 21px rgba(16,117,175, 0.25), 0 0 0 0px rgba(16,117,175,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 28px rgba(16,117,175, 0.0), 0 0 0 7px rgba(16,117,175, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(16,117,175, 0.0), 0 0 0 14px rgba(16,117,175, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);}
}

@keyframes national-park-map-marker-pulse-blue {
    0% {-moz-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);
        box-shadow: 0 0 0 0px rgba(16,117,175 1.0), 0 0 0 15px rgba(16,117,175, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(16,117,175, 0.75), 0 0 0 28px rgba(16,117,175, 0.0);
        box-shadow: 0 0 0 7px rgba(16,117,175, 0.75), 0 0 0 20px rgba(16,117,175, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(16,117,175, 0.5), 0 0 0 0px rgba(16,117,175, 0.0);
        box-shadow: 0 0 0 14px rgba(16,117,175, 0.5), 0 0 0 0px rgba(16,117,175, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(16,117,175, 0.25), 0 0 0 0px rgba(16,117,175,1.0);
        box-shadow: 0 0 0 21px rgba(16,117,175, 0.25), 0 0 0 0px rgba(16,117,175,1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(16,117,175, 0.0), 0 0 0 7px rgba(16,117,175, 0.75);
        box-shadow: 0 0 0 28px rgba(16,117,175, 0.0), 0 0 0 5px rgba(16,117,175, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(16,117,175, 0.0), 0 0 0 14px rgba(16,117,175, 0.5);
        box-shadow: 0 0 0 0px rgba(16,117,175, 0.0), 0 0 0 10px rgba(16,117,175, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);
        box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 15px rgba(16,117,175, 0.25);}
}

@-webkit-keyframes national-park-map-marker-pulse-black {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}

@keyframes national-park-map-marker-pulse-black {
    0% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0,1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(0,0,0,0.25), 0 0 0 0px rgba(0,0,0, 1.0);
        box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);
        box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);
        box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}


/*
============================================================================================
    create Box
============================================================================================
*/

.national-park-map-create-box {
    text-align: center;
    padding: 5px 2px;
    transform: translate(0%, -35%);
    border: 3px solid rgba(255, 255, 255, 0.50);
    background-color: rgba(0, 60, 100, 0.75);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    border-radius: 12px;
}

.national-park-map-create-box-text {
    padding: 0px 6px;
    margin-bottom: 6px;
}

.national-park-map-create-answer-container {
    white-space: nowrap;
    margin-bottom: 2px;
}

.national-park-map-create-answer-button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 60px;
    padding: 3px 0px;
    margin: 0px 4px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    opacity: 1.0;
    background-color: rgba(255, 255, 255, 0.25);
}

.national-park-map-create-answer-button:hover {
    background-color: rgba(255, 255, 255, 0.4);
}


/*
============================================================================================
    Near by search Box
============================================================================================
*/

.national-park-map-near-by-search-box {
    text-align: center;
    padding: 6px 6px;
    white-space: nowrap;
    transform: translate(0%, -30%);
    border: 3px solid rgba(255, 255, 255, 0.50);
    border-radius: 12px;
    background-color: rgba(0, 60, 100, 0.75);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.national-park-map-near-by-search-box-text {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 6px;
}

.national-park-map-near-by-search-answer-container {
    display: inline-block;
    vertical-align: middle;
}

.national-park-map-near-by-search-answer-button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 3px 10px;
    margin-left: 2px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    opacity: 1.0;
    background-color: rgba(255, 255, 255, 0.25);
}

.national-park-map-near-by-search-answer-button:hover {
    background-color: rgba(255, 255, 255, 0.4);
}
