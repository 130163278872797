/*
@import "../../css/_variables";
@import "../../css/_mixins";
*/

/*
============================================================================================
    Open Map
============================================================================================
*/

#search-open-map-container {
    display: inline-block;
    padding: 5px;
}

#search-open-map-left-column {
    display: inline-block;
}

#search-open-map {
    position: relative;
    display: inline-block;
    overflow: hidden;
    /* This fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

#search-open-map-small {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    /* This fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

#search-open-set-map {

}

#search-open-set-map-border {
    border-radius: 10px;
    overflow: hidden;
}


/*
============================================================================================
    Map Height Buttons
============================================================================================
*/

.search-open-map-height-buttons {
    bottom: .5em;
    left: .5em;
}

.search-open-map-height-buttons button {
    line-height: 0.0em;
}

/*
============================================================================================
    Map Function Buttons
============================================================================================
*/

.search-open-map-function-buttons {
    top: .5em;
    right: .5em;
}

.search-open-map-function-buttons button {
    line-height: 0.0em;
}

/*
============================================================================================
    Marker infomation
============================================================================================
*/
/*
.search-open-map-marker {
    position: absolute !important;
    width: 0px !important;
    height: 0px !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
*/
.search-open-map-marker-information-hover-off {
    z-index: 105;
}

.search-open-map-marker-information-hover-on {
    z-index: 106;
}

/*
============================================================================================
    Marker Media
============================================================================================
*/

.search-open-map-marker-media {
    position: absolute !important;
    border-radius: 50% !important;
    border: solid 1px #ddd;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    transform: translate(-50%, -50%);
    -webkit-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    -moz-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    -o-transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
    transition: width 0.15s ease-in-out, height 0.15s ease-in-out;
}

.search-open-map-marker-media-off {
    width: 60px !important;
    height: 60px !important;
}

.search-open-map-marker-media-on {
    width: 120px !important;
    height: 120px !important;
}

/*
============================================================================================
    Marker Name
============================================================================================
*/

.search-open-map-marker-name {
    position: absolute !important;
    top: 65px;
    left: 0px;
    border-radius: 12px;
    padding: 5px 12px;
    margin: 0px auto;
    background-color: rgba(0, 0, 0, 0.65);
    transform: translate(-50%, 0%);
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
}

.search-open-map-marker-name-off {
    opacity: 0.0;
}

.search-open-map-marker-name-on {
    opacity: 1.0;
}


/*
============================================================================================
    Marker Pulse Blue
============================================================================================
*/

.search-open-map-user-marker {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.search-open-map-marker-pulse-red,
.search-open-map-marker-pulse-blue,
.search-open-map-marker-pulse-black {
    position: relative;
    overflow: visible;
    width: 28px;
    height: 28px;
    opacity: 1.0;
    border-radius: 50%;
    cursor: pointer;
    -webkit-animation-duration: 4.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-duration: 4.0s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
}

.search-open-map-marker-pulse-red {
    -webkit-animation-name: search-open-map-marker-pulse-red;
    -moz-animation-name: search-open-map-marker-pulse-red;
}

.search-open-map-marker-pulse-blue {
    -webkit-animation-name: search-open-map-marker-pulse-blue;
    -moz-animation-name: search-open-map-marker-pulse-blue;
}

.search-open-map-marker-pulse-black {
    -webkit-animation-name: search-open-map-marker-pulse-black;
    -moz-animation-name: search-open-map-marker-pulse-black;
}

@-webkit-keyframes search-open-map-marker-pulse-red {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}

@keyframes search-open-map-marker-pulse-red {
    0% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16,1.0), 0 0 0 15px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 5px rgba(175,20,16, 0.75), 0 0 0 20px rgba(175,20,16, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);
        box-shadow: 0 0 0 10px rgba(175,20,16, 0.5), 0 0 0 0px rgba(175,20,16, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(175,20,16,0.25), 0 0 0 0px rgba(175,20,16, 1.0);
        box-shadow: 0 0 0 15px rgba(175,20,16, 0.25), 0 0 0 0px rgba(175,20,16, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);
        box-shadow: 0 0 0 20px rgba(175,20,16, 0.0), 0 0 0 5px rgba(175,20,16, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);
        box-shadow: 0 0 0 0px rgba(175,20,16, 0.0), 0 0 0 10px rgba(175,20,16, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);
        box-shadow: 0 0 0 0px rgba(175,20,16, 1.0), 0 0 0 15px rgba(175,20,16, 0.25);}
}

@-webkit-keyframes search-open-map-marker-pulse-blue {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 7px rgba(16,117,175, 0.75), 0 0 0 28px rgba(16,117,175, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 14px rgba(16,117,175, 0.5), 0 0 0 0px rgba(16,117,175, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 21px rgba(16,117,175, 0.25), 0 0 0 0px rgba(16,117,175,1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 28px rgba(16,117,175, 0.0), 0 0 0 7px rgba(16,117,175, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(16,117,175, 0.0), 0 0 0 14px rgba(16,117,175, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);}
}

@keyframes search-open-map-marker-pulse-blue {
    0% {-moz-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);
        box-shadow: 0 0 0 0px rgba(16,117,175 1.0), 0 0 0 15px rgba(16,117,175, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(16,117,175, 0.75), 0 0 0 28px rgba(16,117,175, 0.0);
        box-shadow: 0 0 0 7px rgba(16,117,175, 0.75), 0 0 0 20px rgba(16,117,175, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(16,117,175, 0.5), 0 0 0 0px rgba(16,117,175, 0.0);
        box-shadow: 0 0 0 14px rgba(16,117,175, 0.5), 0 0 0 0px rgba(16,117,175, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(16,117,175, 0.25), 0 0 0 0px rgba(16,117,175,1.0);
        box-shadow: 0 0 0 21px rgba(16,117,175, 0.25), 0 0 0 0px rgba(16,117,175,1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(16,117,175, 0.0), 0 0 0 7px rgba(16,117,175, 0.75);
        box-shadow: 0 0 0 28px rgba(16,117,175, 0.0), 0 0 0 5px rgba(16,117,175, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(16,117,175, 0.0), 0 0 0 14px rgba(16,117,175, 0.5);
        box-shadow: 0 0 0 0px rgba(16,117,175, 0.0), 0 0 0 10px rgba(16,117,175, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 21px rgba(16,117,175, 0.25);
        box-shadow: 0 0 0 0px rgba(16,117,175, 1.0), 0 0 0 15px rgba(16,117,175, 0.25);}
}

@-webkit-keyframes search-open-map-marker-pulse-black {
    0% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-webkit-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-webkit-box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-webkit-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-webkit-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}

@keyframes search-open-map-marker-pulse-black {
    0% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0,1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
    16.67% {-moz-box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 5px rgba(0,0,0, 0.75), 0 0 0 20px rgba(0,0,0, 0.0);}
    33.33% {-moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);
        box-shadow: 0 0 0 10px rgba(0,0,0, 0.5), 0 0 0 0px rgba(0,0,0, 0.0);}
    50% {-moz-box-shadow: 0 0 0 15px rgba(0,0,0,0.25), 0 0 0 0px rgba(0,0,0, 1.0);
        box-shadow: 0 0 0 15px rgba(0,0,0, 0.25), 0 0 0 0px rgba(0,0,0, 1.0);}
    66.67% {-moz-box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);
        box-shadow: 0 0 0 20px rgba(0,0,0, 0.0), 0 0 0 5px rgba(0,0,0, 0.75);}
    83.33% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);
        box-shadow: 0 0 0 0px rgba(0,0,0, 0.0), 0 0 0 10px rgba(0,0,0, 0.5);}
    100% {-moz-box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);
        box-shadow: 0 0 0 0px rgba(0,0,0, 1.0), 0 0 0 15px rgba(0,0,0, 0.25);}
}


/*
============================================================================================
    Search Box
============================================================================================
*/

#search-open-map-search-box {
    position: absolute;
    text-align: center;
    white-space: nowrap;
    bottom: 0.5em;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 4px 2px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 60, 136, 0.4);
    border-radius: 6px;
}

#search-open-map-search-input {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    height: 26px;
    padding: 5px 0px;
    border: none;
    border-radius: 5px;
    opacity: 1.0;
}

.search-open-map-search-input {}

#search-open-map-search-button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 60px;
    height: 24px;
    padding: 3px 0px;
    margin-left: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 1.0;
}

.search-open-map-search-button-on {
    background-color: rgba(20, 190, 255, 0.4);
    /*background-color: rgba(20, 212, 255, 0.4);*/
}

.search-open-map-search-button-on:hover {
    background-color: rgba(20, 190, 255, 0.5);
    /*background-color: rgba(20, 212, 255, 0.5);*/
}

.search-open-map-search-button-off {
    background-color: rgba(255, 255, 255, 0.3);
}

.search-open-map-search-button-off:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

/*
============================================================================================
    create Box
============================================================================================
*/

.search-open-map-create-box {
    text-align: center;
    padding: 4px 2px;
    transform: translate(0%, -35%);
    border: 3px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 60, 136, 0.4);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    border-radius: 12px;
    width: 180px;
    height: 100px;
}

.search-open-map-create-box-text {
    display: inline-block;
    text-align: center;
    width: 160px;
    height: 40px;
    margin: 5px 0px;
}

.search-open-map-create-answer-container {
    margin: 5px 0px;
}
.search-open-map-create-answer-button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 70px;
    height: 30px;
    padding: 3px 0px;
    margin: 0px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 1.0;
    background-color: rgba(255, 255, 255, 0.25);
}

.search-open-map-create-answer-button:hover {
    background-color: rgba(255, 255, 255, 0.4);
}
