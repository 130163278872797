@import "../../css/_variables";
@import "../../css/_mixins";


/* 
============================================================================================
    Log In Simple Menu
============================================================================================
*/

#log-in-simple-modal-container {
    @include modal-background;
}

#log-in-simple-modal {
    @include modal-minimal;
    width: 300px;
    padding-bottom: 25px !important;
}

#log-in-simple-modal-mobile {
    @include modal-minimal;
    width: calc(100% - 20px);
    min-width: 300px;
    max-width: 320px;
}

.log-in-simple-row {
    width: 100%;
    box-sizing: border-box;
}

#log-in-simple-facebook-container {
    display: inline-block;
    box-sizing: border-box;
}

#log-in-simple-google-container {
    display: inline-block;
    box-sizing: border-box;
}

#log-in-simple-form {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

#log-in-simple-facebook-container-active {
    width: 600px;
    margin: 0px auto;
    text-align: center;
}

#log-in-simple-google-container-active {
    width: 600px;
    margin: 0px auto;
    text-align: center;  
}

#log-in-simple-form-active {
    width: 600px;
    margin: 0px auto;
    text-align: center;  
}

#log-in-simple-facebook-button {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    float: right;
}

#log-in-simple-facebook-image {
    width: 100%;
    height: 100%;
}

#log-in-simple-google-image {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    float: left;
}

#log-in-simple-warning,
#log-in-simple-facebook-google-warning {
    margin-top: 6px;
}

#log-in-simple-sign-up {
    margin-top: 10px;
    cursor: pointer;
    opacity: 0.6;
}

#log-in-simple-sign-up:hover {
    opacity: 1.0;
}